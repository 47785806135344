// database.service.ts
//
// Handle database queries, including caching.
// TODO: Clean up database service, breaking it apart.

// ANGULAR
import {Injectable} from "@angular/core";

// FIREBASE
import {FirebaseApp} from "angularfire2";
import {
  AngularFireDatabase,
  FirebaseListObservable,
  FirebaseObjectObservable,
} from "angularfire2/database-deprecated";
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

// MONOREPO
import {
  AfValue,
  Layout,
  Project,
  ProjectResults,
  ProjectTemplate,
  ReportDefinitionHash,
  Summary,
  Unit,
  UnitResults,
  User,
  calculateDashboard,
  calculateProgress,
} from "@nims/red-shared";

import {forEach} from "@nims/jsutils";
import {once} from "@nims/afutils";

import {IdbService} from "../idb.service";

const PROJECTS_PATH = "projects";
const SUMMARIES_PATH = "/summaries";
const TEMPLATES_PATH = "templates";
const USERS_PATH = "users";
const REPORT_DEFINITIONS_PATH = "report-definitions";

const LOG = false;
const MODULE_NAME = "console\\database.service";

type List<T> = FirebaseListObservable<T[]>;
type Obj<T> = FirebaseObjectObservable<T>;

const subscriberQuery = subscriber => ({query: {orderByChild: "subscriber", equalTo: subscriber}});

@Injectable()
export class DatabaseService {
  private summariesListObservable: List<Summary>;

  private projectObjectObservables: {[projectId: string]: Obj<Project>} = {};
  private projectResultsObjectObservables: {[projectId: string]: Obj<ProjectResults>} = {};
  private projectResultsUnitListObservables: {[projectId: string]: List<UnitResults>} = {};
  private summaryObjectObservables: {[projectId: string]: Obj<Summary>} = {};
  private projectResultsUnitObjectObservables: {
    [projectId: string]: {[unitId: string]: Obj<UnitResults>};
  } = {};
  private projectChangedAtObservables: {[projectId: string]: Obj<number>} = {};
  private projectUnitsObjectObservables: {[projectId: string]: any} = {};

  private projectsRef: DatabaseReference;
  private summariesRef: DatabaseReference;
  private usersRef: DatabaseReference;
  private templatesRef: DatabaseReference;

  constructor(
    private afdb: AngularFireDatabase,
    private idbService: IdbService,
    readonly firebaseApp: FirebaseApp
  ) {
    const db = firebaseApp.database();

    this.projectsRef = db.ref(PROJECTS_PATH);
    this.summariesRef = db.ref(SUMMARIES_PATH);
    this.usersRef = db.ref(USERS_PATH);
    this.templatesRef = db.ref(TEMPLATES_PATH);
  }

  // PRE-CALCULATED DATA
  public getProjectChangedAt(projectId) {
    return (this.projectChangedAtObservables[projectId] =
      this.projectChangedAtObservables[projectId] ||
      this.afdb.object(this.projectChangedAtRef(projectId)));
  }

  public getUserList(): FirebaseListObservable<AfValue<User>[]> {
    return this.afdb.list(USERS_PATH);
  }
  public getSubscriberUserList(subscriber: string): FirebaseListObservable<AfValue<User>[]> {
    return this.afdb.list(USERS_PATH, subscriberQuery(subscriber) as any);
  }

  public getUsersObject() {
    return this.afdb.object(USERS_PATH);
  }
  public getUserObject(id) {
    return this.afdb.object(this.userRef(id));
  }
  public getUserObjectP(id) {
    return once<User>(this.userRef(id));
  }

  public getTemplateList() {
    return this.afdb.list(this.templatesRef);
  }
  public getTemplateListBySubscriber(
    subscriber: string
  ): FirebaseListObservable<AfValue<ProjectTemplate>[]> {
    return this.afdb.list(this.templatesRef, subscriberQuery(subscriber) as any);
  }
  public getTemplateObject() {
    return this.afdb.object(this.templatesRef);
  }

  // PROJECTS
  // Obtain Firebase observable of a project.
  public getProjectObject(projectId) {
    if (LOG)
      console.log(
        `${MODULE_NAME}#getProjectObject: possible performance impact: getting entire project including all results.`
      );

       return (this.projectObjectObservables[projectId] =
         this.projectObjectObservables[projectId] || this.afdb.object(this.projectRef(projectId)));
  }

  // Obtain Firebase observable of a project's results.
  public getProjectResultsObject(projectId) {
    if (LOG)
      console.log(
        `${MODULE_NAME}#getProjectResultsObjecdt: possible performance impact: getting entire project results.`
      );

    return (this.projectResultsObjectObservables[projectId] =
      this.projectResultsObjectObservables[projectId] ||
      this.afdb.object(this.resultsRef(projectId)));
  }

  // Obtain Firebase observable of list of unit results in a project.
  public getProjectResultsUnitList(projectId) {
    return (this.projectResultsUnitListObservables[projectId] =
      this.projectResultsUnitListObservables[projectId] ||
      this.afdb.list(this.resultsUnitsRef(projectId)));
  }

  public getProjectUnitsObject(projectId) {
    return (this.projectUnitsObjectObservables[projectId] =
      this.projectUnitsObjectObservables[projectId] ||
      this.afdb.object(this.projectUnitsRef(projectId)));
  }

  // Get the results for a single unit.
  // Since getting results for all units is slow, we may prefer to get them one at a time.
  public getProjectResultsUnitObject(projectId, unitId) {
    const project = (this.projectResultsUnitObjectObservables[projectId] =
      this.projectResultsUnitObjectObservables[projectId] || {});

    return (project[unitId] =
      project[unitId] || this.afdb.object(this.resultsUnitRef(projectId, unitId)));
  }

  // Get observable of list of all summaries.
  public getSummariesList() {
    return (this.summariesListObservable =
      this.summariesListObservable || this.afdb.list(this.summariesRef));
  }

  public getSummariesListBySubscriber(subscriber: string) {
    return this.afdb.list(this.summariesRef, subscriberQuery(subscriber) as any);
  }

  public getSummariesObject() {
    return this.afdb.object(this.summariesRef);
  }

  // Get observable of one summary.
  public getSummaryObject(projectId) {
    return (this.summaryObjectObservables[projectId] =
      this.summaryObjectObservables[projectId] || this.afdb.object(this.summaryRef(projectId)));
  }

  public getLayoutsObject(projectId): Obj<Layout> {
    return this.afdb.object(this.layoutsRef(projectId));
  }

  public getUnitsList(projectId): List<Unit> {
    return this.afdb.list(this.unitsRef(projectId));
  }

  // Calculate dashboard data.
  public calculateDashboardData(projectId) {
    const METHOD_NAME = `${MODULE_NAME}#calculateDashboardData`;
    let projectName;
    this.summaryRef(projectId)
      .once("value")
      .then(summarySnapshot => {
        const summary = summarySnapshot.val();
        const {name} = summary;
        projectName = name;
      })
    if (LOG) console.log(`${METHOD_NAME}: enter`);
    return this.projectRef(projectId)
      .once("value")
      .then(projectSnapshot => {
        if (LOG) console.log(`${METHOD_NAME}: got data`);
        const project = projectSnapshot.val();
        const {results, units} = project;

        console.assert(!!results, `${METHOD_NAME}: project must have results`);

        const progress = calculateProgress(results, units);
        const dashboard = calculateDashboard(results, projectName);
        const changedAt = +new Date();

        if (LOG) console.log(`${MODULE_NAME}#calculateDashboardData: leave`);

        return {projectId, changedAt, dashboard, progress};
      });
  }

  // Get dashboard data, either precalculated or newly calculated.
  // If it does not exist, calculate and store into IDB.
  public async getDashboardData(projectId) {
    return (
      (await this.getPrecalculatedDashboardData(projectId)) ||
      (await this.calculateAndStoreDashboardData(projectId))
    );
  }

  // Get a project's precalculated data from the IDB.
  public getPrecalculatedDashboardData(projectId) {
    return this.idbService.getDashboardData(projectId).catch(e => null);
  }

  public async calculateAndStoreDashboardData(projectId) {
    const data = await this.calculateDashboardData(projectId);

    this.idbService.setDashboardData(data);

    return data;
  }

  // REPORT DEFINITIONS ON SUMMARY
  // Reports for including in visual reports, and for exposing to customers.

  // Get an AngularListObservable of a project's report definitions,
  // for inclusion in visual report.
  // TODO: check that `string` is correct here.
  public getSummaryReportDefinitionsList(projectId): FirebaseListObservable<any[]> {
    return this.afdb.list(this.summaryRef(projectId).child("reportDefinitions"));
  }

  public getSummaryReportDefinitionsObject(
    projectId
  ): FirebaseObjectObservable<ReportDefinitionHash> {
    return this.afdb.object(this.summaryRef(projectId).child("reportDefinitions"));
  }

  // TODO: check return type.
  public getCustomerReportDefinitionsList(projectId): FirebaseListObservable<any[]> {
    return this.afdb.list(this.summaryRef(projectId).child("customerReportDefinitions"));
  }

  public getCustomerReportDefinitionsObject(
    projectId
  ): FirebaseObjectObservable<ReportDefinitionHash> {
    return this.afdb.object(this.summaryRef(projectId).child("customerReportDefinitions"));
  }

  // If a report definition is deleted, we want to remove it from projects.
  public removeReportDefinitionsFromSummary(reportDefinitionId) {
    this.summariesRef.transaction(
      summaries => {
        if (!summaries) {
          console.warn("Empty summaries in database.service#removeReportDefinitionsFromSummary");
          return 0;
        }

        forEach(summaries, (summary: Summary) => {
          delete (summary.reportDefinitions || {})[reportDefinitionId];
          delete (summary.customerReportDefinitions || {})[reportDefinitionId];
          if (summary.defaultReportDefinition === reportDefinitionId)
            delete summary.defaultReportDefinition;
        });
        return summaries;
      },
      function(error, committed, snapshot) {},
      true
    );
  }

  public getReportDefinitionsObject() {
    return this.afdb.object(REPORT_DEFINITIONS_PATH);
  }
  public getReportDefinitionsList() {
    return this.afdb.list(REPORT_DEFINITIONS_PATH);
  }
  public getReportDefinitionsListBySubscriber(subscriber: string) {
    return this.afdb.list(REPORT_DEFINITIONS_PATH, subscriberQuery(subscriber) as any); // Hack for conflicting types
  }
  public getReportDefinitionObject(id) {
    return this.afdb.object(`${REPORT_DEFINITIONS_PATH}/${id}`);
  }

  // Firebase database refs.
  private projectRef(projectId) {
    return this.projectsRef.child(projectId);
  }
  private summaryRef(projectId) {
    return this.summariesRef.child(projectId);
  }

  private unitsRef(projectId) {
    return this.projectRef(projectId).child("units");
  }
  private layoutsRef(projectId) {
    return this.projectRef(projectId).child("layouts");
  }

  private resultsRef(projectId) {
    return this.projectRef(projectId).child("results");
  }
  private resultsUnitsRef(projectId) {
    return this.resultsRef(projectId).child("units");
  }

  private resultsUnitRef(projectId, unitId) {
    return this.resultsUnitsRef(projectId).child(unitId);
  }

  private userRef(userId) {
    return this.usersRef.child(userId);
  }

  private projectChangedAtRef(projectId) {
    return this.projectRef(projectId).child("changedAt");
  }
  private projectUnitsRef(projectId) {
    return this.projectRef(projectId).child("units");
  }

}
