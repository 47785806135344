// @nims/console/src/app/pods/project-units/project-units.component.ts
//
// TS logic for screen showing the units in a project.

import {Component, OnInit, OnDestroy, Input, ViewContainerRef} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable, Subscription, combineLatest} from "rxjs";
import {map, switchMap, take} from "rxjs/operators";
import {FirebaseListObservable} from "angularfire2/database-deprecated";
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

// THIRD PARTY
import {SelectItem} from "primeng/primeng";

import {
  Hash,
  uniq,
  filter,
  map as mapObject,
  length,
  join2,
  forEach,
  parseMadlib,
} from "@nims/jsutils";

import {findByKey, once} from "@nims/afutils";

import {ConfirmRemoveService} from "@nims/ngutils";

import {
  AfValue,
  Aspect,
  Description,
  Item,
  ItemResults,
  Layout,
  ModeHash,
  Reading,
  Room,
  RoomResults,
  Section,
  SnaggingStatus,
  Terms,
  Unit,
  UnitResults,
  UnitStatus,
  defaultReading,
  getProjectItemArtifactSet,
  normalizeItemResults,
  trackByKey,
} from "@nims/red-shared";

import {UserService} from "../../services";

type List<T> = FirebaseListObservable<AfValue<T>[]>;

type Units = Hash<Unit>;

const SNACK_BAR_DURATION = 3000;

@Component({
  selector: "project-units",
  templateUrl: "./project-units.component.html",
  styleUrls: ["./project-units.component.css"],
})
export class ProjectUnitsComponent implements OnInit, OnDestroy {
  @Input()
  units: List<Unit>;
  @Input()
  layouts: List<Layout>;
  @Input()
  sections: List<Section>;
  @Input()
  rooms: List<Room>;
  @Input()
  items: List<Item>;
  @Input()
  aspects: List<Aspect>;
  @Input()
  readings: List<Reading>;
  @Input()
  descriptions: List<Description>;
  @Input()
  terms: Terms;
  @Input()
  noSnagPriorities: boolean;

  // Does this project have no desnagging or fixing?
  // If so, hide options to put units into fixing or desnagging mode.
  @Input()
  modes: ModeHash<boolean>;

  public editable = false;
  public help = false;
  public unitsWithLayoutNames;
  public sectors: Observable<any>;
  public selections: Unit[];
  public layoutOptions: Observable<SelectItem[]>;
  public showRecentFirst = true;
  public trackByUnit = trackByKey;

  // Error message to be displayed in the error component.
  public error: string;

  private currentUnits: Unit[];
  private unitsSubscription;

  // This default value may be overwritten by the value from the database,
  // well before we try to use it during "prepare".
  private readingsValue: AfValue<Reading>[] = [{...defaultReading(), $key: "any", $exists: true}];
  private readingsSubscription: Subscription;

  // Object indexed by unit, containing inspection details.
  // Updated each time row is expanded.
  private unitDetails = {};

  constructor(
    private confirmRemoveService: ConfirmRemoveService,
    private matSnackBar: MatSnackBar,
    private userService: UserService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    function uniqueSectors(units: Unit[]) {
      return uniq(units.map(unit => unit.sector || ""))
        .filter(Boolean)
        .map(sector => ({label: sector, value: sector}));
    }

    this.unitsWithLayoutNames = this.makeUnitsWithLayoutNames();
    this.unitsSubscription = this.units.subscribe(units => (this.currentUnits = units));

    this.layoutOptions = this.layouts.pipe(
      map(layouts => [
        {value: null, label: "not set"},
        ...layouts.map(layout => ({value: layout.$key, label: layout.name})),
      ])
    );

    // NOT USED?
    this.sectors = this.units.pipe(map(uniqueSectors));

    // Set up subscription for readings. We need to handle this differently because it might not exist.
    this.readingsSubscription = this.readings.subscribe(
      readings => (this.readingsValue = readings)
    );
  }

  ngOnDestroy() {
    this.unitsSubscription.unsubscribe();
    if (this.readingsSubscription) this.readingsSubscription.unsubscribe();
  }

  isDuplicateName(unit: AfValue<Unit>): boolean {
    return !!this.currentUnits.find((u: any) => u.$key !== unit.$key && u.name === unit.name);
  }

  // TODO: Move this into a new service, along the lines of other collections.
  // Create a new unit.
  public async create() {
    const unitsSnapshot = await once<Units>(this.units.$ref);
    const numUnits = length(unitsSnapshot);
    const {subscriberData} = this.userService;
    const {usageLimits} = subscriberData;

    if (usageLimits && usageLimits.unitsPerProject && numUnits >= usageLimits.unitsPerProject) {
      this.error = `Sorry, you cannot add any more units to this project.
Please contact your Nemmadi.in customer service representative for more information.`;
      setTimeout(() => (this.error = null), 10000);
      return;
    }

    const name = `New ${this.terms.space} ${numUnits + 1}`;
    const unit: Unit = {
      name,
      status: "not ready",
      sector: "",
      layout: "",
      createdOn: +new Date(),
      createdBy: this.userService.uid,
    };

    // TODO: check if this should be "as Unit"
    return this.units.push(unit);
  }

  // User has edited the room's name or sector. Update it in DB.
  public update(event) {
    const {column, data} = event;
    const unitId = data.$key;

    switch (column.field) {
      case "sector":
        this.units.update(unitId, {sector: data.sector});
        break;

      case "name":
        if (this.isDuplicateName(data.name)) {
          //        unit.name = this.currentUnits.find((u: any) => u.$key === unit.$key).name;
        } else {
          this.units.update(unitId, {name: data.name});
        }
        break;
    }
  }

  // Update the layout.
  // Called when the user selects something from the in the layout column for the unit.
  public updateLayout(unit: AfValue<Unit>, layout) {
    if (layout) this.units.update(unit.$key, {layout});
  }

  // Remove the unit selected in the UI.
  public remove(unit: AfValue<Unit>) {
    this.confirmRemoveService
      .confirm(
        "Really remove unit?",
        `Are you sure you want to remove unit <strong>${unit.name}</strong>?<br>
Any inspection results will be lost.
<span style="color: red">This cannot be undone.</span>`,
        this.viewContainerRef
      )
      .subscribe(res => {
        if (res) this._remove(unit);
      });
  }

  // Prepare a unit for inspection.
  // Create the relevant results node in the database.
  // TODO: Move unit preparation into a service.
  public prepare(unit: AfValue<Unit>) {
    const unitsRef = this.units.$ref as DatabaseReference;
    const projectRef = unitsRef.parent;
    const unitResultsRef = projectRef.child(`results/units/${unit.$key}`);
    const layoutId = unit.layout;

    this.error = null;

    if (!layoutId) {
      console.error("Attempting to prepare unit with no layout specified.");
      return;
    }

    combineLatest(
      this.layouts,
      this.rooms,
      this.sections,
      this.items,
      this.aspects,
      this.descriptions
    )
      .pipe(take(1))
      .subscribe(([layouts, rooms, sections, items, aspects, descriptions]) => {
        const roomsWithNoItems = [];

        // Get the layout for this unit.
        const layout = findByKey(layouts, layoutId);
        if (!layout) throw new Error("In project-units#prepare, cannot find layout for unit");

        // The rooms are the rooms in the layout for this unit.
        const layoutRooms = filter(layout.rooms, Boolean);

        // Calculate the rooms for the unit node under results.
        // Each one contains all items applicable to that room.
        const roomResults = mapObject(layoutRooms, (_, roomId) => {
          // The room in question, from the rooms node of the project.
          const room = findByKey(rooms, roomId);

          // Keep track of rooms with no items--this is probably a mistake.
          if (!length(room.items)) roomsWithNoItems.push(room.name);

          // The items for that room, mapped to the actual item objects in the items node of the project.
          const allRoomItems = mapObject(room.items || {}, (__, itemId) =>
            findByKey(items, itemId)
          );

          // Items may have been deleted, without having been removed from rooms.
          const roomItems = filter(allRoomItems, Boolean);

          // Parallel map of section objects, indexed by item index.
          const roomItemSections = mapObject(roomItems, ({section}) =>
            findByKey(sections, section)
          );

          const includedItems = filter(
            roomItems,
            ({disabled}, itemId) => !disabled && !roomItemSections[itemId].disabled
          );

          return {
            name: room.name,
            items: mapObject(includedItems, (item: Item, itemId) => {
              const {name, order, reading, description} = item;
              const section = roomItemSections[itemId];
              const {
                order: sectionOrder,
                name: sectionName,
                aspect: aspectId = "default",
              } = section;
              const aspectObject = aspects.find(_aspect => _aspect.$key === aspectId);

              // This should never happen, but might for some legacy projects.
              if (!aspectObject) {
                console.error("in project.units#prepare, failed to find aspect in aspect list!");
              }

              const itemArtifactSet = getProjectItemArtifactSet(this.modes);

              if (description) {
                const descriptionObject = descriptions.find(({$key}) => $key === description);

                if (!descriptionObject)
                  throw new Error(`Could not find smart description (${description})`);

                const {snagging} = itemArtifactSet;
                const section = sectionName.toLowerCase();

                snagging.madlib = parseMadlib(descriptionObject.source);
                if (!snagging.madlib.options) snagging.madlib.options = {};

                snagging.madlib.options.section = {[section]: true};
                snagging.madlibValues = {section};
              }

              const itemResults = {
                name,
                order,
                sectionOrder,
                sectionName,
                aspect: aspectObject ? aspectObject.name : "other",

                // Each item has a pre-populated set of artifacts, depending on the project's phases.
                itemArtifactSet,
              } as ItemResults;

              if (reading && reading !== "none") {
                const readingObject = this.readingsValue.find(
                  _reading => _reading.$key === reading
                );

                // This should never happen, but might for some legacy projects.
                if (!readingObject)
                  console.error("in project.units#prepare, failed to find reading in reading list");

                itemResults.reading = readingObject;
              }

              return itemResults;
            }),
          } as RoomResults;
        });

        const unitResults: UnitResults = {
          name: unit.name,
          notes: "",
          sector: unit.sector,
          layout: layout.name,
          rooms: roomResults,
          terms: this.terms,

          // Set initial snag priorities flag from project default.
          // This can be changed later in the visual report.
          noSnagPriorities: !!this.noSnagPriorities,
        };

        unitResultsRef.set(unitResults);
        //        this.setStatus(unit, "snagging");

        if (roomsWithNoItems.length)
          this.error = `No items were found for
${roomsWithNoItems.length} rooms:
the ${join2(roomsWithNoItems, ", ", " and ")}
in unit ${unit.name}.
This is probably a mistake,
and you might want to reset this unit and check each room's items.`;
      });
  }

  // Reset the unit--in other words, place it back into "not ready" status.
  public async reset(unit) {
    const unitsRef = this.units.$ref as DatabaseReference;
    const projectRef = unitsRef.parent;
    const unitResultsRef = projectRef.child(`results/units/${unit.$key}`);

    const confirm = await this.confirmReset(unit);
    this.error = null;

    if (confirm) {
      //       this.units.update(unit.$key, {status: "not ready"});
      unitResultsRef.remove();
      this.toast(`${this.terms.space} successfully reset`);
      return true;
    }
    return false;
  }

  public makeUnitsWithLayoutNames() {
    function findLayoutName(unitLayout, layouts) {
      if (!unitLayout) return "not set";

      const layout = layouts.find(_layout => _layout.$key === unitLayout);
      return layout.name;
    }

    return this.layouts.pipe(
      switchMap(layoutList =>
        this.units.pipe(
          map(unitList =>
            unitList.map(unit =>
              Object.assign({}, unit, {
                layoutName: findLayoutName(unit.layout, layoutList),
                $key: unit.$key,
              })
            )
          )
        )
      )
    );
  }

  // Update the unit's status. Called from template.
  // Remember the timestamp of the change in status.
  // Reset timestamps for "future" statuses.
  // Mark the unit as touched so it appears first in the unit list on the visual report page.
  public async setStatus(unit: AfValue<Unit>, status: string) {
    let update: Partial<Unit>;
    const now = +new Date();

    switch (status) {
      case "not ready":
        if (await this.reset(unit)) {
          update = {
            enteredSnaggingOn: 0,
            enteredSnaggingCompleteOn: 0,
            enteredFixingOn: 0,
            enteredFixingCompleteOn: 0,
            enteredDesnaggingOn: 0,
            enteredDesnaggingCompleteOn: 0,
          };
          break;
        } else return;

      case "snagging":
        update = {
          enteredSnaggingOn: now,
          enteredSnaggingCompleteOn: 0,
          enteredFixingOn: 0,
          enteredFixingCompleteOn: 0,
          enteredDesnaggingOn: 0,
          enteredDesnaggingCompleteOn: 0,
        };
        // Being put into snagging status for the first time.
        if (unit.status === UnitStatus.notReady) this.prepare(unit);

        break;
      case "snagging complete":
        update = {
          enteredSnaggingCompleteOn: now,
          enteredFixingOn: 0,
          enteredFixingCompleteOn: 0,
          enteredDesnaggingOn: 0,
          enteredDesnaggingCompleteOn: 0,
        };
        break;
      case "fixing":
        update = {
          enteredFixingOn: now,
          enteredFixingCompleteOn: 0,
          enteredDesnaggingOn: 0,
          enteredDesnaggingCompleteOn: 0,
        };
        break;
      case "fixing complete":
        update = {
          enteredFixingCompleteOn: now,
          enteredDesnaggingOn: 0,
          enteredDesnaggingCompleteOn: 0,
        };
        break;
      case "desnagging":
        update = {enteredDesnaggingOn: now, enteredDesnaggingCompleteOn: 0};
        break;
      case "desnagging complete":
        update = {enteredDesnaggingCompleteOn: now};
        break;
      default:
        throw new Error("invalid unit status in project-units#setStatus");
    }

    update.touchedOn = +new Date();
    update.status = status;
    this.units.update(unit.$key, update);
    this.toast(`Unit '${unit.name}' placed into '${status}' status`);
  }

  // The user has expanded a row. Re-calculate inspection status.
  // Store the results in the `unitDetails` object.
  public details(unit: AfValue<Unit>) {
    this.error = null;

    (this.units.$ref as DatabaseReference).parent // project
      .child("results")
      .child("units")
      .child(unit.$key)
      .once("value")
      .then(snap => {
        let totalItems = 0;
        let snags = 0;

        let pendingSnagging = 0;
        let pendingFixing = 0;
        let pendingDesnagging = 0;

        const unitResults: UnitResults = snap.val();
        if (!unitResults) return;

        forEach(unitResults.rooms || {}, (room: RoomResults) =>
          forEach(room.items || {}, (item: ItemResults) => {
            normalizeItemResults(item);
            const {snagging, desnagging, fixing} = item.itemArtifactSet;

            totalItems++;
            if (!snagging.status) pendingSnagging++;

            if (snagging.status === SnaggingStatus.notOk) {
              snags++;
              if (fixing && !fixing.status) pendingFixing++;
              if (desnagging && !desnagging.status) pendingFixing++;
            }

            if (snagging.status === SnaggingStatus.notOk) {
              snags++;
              if (desnagging && !desnagging.status) pendingDesnagging++;
            }
          })
        );
        this.unitDetails[unit.$key] = {
          totalItems,
          snags,
          pendingSnagging,
          pendingFixing,
          pendingDesnagging,
        };
      });
  }

  // Check to make sure the user really, really wants to reset the unit.
  private confirmReset(unit: AfValue<Unit>) {
    return this.confirmRemoveService
      .confirm(
        "Really remove inspection results and reset unit?",
        `Are you sure you want to reset unit <strong>${unit.name}</strong> to "not ready" status,
which will remove all existing inspection results?
<span style="color: red">This cannot be undone.</span>`,
        this.viewContainerRef
      )
      .toPromise();
  }

  // Remove the unit and any results, assuming the user has already confirmed he wants to do this.
  private _remove(unit) {
    const unitsRef = this.units.$ref as DatabaseReference;
    const projectRef = unitsRef.parent;
    const unitResultsRef = projectRef.child(`results/units/${unit.$key}`);

    unitResultsRef.remove();
    this.units.remove(unit.$key);
  }

  private toast(msg) {
    return this.matSnackBar.open(msg, "", {duration: SNACK_BAR_DURATION});
  }
}
