// components/cover-page.component.ts
//
// Template for report cover page.

import {Component, OnInit, Input} from "@angular/core";

import {Summary, UnitResults, Subscriber, Terms} from "@nims/red-shared";

@Component({
  selector: "cover-page",
  templateUrl: "./cover-page.component.html",
  styleUrls: ["./cover-page.component.css"],
})
export class CoverPageComponent implements OnInit {
  @Input()
  summary: Summary;
  @Input()
  unit: UnitResults;
  @Input()
  subscriber: Subscriber;
  @Input()
  terms: Terms;

  constructor() {}

  ngOnInit() {
  }
}
