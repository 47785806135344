// pods/accounts/accounts/components.ts
//
// TS logic for page to display and edit user accounts.
// Managers may only view and edit customer accounts.
// Administrators can view all accounts.

import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  ViewChild,
  HostBinding,
} from "@angular/core";

import {Observable, Subject} from "rxjs";
import {map, flatMap} from "rxjs/operators";

import {FirebaseListObservable} from "angularfire2/database-deprecated";
import {SelectItem} from "primeng/primeng";

import {
  AfValue,
  Capability,
  Summary,
  User,
  UserRole,
  canCreateAccount,
  isUnviewableTestUserForUser,
} from "@nims/red-shared";

import {K, mapFromArray, mapToArray, not} from "@nims/jsutils";

import {DatabaseService, UserService} from "../../services/";
import {ConfirmRemoveService} from "@nims/ngutils";


const LOG = false;
const MODULE_NAME = "app\\accounts.component";

function booleanHash(array) {
  return mapFromArray(array, K(true));
}

@Component({
  selector: "app-accounts",
  templateUrl: "./accounts.component.html",
  styleUrls: ["./accounts.component.css"],
})
export class AccountsComponent implements OnInit, OnDestroy {
  public help = false;

  @HostBinding("class.editable") public editable = false;

  // We need to filter the users, to show only customers to managers.
  public filteredUsers$;
  public projectSuggestions$;

  public canViewTestUsers: boolean;

  // Create options for dropdown for selecting role.
  public roleOptions: SelectItem[];

  // Fields for new user.
  public newName;
  public newEmail;
  public password;
  public createUserStatus;
  public newRole;
  public UserRole = UserRole;
  public layouts;

  // Is this needed?
  @ViewChild("inputName") inputName;

  private users$: FirebaseListObservable<AfValue<User>[]>;
  private canViewTestProjects: boolean;
  private summariesSubscription;
  private summaries;
  private role; // this user's role

  // A stream of queries for project names.
  // This feeds into the stream of suggestions.
  private query$ = new Subject<string>();

  private summaries$: Observable<Summary[]>;

  constructor(
    private readonly databaseService: DatabaseService,
    private readonly userService: UserService,
    private readonly confirmRemoveService: ConfirmRemoveService,
    private readonly viewContainerRef: ViewContainerRef,

  ) {}

  ngOnInit() {
    const {user, uid, subscriber} = this.userService;
    
    this.users$ = this.userService.viewAllSubscribers
      ? this.databaseService.getUserList()
      : this.databaseService.getSubscriberUserList(subscriber);

    //    this.canManageAccounts = this.userService.can(Capability.manageAccounts);
    this.canViewTestProjects = this.userService.can(Capability.viewTestProjects);
    this.canViewTestUsers = this.userService.can(Capability.viewTestUsers);
    const isUnviewableTestUser = isUnviewableTestUserForUser(user);

    this.role = user.role;
    this.roleOptions = this.createRoleOptions(user);

    // Set up project summaries for selecting projects to be accessible by each user.
    this.summaries$ = this.databaseService.getSummariesListBySubscriber(subscriber);
    this.summariesSubscription = this.databaseService
      .getSummariesObject()
      .subscribe(summaries => (this.summaries = summaries));

    this.filteredUsers$ = this.users$.pipe(
      map(users => users.filter(not(isUnviewableTestUser))),
      map(users =>
        users
          .filter(
            _user =>
              _user.$key === uid || canCreateAccount[UserRole[this.role]].indexOf(_user.role) >= 0
          )
          .map(_user => ({
            ..._user,
            $key: _user.$key,

            // Take the user's `projects` property, and map it to a list of project summaries.
            selectedProjects: mapToArray(_user.projects || {}, (_, key) => ({
              ...this.summaries[key],
              key,
            })),
          }))
      )
    );

    // If this user is "below the line" (can only view his own projects),
    // restrict project suggestions to only their current projects too.
    // TODO: urgently fix this.
    this.projectSuggestions$ = this.query$.pipe(
      flatMap(query =>
        this.summaries$.pipe(
          map(summaries =>
            summaries
              .filter(summary => this.canViewTestProjects || !summary.test)
              .filter(summary => summary.name.toLowerCase().includes(query.toLowerCase()))
          )
        )
      )
    );
  }

  ngOnDestroy() {
    this.summariesSubscription.unsubscribe();
  }

  // Given some partial input, find the matching project name,
  // by emitting onto a query observable which triggers recalc of suggestions.
  public findProject(query: string) {
    this.query$.next(query);
  }

  // Create new account, capturing and reporting errors.
  public async create() {
    // Actually, this message should be removed when the user touches the input fields.
    this.password = null;

    this._create().catch(
      e => (this.createUserStatus = (e && e.message) || "Unknown error occurred")
    );
  }

  public remove(user: AfValue<User>) {
    this.confirmRemoveService
      .confirm(
        "Really remove user?",
        `Are you sure you want to remove user &ldquo;${user.name}&rdquo; from the system?
<b>This cannot be undone!</b>`,
        this.viewContainerRef
      )
      .subscribe(res => {
        const uid = user.$key;
        // TODO: Change this to a disable. Otherwise, we will have zombie user references.
        this.databaseService.getUserObject(uid).remove();
        this.userService
          .deleteFirebaseAccount(uid)
          .toPromise()
          .catch(error => alert(`Problem with deletion: ${error}`));
      });
  }

  // The user has updated some user's role.
  // Update the affected user's record in the database.
  public updateRole(user: AfValue<User>) {
    this.databaseService.getUserObject(user.$key).update({role: user.role});
  }

  // The user has selected or deselected a project,
  // using the `p-autoComplete` widget.
  // Update the affected user's record in the database.
  public updateProjects(user) {
    this.databaseService
      .getUserObject(user.$key)
      .update({projects: booleanHash(user.selectedProjects)});
  }

  // public addProject(user: AfValue<User>, {$key}: AfValue<Summary>) {
  //   const {projects = {}} = user;

  //   projects[$key] = true;
  //   this.databaseService.getUserObject(user.$key).update({projects});
  // }

  public addProject(user, {$key}: AfValue<Summary>) {
    const {projects = {}} = user;
    projects[$key] = true;
    this.databaseService.getUserObject(user.$key).update({projects});

    const selectedprojkey = [$key];
    
    const selectedProj = selectedprojkey
      .reduce((obj, key) => ({ ...obj, [key]: this.summaries[key] }), {});

    let inspectorEmail = user.email;
    let inspectorName = user.name;
    let projectAssigned = selectedProj[$key].name;
    let projectCustomer = selectedProj[$key].customer;

    if(!projectCustomer){
      projectCustomer = '-';
    }else{
      projectCustomer = projectCustomer;
    }

    let customerAddress = selectedProj[$key].address; 
    if(!customerAddress){
      customerAddress = '-';
    }else{
      customerAddress = customerAddress;
    } 

    let projectType = selectedProj[$key].type;
    if(!projectType){
      projectType = '-';
    }else{
      projectType = projectType;
    } 
    
    let flatNo = customerAddress.substr(0, customerAddress.indexOf(',')); 
    if(!flatNo){
      flatNo = '-';
    }else{
      flatNo = flatNo;
    } 
   // let projectConfig = selectedProj[$key];

    



    const projectassignedData = {"dest": inspectorEmail, "inspectorname": inspectorName, "clientname": projectCustomer, "projecttype" : projectType, "projectname" : projectAssigned, "configuration" : "-", "flatno" : flatNo, "address" : customerAddress}

    this.userService
    .sendEmailToInspector(projectassignedData);

    
  }

  // Delete a project from a user, when the (x) on the little chip is clicked.
  public removeProject(user: AfValue<User>, deleteProject: AfValue<Summary>) {
    const {projects = {}} = user;

    delete projects[deleteProject["key"]];
    this.databaseService.getUserObject(user.$key).update({projects});
  }

  public updateName(user) {
    this.databaseService.getUserObject(user.$key).update({name: user.name});
  }

  public setTestUser(user: AfValue<User>, test: boolean) {
    this.databaseService.getUserObject(user.$key).update({test});
  }

  // Create the values for the dropdown in the "new account" area to select user role.
  private createRoleOptions(user) {
    // Get an array of all the types of accounts the current user can create.
    return canCreateAccount[UserRole[this.role]].map(
      value => ({label: UserRole[value], value} as SelectItem)
    );
  }

  // Create new user.
  // First, create new Firebase user.
  // Then, using the uid, create a user in our own database with role etc.
  private async _create() {
    const METHOD_NAME = `${MODULE_NAME}#_create`;

    const subscriber = this.userService.subscriber;
    if (!subscriber) throw new Error("Cannot find subscriber info for current user");

    const {newName: name, newEmail: email, newRole: role} = this;

    if (!name || !email || !role) throw new Error("Missing values in accounts.component#create");

    const {password, user: {uid}} = await this.userService.createFirebaseAccount(email).toPromise();
    if (LOG) console.log(`${METHOD_NAME}: password and uid are`, password, uid);

    this.password = password;
    this.createUserStatus = "Account created successfully.";

    return this.databaseService
      .getUsersObject()
      .update({[uid]: {name, email, role, subscriber}} as Partial<User>);
  }
}
