// app-routing.module.ts
//
// Routes for NIMS.

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule, Routes} from "@angular/router";

import {environment} from "../environments/environment";

import {LoginComponent} from "./pods/login/login.component";

import {
  CanViewProjects,
  CanManageTemplates,
  CanViewSelectedProjects,
  IsLoggedIn,
  CanManageAccounts,
  CanManageSubscribers,
  CanViewMultiProjectDashboard,
} from "./services/user/authguards";

import {HomeComponent} from "./pods/home/home.component";
import {ProjectsComponent} from "./pods/projects/projects.component";
import {TemplatesComponent} from "./pods/templates/templates.component";
import {ProjectComponent} from "./pods/project/project.component";
import {ProjectResultsComponent} from "./pods/visual-report/project-results/project-results.component";
import {ProjectProgressComponent} from "./pods/project-progress/project-progress.component";
import {ResultsComponent} from "./pods/results/results.component";
import {SingleProjectDashboardComponent} from "./pods/dashboard/single-project-dashboard";
import {MyAccountComponent} from "./pods/my-account/my-account.component";
import {AccountsComponent} from "./pods/accounts/accounts.component";
import {SubscribersComponent} from "./pods/subscribers/subscribers.component";
import {SubscriberComponent} from "./pods/subscriber/subscriber.component";
import { MultiProjectDashboardComponent} from "./pods/dashboard/multi-project-dashboard/multi-project-dashboard.component";

const appRoutes: Routes = [
  {path: "project/:id", component: ProjectComponent, canActivate: [IsLoggedIn, CanViewProjects]},
  {path: "login", component: LoginComponent},
  {path: "home", component: HomeComponent, canActivate: [IsLoggedIn]},
  {
    path: "projects",
    component: ProjectsComponent,
    canActivate: [IsLoggedIn, CanViewSelectedProjects],
  },
  {path: "multi-project-dashboard", component: MultiProjectDashboardComponent, canActivate: [IsLoggedIn, CanViewMultiProjectDashboard]},
  {path: "templates", component: TemplatesComponent, canActivate: [IsLoggedIn, CanManageTemplates]},
  {path: "my-account", component: MyAccountComponent, canActivate: [IsLoggedIn]},
  {path: "accounts", component: AccountsComponent, canActivate: [IsLoggedIn, CanManageAccounts]},
  {
    path: "subscribers",
    component: SubscribersComponent,
    canActivate: [IsLoggedIn, CanManageSubscribers],
    children: [
      {
        path: "subscriber/:id",
        component: SubscriberComponent,
        canActivate: [IsLoggedIn, CanManageSubscribers],
      },
    ],
  },

  // The results path has three sub-routes displayed in tabs.
  {
    path: "results/:id",
    component: ResultsComponent,
    children: [
      {path: "report", component: ProjectResultsComponent, canActivate: [IsLoggedIn]},
      {path: "dashboard", component: SingleProjectDashboardComponent, canActivate: [IsLoggedIn]},
      {path: "progress", component: ProjectProgressComponent, canActivate: [IsLoggedIn]},
    ],
  },

  {path: "", redirectTo: "/login", pathMatch: "full"},
  //  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: environment.debug && environment.debug.enableRouterTracing,
    }),
  ],
  exports: [RouterModule],
  declarations: [],
  providers: [
    CanViewProjects,
    CanViewSelectedProjects,
    CanManageTemplates,
    CanManageAccounts,
    CanManageSubscribers,
    CanViewMultiProjectDashboard,
    IsLoggedIn,
  ],
})
export class AppRoutingModule {}
