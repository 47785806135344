// item-artifacts.component.ts
//
// TS for component to display one set of item artifacts (status, photo, description) in visual report.
// Called from `item-results.component` for each mode.
// This is vaguely parallel to the `display-tabbed-item-artifacts` component in the app.

import {Component, Input, Output, EventEmitter, OnInit, ViewChild} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";

import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {FirebaseApp} from "angularfire2";

import {
  ItemArtifacts,
  ItemPhoto,
  ItemPhotoService,
  Mode,
  Reading,
  SnaggingStatus,
  SnagPriority,
  modeTitles,
  modeStatuses,
} from "@nims/red-shared";

import {Required} from "@nims/ngutils";
import {viewBig} from "../../../utils/view-big";
import {ItemArtifactsMetadata} from "../item-results/item-results.component";

// const MODULE_NAME = "console\\item-artifacts.component";

const PHOTO_UPLOAD_FAILED_MSG = "Photo upload failed";
const PHOTO_UPLOAD_SUCCEEDED_MSG = "Photo upload succeeded";

@Component({
  selector: "item-artifacts",
  templateUrl: "./item-artifacts.component.html",
  styleUrls: ["./item-artifacts.component.css"],
})
export class ItemArtifactsComponent implements OnInit {
  @Input() public itemArtifacts: ItemArtifacts;
  @Input() public itemArtifactsMetadata: ItemArtifactsMetadata;
  @Input() public disabled = false;
  @Input() public noPhoto = false;
  @Input() public hidePriority = false;
  @Input() public reading: Reading;

  // Is this used/passed?
  @Input()
  @Required()
  public mode: Mode;

  @Input() public canEdit: boolean;

  @Output() public changeItemArtifacts = new EventEmitter<ItemArtifacts>();

  public statuses: string[];
  public modeTitle: string;
  public editable = false;
  public uploadProgress: Observable<number>;
  public SnaggingStatus = SnaggingStatus;

  @ViewChild("imgFileInput") public imgFileInput;

  private dirty = false;

  constructor(
    private readonly firebaseApp: FirebaseApp,
    private readonly itemPhotoService: ItemPhotoService,
    private readonly matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.statuses = modeStatuses[this.mode];
    this.modeTitle = modeTitles[this.mode];
  }

  ////////////////////////////////////////////////////////////////
  // EVENT HANDLERS

  // Handle the click on tbe upload button.
  // Confirm with user first, since existing photo will be destroyed.
  public async onFileChange(fileInput) {
    this.doUpload(fileInput.target.files && fileInput.target.files[0]);
  }

  public onChangeStatus(status: string) {
    this.itemArtifacts.status = status;

    if (status === SnaggingStatus.ok) {
      delete this.itemArtifacts.photo;
      delete this.itemArtifacts.description;
    }

    this.dirty = true;
  }

  public onChangeSnagPriority(snagPriority: SnagPriority) {
    this.itemArtifacts.snagPriority = snagPriority;
    this.dirty = true;
  }

  public onChangeSmartDescription({
    description,
    overrideMadlib,
    madlibValues,
  }: Partial<ItemArtifacts>) {
    const artifacts = this.itemArtifacts;

    artifacts.description = description || "";
    artifacts.overrideMadlib = !!overrideMadlib;

    if (madlibValues) artifacts.madlibValues = madlibValues;
    else delete artifacts.madlibValues;

    this.dirty = true;
  }

  // The highlight has changed, most likely.
  public onChangePhoto(photo: ItemPhoto) {
    this.itemArtifacts.photo = photo;
    this.dirty = true;
  }

  // The reading has changed.
  public onChangedReading(value: number) {
    this.itemArtifacts.value = value;
    this.dirty = true;
  }

  public onEditableChange(editable: boolean) {
    if (!this.editable && this.dirty) this.changeItemArtifacts.emit(this.itemArtifacts);
  }

  // Brings up the image selection dialog after user's confirmation.
  public async onClickUpload() {
    if (this.confirmPhotoUpload()) {
      this.imgFileInput.nativeElement.click();
    }
  }

  private confirmPhotoUpload(): boolean {
    return !this.itemArtifacts.photo ? true : confirm("Really replace existing photo?");
  }

  private async doUpload(big: Blob) {
    const snack = msg => this.matSnackBar.open(msg, "", {duration: 2000});

    try {
      this._doUpload(big);
      snack(PHOTO_UPLOAD_SUCCEEDED_MSG);
    } catch (e) {
      snack(PHOTO_UPLOAD_FAILED_MSG);
    }
  }

  private async _doUpload(big: Blob): Promise<void> {
    const {projectId, unitId, roomId, itemId, snagIndex, roomName} = this.itemArtifactsMetadata;
    const customMetadata = {
      snagIndex: String(snagIndex),
      itemId,
      roomId,
      unitId,
      projectId,
    };
    // TODO: What is there is no snagindex?
    const filename = `${roomName}, snag ${snagIndex}.jpg`;
    const {progress, result} = await this.itemPhotoService.upload(big, filename, customMetadata);

    progress.subscribe(console.log);
    this.uploadProgress = progress.pipe(
      map(({bytesTransferred, totalBytes}) => (bytesTransferred / totalBytes) * 100)
    );
    this.itemArtifacts.photo = {...((await result) as any)} as ItemPhoto;
    this.uploadProgress = null;
    this.dirty = true;
  }

  // The user has double-clicked on an image in the visual report.
  // Open a new window with the big version of the image.
  // TODO: move this into a service.
  public viewBig() {
    const {roomName, snagIndex} = this.itemArtifactsMetadata;

    return viewBig(
      this.itemArtifacts.photo.big,
      `${roomName}, snag ${snagIndex}.jpg`,

      // Some weird version problem, telling me `Property messaging is missing in type FirebaseApp`.
      this.firebaseApp.storage() as any
    );
  }
}
