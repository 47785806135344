// components/template-interpolatables.component.ts
//
// Template for displaying interpolatables in templates.

import {Component, Input} from "@angular/core";

@Component({
  selector: "template-interpolatables",
  templateUrl: "./template-interpolatables.component.html",
  styleUrls: ["./template-interpolatables.component.css"],
})
export class TemplateInterpolatablesComponent {
  @Input() tokens;
}
