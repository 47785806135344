import "./polyfills.ts";

import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {enableProdMode} from "@angular/core";
import {ravenConfig} from "@nims/ngutils";
import {environment} from "./environments/environment";
import {AppModule} from "./app/app.module";
import {ngLogConfig} from "@nims/ngutils";

const {production, sentryDsn, debug} = environment;

if (production && sentryDsn) ravenConfig(sentryDsn);

ngLogConfig(debug && debug.ngLog);

if (production) enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule);
