import {catchError, map} from "rxjs/operators";
// firebase-account.service.ts
//
// Service for invoking cloud functions for creating and deleting Firebase accounts.

import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {ErrorObservable} from "rxjs/observable/ErrorObservable";
import {environment} from "../../environments/environment";

const {CREATE_USER, DELETE_USER} = environment.endpoints;

// Response types from create and delete user APIs.
// TODO: Sync these with logic in `@nims/cloud/functions/src/user.ts`.
interface CreateUserResponse {
  status: "success" | "failure";
  data: any;
}

interface DeleteUserResponse {
  status: "success" | "failure";
  data: any;
}

@Injectable()
export class FirebaseAccountService {
  constructor(private httpClient: HttpClient) {}

  // Create a new user by calling the server API.
  // The response will be of the form {uid, ..., providerData: {password, ...}}`.
  // TODO: move this to a user-api.service.
  public create(email): Observable<any> {
    const params = new HttpParams().set("email", email);

    return this.httpClient.get<CreateUserResponse>(CREATE_USER, {params}).pipe(
      map(json => {
        console.log("response from create user API is", json);

        if (!json.status) return ErrorObservable.create("Missing status");
        if (json.status === "success") return json.data;
        return ErrorObservable.create(json.data);
      }),
      catchError((response: HttpErrorResponse) => {
        console.log("error from create user API call is", JSON.stringify(response));
        return ErrorObservable.create(response.error.data);
      })
    );
  }

  // Delete a firebase user account by calling the server API.
  public delete(uid: string) {
    const params = new HttpParams().set("uid", uid);

    return this.httpClient.get<DeleteUserResponse>(DELETE_USER, {params}).pipe(
      map(json => {
        if (!json.status) return ErrorObservable.create("Missing status");
        if (json.status === "success") return json.data;
        return ErrorObservable.create(json.data);
      }),
      catchError(json => {
        console.log("errof from delete user API call is", json);
        return ErrorObservable.create(json.data);
      })
    );
  }
}
