// pods/project-progress/project-progress.component.ts
//
// Component for showing project progress.
// One tab in the "results" area.

import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {DatePipe} from "@angular/common";

import * as d3 from "d3";
import eventDrops from "@nims/event-drops/dist/eventDrops";

// COMMON
import {mapFromArray} from "@nims/jsutils";

import {DatabaseService} from "../../services/database/database.service";

// interface UnitData extends Unit {
//   layoutName: string;
//   $key: string;
//   results: UnitResults;
// }

const WEEK_MS = 7 * 24 * 60 * 60 * 1000;

// COLORS/EVENTS
// These must be the same as give in `common\functions/progress.ts`.
const dateTypes = [
  "enteredSnagging",
  "enteredSnaggingComplete",
  "enteredFixing",
  "enteredFixingComplete",
  "enteredDesnagging",
  "enteredDesnaggingComplete",
  "snaggingReviewed",
  "fixingReviewed",
  "desnaggingReviewed",
  "roomSnagging",
  "roomFixing",
  "roomDesnagging",
  "itemSnagging",
  "itemFixing",
  "itemDesnagging",
  "created",
];

const colorScale = d3
  .scaleOrdinal(d3.schemeCategory20)
  .domain(dateTypes)
  .range(d3.schemeCategory20);

@Component({
  selector: "app-project-progress",
  templateUrl: "./project-progress.component.html",
  styleUrls: ["./project-progress.component.css"],
})
export class ProjectProgressComponent implements OnInit {
  public help = false;
  public colors = mapFromArray(dateTypes, type => colorScale(type));
  public datum$ = {};

  private chart;
  private start;
  private end;
  private id: string;
  private datum;

  constructor(
    private route: ActivatedRoute,
    private databaseService: DatabaseService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    const id = (this.id = this.route.parent.snapshot.params["id"]);
    const database = this.databaseService;

    (async () => {
      const data = await database.getDashboardData(id);

      this.datum = data.progress;
      this.makeChart();
    })();
  }

  public makeChart() {
    const dates = [].concat(
      ...this.datum.map(unit => [].concat(...unit.data.map(item => item.date)))
    );
    const start = (this.start = Math.min(...dates) - WEEK_MS);
    const end = (this.end = Math.max(...dates) + WEEK_MS);

    const chart = (this.chart = eventDrops({title: d => this.title(d)})
      .start(new Date(start))
      .end(new Date(end))
      .eventColor(d => colorScale(d.type))
      .margin({top: 60, left: 0, bottom: 40, right: 50})
      .labelsWidth(160)
      .date(d => d.date));

    d3
      .select("#eventDrops")
      .datum(this.datum)
      .call(chart);
  }

  // Create the "title" displayed when hovering on a drop.
  // This is passed as a configuration option to `eventDrops()`.
  private title(d) {
    const {unitName, date, roomName, type} = d;
    const unit = unitName ? `unit ${unitName}` : "this unit";
    const room = roomName || "a room";
    const x = this.datePipe.transform(date, "medium");

    switch (type) {
      case "created":
        return `Unit ${unit} created on ${x}`;
      case "enteredSnagging":
        return `Snagging of ${unit} began on ${x}`;
      case "enteredSnaggingComplete":
        return `Snagging of ${unit} completed on ${x}`;
      case "snaggingReviewed":
        return `Snagging of ${unit} was reviewed on ${x}`;
      case "roomSnagging":
        return `${room} in ${unit} was snagged on ${x}`;
      case "itemSnagging":
        return `item in ${room} in ${unit} was snagged on ${x}`;

      case "enteredFixing":
        return `Fixing of ${unit} began on ${x}`;
      case "enteredFixingComplete":
        return `Fixing of ${unit} completed on ${x}`;
      case "fixingReviewed":
        return `Fixing of ${unit} was reviewed on ${x}`;
      case "roomFixing":
        return `${room} in ${unit} was fixed on ${x}`;
      case "itemFixing":
        return `item in ${room} in ${unit} was fixed on ${x}`;

      case "enteredDesnagging":
        return `De-snagging of ${unit} began on ${x}`;
      case "enteredDesnaggingComplete":
        return `De-snagging of ${unit} was marked complete on ${x}`;
      case "desnaggingReviewed":
        return `De-snagging of ${unit} was reviewed on ${x}`;
      case "roomDesnagging":
        return `${room} in ${unit} was de-snagged on ${x}`;
      case "itemDesnagging":
        return `item in ${room} in ${unit} was de-snagged on ${x}`;
    }
  }
}
