// project.component.ts
//
// Main page for a single project.

// ANGULAR
import {Component, OnInit} from "@angular/core";
import {Router, ActivatedRoute} from "@angular/router";

// RXJS
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {Hash} from "@nims/jsutils";

// FIREBASE
import {
  AngularFireDatabase,
  FirebaseObjectObservable,
  FirebaseListObservable,
} from "angularfire2/database-deprecated";

import {
  Summary,
  Room,
  ModeHash,
  Unit,
  Layout,
  Section,
  Item,
  Aspect,
  Description,
  Reading,
  Terms,
  defaultReadings,
  legacyTerms,
  getProjectModes,
} from "@nims/red-shared";

// SERVICES
import {DatabaseService} from "../../services/database/database.service";
import {UserService} from "../../services/user/user.service";

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.css"],
})
export class ProjectComponent implements OnInit {
  public summary: FirebaseObjectObservable<Summary>;
  public rooms: FirebaseListObservable<Room[]>;
  public units: FirebaseListObservable<Unit[]>;
  public layouts: FirebaseListObservable<Layout[]>;
  public sections: FirebaseListObservable<Section[]>;
  public items: FirebaseListObservable<Item[]>;
  public masterRooms: FirebaseListObservable<Room[]>;
  public aspects: FirebaseListObservable<Aspect[]>;
  public descriptions: FirebaseListObservable<Description[]>;
  public readings: FirebaseListObservable<Reading[]>;
  public terms: FirebaseObjectObservable<Terms>;
  public summaryId: string;

  // What are the project's modes?
  public modes$: Observable<Partial<ModeHash<boolean>>>;

  // Is this project a favorite of this usre?
  public isFavorite$: Observable<boolean>;

  public help = false;
  public name: Observable<string>;
  public noSnagPriorities: Observable<boolean>;

  public roomCount: Observable<number>;
  public layoutCount: Observable<number>;
  public unitCount: Observable<number>;
  public itemCount: Observable<number>;
  public aspectCount: Observable<number>;
  public descriptionCount: Observable<number>;
  public readingCount: Observable<number>;

  public loaded: Observable<boolean>; // NOT USED NOW

  private readingsObject: FirebaseObjectObservable<Hash<Reading>>;

  private id: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
    private userService: UserService,
    private databaseService: DatabaseService
  ) {}

  ngOnInit() {
    const id = (this.id = this.route.snapshot.params["id"]);

    this.summary = this.db.object(`summaries/${id}`);
    this.summaryId = `${id}`;

    this.rooms = this.db.list(`projects/${id}/rooms`);
    this.layouts = this.db.list(`projects/${id}/layouts`);
    this.units = this.db.list(`projects/${id}/units`);
    this.sections = this.db.list(`projects/${id}/sections`);
    this.items = this.db.list(`projects/${id}/items`);
    this.masterRooms = this.db.list(`projects/${id}/masterRooms`);
    this.aspects = this.db.list(`projects/${id}/aspects`);
    this.descriptions = this.db.list(`projects/${id}/descriptions`);
    this.readings = this.db.list(`projects/${id}/readings`);
    this.readingsObject = this.db.object(`projects/${id}/readings`);
    this.terms = this.db.object(`projects/${id}/terms`);

    this.modes$ = this.summary.pipe(map(summary => getProjectModes(summary)));

    this.roomCount = this.rooms.pipe(map(list => list.length));
    this.layoutCount = this.layouts.pipe(map(list => list.length));
    this.unitCount = this.units.pipe(map(list => list.length));
    this.aspectCount = this.aspects.pipe(map(list => list.length));
    this.descriptionCount = this.descriptions.pipe(map(list => list.length));
    this.readingCount = this.readings.pipe(map(list => list.length));

    this.name = this.summary.pipe(map(data => data.name));
    this.noSnagPriorities = this.summary.pipe(map(data => data.noSnagPriorities));

    this.isFavorite$ = this.databaseService
      .getUserObject(this.userService.uid)
      .pipe(map(user => user.projects && user.projects[id]));

    // Patch in default readings if they are missing.
    this.readingsObject.$ref.once("value").then(snapshot => {
      if (!snapshot.exists()) this.readingsObject.update(defaultReadings());
    });

    // Patch in default terms if they are missing.
    // Note: this logic is duplicated in the template component.
    this.terms.$ref.once("value").then(snapshot => {
      if (!snapshot.exists()) this.terms.update(legacyTerms);
    });
  }

  // Return to projects page, passing along this project's id.
  gotoProjects() {
    this.router.navigate(["/projects"]);
  }
  gotoResults() {
    this.router.navigate(["/results", this.id, "dashboard"]);
  }

  // Set the project as favorite, or unset.
  // Invoked when user clicks on "star" icon.
  public toggleFavorite() {
    this.userService.toggleFavorite(this.id);
  }
}
