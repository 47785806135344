// copy-template-to-subscriber.component.ts: Component for dialog box to select subscriber to copy template to.
// Called from `templates`.

import {map} from "rxjs/operators";

import {Component, OnInit} from "@angular/core";
import {SubscribersService} from "@nims/red-shared";
import {UserService} from "../../services";

enum Action {
  cancel,
  thisSubscriber,
  otherSubscriber,
}

@Component({
  templateUrl: "./copy-template-to-subscriber.component.html",
})
export class CopyTemplateToSubscriberComponent implements OnInit {
  public subscriberOptions$;
  public subscriberId;
  public Action = Action;

  constructor(private subscribersService: SubscribersService, private userService: UserService) {}

  ngOnInit() {
    this.userService.subscriberId$.subscribe(subscriberId => (this.subscriberId = subscriberId));

    // Duplicates logic in home component.
    this.subscriberOptions$ = this.subscribersService.get$().pipe(
      map(subscribers =>
        subscribers
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter(subscriber => !subscriber.disabled)
          .map(subscriber => ({label: subscriber.name, value: subscriber.$key}))
      )
    );
  }

  public changeSubscriber(id: string) {
    this.subscriberId = id;
  }
}
