// services/sections.service.ts
//
// Handle manipulation of "sections" in "checklists".
// Mainly invoked via the checklist service.

import {Injectable} from "@angular/core";

// FIREBASE
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

// COMMON
import {Section, moveOrdered, removeOrdered, maxOrdered} from "@nims/red-shared";

import {values, forEach, Hash, length} from "@nims/jsutils";
import {once} from "@nims/afutils";

type Sections = Hash<Section>;

@Injectable()
export class SectionsService {
  // Create a new section, with default name.
  public async create(
    ref: DatabaseReference,
    data: Partial<Section> = {},
    term: string = "section"
  ) {
    const sections = await this.get(ref);
    const count = length(sections);
    const last = maxOrdered(values(sections));
    const section: Section = {
      name: `New ${term} ${count + 1}`,
      order: last === -Infinity ? 0 : last + 1,
      disabled: false,
      aspect: "default",
      ...data,
    };

    return ref.push(section);
  }

  // Move a section up or down.
  public async move(ref: DatabaseReference, key: string, delta: number) {
    const sections = await this.get(ref);

    moveOrdered(values(sections), sections[key], delta);
    return this.put(ref, sections);
  }

  // Remove a section, and re-order around it.
  public async remove(ref: DatabaseReference, key: string) {
    const sections = await this.get(ref);

    removeOrdered(values(sections), sections[key]);
    delete sections[key];

    return ref.set(sections);
  }

  public update(ref: DatabaseReference, key: string, data: Partial<Section>) {
    return ref.child(key).update(data);
  }

  // When an aspect is deleted, all the sections referring to it must be set back to default.
  public async removeAspect(ref: DatabaseReference, aspectKey: string) {
    const sections = await this.get(ref);

    forEach(sections, section => {
      if (section.aspect === aspectKey) section.aspect = "default";
    });

    return ref.set(sections);
  }

  // Put and get this node.
  private async get(ref: DatabaseReference): Promise<Sections> {
    return (await once<Sections>(ref)) || {};
  }
  private async put(ref: DatabaseReference, sections: Sections) {
    return ref.set(sections);
  }
}
