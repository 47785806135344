// item-statuses-combinations.ts: Define various status combinations.
// Used in dashboard legend, in addition to grouping items by status combination for bar display.

import {DesnaggingStatus, FixingStatus, ItemStatus, SnaggingStatus} from "@nims/red-shared";

export const itemStatusesCombinations: ItemStatus[][] = [
  [SnaggingStatus.pending],
  [SnaggingStatus.ok],

  [SnaggingStatus.notOk, FixingStatus.fixed, DesnaggingStatus.pending],
  [SnaggingStatus.notOk, FixingStatus.fixed, DesnaggingStatus.rectified],
  [SnaggingStatus.notOk, FixingStatus.fixed, DesnaggingStatus.partiallyRectified],
  [SnaggingStatus.notOk, FixingStatus.fixed, DesnaggingStatus.notRectified],
  [SnaggingStatus.notOk, FixingStatus.fixed, undefined],

  [SnaggingStatus.notOk, FixingStatus.cantFix, DesnaggingStatus.pending],
  [SnaggingStatus.notOk, FixingStatus.cantFix, DesnaggingStatus.rectified],
  [SnaggingStatus.notOk, FixingStatus.cantFix, DesnaggingStatus.partiallyRectified],
  [SnaggingStatus.notOk, FixingStatus.cantFix, DesnaggingStatus.notRectified],
  [SnaggingStatus.notOk, FixingStatus.cantFix, undefined],

  [SnaggingStatus.notOk, FixingStatus.notASnag, DesnaggingStatus.pending],
  [SnaggingStatus.notOk, FixingStatus.notASnag, DesnaggingStatus.rectified],
  [SnaggingStatus.notOk, FixingStatus.notASnag, DesnaggingStatus.partiallyRectified],
  [SnaggingStatus.notOk, FixingStatus.notASnag, DesnaggingStatus.notRectified],
  [SnaggingStatus.notOk, FixingStatus.notASnag, undefined],

  [SnaggingStatus.notOk, FixingStatus.pending, DesnaggingStatus.pending],
  [SnaggingStatus.notOk, FixingStatus.pending, DesnaggingStatus.rectified],
  [SnaggingStatus.notOk, FixingStatus.pending, DesnaggingStatus.partiallyRectified],
  [SnaggingStatus.notOk, FixingStatus.pending, DesnaggingStatus.notRectified],
  [SnaggingStatus.notOk, FixingStatus.pending, undefined],

  [SnaggingStatus.notOk, undefined, DesnaggingStatus.pending],
  [SnaggingStatus.notOk, undefined, DesnaggingStatus.rectified],
  [SnaggingStatus.notOk, undefined, DesnaggingStatus.partiallyRectified],
  [SnaggingStatus.notOk, undefined, DesnaggingStatus.notRectified],
  [SnaggingStatus.notOk, undefined, undefined],
];
