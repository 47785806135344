// project-rooms.component.ts
//
// Component for page showing project rooms.
// This is a tab under Projects.

// TODO: Move logic here into a "project-rooms" service.

import {Component, OnInit, Input} from "@angular/core";
import {Observable} from "rxjs";
import {map, take, switchMap} from "rxjs/operators";
import {SelectItem} from "primeng/primeng";
import {FirebaseListObservable} from "angularfire2/database-deprecated";
import {Room, Layout, Terms, AfValue} from "@nims/red-shared";

// The dropdown values for master room have a value which is the master room key.
interface SelectRoomItem extends SelectItem {
  value: string;
}
type SelectRoomItems = SelectRoomItem[];
type SelectRoomItems$ = Observable<SelectRoomItems>;

@Component({
  selector: "project-rooms",
  templateUrl: "./project-rooms.component.html",
  styleUrls: ["./project-rooms.component.css"],
})
export class ProjectRoomsComponent implements OnInit {
  @Input()
  rooms: FirebaseListObservable<AfValue<Room>[]>;
  @Input()
  layouts: FirebaseListObservable<Layout[]>;
  @Input()
  masterRooms: FirebaseListObservable<AfValue<Room>[]>;
  @Input()
  terms: Terms;

  public editable = false;
  public help = false;
  public roomsWithLayouts: Observable<Room[]>;

  // The list of dropdown options for selecting each room's master room.
  // Initialized in `ngOnInit`.
  public masterRoomOptions: SelectRoomItems$;

  public selectedRoom: Room;

  constructor() {}

  ngOnInit() {
    this.roomsWithLayouts = this.makeRoomsWithLayouts();

    // Create options list to populate pulldown.
    this.masterRoomOptions = this.masterRooms.pipe(
      map(rooms => [
        {value: null, label: "not set"},
        ...rooms.map(room => ({value: room.$key, label: room.name})),
      ])
    );
  }

  // Create a new room based on a template room type.
  public create() {
    const room = {name: `New ${this.terms.feature}`, masterRoom: null};

    this.rooms.push(room);
  }

  // User has edited the room's name or amster room. Update it in DB.
  public update(event) {
    const room: AfValue<Room> = event.data;
    const colData = event.column;

    switch (colData.field) {
      case "name":
        this.rooms.update(room.$key, {name: room.name});
        break;
      case "masterRoom": // TODO: check if this is used/necessary
        this.rooms.update(room.$key, {masterRoom: room.masterRoom});
        break;
      default:
        throw new Error("Invalid column field in project-rooms.component#update");
    }
  }

  // The user has updated the template room.
  public updateMasterRoom(room: AfValue<Room>, masterRoom: string) {
    if (!masterRoom) return;

    const roomId = room.$key;

    this.rooms.update(roomId, {masterRoom});
    this.updateChecklist(roomId, masterRoom);
  }

  // Remove the room selected in the UI.
  // The UI should not allow this if the room is used in any layouts.
  public remove(room: AfValue<Room>) {
    this.rooms.remove(room.$key);
  }

  // Create a version of rooms with list of layouts they are found in.
  public makeRoomsWithLayouts() {
    function getRoomLayouts(roomId, layouts) {
      return layouts.filter(layout => layout.rooms && layout.rooms[roomId]);
    }

    return this.masterRooms.pipe(
      switchMap(masterRooms =>
        this.layouts.pipe(
          switchMap(layouts =>
            this.rooms.pipe(
              map(rooms =>
                rooms.map(room => {
                  const {$key: roomId, masterRoom: masterRoomId} = room;
                  const masterRoomObject = masterRooms.find(
                    masterRoom => masterRoom.$key === masterRoomId
                  );
                  const masterRoomName = masterRoomObject ? masterRoomObject.name : "not set";
                  const usedInLayouts = getRoomLayouts(roomId, layouts);
                  const layoutNames = usedInLayouts.length
                    ? usedInLayouts.map(layout => layout.name).join(", ")
                    : "None";

                  return Object.assign({}, room, {$key: room.$key, layoutNames, masterRoomName});
                })
              )
            )
          )
        )
      )
    );
  }

  // The master (template) room for this room type has been updated.
  // Re-assign items for this room. Old items will be overwritten!
  // Move the list of items for the template room type over to this project room.
  // TODO: omit items which have been deleted from template checklist or project checklist?
  private updateChecklist(roomId: string, masterRoomId: string) {
    this.masterRooms.pipe(take(1)).subscribe(masterRooms => {
      const masterRoom = masterRooms.find(room => room.$key === masterRoomId);
      this.rooms.update(roomId, {items: masterRoom.items});
    });
  }
}
