// utils/idb.util.ts
//
// Utility-level support for IndexedDb.

const MODULE_NAME = "idb.util";
const LOG = false;

export class IndexedDb {
  private _db: Promise<IDBDatabase>;

  constructor(
    private name: string,
    private version: number,
    private onupgradeneeded: (db: IDBDatabase) => void
  ) {}

  public get db() {
    return (this._db = this._db || this.open(this.name, this.version, this.onupgradeneeded));
  }

  // Open the DB, returning a promise for the db object itself.
  private open(
    name: string,
    version: number,
    onupgradeneeded: (db: IDBDatabase) => void
  ): Promise<IDBDatabase> {
    if (LOG) console.log(`${MODULE_NAME}#IndexedDb$open: opening database ${name}`);

    const ret = new Promise<IDBDatabase>((resolve, reject) => {
      // Condition to check whether shimIndexedDB available or not (to work on browser)
      // TODO: remove this.
      const request = indexedDB.open(name, version);

      request.addEventListener("error", reject);
      request.addEventListener("success", function() {
        resolve(<IDBDatabase>this.result);
      });
      //        request.onerror = reject;
      //        request.onsuccess = function() {
      //          resolve(this.result); };
      // const upgradeneeded = this.onupgradeneeded;
      request.onupgradeneeded = function() {
        onupgradeneeded(this.result);
      };
    });

    ret.catch(e =>
      console.error(
        `${MODULE_NAME}#IndexedDb$open: error opening IndexedDB with name ${name}. Error: ${e}`
      )
    );

    return ret;
  }
}
