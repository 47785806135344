// pods/dashboard/report-filters/report-filters.class.ts
//
// Definition of report filters.

import {
  FlatItemResults,
  SnaggingStatus,
  Terms,
  rectificationStatuses,
  fixingStatuses as fixStatuses,
  snaggingStatuses,
  snagPriorities,
} from "@nims/red-shared";

import {NE, booleanHash, join2, length, pluralize} from "@nims/jsutils";
import {ReportFilters} from "./report-filters.interface";

function makeOptions(_statuses: string[]) {
  return _statuses.map(status => ({label: status, value: status}));
}

export {ReportFilters};

export const statuses = snaggingStatuses.filter(NE(SnaggingStatus.na));

export const statusOptions = makeOptions(statuses);
export const rectificationStatusOptions = makeOptions(rectificationStatuses);
export const fixStatusOptions = makeOptions(fixStatuses);
export const snagPriorityOptions = makeOptions(snagPriorities);

fixStatuses.map(status => ({label: status, value: status}));

export const defaultFixStatuses = booleanHash(fixStatuses);
export const defaultRectificationStatuses = booleanHash(rectificationStatuses);
export const defaultStatuses = booleanHash(["not OK"]);
export const defaultSnagPriorities = booleanHash(snagPriorities);

export function getDefaultReportFilters(): ReportFilters {
  return {
    descriptionSearchStrings: {},
    locationSearchStrings: {},
    roomSearchStrings: {},
    itemSearchStrings: {},
    aspectSearchStrings: {},
    reviewed: {reviewed: true},
    rectificationStatuses: {...defaultRectificationStatuses},
    fixStatuses: {...defaultFixStatuses},
    statuses: defaultStatuses,
    snagPriorities: defaultSnagPriorities,
  };
}

export const reviewedOptions = [
  {label: "reviewed", value: "reviewed"},
  {label: "not reviewed", value: "not reviewed"},
];

export function allRectificationStatuses(filter) {
  filter.rectificationStatuses = defaultRectificationStatuses;
}
export function noRectificationStatuses(filter) {
  filter.rectificationStatuses = {};
}

export function updateRectificationStatuses(filter, _rectificationStatuses) {
  filter.rectificationStatuses = booleanHash(_rectificationStatuses);
}

export function allFixStatuses(filter) {
  filter.fixStatuses = defaultFixStatuses;
}
export function noFixStatuses(filter) {
  filter.fixStatuses = {};
}

export function updateFixStatuses(filter, _fixStatuses) {
  filter.fixStatuses = booleanHash(_fixStatuses);
}

export function updateStatuses(filter, _statuses) {
  filter.statuses = booleanHash(_statuses);
}
export function updateReviewed(filter, reviewed) {
  filter.reviewed = booleanHash(reviewed);
}

// Snag priorities
export function allSnagPriorities(filter) {
  filter.snagPriorities = defaultSnagPriorities;
}

export function noSnagPriorities(filter) {
  filter.snagPriorities = {};
}

export function updateSnagPriorities(filter, _priorities) {
  filter.snagPriorities = booleanHash(_priorities);
}

export function addLocationSearch(filter, location) {
  filter.locationSearchStrings[location] = true;
}
export function removeLocationSearch(filter, location) {
  delete filter.locationSearchStrings[location];
}
export function addDescriptionoSearch(filter, description) {
  filter.descriptionSearchStrings[description] = true;
}
export function removeDescriptionSearch(filter, description) {
  delete filter.descriptionSearchStrings[description];
}
export function addRoomSearch(filter, location) {
  filter.roomSearchStrings[location] = true;
}
export function removeRoomSearch(filter, room) {
  delete filter.roomSearchStrings[room];
}
export function addItemSearch(filter, location) {
  filter.itemSearchStrings[location] = true;
}
export function removeItemSearch(filter, room) {
  delete filter.itemSearchStrings[room];
}
export function addAspectSearch(filter, location) {
  filter.aspectSearchStrings[location] = true;
}
export function removeAspectSearch(filter, aspect) {
  delete filter.aspectSearchStrings[aspect];
}

// Based on a filters object, create a function to filter items.
export function filterer(filters: ReportFilters) {
  const {
    statuses: _statuses,
    rectificationStatuses: _rectificationStatuses,
    fixStatuses: _fixStatuses,
    snagPriorities: _snagPriorities,
    reviewed,
    descriptionSearchStrings,
    locationSearchStrings,
    roomSearchStrings,
    itemSearchStrings,
    aspectSearchStrings,
  } = filters;

  return function(item: FlatItemResults) {
    const {
      itemArtifactSet: {snagging, fixing, desnagging},
    } = item;

    return (
      statusFilter() &&
      rectificationStatusFilter() &&
      fixStatusFilter() &&
      snagPriorityFilter() &&
      descriptionSearchFilter() &&
      locationSearchFilter() &&
      roomSearchFilter() &&
      itemSearchFilter() &&
      aspectSearchFilter() &&
      reviewedFilter()
    );

    function statusFilter() {
      return !_statuses || length(_statuses) === 0 || !snagging || snagging.status in _statuses;
    }

    // An item passes the fixing status filter if it HAS no fixing status,
    // either because there was no fixing phase, or the fixing phase has not happened yet,
    // OR the user has selected NO statuses, which sort of means selecting ALL,
    // OR the status is in the selected list.
    function fixStatusFilter() {
      return (
        snagging.status === SnaggingStatus.ok ||
        !_fixStatuses ||
        length(_fixStatuses) === 0 ||
        !fixing ||
        !fixing.status ||
        fixing.status in _fixStatuses
      );
    }

    // An item passes the desnagging (rectification) status filter if it HAS no desnagging status,
    // either because there was no desnagging phase, or the desnagging phase has not happened yet,
    // OR the user has selected NO desnagging statuses, which sort of means selecting ALL of them,
    // OR the desnagging status is in the selected list.
    function rectificationStatusFilter() {
      return (
        snagging.status === SnaggingStatus.ok ||
        !_rectificationStatuses ||
        length(_rectificationStatuses) === 0 ||
        !desnagging ||
        !desnagging.status ||
        desnagging.status in _rectificationStatuses
      );
    }

    // Snag priority filter is satisfied if never specified,
    // if empty, if the snagging results are missing for some odd reason,
    // or the snag's priority is included in the filter (defaulting to "medium").
    // This default should match that used in the visual report.
    function snagPriorityFilter() {
      return (
        !_snagPriorities ||
        !length(_snagPriorities) ||
        !snagging ||
        snagging.status !== SnaggingStatus.notOk ||
        _snagPriorities[snagging.snagPriority || "medium"]
      );
    }

    function reviewedFilter() {
      return item.reviewed in reviewed;
    }

    // Given a boolean hash of search strings, and properties in an item to search for,
    // see if any of those properties contain any of those strings.
    function searchFilter(searchStrings, props) {
      const strings = Object.keys(searchStrings || {}).map(str => str.toLowerCase());

      return (
        !strings.length ||
        strings.some(string =>
          props.some(prop => item[prop] && item[prop].toLowerCase().includes(string))
        )
      );
    }

    function descriptionSearchFilter() {
      return searchFilter(descriptionSearchStrings, ["description"]);
    }
    function locationSearchFilter() {
      return searchFilter(locationSearchStrings, ["unitName", "sector", "tower"]);
    }
    function roomSearchFilter() {
      return searchFilter(roomSearchStrings, ["roomName"]);
    }
    function itemSearchFilter() {
      return searchFilter(itemSearchStrings, ["name"]);
    }
    function aspectSearchFilter() {
      return searchFilter(aspectSearchStrings, ["aspect"]);
    }
  };
}

export function reportFiltersToString(filters: ReportFilters, terms: Terms): string {
  let result = "";
  let statusesString = "";
  let reviewedString = "";

  const statusesKeys = Object.keys(filters.statuses || {});
  const reviewedKeys = Object.keys(filters.reviewed || {});

  if (statusesKeys.length !== statuses.length) statusesString = statusesKeys.join(" or");
  if (reviewedKeys.length !== reviewedOptions.length) reviewedString = reviewedKeys.join(" or");

  if (statusesString || reviewedString)
    result =
      `Show only ${pluralize(terms.checkpoint)} which are ` +
      join2([statusesString, reviewedString].filter(Boolean), ", ", " and ");

  return result;
}
