// project-layout.component.ts
//
// Display project layouts.

import {Component, OnInit, Input, ViewContainerRef} from "@angular/core";
import {Observable} from "rxjs";
import {FirebaseListObservable} from "angularfire2/database-deprecated";
import {map, switchMap} from "rxjs/operators";
import {AfValue, Layout, Room, Terms, Unit} from "@nims/red-shared";
import {ConfirmRemoveService} from "@nims/ngutils";

@Component({
  selector: "project-layouts",
  templateUrl: "./project-layouts.component.html",
  styleUrls: ["./project-layouts.component.css"],
})
export class ProjectLayoutsComponent implements OnInit {
  @Input()
  public layouts: FirebaseListObservable<AfValue<Layout>[]>;
  @Input()
  public rooms: FirebaseListObservable<Room[]>;
  @Input()
  public units: FirebaseListObservable<Unit[]>;
  @Input()
  public terms: Terms;

  public layoutsWithUnitNames: Observable<Layout[]>;
  public editable = false;
  public help = false;

  public constructor(
    private confirmRemoveService: ConfirmRemoveService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.layoutsWithUnitNames = this.makeLayoutsWithUnitNames();
  }

  // CRUD
  public create() {
    // TODO: give sequentially increasing layout names?
    const layout = {name: `New ${this.terms.featureSet}`, rooms: {}} as Partial<Layout>;

    // Firebase will not store `rooms`. Code must be aware of this.
    this.layouts.push(layout);
  }

  public remove(layout) {
    this.confirmRemoveService
      .confirm(
        "Really remove layout?",
        `Are you sure you want to remove ${this.terms.featureSet} <b>${
          layout.name
        }<b>? This cannot be undone.`,
        this.viewContainerRef
      )
      .subscribe(res => {
        if (res) this._remove(layout);
      });
  }

  public updateName(layout) {
    this.layouts.update(layout.$key, {name: layout.name} as Partial<Layout>);
  }

  public toggleLayoutRoomMap(layout, room) {
    if (!layout.rooms) layout.rooms = {};
    layout.rooms[room.$key] = !layout.rooms[room.$key];
    this.layouts.update(layout.$key, {rooms: layout.rooms});
  }

  private makeLayoutsWithUnitNames() {
    function getLayoutUnitNames(layoutId, units) {
      return units.filter(unit => unit.layout === layoutId).map(unit => unit.name);
    }

    return this.units.pipe(
      switchMap(unitList =>
        this.layouts.pipe(
          map(layoutList =>
            layoutList.map(layout =>
              Object.assign({}, layout, {
                $key: layout.$key,
                unitNames: getLayoutUnitNames(layout.$key, unitList),
              })
            )
          )
        )
      )
    );
  }

  private _remove(layout) {
    this.layouts.remove(layout.$key);
  }
}
