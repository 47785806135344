// descriptions/descriptions.component.ts
//
// Display and edit template or project descriptions.

import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Input,
  ViewContainerRef,
} from "@angular/core";
import {FirebaseListObservable} from "angularfire2/database-deprecated";
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";
import {pluralize, curlyTokenTagger, parseMadlib} from "@nims/jsutils";
import {AfValue, trackByKey, Terms, Description} from "@nims/red-shared";
import {ConfirmRemoveService} from "@nims/ngutils";
import {ChecklistService} from "../../services/checklist.service";

type List<T> = FirebaseListObservable<AfValue<T>[]>;

@Component({
  selector: "descriptions",
  templateUrl: "./descriptions.component.html",
  styleUrls: ["./descriptions.component.css"],
})
export class DescriptionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public descriptions: List<Description>;

  @Input()
  title: string;
  @Input()
  editable: boolean;
  @Input()
  terms: Terms;

  public help = false;
  public emptyMessage = "Loading, wait...";
  public trackByKey = trackByKey;
  public tokens = {};

  private ref: DatabaseReference;
  private subscription;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private confirmRemoveService: ConfirmRemoveService,
    private readonly checklistService: ChecklistService
  ) {}

  ngOnInit() {
    this.emptyMessage = `No descriptions found`;
    this.ref = (this.descriptions.$ref as DatabaseReference).parent;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    // Calculate the tagged version of the tokens making up each template.
    this.subscription = this.descriptions.subscribe(descriptions =>
      descriptions.forEach(({name, source}) => {
        const {template, options} = parseMadlib(source);
        const tokens = curlyTokenTagger(template);

        this.tokens[name] = tokens.map(({token, interpolatable}) => ({
          token,
          interpolatable,
          options: interpolatable ? Object.keys(options[token.replace(/\W/g, "")] || {}) : [],
        }));
      })
    );
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  // Handle updates to section properties.
  // TOOD: rename this.
  public onChangeName(description: AfValue<Description>) {
    const {name, source} = description;

    this.update(description, {name, source});
  }

  // create a new description.
  public create() {
    return this.checklistService
      .createDescription(this.ref, {})
      .then(() => this.success("Successfully created description"))
      .catch(e => this.error("Failed to create description", e));
  }

  // Remove a section, after confirmation.
  public remove({$key: key, name}: AfValue<Description>) {
    return this.confirmRemove(name).subscribe(res => {
      if (res) {
        this.checklistService
          .removeDescription(this.ref, key)
          .then(() => this.success("Successfully removed description"))
          .catch(e => this.error("Failed to remove description", e));
      }
    });
  }

  // Make sure the user is OK with deleting this description.
  private confirmRemove(name: string) {
    return this.confirmRemoveService.confirm(
      "Really remove description?",
      `Are you sure you want to remove description &ldquo;${name}&rdquo;?
<b>This description will be permanently lost!</b>
All ${pluralize(this.terms.checkpoint)} with this description will be set back to the default.`,
      this.viewContainerRef
    );
  }

  // Update an description property.
  private update({$key: key}: AfValue<Description>, data: Partial<Description>) {
    return this.checklistService
      .updateDescription(this.ref, key, data)
      .then(() => this.success("Successfully updated description"))
      .catch(e => this.error("Failed to update description", e));
  }

  private error(msg: string, err: Error) {
    console.error(msg, err);
  }

  private success(msg: string) {
    console.log(msg);
  }
}
