// visual-report/unit-results.component.js
//
// JS logic for component displaying unit results in visual report.
// This is invoked from the project page.
// It in turn invokes components to display results for each aspect.

import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from "@angular/core";

import { formatDateRange } from "../../../utils/date-range.util";
import { AngularFireDatabase } from "angularfire2/database-deprecated";
import { map, take } from "rxjs/operators";
import { DatabaseReference } from "angularfire2/database-deprecated/interfaces";
import * as firebase from "firebase";
import { SelectItem } from "primeng/primeng";

import { Hash, filter, flatten, join2, mapToArray, truncateDate, uniq, values } from "@nims/jsutils";
import axios from "axios";
import { MatDialog } from "@angular/material/dialog";
import { TemplateRef } from '@angular/core';

import {
  AfValue,
  Aspect,
  Capability,
  ItemResults,
  Mode,
  ModeHash,
  RoomResults,
  SnaggingStatus,
  snagPriorities,
  Subscriber,
  Summary,
  Terms,
  Unit,
  UnitResults,
  fixingStatuses as fixStatuses,
  getProjectModes,
  inspectionStatuses,
  normalizeItemResults,
  rectificationStatuses,
} from "@nims/red-shared";

import { DatabaseService, UserService } from "../../../services/";
import { AngularFireStorage, AngularFireUploadTask } from "angularfire2/storage";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { FirebaseApp } from "angularfire2";
import { Chart } from "chart.js";
import * as pluginLabels from 'chartjs-plugin-labels';

const MODULE_NAME = "console\\unit-results.component";
const LOG = false;


function log(msg: string) {
  if (LOG) console.log(MODULE_NAME, msg);
}


// Make a list of options for PrimeNG.
// There is another one of these lurking elsewhere in the system.
function makeSelectItems(statuses: string[]): SelectItem[] {
  return statuses.map(status => ({
    label: status,
    value: status,
  }));
}

@Component({
  selector: "unit-results",
  templateUrl: "./unit-results.component.html",
  styleUrls: ["./unit-results.component.css"],
})
export class UnitResultsComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  unit: AfValue<Unit>;
  @Input()
  aspects: AfValue<Aspect>[];
  @Input()
  resultsRef: DatabaseReference;
  @Input()
  summary: Summary;
  @Input()
  canEdit: boolean;
  @Input()
  reportDefinitions$;
  @Input()
  subscriber: Subscriber;
  @Input()
  terms: Terms;
  // Dashboard items for this unit.
  @Input()
  items;

  public unitRef;
  public roomsRef;
  public unitResults: UnitResults;

  public inspectedBy;
  public inspectedOn;
  public reviewedByName: string;
  public editNotes = false;
  public subscriber$;
  public projectModes: Partial<ModeHash<boolean>>;
  public projectModesDisplay: string;

  // User may select status of items to view.
  public inspectionStatuses = makeSelectItems(inspectionStatuses);
  public rectificationStatuses = makeSelectItems(rectificationStatuses);
  public fixStatuses = makeSelectItems(fixStatuses);
  public snagPriorities = makeSelectItems(snagPriorities);

  public selectedStatuses = [SnaggingStatus.notOk];
  public selectedRectificationStatuses = rectificationStatuses; // In other words, all of them.
  public selectedFixStatuses = fixStatuses;
  public selectedSnagPriorities = snagPriorities;

  // List of items, by aspect (name). These are calculated here and passed to `<aspect-results>`.
  public aspectItems: any[];

  // Display loading message, since a big unit can take some time to load.
  public loading = true;
  // Display spinner icon, when clicked on refresh button
  public loadingRef = true;
  // Display refresh text when clicked on refresh button
  public showMyMessage = false;
  // Change color of Refresh Icon on clicked
  public colorRef = false;
  // Is the `reviewedBy` information taken from the older field, which does not
  // distinguish between snagging and desnagging?
  // If so, add a footnote to the reviewedBy display.
  public oldReviewedBy = false;

  public allSnaggedBy;
  public allFixedBy;
  public allDesnaggedBy;

  // These three properties are to render the `reviewed by` information in the cover page.
  public snaggingReviewedBy: string;
  public fixReviewedBy: string;
  public desnaggingReviewedBy: string;

  public allSnaggedOn;
  public allFixedOn;
  public allDesnaggedOn;
  public allDesnaggedByArr = [];
  public getField;
  public bunches;
  public emailsent = false;
  public unitDataAvl;
  public customertypeB2C = false;
  public isReportSent = false;

  public fixedKeys = [];
  public fixedValues = [];
  public cantFixedKeys = [];
  public cantFixedValues = [];
  public notSnagKeys = [];
  public notSnagValues = [];

  public allfixedValues = [];

  public DesnagsKey = [];
  public DesnagsValues = [];

  @Input() unitId = '';
  @Input() documentPath;
  @Input() unitName;
  public projectId;
  basePath = '/pdf';
  fileBasePath = '/documents';
  uploadPercent: Observable<number>;
  task: AngularFireUploadTask;

  public projectRef;
  linksDetailList: any[];
  public uploading = false;
  public sending = false;
  public showSummary = false;
  public checkpointAvl = false;
  public snagsAvl = false;
  public highsnagsAvl = false;
  public mediumsnagsAvl = false;
  public snagsroomAvl = false;
  public topsnagsAvl = false;
  public noDesnags = true;

  public labelsArr = [];

  public totalCheckPoints;
  public totalSnagPoints;

  public checkpointsKey;
  public checkpointsValues;
  public snagsKey;
  public snagsValues;

  public desnagKeys = [];
  public desnagValues = [];

  pieChartPlugins = [];

  public checkpointsCount;
  public snagsCount;
  public highPriorityCount;
  public highPriorityKey;
  public highPriorityValues;
  public mediumPriorityCount;
  public mediumPriorityKey;
  public mediumPriorityValues;
  public lowPriorityCount;
  public lowPriorityKey;
  public lowPriorityValues;

  // Hide the status filter control from customers.
  public canFilterVisualReportByStatus = this.userService.can(
    Capability.filterVisualReportByStatus
  );

  private rooms: { [key: string]: RoomResults };
  private unitSubscription;
  private snaggedByNameIds: Set<string>;
  private fixedByNameIds: Set<string>;
  private desnaggedByNameIds: Set<string>;
  public noSnagPriorities: boolean;
  uploadProgress: Observable<number>;

  constructor(
    private db: AngularFireDatabase,
    private userService: UserService,
    private databaseService: DatabaseService,
    private readonly matDialog: MatDialog,
    private fireStorage: AngularFireStorage,
    private readonly firebaseApp: FirebaseApp,
    private readonly matSnackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.pieChartPlugins = [pluginLabels];
    let reportSent = this.summary.isReportSent;
    if (reportSent == true) {
      this.isReportSent = false;
    } else if (reportSent == false || reportSent == undefined) {
      this.isReportSent = true;
    }
    this.projectId = this.route.parent.snapshot.params["id"];
    this.subscriber$ = this.userService.subscriber$;

    // What modes does this project have, based on its summary settings such as `noDensagging`?
    // Based on that, decide what filter to present.
    this.projectModes = getProjectModes(this.summary);
    this.projectModesDisplay = join2(
      Object.keys(filter(this.projectModes, Boolean)),
      ", ",
      " and "
    );

  }

  // Handle changes to input parameters, `unit` in particular.
  ngOnChanges(changes: SimpleChanges) {
    // TODO: ensure switching units does not leak memory.
    this.unitRef = this.resultsRef.child("units").child(this.unit.$key);
    this.roomsRef = this.unitRef.child("rooms");
    this.loading = true;
    this.reload();
    this.emailsent = false;
  }

  public reload() {
    this.loadingRef = true;
    this.allDesnaggedByArr = [];
    //refresh text hide timeout
    setTimeout(() => {
      this.showMyMessage = false;
      this.colorRef = false;
    }, 5000)
    if (this.unitSubscription) this.unitSubscription.unsubscribe();

    this.unitSubscription = this.db
      .object(this.unitRef)
      .pipe(take(1))
      .subscribe((unitResults: UnitResults) => {
        this.unitResults = unitResults;
        const rooms = (this.rooms = unitResults.rooms);
        if (rooms) {
          const roomValues = values(rooms);
          this.inspectedBy = this.calcInspectedBy(roomValues);
          this.inspectedOn = this.getInspectedOnDates(roomValues, "inspectedOn");
          this.aspectItems = this.makeAspectItems(rooms);
        }
        this.noSnagPriorities = unitResults.noSnagPriorities;
        this.calcReviewedBy();
        this.prepareCoverPageData();
        this.loading = false;
        this.loadingRef = false;
        this.showMyMessage = true;
        this.colorRef = true;
      });

    this.checkunitdataexist();
    let ctype = this.summary.customerType;
    if (ctype == "PDQI") {
      this.customertypeB2C = true;
    }
  }

  //get unit wise data for B2C
  public getEmailSummaryDataByUnit() {
    this.sending = true;
    let itemsArr = [];
    this.items.forEach(function (item) {
      if (typeof item.snaggingStatus !== 'undefined') {
        itemsArr.push(item);
      }
    });

    if (itemsArr.length > 0) {
      var unitName = itemsArr[0].unitName;
      var inspectedOndate = new Date((itemsArr[0].snaggedOn)).toLocaleDateString("es-CL");
    }
    let projectName = this.summary.name;
    let customerName = this.summary.customer;
    let customerEmail = this.summary.email;
    let customerAddress = this.summary.address;

    const { user } = this.userService;
    let loggedInUser = user.name;
    let loggedInUserEmail = user.email;

    var unitsArr = [];
    var linksArr = [];

    let finalResltArr = [];
    if (itemsArr.length == 0) {
      const firebaseDatabase = this.firebaseApp.database();
      const projectRef = (this.projectRef = firebaseDatabase.ref(`projects/${this.projectId}`));
      this.db.list(projectRef.child("units")).subscribe(links => {
        this.linksDetailList = links;

        var fileLink = {};
        Object.assign(fileLink, { fileLocation: linksArr });

        for (var i = 0; i < this.linksDetailList.length; i++) {
          if (this.linksDetailList[i].name != 'Inspection Status') {
            const refPath = `pdf/${this.projectId}/${this.linksDetailList[i].name}`;
            const fileRef = this.fireStorage.ref(refPath);
            fileRef.getDownloadURL().subscribe((url) => {
              if (url && url.length > 0) {

                linksArr.push(url);
                const urlList = new URL(url);
                const myArray = urlList.pathname.split("%2F");
                var string = myArray[myArray.length - 1];

                unitsArr.push(string.replace(/ |%20/g, ' '));
              }
            }, err => {
              console.log(err);
              if (err.code_ === 'storage/object-not-found') {
                return Promise.resolve(false);
              }
            });
          }
        }

        var fileUnit = {};
        Object.assign(fileUnit, { unitNames: unitsArr });

        finalResltArr.push(fileUnit);
        finalResltArr.push(fileLink);
      });

      setTimeout(() => {
        this.sendReportToCustomer(finalResltArr, customerEmail, customerName, projectName, customerAddress, loggedInUser, loggedInUserEmail);
      }, 10000);
    }

    //item aspect count obj
    const aspectGroups = itemsArr.reduce((aspectGroups, item) => {
      const aspect = (aspectGroups[item.aspect] || []);
      aspect.push(item);
      aspectGroups[item.aspect] = aspect;
      return aspectGroups;
    }, {});

    const aspectArr = [];
    aspectArr.push(aspectGroups);

    // array of items having snagging status   
    let snaggingrequireditemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];
      let searchbytext = "not OK";

      itemAspectName.filter(function (obj) {
        let serrchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            snaggingrequireditemArr.push(obj);

          }
        }
      });
    });


    //get aspect group with item having snagging status not OK
    const aspectgroupBySnagging = snaggingrequireditemArr.reduce((aspectgroupBySnagging, item) => {
      const aspect = (aspectgroupBySnagging[item.aspect] || []);
      aspect.push(item);
      aspectgroupBySnagging[item.aspect] = aspect;
      return aspectgroupBySnagging;
    }, {});

    // total chekpoints by aspects
    var checpointsByAspectCount = {};
    let totalCheckPoints = 0;
    Object.keys(aspectGroups).forEach(function (key) {
      totalCheckPoints += aspectGroups[key].length;
      Object.assign(checpointsByAspectCount, { total: totalCheckPoints });
      checpointsByAspectCount[key] = aspectGroups[key].length;
    });

    const orderedCheckpointsByAspectCount = Object.keys(checpointsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = checpointsByAspectCount[key];
        return obj;
      },
      {}
    );

    // get snaging item count by each room
    const roomgroupBySnagging = snaggingrequireditemArr.reduce((roomgroupBySnagging, item) => {
      const roomName = (roomgroupBySnagging[item.roomName] || []);
      roomName.push(item);
      roomgroupBySnagging[item.roomName] = roomName;
      return roomgroupBySnagging;
    }, {});

    var snaggingByRoomCount = {};
    Object.keys(roomgroupBySnagging).forEach(function (key) {
      snaggingByRoomCount[key] = roomgroupBySnagging[key].length;
    });

    var sortableSnagByRoom = [];
    for (var nameCount in snaggingByRoomCount) {
      sortableSnagByRoom.push([nameCount, snaggingByRoomCount[nameCount]]);

    }

    // sort snags by room based on descending order
    sortableSnagByRoom.sort(function (a, b) {
      return b[1] - a[1];
    });

    var snagsByRoomSortableObj = sortableSnagByRoom.reduce(function (acc, cur, i) {
      acc[i] = cur;
      return acc;
    }, {});


    const objPriority = {
      'high': { "total": 0 },
      'medium': { "total": 0 },
      'low': { "total": 0 }
    };

    const objAllSnags = { "total": 0 };

    let totalHigh = 0;
    let totalLow = 0;
    let totalMedium = 0;
    let totalSnags = 0;

    Object.keys(aspectgroupBySnagging).forEach(function (key) {
      let getAspectPriority = calSnagPriority(key);
      objPriority.high[key] = getAspectPriority.high;
      totalHigh += getAspectPriority.high;
      objPriority.high.total = totalHigh;

      objPriority.medium[key] = getAspectPriority.medium;
      totalMedium += getAspectPriority.medium;
      objPriority.medium.total = totalMedium;

      objPriority.low[key] = getAspectPriority.low;
      totalLow += getAspectPriority.low;
      objPriority.low.total = totalLow;

      objAllSnags[key] = getAspectPriority.totalsnag;
      totalSnags += getAspectPriority.totalsnag;
      objAllSnags.total = totalSnags;

    });

    const orderedobjAllSnags = Object.keys(objAllSnags).sort().reduce(
      (obj, key) => {
        obj[key] = objAllSnags[key];
        return obj;
      },
      {}
    );

    const orderedobjHighSnags = Object.keys(objPriority.high).sort().reduce(
      (obj, key) => {
        obj[key] = objPriority.high[key];
        return obj;
      },
      {}
    );

    const orderedobjMediumSnags = Object.keys(objPriority.medium).sort().reduce(
      (obj, key) => {
        obj[key] = objPriority.medium[key];
        return obj;
      },
      {}
    );


    var totalsnagsobj = {};
    Object.assign(totalsnagsobj, { totalsnags: orderedobjAllSnags });
    var totalcheckpointsobj = {};
    Object.assign(totalcheckpointsobj, { totalcheckpoints: orderedCheckpointsByAspectCount });
    var highsnagsobj = {};
    Object.assign(highsnagsobj, { highsnags: orderedobjHighSnags });
    var mediumsnagsobj = {};
    Object.assign(mediumsnagsobj, { mediumsnags: orderedobjMediumSnags });
    var snagsbyroomobj = {};
    Object.assign(snagsbyroomobj, { snagsbyroom: snagsByRoomSortableObj });

    finalResltArr.push(totalcheckpointsobj);
    finalResltArr.push(totalsnagsobj);
    finalResltArr.push(highsnagsobj);
    finalResltArr.push(mediumsnagsobj);
    finalResltArr.push(snagsbyroomobj);

    function calSnagPriority(type) {
      let highcount = 0;
      let lowcount = 0;
      let checklen;
      let checkaspect = aspectgroupBySnagging[type];
      checkaspect.forEach(item => {
        checklen = checkaspect.length;
        let checkSnagPriority = item.itemArtifactSet.snagging.snagPriority;
        if (checkSnagPriority == 'high') {
          highcount++;
        } else if (checkSnagPriority == 'low') {
          lowcount++;
        }
      });

      let totalsnagcount = checklen;
      let mediumcount = checklen - (highcount + lowcount);

      let returnobj = { 'totalsnag': totalsnagcount, 'high': highcount, 'medium': mediumcount, 'low': lowcount }
      return returnobj;

    }


    //snag item name obj      
    const topSnagGroups = itemsArr.reduce((topSnagGroups, item) => {

      const itemname = (topSnagGroups[item.name] || []);
      itemname.push(item);
      topSnagGroups[item.name] = itemname;
      return topSnagGroups;
    }, {});

    let snaggeditemArr = [];
    Object.keys(topSnagGroups).forEach(function (key) {
      const itemName = topSnagGroups[key];

      let searchbytext = "not OK";

      itemName.filter(function (obj) {
        let serrchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            snaggeditemArr.push(obj);
          }
        }
      });
    });


    // get by count for same item name
    let snagCountVal = snaggeditemArr.reduce((acc, child) => {
      if (!acc[child.name]) {
        acc[child.name] = 0;
      }
      acc[child.name]++;
      return acc;
    }, {});

    var sortable = [];
    for (var nameCount in snagCountVal) {
      sortable.push([nameCount, snagCountVal[nameCount]]);

    }

    // sort snags based on occurance
    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });


    // limit 10 for top10 snags
    var toptenSnagArr = sortable.slice(0, 10);

    var top10SnagObj = toptenSnagArr.reduce(function (acc, cur, i) {
      acc[i] = cur;
      return acc;
    }, {});

    var top10snagsobj = {};
    Object.assign(top10snagsobj, { top10snags: top10SnagObj });

    finalResltArr.push(top10snagsobj);

    //Total De-snags data    
    // array of items that are de-snagged   
    let desnaggingrequireditemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];
      let searchbytext = "rectified";

      itemAspectName.filter(function (obj) {
        let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            desnaggingrequireditemArr.push(obj);

          }
        }
      });
    });

    const densnagsbyaspectGroups = desnaggingrequireditemArr.reduce((densnagsbyaspectGroups, item) => {
      const aspect = (densnagsbyaspectGroups[item.aspect] || []);
      aspect.push(item);
      densnagsbyaspectGroups[item.aspect] = aspect;
      return densnagsbyaspectGroups;
    }, {});

    var desnagsByAspectCount = {};
    let totalDeSnags = 0;
    Object.keys(densnagsbyaspectGroups).forEach(function (key) {
      totalDeSnags += densnagsbyaspectGroups[key].length;
      Object.assign(desnagsByAspectCount, { total: totalDeSnags });
      desnagsByAspectCount[key] = densnagsbyaspectGroups[key].length;
    });


    //Total Rectified De-snags data    
    // array of items that are de-snagged(having status rectified)   
    let desnaggedrcitemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'rectified') {
            //add this object to the filtered array
            desnaggedrcitemArr.push(obj);
          }
        }
      });
    });

    const densnagsrcbyaspectGroups = desnaggedrcitemArr.reduce((densnagsrcbyaspectGroups, item) => {
      const aspect = (densnagsrcbyaspectGroups[item.aspect] || []);
      aspect.push(item);
      densnagsrcbyaspectGroups[item.aspect] = aspect;
      return densnagsrcbyaspectGroups;
    }, {});

    var desnagsrcByAspectCount = {};
    Object.keys(densnagsrcbyaspectGroups).forEach(function (key) {
      desnagsrcByAspectCount[key] = densnagsrcbyaspectGroups[key].length;
    });

    var desnagsrcCount = {};
    let totalrcDeSnags = 0;
    Object.keys(densnagsrcbyaspectGroups).forEach(function (key) {
      totalrcDeSnags += densnagsrcbyaspectGroups[key].length;
      Object.assign(desnagsrcCount, { total: totalrcDeSnags });
    });

    const orderedrcDesnagsByAspectCount = Object.keys(desnagsrcByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = desnagsrcByAspectCount[key];
        return obj;
      },
      {}
    );

    //Total Partially Rectified De-snags data    
    // array of items that are de-snagged(having status partially rectified)   
    let desnaggedpritemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'partially rectified') {
            //add this object to the filtered array
            desnaggedpritemArr.push(obj);
          }
        }
      });
    });

    const densnagsprbyaspectGroups = desnaggedpritemArr.reduce((densnagsprbyaspectGroups, item) => {
      const aspect = (densnagsprbyaspectGroups[item.aspect] || []);
      aspect.push(item);
      densnagsprbyaspectGroups[item.aspect] = aspect;
      return densnagsprbyaspectGroups;
    }, {});

    var desnagsprByAspectCount = {};

    Object.keys(densnagsprbyaspectGroups).forEach(function (key) {
      desnagsprByAspectCount[key] = densnagsprbyaspectGroups[key].length;
    });

    var desnagsprCount = {};
    let totalprDeSnags = 0;
    Object.keys(densnagsprbyaspectGroups).forEach(function (key) {
      totalprDeSnags += densnagsprbyaspectGroups[key].length;
      Object.assign(desnagsprCount, { total: totalprDeSnags });
    });


    const orderedprDesnagsByAspectCount = Object.keys(desnagsprByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = desnagsprByAspectCount[key];
        return obj;
      },
      {}
    );

    //Total not Rectified De-snags data    
    // array of items that are de-snagged(having status not rectified)   
    let desnaggednritemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'not rectified') {
            //add this object to the filtered array
            desnaggednritemArr.push(obj);
          }
        }
      });
    });

    const densnagsnrbyaspectGroups = desnaggednritemArr.reduce((densnagsnrbyaspectGroups, item) => {
      const aspect = (densnagsnrbyaspectGroups[item.aspect] || []);
      aspect.push(item);
      densnagsnrbyaspectGroups[item.aspect] = aspect;
      return densnagsnrbyaspectGroups;
    }, {});

    var desnagsnrByAspectCount = {};
    Object.keys(densnagsnrbyaspectGroups).forEach(function (key) {
      desnagsnrByAspectCount[key] = densnagsnrbyaspectGroups[key].length;
    });

    var desnagsnrCount = {};
    let totalnrDeSnags = 0;
    Object.keys(densnagsnrbyaspectGroups).forEach(function (key) {
      totalnrDeSnags += densnagsnrbyaspectGroups[key].length;
      Object.assign(desnagsnrCount, { total: totalnrDeSnags });
    });


    const orderednrDesnagsByAspectCount = Object.keys(desnagsnrByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = desnagsnrByAspectCount[key];
        return obj;
      },
      {}
    );

    var totaldesnagssobj = {};
    Object.assign(totaldesnagssobj, { totaldesnags: { rectified: totalrcDeSnags, partiallyrectified: totalprDeSnags, notrectified: totalnrDeSnags } });

    finalResltArr.push(totaldesnagssobj);

    var desnagssobj = {};
    Object.assign(desnagssobj, { desnagsrectified: orderedrcDesnagsByAspectCount, desnagspr: orderedprDesnagsByAspectCount, desnagsnr: orderednrDesnagsByAspectCount });

    // de-snags data extraction end 

    finalResltArr.push(desnagssobj);

    const firebaseDatabase = this.firebaseApp.database();
    const projectRef = (this.projectRef = firebaseDatabase.ref(`projects/${this.projectId}`));

    this.db.list(projectRef.child("units")).subscribe(links => {
      this.linksDetailList = links;
      var unitsArr = [];
      var linksArr = [];

      var fileLink = {};
      Object.assign(fileLink, { fileLocation: linksArr });

      for (var i = 0; i < this.linksDetailList.length; i++) {
        if (this.linksDetailList[i].name != 'Inspection Status') {
          const refPath = `pdf/${this.projectId}/${this.linksDetailList[i].name}`;
          const fileRef = this.fireStorage.ref(refPath);

          fileRef.getDownloadURL().subscribe((url) => {
            if (url && url.length > 0) {
              linksArr.push(url);
              const urlList = new URL(url);
              const myArray = urlList.pathname.split("%2F");
              var string = myArray[myArray.length - 1];

              unitsArr.push(string.replace(/ |%20/g, ' '));
            }
          }, err => {
            console.log(err);
            if (err.code_ === 'storage/object-not-found') {
              return Promise.resolve(true);
            }
          });
        }
      }

      var fileUnit = {};
      Object.assign(fileUnit, { unitNames: unitsArr });

      finalResltArr.push(fileUnit);
      finalResltArr.push(fileLink);

    });

    // console.log(finalResltArr, "finalResultArray");
    const checktotalchepointlen = finalResltArr[0].totalcheckpoints;

    if (Object.keys(checktotalchepointlen).length !== 0) {
      setTimeout(() => {
        this.sendEmailToCustomer(finalResltArr, customerEmail, customerName, projectName, unitName, customerAddress, inspectedOndate, loggedInUser, loggedInUserEmail);
        this.sending = false;
      }, 10000);
    }

  }

  public sendEmailToCustomer(execsummaryData, email, customerName, projectName, unitName, customerAddress, inspectedOndate, sender, loggedInUserEmail) {
    let url = "https://us-central1-nemmadi-console.cloudfunctions.net/sendReportsB2CTest";
    let data = {
      dest: email,
      cname: customerName,
      pname: projectName,
      uname: unitName,
      address: customerAddress,
      date: inspectedOndate,
      sname: sender,
      semail: loggedInUserEmail,
      dataObj: execsummaryData
    }
    axios.post(url, data)
      .then(res => {
        // console.log("res",res);  
        firebase.database().ref('summaries/' + this.projectId).update({
          isReportSent: true
        });
        this.emailsent = true;
        this.isReportSent = false;
        this.snackBar("Summary sent successfully !!");
      })
      .catch(err => {
        //   console.log("error",err);  
      })
  }

  public sendReportToCustomer(execsummaryData, email, customerName, projectName, customerAddress, sender, loggedInUserEmail) {
    let url = "https://us-central1-nemmadi-console.cloudfunctions.net/sendReportsByEmailTest";
    let data = {
      dest: email,
      cname: customerName,
      pname: projectName,
      address: customerAddress,
      sname: sender,
      semail: loggedInUserEmail,
      dataObj: execsummaryData
    }
    axios.post(url, data)
      .then(res => {
        // console.log("res",res);  
        firebase.database().ref('summaries/' + this.projectId).update({
          isReportSent: true
        });
        this.emailsent = true;
        this.isReportSent = false;
        this.snackBar("Summary sent successfully !!");
        this.sending = false;
      })
      .catch(err => {
        //   console.log("error",err);  
      })
  }

  onSendSummary() {
    this.getEmailSummaryDataByUnit();
  }

  ConfirmSendSummary(ref: TemplateRef<any>) {
    this.matDialog.open(ref);
  }

  sendReportTitle() {
    return (this.emailsent ? "Summary Sent" : "Send Summary");
  }

  // Summary dashboard functionality start      
  public getSummaryDataByUnitToDisp() {
    let itemsArr = [];
    this.items.forEach(function (item) {
      if (typeof item.snaggingStatus !== 'undefined') {
        itemsArr.push(item);
      }
    });

    let finalResltArr = [];

    //item aspect count obj
    const aspectGroups = itemsArr.reduce((aspectGroups, item) => {
      const aspect = (aspectGroups[item.aspect] || []);
      aspect.push(item);
      aspectGroups[item.aspect] = aspect;
      return aspectGroups;
    }, {});

    const aspectArr = [];
    aspectArr.push(aspectGroups);

    // array of items having snagging status   
    let snaggingrequireditemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];
      let searchbytext = "not OK";

      itemAspectName.filter(function (obj) {
        let serrchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            snaggingrequireditemArr.push(obj);

          }
        }
      });
    });


    //get aspect group with item having snagging status not OK
    const aspectgroupBySnagging = snaggingrequireditemArr.reduce((aspectgroupBySnagging, item) => {
      const aspect = (aspectgroupBySnagging[item.aspect] || []);
      aspect.push(item);
      aspectgroupBySnagging[item.aspect] = aspect;
      return aspectgroupBySnagging;
    }, {});

    // total chekpoints by aspects
    var checpointsByAspectCount = {};
    let totalCheckPoints = 0;
    Object.keys(aspectGroups).forEach(function (key) {
      totalCheckPoints += aspectGroups[key].length;
      Object.assign(checpointsByAspectCount, { total: totalCheckPoints });
      checpointsByAspectCount[key] = aspectGroups[key].length;
    });

    const orderedCheckpointsByAspectCount = Object.keys(checpointsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = checpointsByAspectCount[key];
        return obj;
      },
      {}
    );

    // get snaging item count by each room
    const roomgroupBySnagging = snaggingrequireditemArr.reduce((roomgroupBySnagging, item) => {
      const roomName = (roomgroupBySnagging[item.roomName] || []);
      roomName.push(item);
      roomgroupBySnagging[item.roomName] = roomName;
      return roomgroupBySnagging;
    }, {});

    var snaggingByRoomCount = {};
    Object.keys(roomgroupBySnagging).forEach(function (key) {
      snaggingByRoomCount[key] = roomgroupBySnagging[key].length;
    });

    var sortableSnagByRoom = [];
    for (var nameCount in snaggingByRoomCount) {
      sortableSnagByRoom.push([nameCount, snaggingByRoomCount[nameCount]]);

    }

    // sort snags by room based on descending order
    sortableSnagByRoom.sort(function (a, b) {
      return b[1] - a[1];
    });

    var snagsByRoomSortableObj = sortableSnagByRoom.reduce(function (acc, cur, i) {
      acc[i] = cur;
      return acc;
    }, {});


    const objPriority = {
      'high': { "total": 0 },
      'medium': { "total": 0 },
      'low': { "total": 0 }
    };

    const objAllSnags = { "total": 0 };

    let totalHigh = 0;
    let totalLow = 0;
    let totalMedium = 0;
    let totalSnags = 0;

    Object.keys(aspectgroupBySnagging).forEach(function (key) {
      let getAspectPriority = calSnagPriority(key);
      objPriority.high[key] = getAspectPriority.high;
      totalHigh += getAspectPriority.high;
      objPriority.high.total = totalHigh;

      objPriority.medium[key] = getAspectPriority.medium;
      totalMedium += getAspectPriority.medium;
      objPriority.medium.total = totalMedium;

      objPriority.low[key] = getAspectPriority.low;
      totalLow += getAspectPriority.low;
      objPriority.low.total = totalLow;

      objAllSnags[key] = getAspectPriority.totalsnag;
      totalSnags += getAspectPriority.totalsnag;
      objAllSnags.total = totalSnags;

    });

    const orderedobjAllSnags = Object.keys(objAllSnags).sort().reduce(
      (obj, key) => {
        obj[key] = objAllSnags[key];
        return obj;
      },
      {}
    );

    const orderedobjHighSnags = Object.keys(objPriority.high).sort().reduce(
      (obj, key) => {
        obj[key] = objPriority.high[key];
        return obj;
      },
      {}
    );

    const orderedobjMediumSnags = Object.keys(objPriority.medium).sort().reduce(
      (obj, key) => {
        obj[key] = objPriority.medium[key];
        return obj;
      },
      {}
    );

    const orderedobjLowSnags = Object.keys(objPriority.low).sort().reduce(
      (obj, key) => {
        obj[key] = objPriority.low[key];
        return obj;
      },
      {}
    );


    var totalsnagsobj = {};
    Object.assign(totalsnagsobj, { totalsnags: orderedobjAllSnags });
    var totalcheckpointsobj = {};
    Object.assign(totalcheckpointsobj, { totalcheckpoints: orderedCheckpointsByAspectCount });
    var highsnagsobj = {};
    Object.assign(highsnagsobj, { highsnags: orderedobjHighSnags });
    var mediumsnagsobj = {};
    Object.assign(mediumsnagsobj, { mediumsnags: orderedobjMediumSnags });
    var lowsnagsobj = {};
    Object.assign(lowsnagsobj, { lowsnags: orderedobjLowSnags });
    var snagsbyroomobj = {};
    Object.assign(snagsbyroomobj, { snagsbyroom: snagsByRoomSortableObj });

    finalResltArr.push(totalcheckpointsobj);
    finalResltArr.push(totalsnagsobj);
    finalResltArr.push(highsnagsobj);
    finalResltArr.push(mediumsnagsobj);
    finalResltArr.push(lowsnagsobj);
    finalResltArr.push(snagsbyroomobj);

    function calSnagPriority(type) {
      let highcount = 0;
      let lowcount = 0;
      let checklen;
      let checkaspect = aspectgroupBySnagging[type];
      checkaspect.forEach(item => {
        checklen = checkaspect.length;
        let checkSnagPriority = item.itemArtifactSet.snagging.snagPriority;
        if (checkSnagPriority == 'high') {
          highcount++;
        } else if (checkSnagPriority == 'low') {
          lowcount++;
        }
      });

      let totalsnagcount = checklen;
      let mediumcount = checklen - (highcount + lowcount);

      let returnobj = { 'totalsnag': totalsnagcount, 'high': highcount, 'medium': mediumcount, 'low': lowcount }
      return returnobj;

    }


    //snag item name obj      
    const topSnagGroups = itemsArr.reduce((topSnagGroups, item) => {

      const itemname = (topSnagGroups[item.name] || []);
      itemname.push(item);
      topSnagGroups[item.name] = itemname;
      return topSnagGroups;
    }, {});

    let snaggeditemArr = [];
    Object.keys(topSnagGroups).forEach(function (key) {
      const itemName = topSnagGroups[key];

      let searchbytext = "not OK";

      itemName.filter(function (obj) {
        let serrchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            snaggeditemArr.push(obj);
          }
        }
      });
    });


    // get by count for same item name
    let snagCountVal = snaggeditemArr.reduce((acc, child) => {
      if (!acc[child.name]) {
        acc[child.name] = 0;
      }
      acc[child.name]++;
      return acc;
    }, {});

    var sortable = [];
    for (var nameCount in snagCountVal) {
      sortable.push([nameCount, snagCountVal[nameCount]]);

    }

    // sort snags based on occurance
    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });


    // limit 10 for top10 snags
    var toptenSnagArr = sortable.slice(0, 10);

    var top10SnagObj = toptenSnagArr.reduce(function (acc, cur, i) {
      acc[i] = cur;
      return acc;
    }, {});

    var top10snagsobj = {};
    Object.assign(top10snagsobj, { top10snags: top10SnagObj });

    finalResltArr.push(top10snagsobj);

    //Total De-snags data    
    // array of items that are de-snagged   
    let desnaggingrequireditemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];
      let searchbytext = "rectified";

      itemAspectName.filter(function (obj) {
        let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            desnaggingrequireditemArr.push(obj);

          }
        }
      });
    });

    const densnagsbyaspectGroups = desnaggingrequireditemArr.reduce((densnagsbyaspectGroups, item) => {
      const aspect = (densnagsbyaspectGroups[item.aspect] || []);
      aspect.push(item);
      densnagsbyaspectGroups[item.aspect] = aspect;
      return densnagsbyaspectGroups;
    }, {});

    var desnagsByAspectCount = {};
    let totalDeSnags = 0;
    Object.keys(densnagsbyaspectGroups).forEach(function (key) {
      totalDeSnags += densnagsbyaspectGroups[key].length;
      Object.assign(desnagsByAspectCount, { total: totalDeSnags });
      desnagsByAspectCount[key] = densnagsbyaspectGroups[key].length;
    });

    //Total Rectified De-snags data    
    // array of items that are de-snagged(having status rectified)   
    let desnaggedrcitemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'rectified') {
            //add this object to the filtered array
            desnaggedrcitemArr.push(obj);
          }
        }
      });
    });

    const densnagsrcbyaspectGroups = desnaggedrcitemArr.reduce((densnagsrcbyaspectGroups, item) => {
      const aspect = (densnagsrcbyaspectGroups[item.aspect] || []);
      aspect.push(item);
      densnagsrcbyaspectGroups[item.aspect] = aspect;
      return densnagsrcbyaspectGroups;
    }, {});

    var desnagsrcByAspectCount = {};
    //let totalrcDeSnags = 0;
    Object.keys(densnagsrcbyaspectGroups).forEach(function (key) {
      //  totalrcDeSnags += densnagsrcbyaspectGroups[key].length;
      //  Object.assign(desnagsrcByAspectCount, {total: totalrcDeSnags});
      desnagsrcByAspectCount[key] = densnagsrcbyaspectGroups[key].length;
    });

    var desnagsrcCount = {};
    let totalrcDeSnags = 0;
    Object.keys(densnagsrcbyaspectGroups).forEach(function (key) {
      totalrcDeSnags += densnagsrcbyaspectGroups[key].length;
      Object.assign(desnagsrcCount, { total: totalrcDeSnags });
    });

    const orderedrcDesnagsByAspectCount = Object.keys(desnagsrcByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = desnagsrcByAspectCount[key];
        return obj;
      },
      {}
    );

    //Total Partially Rectified De-snags data    
    // array of items that are de-snagged(having status partially rectified)   
    let desnaggedpritemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'partially rectified') {
            //add this object to the filtered array
            desnaggedpritemArr.push(obj);
          }
        }
      });
    });

    const densnagsprbyaspectGroups = desnaggedpritemArr.reduce((densnagsprbyaspectGroups, item) => {
      const aspect = (densnagsprbyaspectGroups[item.aspect] || []);
      aspect.push(item);
      densnagsprbyaspectGroups[item.aspect] = aspect;
      return densnagsprbyaspectGroups;
    }, {});

    var desnagsprByAspectCount = {};
    //let totalprDeSnags = 0;
    Object.keys(densnagsprbyaspectGroups).forEach(function (key) {
      //  totalprDeSnags += densnagsprbyaspectGroups[key].length;
      //  Object.assign(desnagsprByAspectCount, {total: totalprDeSnags});
      desnagsprByAspectCount[key] = densnagsprbyaspectGroups[key].length;
    });

    var desnagsprCount = {};
    let totalprDeSnags = 0;
    Object.keys(densnagsprbyaspectGroups).forEach(function (key) {
      totalprDeSnags += densnagsprbyaspectGroups[key].length;
      Object.assign(desnagsprCount, { total: totalprDeSnags });
    });


    const orderedprDesnagsByAspectCount = Object.keys(desnagsprByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = desnagsprByAspectCount[key];
        return obj;
      },
      {}
    );

    //Total not Rectified De-snags data    
    // array of items that are de-snagged(having status not rectified)   
    let desnaggednritemArr = [];
    Object.keys(aspectGroups).forEach(function (key) {
      const itemAspectName = aspectGroups[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'not rectified') {
            //add this object to the filtered array
            desnaggednritemArr.push(obj);
          }
        }
      });
    });

    const densnagsnrbyaspectGroups = desnaggednritemArr.reduce((densnagsnrbyaspectGroups, item) => {
      const aspect = (densnagsnrbyaspectGroups[item.aspect] || []);
      aspect.push(item);
      densnagsnrbyaspectGroups[item.aspect] = aspect;
      return densnagsnrbyaspectGroups;
    }, {});

    var desnagsnrByAspectCount = {};
    //let totalnrDeSnags = 0;
    Object.keys(densnagsnrbyaspectGroups).forEach(function (key) {
      //  totalnrDeSnags += densnagsnrbyaspectGroups[key].length;
      //  Object.assign(desnagsnrByAspectCount, {total: totalnrDeSnags});
      desnagsnrByAspectCount[key] = densnagsnrbyaspectGroups[key].length;
    });

    var desnagsnrCount = {};
    let totalnrDeSnags = 0;
    Object.keys(densnagsnrbyaspectGroups).forEach(function (key) {
      totalnrDeSnags += densnagsnrbyaspectGroups[key].length;
      Object.assign(desnagsnrCount, { total: totalnrDeSnags });
    });


    const orderednrDesnagsByAspectCount = Object.keys(desnagsnrByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = desnagsnrByAspectCount[key];
        return obj;
      },
      {}
    );

    // desnag data

    const getDeSnagArr = this.items.reduce((getDeSnagArr, item) => {
      const aspect = (getDeSnagArr[item.aspect] || []);
      aspect.push(item);
      getDeSnagArr[item.aspect] = aspect;
      return getDeSnagArr;
    }, {});

    this.desnagKeys = [];
    this.desnagValues = [];
    Object.keys(getDeSnagArr).forEach(function (key) {
      const itemAspectName = getDeSnagArr[key];
      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          this.desnagValues.push(obj);
        }
      });
    });

    // fixes data

    let allFixedData = [];
    let fixedArr = [];
    let cantFixArr = [];
    let notSnagArr = [];

    const getFixedArr = this.items.reduce((getFixedArr, item) => {
      const aspect = (getFixedArr[item.aspect] || []);
      aspect.push(item);
      getFixedArr[item.aspect] = aspect;
      return getFixedArr;
    }, {});

    Object.keys(getFixedArr).forEach(function (key) {
      const itemAspectName = getFixedArr[key];
      itemAspectName.filter(function (obj) {
        if (typeof obj.fixingStatus !== 'undefined') {
          allFixedData.push(obj);
          if (obj.fixingStatus == 'fixed') {
            fixedArr.push(obj);
          } else if (obj.fixingStatus == `can't fix`) {
            cantFixArr.push(obj);
          } else if (obj.fixingStatus == 'not a snag') {
            notSnagArr.push(obj);
          }
        }
      });
    });

    const getAllFixedItems = allFixedData.reduce((getAllFixedItems, item) => {
      const aspect = (getAllFixedItems[item.aspect] || []);
      aspect.push(item);
      getAllFixedItems[item.aspect] = aspect;
      return getAllFixedItems;
    }, {});

    this.allfixedValues = [];
    Object.keys(getAllFixedItems).forEach(key => {
      this.allfixedValues.push(getAllFixedItems[key].length);
    });

    const getFixedItems = fixedArr.reduce((getFixedItems, item) => {
      const aspect = (getFixedItems[item.aspect] || []);
      aspect.push(item);
      getFixedItems[item.aspect] = aspect;
      return getFixedItems;
    }, {});

    this.fixedKeys = [];
    this.fixedValues = [];
    Object.keys(getFixedItems).forEach(key => {
      this.fixedKeys.push(key);
      this.fixedValues.push(getFixedItems[key].length);
    });

    const getCantFixedItems = fixedArr.reduce((getCantFixedItems, item) => {
      const aspect = (getCantFixedItems[item.aspect] || []);
      aspect.push(item);
      getCantFixedItems[item.aspect] = aspect;
      return getCantFixedItems;
    }, {});

    this.cantFixedKeys = [];
    this.cantFixedValues = [];
    Object.keys(getCantFixedItems).forEach(key => {
      this.cantFixedKeys.push(key);
      this.cantFixedValues.push(getCantFixedItems[key].length);
    });

    const getNotSnagItems = fixedArr.reduce((getNotSnagItems, item) => {
      const aspect = (getNotSnagItems[item.aspect] || []);
      aspect.push(item);
      getNotSnagItems[item.aspect] = aspect;
      return getNotSnagItems;
    }, {});

    this.notSnagKeys = [];
    this.notSnagValues = [];
    Object.keys(getNotSnagItems).forEach(key => {
      this.notSnagKeys.push(key);
      this.notSnagValues.push(getNotSnagItems[key].length);
    });

    var totaldesnagssobj = {};
    Object.assign(totaldesnagssobj, { totaldesnags: { rectified: totalrcDeSnags, partiallyrectified: totalprDeSnags, notrectified: totalnrDeSnags } });

    finalResltArr.push(totaldesnagssobj);

    var desnagssobj = {};
    Object.assign(desnagssobj, { desnagsrectified: orderedrcDesnagsByAspectCount, desnagspr: orderedprDesnagsByAspectCount, desnagsnr: orderednrDesnagsByAspectCount });

    // de-snags data extraction end 

    finalResltArr.push(desnagssobj);

    //console.log(finalResltArr);

    const checktotalchepointlen = finalResltArr[0].totalcheckpoints;

    if (Object.keys(checktotalchepointlen).length !== 0) {
      this.dispchartsummary(finalResltArr);
    }

  }

  public dispchartsummary(summaryData) {
    // console.log(summaryData);

    var dataObj = summaryData;

    var chart;
    console.log(chart);
    var dataVal;
    var objKeys = [];
    var objValues = [];
    var objKeysD = [];
    var objValuesD = [];
    var objKeyss = [];
    var objValuess = [];
    // var myChart = [];
    // var chartImageUrl = [];
    // var totalVal;
    // var totalValD;
    // var nullChart= false;
    //var title;
    var objKeysCaps = [];
    var objKeysCapsD = [];
    var areaLegend = [];
    var areaAspectsR = [];
    var areaValuesR = [];
    var areaAspectsPR = [];
    var areaValuesPR = [];
    var areaAspectsNR = [];
    var areaValuesNR = [];
    var areaLabels = [];
    var areaLabelsCapsD = [];
    var backgroundColor = ['rgb(244, 109, 67)', 'rgb(50, 136, 189)', 'rgb(171, 221, 164)', 'rgb(102, 194, 165)', 'rgb(230, 245, 152)', 'rgb(230, 245, 152)', 'rgb(253, 174, 97)', 'rgb(244, 109, 67)', '#5d62b5', '#29c3be', '#f2726f', '#ffc533', '#62b58f', '#bc95df', '#67cdf2'];
    // var bgcolorDesnag = ['rgb(143,188,143)', 'rgb(242, 202, 133)', 'rgb(250, 128, 114)'];//chart colors for desnags
    //loop through whole data source object

    for (var i = 0; i < dataObj.length; i++) {

      dataVal = dataObj[i];

      if (Object.keys(dataVal)[0] == 'totalcheckpoints') {
        Object.keys(dataVal.totalcheckpoints).forEach(key => {
          if (dataVal.totalcheckpoints.total == 0) {
            objKeyss.push();
            objValuess.push();
            this.checkpointAvl = false;
          } else {
            var objKeys1 = key;
            objKeyss.push(objKeys1);
            objValuess.push(dataVal.totalcheckpoints[key]);
            this.checkpointAvl = true;
          }
        });
        objKeys.push(objKeyss);
        objValues.push(objValuess);
        objKeyss = [];
        objValuess = [];

      } else if (Object.keys(dataVal)[0] == 'totalsnags') {
        Object.keys(dataVal.totalsnags).forEach(key => {
          if (dataVal.totalsnags.total == 0) {
            objKeyss.push();
            objValuess.push();
            this.snagsAvl = false;
          } else {
            var objKeys1 = key;
            objKeyss.push(objKeys1);
            objValuess.push(dataVal.totalsnags[key]);
            this.snagsAvl = true;
          }

        });
        objKeys.push(objKeyss);
        objValues.push(objValuess);
        objKeyss = [];
        objValuess = [];

      } else if (Object.keys(dataVal)[0] == 'highsnags') {
        Object.keys(dataVal.highsnags).forEach(key => {
          if (dataVal.highsnags.total == 0) {
            objKeyss.push();
            objValuess.push();
            this.highsnagsAvl = false;
          } else {
            var objKeys1 = key;
            objKeyss.push(objKeys1);
            objValuess.push(dataVal.highsnags[key]);
            this.highsnagsAvl = true;
          }

        });
        objKeys.push(objKeyss);
        objValues.push(objValuess);
        objKeyss = [];
        objValuess = [];

      } else if (Object.keys(dataVal)[0] == 'mediumsnags') {
        Object.keys(dataVal.mediumsnags).forEach(key => {
          if (dataVal.mediumsnags.total == 0) {
            objKeyss.push();
            objValuess.push();
            this.mediumsnagsAvl = false;
          } else {
            var objKeys1 = key;
            objKeyss.push(objKeys1);
            objValuess.push(dataVal.mediumsnags[key]);
            this.mediumsnagsAvl = true;
          }
        });
        objKeys.push(objKeyss);
        objValues.push(objValuess);
        objKeyss = [];
        objValuess = [];

      } else if (Object.keys(dataVal)[0] == 'lowsnags') {
        Object.keys(dataVal.lowsnags).forEach(key => {
          if (dataVal.lowsnags.total == 0) {
            objKeyss.push();
            objValuess.push();
          } else {
            var objKeys1 = key;
            objKeyss.push(objKeys1);
            objValuess.push(dataVal.lowsnags[key]);
          }
        });
        objKeys.push(objKeyss);
        objValues.push(objValuess);
        objKeyss = [];
        objValuess = [];

      } else if (Object.keys(dataVal)[0] == 'snagsbyroom') {//data for horizontal bars
        var dataBarArr = [];
        if (Object.keys(dataVal.snagsbyroom).length != 0) {
          var objKeys1 = dataVal.snagsbyroom[0];
          dataBarArr.push(objKeys1);
          for (var j = 0; j < dataBarArr.length; j++) {
            objKeyss.push(dataBarArr[j][0]);
            objValuess.push(dataBarArr[j][1]);
          }
          objKeys.push(objKeyss);
          objValues.push(objValuess);
          objKeyss = [];
          objValuess = [];
          this.snagsroomAvl = true;
        } else {
          this.snagsroomAvl = false;
        }
      }
      else if (Object.keys(dataVal)[0] == 'top10snags') {
        if (Object.keys(dataVal.top10snags).length != 0) {
          Object.keys(dataVal.top10snags).forEach(key => {
            var objKeys1 = dataVal.top10snags[key];
            objKeyss.push(objKeys1[0]);
            objValuess.push(objKeys1[1]);
            this.topsnagsAvl = false;
          });
        } else {
          this.topsnagsAvl = true;
        }
        objKeys.push(objKeyss);
        objValues.push(objValuess);
        objKeyss = [];
        objValuess = [];
      }//end if snagsbyroom top10snags
      else if (Object.keys(dataVal)[0] == 'totaldesnags') {
        Object.keys(dataVal.totaldesnags).forEach(key => {
          var objKeys1 = key;
          if (dataVal.totaldesnags[key] == 0) {
            objKeysD.push();
            objValuesD.push();
          } else {
            objKeysD.push(objKeys1);
            objValuesD.push(dataVal.totaldesnags[key]);
          }

          // if(objKeysD.length>0){
          //  this.totdesnagsAvl = true;
          // }else{
          //   this.totdesnagsAvl = false;
          // }
        });
      } else if (Object.keys(dataVal).length == 3) {
        if (Object.keys(dataVal)[0] == 'desnagsrectified') {
          areaLegend[0] = 'Rectified';

          for (const key of this.labelsArr) {
            dataVal.desnagsrectified[key] = dataVal.desnagsrectified[key] ? dataVal.desnagsrectified[key] : 0;
          }

          const sortedDesnagR = Object.keys(dataVal.desnagsrectified).sort().reduce(function (result, key) {
            result[key] = dataVal.desnagsrectified[key];
            return result;
          }, {});

          Object.keys(sortedDesnagR).forEach(key => {
            var objKeys1 = key;
            areaAspectsR.push(objKeys1);
            areaValuesR.push(sortedDesnagR[key]);
          });
        }
        if (Object.keys(dataVal)[1] == 'desnagspr') {
          areaLegend[1] = 'Partially Rectified';

          for (const key of this.labelsArr) {
            dataVal.desnagspr[key] = dataVal.desnagspr[key] ? dataVal.desnagspr[key] : 0;
          }

          const sortedDesnagPR = Object.keys(dataVal.desnagspr).sort().reduce(function (result, key) {
            result[key] = dataVal.desnagspr[key];
            return result;
          }, {});

          Object.keys(sortedDesnagPR).forEach(key => {
            var objKeys1 = key;
            areaAspectsPR.push(objKeys1);
            areaValuesPR.push(sortedDesnagPR[key]);
          });
        }
        if (Object.keys(dataVal)[2] == 'desnagsnr') {
          areaLegend[2] = 'Not Rectified';

          for (const key of this.labelsArr) {
            dataVal.desnagsnr[key] = dataVal.desnagsnr[key] ? dataVal.desnagsnr[key] : 0;
          }

          const sortedDesnagNR = Object.keys(dataVal.desnagsnr).sort().reduce(function (result, key) {
            result[key] = dataVal.desnagsnr[key];
            return result;
          }, {});

          Object.keys(sortedDesnagNR).forEach(key => {
            var objKeys1 = key;
            areaAspectsNR.push(objKeys1);
            areaValuesNR.push(sortedDesnagNR[key]);
          });
        }

      }


      for (var f = 0; f < areaAspectsR.length; f++) {//loop through rectified desnags
        // if(!areaLabels.includes(areaAspectsR[f])){
        //   areaLabels.push(areaAspectsR[f]);
        // }//end if
      }//end for f
      for (var f = 0; f < areaAspectsPR.length; f++) {//loop through partially rectified desnags
        // if(!areaLabels.includes(areaAspectsPR[f])){
        //   areaLabels.push(areaAspectsPR[f]);
        // }//end if
      }//end for f
      for (var f = 0; f < areaAspectsNR.length; f++) {//loop through not rectified desnags
        // if(!areaLabels.includes(areaAspectsNR[f])){
        //   areaLabels.push(areaAspectsNR[f]);
        // }//end if
      }//end for f

      //capitalize aspects
      for (var j = 0; j < areaLabels.length; j++) {
        areaLabelsCapsD.push(areaLabels[j].charAt(0).toUpperCase() + areaLabels[j].slice(1));
      }//end for j
      // if(chart) chart.destroy();
      // prepare chart config  
      if (Object.keys(dataObj[i])[0] == 'totalcheckpoints') {
        objKeys[i].pop();
        objValues[i].pop();

        //console.log(objValues[0]);

        for (var j = 0; j < objKeys[i].length; j++) {
          objKeysCaps.push(objKeys[i][j].charAt(0).toUpperCase() + objKeys[i][j].slice(1));
          if (objValues[i][j] == 0) {
            // console.log('value null');
            objValues[i][j] = null;
          }
        }

        this.labelsArr = objKeys[i].map(day => day);

        if (objValues[i].length != 0) {
          this.totalCheckPoints = objValues[i];

          // document.getElementById("chart1").innerHTML = '';
          // document.getElementById("chart1").innerHTML = '<canvas id="dougnutChart1"></canvas><span style="position:absolute;top:45%;left:49%;">'+objValues[i].reduce(function(a, b) { return a + b; }, 0)+'</span>';
          // document.getElementById("newchart1").innerHTML = '';
          // document.getElementById("newchart1").innerHTML = '<canvas id="newcheckpoints"></canvas><span style="position:absolute;top:50%;left:50%;">'+objValues[i].reduce(function(a, b) { return a + b; }, 0)+'</span>';
          document.getElementById("newchart2").innerHTML = '';
          document.getElementById("newchart2").innerHTML = '<canvas id="newcheckpoints2"></canvas><span style="position:absolute;top:50%;left:50%;">' + objValues[i].reduce(function (a, b) { return a + b; }, 0) + '</span>';
        }

        // total checkpoint doughnut chart
        // chart = new Chart('dougnutChart1', {
        //   type: 'doughnut',
        //   data: {
        //     labels: labelsArr,
        //     datasets: [{
        //       data: objValues[i],
        //       backgroundColor: backgroundColor
        //     }]
        //   },
        //   options: {
        //     responsive: true,
        //     legend: {
        //       position: 'bottom',
        //       labels: {
        //         padding: 10,
        //         boxWidth: 10,
        //         fontSize: 15,
        //         fontColor: 'black'
        //       }
        //     },
        //     plugins: {
        //       datalabels: {
        //         padding: {
        //           bottom: 0
        //         },
        //         color: 'black',
        //         font: {
        //           size: 12,
        //           family: "verdana",
        //           weight: "600",
        //           lineHeight: 0.1
        //         },

        //         formatter: (value) => {
        //           return value;
        //         }
        //       },
        //       doughnutlabel: {
        //         labels: [{
        //           text: 11,
        //           font: {
        //             size: 20,
        //             family: "verdana",
        //             weight: 'bold'
        //           },
        //           color: '#00416a'
        //         }]
        //       },
        //       labels: {
        //         render: 'percentage',
        //         fontColor: ['black'],
        //         precision: 0
        //       }
        //     }
        //   }
        // });

        // chart = new Chart('newcheckpoints', {
        //   type: 'doughnut',
        //   data: {
        //     labels: this.labelsArr,
        //     datasets: [{
        //       data: objValues[i],
        //       backgroundColor: backgroundColor
        //     }]
        //   },
        //   options: {
        //     responsive: true,
        //     maintainAspectRatio: false,
        //     legend: {
        //       position: 'bottom',
        //       labels: {
        //         padding: 10,
        //         boxWidth: 10,
        //         fontSize: 15,
        //         fontColor: 'black'
        //       }
        //     },
        //     plugins: {
        //       datalabels: {
        //         padding: {
        //           bottom: 0
        //         },
        //         color: 'black',
        //         font: {
        //           size: 12,
        //           family: "verdana",
        //           weight: "600",
        //           lineHeight: 0.1
        //         },

        //         formatter: (value) => {
        //           return value;
        //         }
        //       },
        //       doughnutlabel: {
        //         labels: [{
        //           text: 11,
        //           font: {
        //             size: 20,
        //             family: "verdana",
        //             weight: 'bold'
        //           },
        //           color: '#00416a'
        //         }]
        //       },
        //       labels: {
        //         render: 'percentage',
        //         fontColor: ['black'],
        //         precision: 0
        //       }
        //     }
        //   }
        // });

        const totalCPSortedArray = sortArray(objValues[i], this.labelsArr);

        this.checkpointsCount = totalCPSortedArray[0].reduce((a, b) => a + b, 0);

        this.checkpointsKey = totalCPSortedArray[1];
        this.checkpointsValues = totalCPSortedArray[0];

        chart = new Chart('newcheckpoints2', {
          type: 'doughnut',
          data: {
            labels: totalCPSortedArray[1],
            datasets: [{
              data: totalCPSortedArray[0],
              backgroundColor: backgroundColor
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
              labels: {
                padding: 10,
                boxWidth: 10,
                fontSize: 15,
                fontColor: 'black'
              }
            },
            plugins: {
              datalabels: {
                padding: {
                  bottom: 0
                },
                color: 'black',
                font: {
                  size: 12,
                  family: "verdana",
                  weight: "600",
                  lineHeight: 0.1
                },

                formatter: (value) => {
                  return value;
                }
              },
              doughnutlabel: {
                labels: [{
                  text: 11,
                  font: {
                    size: 20,
                    family: "verdana",
                    weight: 'bold'
                  },
                  color: '#00416a'
                }]
              },
              labels: {
                render: 'value',
                fontColor: ['black'],
                precision: 0
              }
            }
          }
        });

      } else if (Object.keys(dataObj[i])[0] == 'totalsnags') {
        //objKeys[i].splice(0, objKeys[i].length);
        objKeys[i].pop();
        objValues[i].pop();


        for (var j = 0; j < objKeys[i].length; j++) {
          //objKeysCaps.push(objKeys[i][j].charAt(0).toUpperCase() + objKeys[i][j].slice(1));
          if (objValues[i][j] == 0) {
            // console.log('value null');
            objValues[i][j] = null;
          }
        }

        let labelsArr = objKeys[i].map(day => day.charAt(0).toUpperCase() + day.slice(1).toLowerCase());

        if (objValues[i].length != 0) {
          // document.getElementById("chart2").innerHTML = '';
          // document.getElementById("chart2").innerHTML = '<canvas id="dougnutChart2"></canvas><span style="position:absolute;top:50%;left:50%;">'+objValues[i].reduce(function(a, b) { return a + b; }, 0)+'</span>';
          // document.getElementById("newchart3").innerHTML = '';
          // document.getElementById("newchart3").innerHTML = '<canvas id="newsnagsidentified"></canvas><span style="position:absolute;top:50%;left:50%;">'+objValues[i].reduce(function(a, b) { return a + b; }, 0)+'</span>';
          document.getElementById("newchart4").innerHTML = '';
          document.getElementById("newchart4").innerHTML = '<canvas id="newsnagsidentified2"></canvas><span style="position:absolute;top:50%;left:50%;">' + objValues[i].reduce(function (a, b) { return a + b; }, 0) + '</span>';
        }
        // total checkpoint doughnut chart
        // chart = new Chart('dougnutChart2', {
        //   type: 'doughnut',
        //   data: {
        //     labels: labelsArr,
        //     datasets: [{
        //       data: objValues[i],
        //       backgroundColor: backgroundColor
        //     }]
        //   },
        //   options: {
        //     responsive: true,
        //     legend: {
        //       position: 'bottom',
        //       labels: {
        //         padding: 10,
        //         boxWidth: 10,
        //         fontSize: 15,
        //         fontColor: 'black'
        //       }
        //     },
        //     plugins: {
        //       datalabels: {
        //         padding: {
        //           bottom: 0
        //         },
        //         color: 'black',
        //         font: {
        //           size: 12,
        //           family: "verdana",
        //           weight: "600",
        //           lineHeight: 0.1
        //         },
        //         formatter: (value) => {
        //           return value;
        //         }
        //       },
        //       doughnutlabel: {
        //         labels: [{
        //           text: 300,
        //           font: {
        //             size: 20,
        //             family: "verdana",
        //             weight: 'bold'
        //           },
        //           color: '#00416a'
        //         }]
        //       },
        //       labels: {
        //         render: 'value',
        //         fontColor: ['black'],
        //         precision: 0
        //       }
        //     }
        //   }
        // });

        // chart = new Chart('newsnagsidentified', {
        //   type: 'doughnut',
        //   data: {
        //     labels: labelsArr,
        //     datasets: [{
        //       data: objValues[i],
        //       backgroundColor: backgroundColor
        //     }]
        //   },
        //   options: {
        //     responsive: true,
        //     maintainAspectRatio: false,
        //     legend: {
        //       position: 'bottom',
        //       labels: {
        //         padding: 10,
        //         boxWidth: 10,
        //         fontSize: 15,
        //         fontColor: 'black'
        //       }
        //     },
        //     plugins: {
        //       datalabels: {
        //         padding: {
        //           bottom: 0
        //         },
        //         color: 'black',
        //         font: {
        //           size: 12,
        //           family: "verdana",
        //           weight: "600",
        //           lineHeight: 0.1
        //         },
        //         formatter: (value) => {
        //           return value;
        //         }
        //       },
        //       doughnutlabel: {
        //         labels: [{
        //           text: 300,
        //           font: {
        //             size: 20,
        //             family: "verdana",
        //             weight: 'bold'
        //           },
        //           color: '#00416a'
        //         }]
        //       },
        //       labels: {
        //         render: 'percentage',
        //         fontColor: ['black'],
        //         precision: 0
        //       }
        //     }
        //   }
        // });

        const totalSnagsSortedArray = sortArray(objValues[i], labelsArr);

        this.snagsCount = totalSnagsSortedArray[0].reduce((a, b) => a + b, 0);
        this.snagsKey = totalSnagsSortedArray[1];
        this.snagsValues = totalSnagsSortedArray[0];

        this.totalSnagPoints = objValues[i];
        chart = new Chart('newsnagsidentified2', {
          type: 'doughnut',
          data: {
            labels: totalSnagsSortedArray[1],
            datasets: [{
              data: totalSnagsSortedArray[0],
              backgroundColor: backgroundColor
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
              labels: {
                padding: 10,
                boxWidth: 10,
                fontSize: 15,
                fontColor: 'black'
              }
            },
            plugins: {
              datalabels: {
                padding: {
                  bottom: 0
                },
                color: 'black',
                font: {
                  size: 12,
                  family: "verdana",
                  weight: "600",
                  lineHeight: 0.1
                },
                formatter: (value) => {
                  return value;
                }
              },
              doughnutlabel: {
                labels: [{
                  text: 300,
                  font: {
                    size: 20,
                    family: "verdana",
                    weight: 'bold'
                  },
                  color: '#00416a'
                }]
              },
              labels: {
                render: 'value',
                fontColor: ['black'],
                precision: 0
              }
            }
          }
        });

      } else if (Object.keys(dataObj[i])[0] == 'highsnags') {
        //objKeys[i].splice(0, objKeys[i].length);
        objKeys[i].pop();
        objValues[i].pop();

        for (var j = 0; j < objKeys[i].length; j++) {
          objKeysCaps.push(objKeys[i][j].charAt(0).toUpperCase() + objKeys[i][j].slice(1));
          // if (objValues[i][j] == 0) {
          //   // console.log('value null');
          //   objValues[i][j] = null;
          // }
        }

        let labelsArr = objKeys[i].map(day => day.charAt(0).toUpperCase() + day.slice(1).toLowerCase());


        if (objValues[i].length != 0) {
          // document.getElementById("chart3").innerHTML = '';
          // document.getElementById("chart3").innerHTML = '<canvas id="dougnutChart3"></canvas><span style="position:absolute;top:45%;left:49%;">'+objValues[i].reduce(function(a, b) { return a + b; }, 0)+'</span>';
          document.getElementById("newchart6").innerHTML = '';
          document.getElementById("newchart6").innerHTML = '<canvas id="highpriority"></canvas>';
        }

        // total checkpoint doughnut chart
        // chart = new Chart('dougnutChart3', {
        //   type: 'doughnut',
        //   data: {
        //     labels: labelsArr,
        //     datasets: [{
        //       data: objValues[i],
        //       backgroundColor: backgroundColor
        //     }]
        //   },
        //   options: {
        //     responsive: true,
        //     legend: {
        //       position: 'bottom',
        //       labels: {
        //         padding: 10,
        //         boxWidth: 10,
        //         fontSize: 15,
        //         fontColor: 'black'
        //       }
        //     },
        //     plugins: {
        //       datalabels: {
        //         padding: {
        //           bottom: 0
        //         },
        //         color: 'black',
        //         font: {
        //           size: 12,
        //           family: "verdana",
        //           weight: "600",
        //           lineHeight: 0.1
        //         },
        //         formatter: (value) => {
        //           return value;
        //         }
        //       },
        //       doughnutlabel: {
        //         labels: [{
        //           text: 300,
        //           font: {
        //             size: 20,
        //             family: "verdana",
        //             weight: 'bold'
        //           },
        //           color: '#00416a'
        //         }]
        //       }
        //     }
        //   }
        // });

        const highPrioritySortedArray = sortArray(objValues[i], labelsArr);

        this.highPriorityCount = highPrioritySortedArray[0].reduce((a, b) => a + b, 0);
        this.highPriorityKey = highPrioritySortedArray[1];
        this.highPriorityValues = highPrioritySortedArray[0];

        chart = new Chart('highpriority', {
          type: 'bar',
          data: {
            labels: highPrioritySortedArray[1],
            datasets: [{
              data: highPrioritySortedArray[0],
              backgroundColor: backgroundColor
            }]
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: 'black',
              rotation: 270,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
            plugins: {
              labels: {
                showZero: false,
                render: 'value',
                fontColor: ['black'],
                textMargin: -5
              }
            },
            scales: {
              xAxes: [{
                ticks: {
                  fontSize: 10,
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
                }
              }],
              yAxes: [{
                ticks: {
                  userCallback: function (value) {
                    if ((value / 1000) >= 1) {
                      value = value / 1000 + "K";
                    }
                    return value;
                  },
                  fontSize: 10,
                  beginAtZero: true,
                  stepSize: 1
                }
              }]
            }
          }
        });

      } else if (Object.keys(dataObj[i])[0] == 'mediumsnags') {
        //objKeys[i].splice(0, objKeys[i].length);
        objKeys[i].pop();
        objValues[i].pop();

        for (var j = 0; j < objKeys[i].length; j++) {
          objKeysCaps.push(objKeys[i][j].charAt(0).toUpperCase() + objKeys[i][j].slice(1));
          if (objValues[i][j] == 0) {
            // console.log('value null');
            objValues[i][j] = null;
          }
        }

        let labelsArr = objKeys[i].map(day => day.charAt(0).toUpperCase() + day.slice(1).toLowerCase());

        if (objValues[i].length != 0) {
          // document.getElementById("chart4").innerHTML = '';
          // document.getElementById("chart4").innerHTML = '<canvas id="dougnutChart4"></canvas><span style="position:absolute;top:45%;left:49%;">'+objValues[i].reduce(function(a, b) { return a + b; }, 0)+'</span>';
          document.getElementById("newchart7").innerHTML = '';
          document.getElementById("newchart7").innerHTML = '<canvas id="mediumpriority"></canvas>';
        }

        // total checkpoint doughnut chart
        // chart = new Chart('dougnutChart4', {
        //   type: 'doughnut',
        //   data: {
        //     labels: labelsArr,
        //     datasets: [{
        //       data: objValues[i],
        //       backgroundColor: backgroundColor
        //     }]
        //   },
        //   options: {
        //     responsive: true,
        //     legend: {
        //       position: 'bottom',
        //       labels: {
        //         padding: 10,
        //         boxWidth: 10,
        //         fontSize: 15,
        //         fontColor: 'black'
        //       }
        //     },
        //     plugins: {
        //       datalabels: {
        //         padding: {
        //           bottom: 0
        //         },
        //         color: 'black',
        //         font: {
        //           size: 12,
        //           family: "verdana",
        //           weight: "600",
        //           lineHeight: 0.1
        //         },
        //         formatter: (value) => {
        //           return value;
        //         }
        //       },
        //       doughnutlabel: {
        //         labels: [{
        //           text: 300,
        //           font: {
        //             size: 20,
        //             family: "verdana",
        //             weight: 'bold'
        //           },
        //           color: '#00416a'
        //         }]
        //       }
        //     }
        //   }
        // });

        const mediumPrioritySortedArray = sortArray(objValues[i], labelsArr);

        this.mediumPriorityCount = mediumPrioritySortedArray[0].reduce((a, b) => a + b, 0);
        this.mediumPriorityKey = mediumPrioritySortedArray[1];
        this.mediumPriorityValues = mediumPrioritySortedArray[0];

        chart = new Chart('mediumpriority', {
          type: 'bar',
          data: {
            labels: mediumPrioritySortedArray[1],
            datasets: [{
              data: mediumPrioritySortedArray[0],
              backgroundColor: backgroundColor
            }]
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: 'black',
              rotation: 270,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
            plugins: {
              labels: {
                showZero: false,
                render: 'value',
                fontColor: ['black'],
                textMargin: -5
              }
            },
            scales: {
              xAxes: [{
                ticks: {
                  fontSize: 10,
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
                }
              }],
              yAxes: [{
                ticks: {
                  userCallback: function (value) {
                    if ((value / 1000) >= 1) {
                      value = value / 1000 + "K";
                    }
                    return value;
                  },
                  fontSize: 10,
                  beginAtZero: true,
                  stepSize: 1
                }
              }]
            }
          }
        });

      }
      else if (Object.keys(dataObj[i])[0] == 'lowsnags') {
        objKeys[i].pop();
        objValues[i].pop();

        for (var j = 0; j < objKeys[i].length; j++) {
          objKeysCaps.push(objKeys[i][j].charAt(0).toUpperCase() + objKeys[i][j].slice(1));
          // if (objValues[i][j] == 0) {
          //   objValues[i][j] = null;
          // }
        }

        let labelsArr = objKeys[i].map(day => day.charAt(0).toUpperCase() + day.slice(1).toLowerCase());

        if (objValues[i].length != 0) {
          document.getElementById("newchart8").innerHTML = '';
          document.getElementById("newchart8").innerHTML = '<canvas id="lowpriority"></canvas>';
        }

        const lowPrioritySortedArray = sortArray(objValues[i], labelsArr);

        this.lowPriorityCount = lowPrioritySortedArray[0].reduce((a, b) => a + b, 0);
        this.lowPriorityKey = lowPrioritySortedArray[1];
        this.lowPriorityValues = lowPrioritySortedArray[0];

        chart = new Chart('lowpriority', {
          type: 'bar',
          data: {
            labels: lowPrioritySortedArray[1],
            datasets: [{
              data: lowPrioritySortedArray[0],
              backgroundColor: backgroundColor
            }]
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: 'black',
              rotation: 270,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
            plugins: {
              labels: {
                showZero: false,
                render: 'value',
                fontColor: ['black'],
                textMargin: -5
              }
            },
            scales: {
              xAxes: [{
                ticks: {
                  fontSize: 10,
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
                }
              }],
              yAxes: [{
                ticks: {
                  userCallback: function (value) {
                    if ((value / 1000) >= 1) {
                      value = value / 1000 + "K";
                    }
                    return value;
                  },
                  fontSize: 10,
                  beginAtZero: true,
                  stepSize: 1
                }
              }]
            }
          }
        });
      }
      else if (Object.keys(dataObj[i])[0] == 'snagsbyroom') {

        if (objValues[i].length != 0) {
          // document.getElementById("chart5").innerHTML = '';
          // document.getElementById("chart5").innerHTML = '<canvas id="barchart1"></canvas>';
        }

        // snags by room bar chart 
        // chart = new Chart('barchart1', {
        //   type: 'horizontalBar',
        //   data: {
        //     labels: objKeys[i],
        //     datasets: [
        //       {
        //         label: 'Snags by Room',
        //         data: objValues[i],
        //         backgroundColor: ['rgb(32,178,170)', 'rgb(72,209,204)', 'rgb(155,221,255)', 'rgb(135,206,235)', 'rgb(171,205,239)', 'rgb(137,207,240)', 'rgb(138,185,241)', 'rgb(135,206,235)', 'rgb(140,190,214)', 'rgb(161,202,241)', 'rgb(206,200,239)', 'rgb(214,202,221)', 'rgb(204,204,255)', 'rgb(216,191,216)', 'rgb(0,206,209)', 'rgb(166,231,255)', 'rgb(150,222,209)', 'rgb(227,218,201)', 'rgb(245,222,179)', 'rgb(255,203,164)', 'rgb(251,206,177)', 'rgb(240,220,130)', 'rgb(232,244,140)', 'rgb(209,225,137)', 'rgb(245,222,179)', 'rgb(227,218,201)', 'rgb(255,182,193)', 'rgb(251,204,231)', 'rgb(255,188,217)', 'rgb(255,192,203)', 'rgb(255,193,204)'],
        //       },
        //     ],
        //   },
        //   options: {
        //     responsive: true,
        //     legend: {
        //       display: false,
        //       position: 'bottom',
        //       labels: {
        //         padding: 20,
        //         boxWidth: 10,
        //         fontSize: 11,
        //         fontColor: 'black'
        //       }
        //     },
        //     scales: {
        //       yAxes: [{
        //         ticks: {
        //           fontSize: 10,
        //           fontColor: 'black',
        //           callback: function (label) {
        //             if (label.length <= 30) {
        //               return label.substr(0, label.length)
        //             }
        //             else if (label.length > 60) {
        //               return label.substr(0, 30) + '-\n' + label.substr(30, 30) + '...';
        //             }
        //             else if (label.length > 30) {
        //               return label.substr(0, 30) + '-\n' + label.substr(30, label.length);
        //             }
        //             else {
        //               return label;
        //             }
        //           },
        //           autoSkip: false,
        //         }
        //       }],
        //       xAxes: [{
        //         ticks: {
        //           min: 0,
        //           fontSize: 12,
        //           fontColor: 'black',
        //         }
        //       }]
        //     },
        //     plugins: {
        //       datalabels: {
        //         padding: {
        //           bottom: 0
        //         },
        //         font: {
        //           size: 12,
        //           family: "cursive",
        //           weight: "600",
        //           lineHeight: 0.1
        //         },

        //         anchor: 'center',
        //         align: 'center',
        //         clamp: true,
        //         formatter: (value) => {
        //           return value;
        //         }
        //       }
        //     }
        //   },
        // });
      } else if (Object.keys(dataObj[i])[0] == 'top10snags') {

        if (objValues[i].length != 0) {
          // document.getElementById("chart6").innerHTML = '';
          // document.getElementById("chart6").innerHTML = '<canvas id="barchart2"></canvas>';
        }
        // top 10 snags bar chart 
        // chart = new Chart('barchart2', {
        //   type: 'horizontalBar',
        //   data: {
        //     labels: objKeys[i],
        //     datasets: [
        //       {
        //         label: 'Top 10 Snags',
        //         data: objValues[i],
        //         backgroundColor: ['rgb(232, 171, 45)', 'rgb(248,184,120)', 'rgb(255,200,124)', 'rgb(239,204,0)', 'rgb(194,178,128)', 'rgb(176,191,26)', 'rgb(154,185,115)', 'rgb(161, 196, 140)', 'rgb(160,214,180)', 'rgb(172,225,175)'],
        //       },
        //     ],
        //   },
        //   options: {
        //     responsive: true,
        //     legend: {
        //       display: false,
        //       position: 'bottom',
        //       labels: {
        //         padding: 20,
        //         boxWidth: 10,
        //         fontSize: 11,
        //         fontColor: 'black'
        //       }
        //     },
        //     scales: {
        //       yAxes: [{
        //         ticks: {
        //           fontSize: 10,
        //           fontColor: 'black',
        //           callback: function (label) {
        //             if (label.length <= 30) {
        //               return label.substr(0, label.length)
        //             }
        //             else if (label.length > 60) {
        //               return label.substr(0, 30) + '-\n' + label.substr(30, 30) + '...';
        //             }
        //             else if (label.length > 30) {
        //               return label.substr(0, 30) + '-\n' + label.substr(30, label.length);
        //             }
        //             else {
        //               return label;
        //             }
        //           },
        //           autoSkip: false,
        //         }
        //       }],
        //       xAxes: [{
        //         ticks: {
        //           min: 0,
        //           fontSize: 12,
        //           fontColor: 'black',
        //         }
        //       }]
        //     },
        //     plugins: {
        //       datalabels: {
        //         padding: {
        //           bottom: 0
        //         },
        //         font: {
        //           size: 12,
        //           family: "cursive",
        //           weight: "600",
        //           lineHeight: 0.1
        //         },

        //         anchor: 'center',
        //         align: 'center',
        //         clamp: true,
        //         formatter: (value) => {
        //           return value;
        //         }
        //       }
        //     }
        //   },
        // });

      } else if (Object.keys(dataObj[i])[0] == 'totaldesnags') {
        for (var j = 0; j < objKeysD.length; j++) {//loop through totaldesnags keys
          if (objKeysD.length != 0) {//if totaldesnags not null
            if (objKeysD[j] == 'rectified') {
              objKeysCapsD.push('Rectified');
            }
            if (objKeysD[j] == 'partiallyrectified') {
              objKeysCapsD.push('Partially Rectified');
            }
            if (objKeysD[j] == 'notrectified') {
              objKeysCapsD.push('Not Rectified');
            }
            if (objValuesD[j] == 0) {
              objValuesD[j] = null;
            }//end if
          }//end if
        }//end for j


        // document.getElementById("chart7").innerHTML = '';
        if (objValuesD.length != 0) {
          // document.getElementById("chart7").innerHTML = '<canvas id="dougnutChart5"></canvas><span style="position:absolute;top:45%;left:49%;">'+objValuesD.reduce(function(a, b) { return a + b; }, 0)+'</span>';
        } else {
          // document.getElementById("chart7").innerHTML = '<div style="text-align: center;"><img style="height: 30vh;" src="../../assets/images/nodata.svg"></div>';
        }

        // total checkpoint doughnut chart
        // chart = new Chart('dougnutChart5', {
        //   type: 'doughnut',
        //   data: {
        //     datasets: [
        //       {
        //         data: objValuesD,
        //         backgroundColor: bgcolorDesnag
        //       },
        //     ],
        //     labels: objKeysCapsD,
        //   },
        //   options: {
        //     responsive: true,
        //     legend: {
        //       position: 'bottom',
        //       labels: {
        //         padding: 10,
        //         boxWidth: 10,
        //         fontSize: 15,
        //         fontColor: 'black'
        //       }
        //     },
        //     plugins: {
        //       datalabels: {
        //         padding: {
        //           bottom: 0
        //         },
        //         color: 'black',
        //         font: {
        //           size: 12,
        //           family: "verdana",
        //           weight: "600",
        //           lineHeight: 0.1
        //         },
        //         formatter: (value) => {
        //           return value;
        //         }
        //       },
        //       doughnutlabel: {
        //         labels: [{
        //           text: 300,
        //           font: {
        //             size: 20,
        //             family: "verdana",
        //             weight: 'bold'
        //           },
        //           color: '#00416a'
        //         }]
        //       }
        //     }
        //   }
        // });

      } else {
        // document.getElementById("chart8").innerHTML = '';
        // document.getElementById("chart8").innerHTML = '<canvas id="areachart"></canvas>';
        if (areaValuesR.length == 0 && areaValuesPR.length == 0 && areaValuesNR.length == 0) {
          this.noDesnags = false;
        }
        else if (areaValuesR || areaValuesPR || areaValuesNR) {
          document.getElementById("newchart5").innerHTML = '';
          document.getElementById("newchart5").innerHTML = '<canvas id="totaldesnags"></canvas>';
        }
        // chart = new Chart('areachart', {
        //   type: 'line',
        //   data: {
        //     labels: areaLabelsCapsD,
        //     datasets: [
        //       {
        //         label: areaLegend[0],
        //         fill: true,
        //         backgroundColor: 'rgb(143,188,143)',
        //         borderColor: 'rgb(143,188,143)',
        //         data: areaValuesR,
        //       },
        //       {
        //         label: areaLegend[1],
        //         fill: true,
        //         backgroundColor: 'rgb(242, 202, 133)',
        //         borderColor: 'rgb(242, 202, 133)',
        //         data: areaValuesPR,
        //       },
        //       {
        //         label: areaLegend[2],
        //         fill: true,
        //         backgroundColor: 'rgb(250, 128, 114)',
        //         borderColor: 'rgb(250, 128, 114)',
        //         data: areaValuesNR
        //       },
        //     ],
        //   },
        //   options: {
        //     scales: {
        //       xAxes: [
        //         {
        //           display: true
        //         },
        //       ],
        //       yAxes: [
        //         {
        //           display: true,
        //           stacked: true,
        //         },
        //       ],
        //     },
        //     legend: {
        //       position: 'bottom',
        //       labels: {
        //         padding: 10,
        //         boxWidth: 10,
        //         fontSize: 12,
        //         fontColor: 'black'
        //       }
        //     },
        //     plugins: {
        //       datalabels: {
        //         anchor: 'end',
        //         align: 'center',
        //         color: 'black',
        //         //  rotation: 270,
        //         font: {
        //           weight: 'normal',
        //           size: 12,
        //         },
        //       },
        //     },
        //   },
        // });

        chart = new Chart('totaldesnags', {
          type: 'bar',
          data: {
            labels: this.labelsArr,
            datasets: [{
              label: 'Rectified',
              data: areaValuesR,
              backgroundColor: 'rgb(244, 109, 67)',
            },
            {
              label: 'Partially Rectified',
              data: areaValuesPR,
              backgroundColor: 'rgb(50, 136, 189)',
            },
            {
              label: 'Not Rectified',
              data: areaValuesNR,
              backgroundColor: 'rgb(171, 221, 164)',
            },
            ]
          },
          options: {
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                stacked: false
              }],
              yAxes: [{
                stacked: false,
                ticks: {
                  beginAtZero: true,
                  stepSize: 1
                }
              }]
            },
            plugins: {
              labels: {
                render: () => { }
              }
            }
          }
        });

        chart = new Chart('fixes', {
          type: 'bar',
          data: {
            labels: this.fixedKeys,
            datasets: [
              {
                label: 'Cant fix',
                backgroundColor: 'rgb(250, 128, 114)',
                data: this.cantFixedValues
              },
              {
                label: 'Fixed',
                backgroundColor: 'rgb(171, 221, 164)',
                data: this.fixedValues
              },
              {
                label: 'Not a Snag',
                backgroundColor: 'rgba(242, 202, 133, 0.5)',
                data: this.notSnagValues
              }
            ],
          },
          options: {
            scales: {
              xAxes: [{
                stacked: false,
                ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
                }
              }],
              yAxes: [{
                stacked: false,
                ticks: {
                  beginAtZero: true
                }
              }]
            },
            responsive: true,
            legend: {
              display: true,
              position: 'bottom'
            },
            plugins: {
              labels: {
                showZero: false,
                render: 'value',
                fontColor: ['black'],
                textMargin: 0
              }
            }
          }
        });

        const fixedFillArr = fillArray(this.snagsKey, this.fixedKeys, this.allfixedValues);
        const desnagFillArr = fillArray(this.snagsKey, this.desnagKeys, this.desnagValues);

        console.log(fixedFillArr);

        chart = new Chart('allinone', {
          type: 'bar',
          data: {
            labels: this.snagsKey,
            datasets: [
              {
                label: 'Total Snags',
                backgroundColor: 'rgb(250, 128, 114)',
                data: this.snagsValues
              },
              {
                label: 'Total Desnags',
                backgroundColor: 'rgb(171, 221, 164)',
                data: desnagFillArr
              },
              {
                label: 'Total Fixes',
                backgroundColor: 'rgba(242, 202, 133, 0.5)',
                data: fixedFillArr
              }
            ],
          },
          options: {
            scales: {
              xAxes: [{
                stacked: false,
                ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
                }
              }],
              yAxes: [{
                stacked: false,
                ticks: {
                  beginAtZero: true
                }
              }]
            },
            responsive: true,
            legend: {
              display: true,
              position: 'bottom'
            },
            plugins: {
              labels: {
                showZero: false,
                render: 'value',
                fontColor: ['black'],
                textMargin: 0
              }
            }
          }
        });

      }

    }

  }

  onDispSummary() {
    this.getSummaryDataByUnitToDisp();
    this.showSummary = true;
  }

  public checkunitdataexist() {
    const unitData = this.items;
    if (unitData.length == 0) {
      this.unitDataAvl = true;
    } else {
      this.unitDataAvl = false;
    }
  }

  allowMeSendReport() {
    firebase.database().ref('summaries/' + this.projectId).update({
      isReportSent: false
    });
  }

  ngOnDestroy() {
    if (this.unitSubscription) this.unitSubscription.unsubscribe();
  }

  public onChangeSelectedStatuses() {
    this.recalc();
  }

  public onChangeSelectedRectificationStatuses() {
    this.recalc();
  }

  public onChangeSelectedFixStatuses() {
    this.recalc();
  }

  public onChangeSelectedSnagPriorities() {
    this.recalc();
  }

  // Each unit can be marked as reviewed by.
  // We update both `reviewedOn` and the previous misspelled `reivewedOn` properties.
  public markReviewed() {
    const uid = this.userService.uid;
    const userName = this.userService.user.name;

    switch (this.unit.status) {
      case "snagging":
        this.unitRef.update({
          snaggingReviewedBy: uid,
          snaggingReviewedOn: firebase.database.ServerValue.TIMESTAMP,
        });
        break;

      case "fixing":
        this.unitRef.update({
          fixingReviewedBy: uid,
          fixingReviewedOn: firebase.database.ServerValue.TIMESTAMP,
        });
        break;

      case "desnagging":
        this.unitRef.update({
          desnaggingReviewedBy: uid,
          desnaggingReviewedOn: firebase.database.ServerValue.TIMESTAMP,
        });
        break;
    }

    // Update this in component so reflected in display.
    this.reviewedByName = userName;
  }

  // Clear review information, when "Remove review" button is clicked.
  public markUnreviewed() {
    const clear = { reviewedBy: "", reviewedOn: 0, reivewedOn: 0 };

    switch (this.unit.status) {
      case "snagging":
      case "snagging complete":
        this.unitRef.update({
          snaggingReviewedBy: "",
          snaggingReviewedOn: 0,
          ...clear,
        } as Partial<UnitResults>);
        break;
      case "fixing":
      case "fixing complete":
        this.unitRef.update({
          fixingReviewedBy: "",
          fixingReviewedOn: 0,
          ...clear,
        } as Partial<UnitResults>);
        break;
      case "desnagging":
      case "desnagging complete":
        this.unitRef.update({
          desnaggingReviewedBy: "",
          desnaggingReviewedOn: 0,
          ...clear,
        } as Partial<UnitResults>);
        break;
      default:
        console.error("Invalid status in unit-results.component");
    }

    this.reviewedByName = "";
  }

  // User has clicked the "Print" button.
  public print() {
    window.print();
  }

  private async prepareCoverPageData() {
    this.resetAllValues();
    await this.deriveAllInspectorDetails(); //error is here
    this.deriveAllReviewers();
  }

  // Retrive inspector details for all stages
  private async deriveAllInspectorDetails() {
    const rooms = this.unitResults.rooms;
    if (rooms) {
      const roomValues = values(rooms);
      this.allSnaggedBy = await this.deriveAllInspectedBy(
        roomValues
          .map(room => room.snaggedBy)
          .filter(Boolean)
          .map(snaggedBy => snaggedBy.split(/\s*,\s*/))
      );
      try {
        // In case snaggedBy is not available, it gets the inspectedBy (or) snagging object (from ItemArtifactSet)      
        if (!this.allSnaggedBy) {
          await this.fetchAllInspectorDetails(
            roomValues,
            roomValues
              .map(room => room.inspectedBy)
              .filter(Boolean)
              .map(inspectedBy => inspectedBy.split(/\s*,\s*/)),
            function () {
              return type => type && type.snagging;
            },
            Mode.SNAGGING
          );
        }
      } catch (err) {
        log("#deriveAllInspectorDetails: Snagging inspector unavailable");
      }
      // console.log(this.allSnaggedBy);
      try {
        // It gets the fixedBy (or) fixing object (from ItemArtifactSet)
        await this.fetchAllInspectorDetails(
          roomValues,
          roomValues
            .map(room => room.fixedBy)
            .filter(Boolean)
            .map(fixedBy => fixedBy.split(/\s*,\s*/)),
          function () {
            return type => type && type.fixing;
          },
          Mode.FIXING
        );
      } catch (err) {
        log("#deriveAllInspectorDetails: Fixing inspector unavailable");
      }

      try {
        // It gets the desnaggedBy (or) desnagging object (from ItemArtifactSet)
        await this.fetchAllInspectorDetails(
          roomValues,
          roomValues
            .map(room => room.desnaggedBy)
            .filter(Boolean)
            .map(desnaggedBy => desnaggedBy.split(/\s*,\s*/)),
          function () {
            return type => type && type.desnagging;
          },
          Mode.DESNAGGING
        );
      } catch (err) {
        log("#deriveAllInspectorDetails: Desnagging inspector unavailable");
      }

      try {
        // Derive the dates for snagging stage
        this.allSnaggedOn = await this.deriveInspectedOnDates(roomValues, "snaggedOn");
      } catch (err) {
        log("#deriveAllInspectorDetails: Snagging date unavailable");
      }

      try {
        // In case snaggedOn is not available, it trys to get the inspectedOn
        if (!this.allSnaggedOn) {
          this.allSnaggedOn = await this.deriveInspectedOnDates(roomValues, "inspectedOn");
        }
      } catch (err) {
        log("#deriveAllInspectorDetails: Inspection date unavailable");
      }

      try {
        // In case inspectedOn is not available, it trys to get the snagging node from itemArtifactSet
        if (!this.allSnaggedOn) {
          await this.deriveAllInspectedOnFromItemArtifactSet(roomValues, Mode.SNAGGING);
        }
      } catch (err) {
        log("#deriveAllInspectorDetails: Snagging date unavailable from item artifact set");
      }

      try {
        // Attempts to get fixedOn date from the room(s) node.
        this.allFixedOn = await this.deriveInspectedOnDates(roomValues, "fixedOn");

        // In case fixedOn is not available, it trys to get the fixing node from itemArtifactSet
        if (!this.allFixedOn) {
          await this.deriveAllInspectedOnFromItemArtifactSet(roomValues, Mode.FIXING);
        }
      } catch (err) {
        log("#deriveAllInspectorDetails: Fixing date unavailable");
      }

      try {
        // Attempts to get desnaggedOn date from the room(s) node.
        this.allDesnaggedOn = await this.deriveInspectedOnDates(roomValues, "desnaggedOn");
        // In case desnaggedOn is not available, it trys to get the desnagging node from itemArtifactSet
        if (!this.allDesnaggedOn) {
          await this.deriveAllInspectedOnFromItemArtifactSet(roomValues, Mode.DESNAGGING);
        }
        // console.log(this.allDesnaggedOn);

      } catch (err) {
        log("#deriveAllInspectorDetails: Desnagging date unavailable");
      }
    }
  }

  // Fetch the inspector details for the requested phase
  private async fetchAllInspectorDetails(roomVals, rooms: String[][], fnStage, mode: Mode) {
    const objTemp = await this.deriveAllInspectedBy(rooms);
    if (!objTemp) {
      await this.deriveAllInspectedByFromItemArtifactSet(roomVals, fnStage, mode);
    } else {
      switch (mode) {
        case Mode.SNAGGING:
          this.allSnaggedBy = objTemp;
          break;

        case Mode.FIXING:
          this.allFixedBy = objTemp;
          break;

        case Mode.DESNAGGING:
          this.allDesnaggedBy = objTemp;
          break;
      }
    }
  }


  // derive the inspectedBy information from itemArtifactSet - for the requested phase
  private async deriveAllInspectedByFromItemArtifactSet(roomValues, fnStage, mode: Mode) {
    const inspectedByIdsSet = new Set<string>();
    try {
      switch (mode) {
        case Mode.SNAGGING:
          roomValues
            .map(roomVal => roomVal.items)
            .map(itemValues => values(itemValues))
            .map(item => item.map(ele => ele.itemArtifactSet))
            .filter(stage =>
              stage.filter(fnStage()).filter(node => inspectedByIdsSet.add(node.snagging.createdBy))
            );
          break;

        case Mode.FIXING:
          roomValues
            .map(roomVal => roomVal.items)
            .map(itemValues => values(itemValues))
            .map(item => item.map(ele => ele.itemArtifactSet))
            .filter(stage =>
              stage.filter(fnStage()).filter(node => inspectedByIdsSet.add(node.fixing.createdBy))
            );
          break;

        case Mode.DESNAGGING:
          roomValues
            .map(roomVal => roomVal.items)
            .map(itemValues => values(itemValues))
            .map(item => item.map(ele => ele.itemArtifactSet))
            .filter(stage =>
              stage
                .filter(fnStage())
                .filter(node => inspectedByIdsSet.add(node.desnagging.createdBy))
                .filter(node => inspectedByIdsSet.add(node.desnagging.modifiedBy))
            );
          break;
      }
    } finally {
      inspectedByIdsSet.delete(undefined);
    }

    this.snaggedByNameIds = new Set();
    this.fixedByNameIds = new Set();
    this.desnaggedByNameIds = new Set();

    let inspectedByIdsArray = Array.from(inspectedByIdsSet);

    //Logic to get all users value(ModifiedBy) who performed desnagging for same item at different time
    let getSplittedArr = [];
    let resltArr = [];
    // console.log(inspectedByIdsArray);

    for (let i = 0; i < inspectedByIdsArray.length; i++) {
      if (inspectedByIdsArray[i].includes(',')) {
        getSplittedArr = inspectedByIdsArray[i].split(',');
        for (let j = 0; j < getSplittedArr.length; j++) {
          resltArr.push(getSplittedArr[j]);
        }
      }
      else {
        resltArr.push(inspectedByIdsArray[i]);
      }
    }

    resltArr = resltArr.filter((v, i, a) => a.indexOf(v) === i);
    inspectedByIdsArray = resltArr;

    while (inspectedByIdsArray.length > 0) {
      await this.getInspectedByNameFromId(inspectedByIdsArray.pop(), mode);
    }
  }

  private async deriveAllInspectedOnFromItemArtifactSet(roomValues, mode: Mode) {
    const inspectedByDatesSet = new Set<number>();
    let tmpDateRange = null;

    try {
      switch (mode) {
        case Mode.SNAGGING:
          this.addDateToSet(inspectedByDatesSet, roomValues, mode, "snagging");
          break;

        case Mode.FIXING:
          this.addDateToSet(inspectedByDatesSet, roomValues, mode, "fixing");
          break;

        case Mode.DESNAGGING:
          this.addDateToSet(inspectedByDatesSet, roomValues, mode, "desnagging");
          break;
      }
    } catch (exp) {
      log("#deriveAllInspectedOnFromItemArtifactSet: Date unavailable for mode = " + mode);
    } finally {
      inspectedByDatesSet.delete(undefined);
      inspectedByDatesSet.delete(NaN);
    }

    const tmpInspectedByDatesArray = Array.from(inspectedByDatesSet);
    const inspectedByDatesNumArray = new Array<Date>();
    tmpInspectedByDatesArray.forEach(dt => inspectedByDatesNumArray.push(new Date(dt)));

    if (inspectedByDatesNumArray.length > 0) {
      tmpDateRange = formatDateRange(inspectedByDatesNumArray);
    }

    switch (mode) {
      case Mode.SNAGGING:
        this.allSnaggedOn = tmpDateRange;
        // console.log(this.allSnaggedOn);

        break;

      case Mode.FIXING:
        this.allFixedOn = tmpDateRange;
        break;

      case Mode.DESNAGGING:
        this.allDesnaggedOn = tmpDateRange;
        break;
    }
  }

  // In the firebase, date and time are stored.
  // This method extracts the 'Date' part from the 'date & time'.
  private toDateAlone(dateTime: Date): number {
    const tmpDate = new Date(dateTime);

    return new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate()).getTime();
  }

  // Get snagging / fixing / desnagging date from itemArtifactSet
  private addDateToSet(inspectedByDatesSet, roomValues, mode, stage) {
    let tempDate = null;
    roomValues.map(obj =>
      values(obj.items).forEach(ele => {
        tempDate = null;
        switch (mode) {
          case Mode.SNAGGING:
            if (!!ele["snaggedOn"]) {
              tempDate = ele["snaggedOn"];
            }
            break;

          case Mode.FIXING:
            if (!!ele["fixedOn"]) {
              tempDate = ele["fixedOn"];
            }

            break;

          case Mode.DESNAGGING:
            if (!!ele["desnaggedOn"]) {
              tempDate = ele["desnaggedOn"];
            }
            break;
        }
        if (tempDate !== null) {
          inspectedByDatesSet.add(this.toDateAlone(tempDate));
        } else if (!!ele["itemArtifactSet"] && !!ele["itemArtifactSet"][stage]) {
          inspectedByDatesSet.add(this.toDateAlone(ele["itemArtifactSet"][stage].createdOn));
          inspectedByDatesSet.add(this.toDateAlone(ele["itemArtifactSet"][stage].modifiedOn));
        }
      })
    );
  }

  // Derives the user name from the id and assigns to the
  // appropriate attribute based on the phase (i.e., mode).
  private async getInspectedByNameFromId(inspectedById, mode: Mode) {
    const userObject = await this.databaseService.getUserObject(inspectedById);
    await userObject.pipe(take(1)).subscribe(user => {
      switch (mode) {
        case Mode.SNAGGING:
          this.snaggedByNameIds.add(user.name);
          this.allSnaggedBy = join2(Array.from(this.snaggedByNameIds), ", ", " and ");
          break;

        case Mode.FIXING:
          this.fixedByNameIds.add(user.name);
          this.allFixedBy = join2(Array.from(this.fixedByNameIds), ", ", " and ");
          break;

        case Mode.DESNAGGING:
          //  console.log(this.desnaggedByNameIds);
          this.desnaggedByNameIds.add(user.name);
          //  console.log(this.desnaggedByNameIds);
          // this.allDesnaggedBy = join2(Array.from(this.desnaggedByNameIds), ", ", " and ");
          this.allDesnaggedByArr.push(user.name);
          this.allDesnaggedBy = this.allDesnaggedByArr.join(', ').replace(/, ([^,]*)$/, ' and $1');
          break;
      }
    });
  }

  // Fetch all reviewers
  private deriveAllReviewers() {
    const results = this.unitResults;
    const fixingReviewedById = results.fixingReviewedBy;
    const desnaggingReviewedById = results.desnaggingReviewedBy;

    let snaggingReviewedById = results.snaggingReviewedBy;

    if (!snaggingReviewedById) {
      snaggingReviewedById = results.reviewedBy;
    }

    if (snaggingReviewedById) {
      this.databaseService
        .getUserObject(snaggingReviewedById)
        .pipe(take(1))
        .subscribe(user => (this.snaggingReviewedBy = user.name));
    }

    if (fixingReviewedById) {
      this.databaseService
        .getUserObject(fixingReviewedById)
        .pipe(take(1))
        .subscribe(user => (this.fixReviewedBy = user.name));
    }

    if (desnaggingReviewedById) {
      this.databaseService
        .getUserObject(desnaggingReviewedById)
        .pipe(take(1))
        .subscribe(user => (this.desnaggingReviewedBy = user.name));
    }
  }

  // Resets all the inspector names and inspection dates
  private resetAllValues() {
    this.snaggingReviewedBy = this.fixReviewedBy = this.desnaggingReviewedBy = this.allSnaggedBy = this.allDesnaggedBy = this.allFixedBy =
      "";

    this.allSnaggedOn = this.allFixedOn = this.allDesnaggedOn = undefined;
  }

  // Derive the inspected time range for various stages
  private async deriveInspectedOnDates(rooms, attr) {
    return formatDateRange(
      uniq<Date>(
        rooms.filter(room => Boolean(room[attr])).map(room => truncateDate(room[attr]))
      ).map(date => new Date(date))
    );
  }

  // get the inspected time range for various stages (without promises)
  private getInspectedOnDates(rooms, attr) {
    return formatDateRange(
      uniq<Date>(
        rooms.filter(room => Boolean(room[attr])).map(room => truncateDate(room[attr]))
      ).map(date => new Date(date))
    );
  }

  // Fetches the inspectedBy
  private async deriveAllInspectedBy(rooms: String[][]) {
    if (rooms.length === 0) return null;

    // Some teams of inspectors may enter their names as "Mahesh, Rajesh". Break them apart.
    const individuals = flatten(rooms);

    const uniqueIndividuals = uniq(individuals.map(individual => individual.trim()));

    return join2(uniqueIndividuals, ", ", " and ");
  }

  // Update notes (general observations) after user edits.
  public updateNotes() {
    this.unitRef.update({ notes: this.unitResults.notes });
  }

  public toggleEditNotes() {
    if (this.editNotes) this.updateNotes();
    this.editNotes = !this.editNotes;
  }

  // Make separate lists for each aspect, containing items within rooms.
  private makeAspectItems(rooms: Hash<RoomResults>) {
    const {
      unitRef,
      selectedStatuses,
      selectedFixStatuses,
      selectedRectificationStatuses,
      selectedSnagPriorities,
    } = this;

    // By the time we get there, we do not have the aspect ID; we only have the aspect NAME.
    // It is theoretically possible that multiple aspects could have the same name,
    // since at the moment we do not check for that on the aspect editing screen.
    // This would cause the "same" aspect, with the same items, to be displayed twice on the report.
    // To solve this problem, omit any aspects with the same name as a preceding one.
    //    const uniqAspects = uniqBy(this.aspects, aspect => aspect.name);

    // We can't base this on aspects in the project definition, since those might have changed .
    const aspectsInResults: string[] = [];
    for (const room of values(rooms))
      for (const item of values(room.items)) aspectsInResults.push(item.aspect);
    const uniqAspectsInResults = uniq(aspectsInResults);

    return (
      uniqAspectsInResults
        //      .sort(byOrder)
        .sort()
        .map(makeAspect)
        .filter(aspectHasItems)
    );

    // Does the collection of data for an aspect have any items?
    function aspectHasItems(aspect) {
      for (const room of aspect.rooms) for (const item of room.items) return !!item;
      return false;
    }

    // Create a single member of aspects array, giving items for that aspect within room.
    function makeAspect(aspect: string) {
      let snagIndex = 0;
      const roomData = makeRooms();

      return { name: aspect, title: aspect, rooms: roomData };

      // For one aspect, make the list of rooms with items for that aspect.
      function makeRooms() {
        return mapToArray(rooms, (room, roomId) => ({ ...room, roomId }))
          .sort(byRoomName)
          .map(makeRoom)
          .filter(room => room.items.length);

        // Rooms are sorted in alphabetic order, for now.
        function byRoomName(room1, room2) {
          return room1.name.localeCompare(room2.name);
        }

        // Create a room object within an aspect, with its name and (filtered, sorted) items.
        function makeRoom(room) {
          const roomRef = unitRef.child(`rooms/${room.roomId}`);

          return { name: room.name, items: makeItems(room.items) };

          // Make a list of filtered, sorted items.
          function makeItems(items) {
            return mapToArray(items, makeItem)
              .sort(byItemOrder)
              .filter(includeItem);

            // Make a single item within a room within an aspect.
            function makeItem(item: ItemResults, itemId) {
              //              return {...item, room, itemId, roomId: room.roomId, itemRef: roomRef.child(`items/${itemId}`)};
              return {
                ...item,
                roomName: room.name,
                itemId,
                roomId: room.roomId,
                itemRef: roomRef.child(`items/${itemId}`),
              };
            }

            // Find only items matching filter(s), for a particular aspect.
            function includeItem(item: ItemResults) {
              normalizeItemResults(item);

              const {
                aspect: itemAspect = "other",
                itemArtifactSet: { snagging, fixing, desnagging },
              } = item;

              console.assert(
                !!snagging,
                `${MODULE_NAME}#makeAspectItems: item is missing snagging itemArtifacts`
              );
              if (itemAspect !== aspect) return false;

              // WARNING: side effect in filter func!
              if (snagging.status === SnaggingStatus.notOk) item.snagIndex = ++snagIndex;

              if (selectedStatuses.indexOf(snagging.status) === -1) return false;

              // Implement snag priority filter.
              const { snagPriority = "medium" } = snagging;
              if (
                selectedSnagPriorities &&
                selectedSnagPriorities.length &&
                selectedSnagPriorities.indexOf(snagPriority) === -1
              )
                return false;

              if (
                snagging.status === SnaggingStatus.notOk &&
                desnagging &&
                desnagging.status &&
                selectedRectificationStatuses.indexOf(desnagging.status) === -1
              )
                return false;

              if (
                snagging.status === SnaggingStatus.notOk &&
                fixing &&
                fixing.status &&
                selectedFixStatuses.indexOf(fixing.status) === -1
              )
                return false;

              return true;
            }

            function byItemOrder(item1, item2) {
              return item1.sectionOrder !== item2.sectionOrder
                ? item1.sectionOrder - item2.sectionorder
                : item1.order - item2.order;
            }
          }
        }
      }
    }
  }

  // The user has changed the setting of "no snag priorities".
  public onChangeNoSnagPriorities() {
    this.db.object(this.unitRef).update({ noSnagPriorities: this.noSnagPriorities });
  }

  // Create "inspectedBy' string by concatenating names of inspectors from each room.
  private calcInspectedBy(rooms: RoomResults[]) {
    const inspectedBys = rooms.map(room => room.inspectedBy).filter(Boolean);

    // Some teams of inspectors may enter their names as "Mahesh, Rajesh". Break them apart.
    const individuals = flatten(inspectedBys.map(inspectedBy => inspectedBy.split(/\s*,\s*/)));

    const uniqueIndividuals = uniq(individuals.map(individual => individual.trim()));

    return join2(uniqueIndividuals, ", ", " and ");
  }

  private calcReviewedBy() {
    const results = this.unitResults;

    // Use the appropriate reviewedBy field if it is present.
    let reviewedBy =
      this.unit.status === "snagging" || this.unit.status === "snagging complete"
        ? results.snaggingReviewedBy
        : this.unit.status === "fixing" || this.unit.status === "fixing complete"
          ? results.fixingReviewedBy
          : results.desnaggingReviewedBy;

    // Otherwise, fall back to the obsolete field.
    if (!reviewedBy) {
      reviewedBy = results.reviewedBy;
      this.oldReviewedBy = true;
    }

    if (reviewedBy)
      this.databaseService
        .getUserObject(reviewedBy)
        .pipe(take(1))
        .subscribe(user => (this.reviewedByName = user.name));
  }

  private recalc() {
    this.aspectItems = this.makeAspectItems(this.rooms);
  }

  async upload(event) {
    const file = event.target.files[0];
    if (file) {
      this.uploading = true;
      const filePath = `${this.basePath}/${this.projectId}/${this.unitName}`;
      this.task = this.fireStorage.upload(filePath, file);
      this.uploadProgress = this.task.snapshotChanges().pipe(map(s => Math.floor((s.bytesTransferred / s.totalBytes) * 100)));
      (await this.task).ref.getDownloadURL().then(url => {
        this.documentPath = url;
      });
      this.snackBar("PDF File Uploaded Successfully !!");
      this.uploading = false;
    } else {
      this.snackBar("Please Try Again !!");
    }
  }

  private snackBar(msg: string) {
    this.matSnackBar.open(msg, null, { duration: 3000 });
  }
}

function sortArray(arr1, arr2) {
  let zipped = arr1.map((x, i) => [x, arr2[i]]);
  zipped.sort((a, b) => b[0] - a[0]);

  return [zipped.map(x => x[0]), zipped.map(x => x[1])];
}

function fillArray(parentKey, childKey, childValue) {
  parentKey = parentKey.map(word => {
    return word.split(' ')
      .map(w => w.charAt(0).toUpperCase() + w.slice(1))
      .join(' ');
  });
  let result = [];
  for (let i = 0; i < parentKey.length; i++) {
    let index = childKey.indexOf(parentKey[i]);
    if (index === -1) {
      result.push(0);
    } else {
      result.push(childValue[index]);
    }
  }
  return result;
}
