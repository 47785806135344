import {Component, OnInit, OnDestroy} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

import {AngularFireDatabase, FirebaseObjectObservable} from "angularfire2/database-deprecated";

import {Terms, legacyTerms, Summary} from "@nims/red-shared";
import {FirebaseApp} from "angularfire2";

@Component({
  selector: "single-project-dashboard",
  templateUrl: "./single-project-dashboard.component.html",
})
export class SingleProjectDashboardComponent implements OnInit, OnDestroy {
  public ids: string[] = [];

  public terms$: FirebaseObjectObservable<Terms>;
  public summary;
  public summary$: FirebaseObjectObservable<Summary>;
  private summarySubscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly angularFireDatabase: AngularFireDatabase,
    private readonly db: AngularFireDatabase,
    private readonly firebaseApp: FirebaseApp
  ) {}

  ngOnInit() {
    const id = this.activatedRoute.parent.snapshot.params["id"];
    this.ids.push(id);

    const path = `projects/${id}/terms`;

    const terms = (this.terms$ = this.angularFireDatabase.object(path));

    terms.$ref.once("value").then(snapshot => {
      if (!snapshot.exists()) terms.update(legacyTerms);
    });

    const firebaseDatabase = this.firebaseApp.database();
    const summaryRef = firebaseDatabase.ref(`summaries/${id}`);
    this.summary$ = this.db.object(summaryRef);
    this.summarySubscription = this.summary$.subscribe(summary => (this.summary = summary));
  }

  ngOnDestroy() {
    if (this.summarySubscription) this.summarySubscription.unsubscribe();
  }
  
}
