import {Component, OnInit, HostListener} from "@angular/core";
import {Router} from "@angular/router";
import {NgLog} from "@nims/ngutils";
import {SubscribersService} from "@nims/red-shared";
import {UserService} from "../../services/user/user.service";
import {environment} from "../../../environments/environment";
import {version} from "../../../version";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
@NgLog()
export class LoginComponent implements OnInit {
  public error: string;

  public email: string;
  public password: string;
  public forgotPasswordError: string;
  public loggingIn = false;

  public version = version || "unknown";
  public name = environment.name || "NIMS";

  constructor(
    private userService: UserService,
    private router: Router,
    private subscribersService: SubscribersService
  ) {}

  // Redirect to home if user is already logged in.
  public ngOnInit() {
    if (this.userService.loggedIn) this.gotoHome();
  }

  // Form has been submitted.
  // Log in user and redirect to home.
  @HostListener("document:keyup.enter")
  async submit() {
    this.loggingIn = true;

    try {
      await this._submit();
    } catch (e) {
      return (this.error = e.message);
    } finally {
      this.loggingIn = false;
    }

    this.gotoHome();
  }

  private async _submit() {
    const {email, password} = this;

    if (!email || !password) return;

    const firebaseUser = await this.userService.login(email, password);
    const user = await this.userService.getUserP(firebaseUser.uid);

    if (user.subscriber) await this.checkSubscriber(user.subscriber);
  }

  logout() {
    this.userService.logout();
  }

  public clearError() {
    this.error = null;
  }

  public async forgotPassword() {
    if (!this.email) return (this.forgotPasswordError = "Please enter your email address.");

    try {
      await this.userService.forgotPassword(this.email);
      this.forgotPasswordError = "Password reset mail sent. Please check your inbox.";
    } catch (e) {
      const errorJson = JSON.parse(e.message);
      const message = errorJson.error.message;

      this.forgotPasswordError = `Problem resetting password (${message})`;
    }
  }

  private gotoHome() {
    this.router.navigate(["/home"]);
  }

  // Check that this subscriber exists, is not expired, etc.
  private async checkSubscriber(subscriberId: string): Promise<void> {
    const subscriber = await this.subscribersService.getOneP(subscriberId || "nemmadi.in");

    if (!subscriber)
      throw new Error(
        "Internal error; subscriber not found. Please contact the system administrator."
      );

    if (subscriber.disabled)
      throw new Error(
        "This subscriber has been disabled. Please contact your account representative."
      );

    if (
      subscriber.usageLimits &&
      subscriber.usageLimits.expires &&
      +new Date() > subscriber.usageLimits.expires
    )
      throw new Error("Subscription has expired. Please contact your account representative.");
  }
}
