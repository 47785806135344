// red/console/app.component.ts
//
// TS logic for NIMS console application's main component.

import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Subscriber} from "@nims/red-shared";
import {NgLog} from "@nims/ngutils";
import {GoogleAnalyticsService} from "./services/google-analytics/google-analytics.service";
import {UserService} from "./services/user/user.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
@NgLog()
export class AppComponent implements OnInit {
  public userName$: Observable<string>;
  public subscriber$: Observable<Subscriber>;

  constructor(
    private router: Router,
    private userService: UserService,
    // This service's constructor will kick off tracking.
    googleAnalyticsService: GoogleAnalyticsService
  ) {}

  public ngOnInit() {
    const {user$, subscriber$} = this.userService;

    this.userName$ = user$.pipe(map(user => user && user.name));
    this.subscriber$ = subscriber$;
  }

  // Return home.
  // Invoked by click on header.
  public gotoHome() {
    this.router.navigate(["/home"]);
  }
}
