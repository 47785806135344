// services/rooms.service.ts
//
// Handle manipulation of rooms.

// ANGULAR
import {Injectable} from "@angular/core";

// FIREBASE
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

// COMMON
import {Room} from "@nims/red-shared";
import {forEach, Hash} from "@nims/jsutils";
import {once} from "@nims/afutils";

type Rooms = Hash<Room>;
type Items = Hash<boolean>;

function onoff<T>(obj: T, key: string, on: boolean) {
  if (on) obj[key] = true;
  else delete obj[key];
}

@Injectable()
export class RoomsService {
  // Remove a bunch of items from all the rooms.
  // This is often called when a section is deleted.
  public async removeItems(ref: DatabaseReference, itemKeys: string[]) {
    const rooms = await this.get(ref);

    forEach(rooms, room => itemKeys.forEach(itemKey => delete room.items[itemKey]));

    return this.put(ref, rooms);
  }

  // Remove an item from all the rooms.
  // This is often called when the item is deleted.
  public async removeItem(ref: DatabaseReference, itemKey: string) {
    const rooms = await this.get(ref);

    forEach(rooms, room => delete room.items[itemKey]);

    return this.put(ref, rooms);
  }

  // Toggle a bunch of items in a room to some new value.
  public async toggleItems(
    ref: DatabaseReference,
    roomKey: string,
    itemKeys: string[],
    on: boolean
  ) {
    const roomItemsRef = ref.child(roomKey).child("items");
    const items = (await once<Items>(roomItemsRef)) || {};

    if (!items) return;

    itemKeys.forEach(itemKey => onoff(items, itemKey, on));

    return roomItemsRef.set(items);
  }

  // Put and get this node.
  private async get(ref: DatabaseReference): Promise<Rooms> {
    return (await once<Rooms>(ref)) || {};
  }
  private async put(ref: DatabaseReference, rooms: Rooms) {
    return ref.set(rooms);
  }
}
