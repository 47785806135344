// app.module.ts
//
// Define app module for NIMS console app.

import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

// COMMON
import {
  GoogleMapsModule,
  ScriptLoaderModule,
  GOOGLE_MAPS_API_KEY,
  FormatPipesModule,
  RavenModule,
} from "@nims/ngutils";
import {SharedServicesModule} from "@nims/red-shared";

// OUR MODULES
import {FirebaseModule} from "./modules/firebase.module";
import { AngularFireStorageModule } from "angularfire2/storage";

import {AppRoutingModule} from "./app-routing.module";
import {ComponentsModule, PipesModule, PodsModule, ServicesModule} from "./modules/";

// ENVIRONMENT
import {environment} from "../environments/environment";

import {CopyTemplateToSubscriberComponent} from "./components/copy-template-to-subscriber";

// OUR COMPONENT
import {AppComponent} from "./app.component";

// Retrieve Google maps API key and create provider to give to module.
const googleMapsApiKey = environment.googleMaps && environment.googleMaps.apiKey;
const googleMapsApiKeyProvider = {provide: GOOGLE_MAPS_API_KEY, useValue: googleMapsApiKey};

@NgModule({
  declarations: [AppComponent],
  imports: [
    // ANGULAR MODULES
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,

    // NGUTILS
    GoogleMapsModule,
    RavenModule,
    ScriptLoaderModule,
    FormatPipesModule,

    // RED SHARED

    SharedServicesModule,

    // OUR MODULES
    FirebaseModule,
    AngularFireStorageModule,
    PodsModule,
    ComponentsModule,
    ServicesModule,
    AppRoutingModule,
    PipesModule
  ],
  providers: [googleMapsApiKeyProvider],
  bootstrap: [AppComponent],
  entryComponents: [CopyTemplateToSubscriberComponent],
})
export class AppModule {}
