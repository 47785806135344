import {Component, OnInit, Input} from "@angular/core";

import {Subscriber} from "@nims/red-shared";

@Component({
  selector: "report-header",
  templateUrl: "./report-header.component.html",
  styleUrls: ["./report-header.component.css"],
})
export class ReportHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() subscriber: Subscriber;

  public address: string;

  constructor() {}

  ngOnInit() {
    this.title = this.title || "Pre-delivery Quality Inspection";
    this.address =
      (this.subscriber && this.subscriber.address) ||
      `491 'VISWAKARMA' East End Main Road,
Jayanagar 9th Block
Bangalore 560 069
India`;
  }
}
