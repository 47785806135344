// dashboard/report-definition/report-definition.class.ts
//
// Define ReportDefinition class to represent report definitions.

import {append} from "@nims/jsutils";
import {Terms} from "@nims/red-shared";

import {
  getDefaultReportOptions,
  reportOptionsToString,
} from "../report-options/report-options.class";
import {
  getDefaultReportFilters,
  reportFiltersToString,
} from "../report-filters/report-filters.class";
import {
  getDefault as getDefaultDimension,
  toString as dimensionToString,
} from "../report-dimension/report-dimension.class";
import {ReportDefinition} from "./report-definition.interface";

const appendPeriod = append(".");

export {ReportDefinition};

export function getDefault(): ReportDefinition {
  return {
    name: "default",
    description: "Default report",
    dimension: getDefaultDimension(),
    secondaryDimension: {...getDefaultDimension(), field: "none"},
    tertiaryDimension: {...getDefaultDimension(), field: "none"},
    owner: "system",
    options: getDefaultReportOptions(),
    filters: getDefaultReportFilters(),
    private: false,
  };
}

export function hasSecondaryDimension(definition) {
  return definition.secondaryDimension && definition.secondaryDimension.field !== "none";
}

// Create a textual description of all dimensions.
export function dimensionsToString(definition, terms: Terms) {
  const {dimension, secondaryDimension, tertiaryDimension} = definition;

  let dimensionString = `Group by ${dimensionToString(dimension, terms)}`;
  if (secondaryDimension && secondaryDimension.field !== "none")
    dimensionString += `, then by ${dimensionToString(secondaryDimension, terms)}`;
  if (tertiaryDimension && tertiaryDimension.field !== "none")
    dimensionString += `, then by ${dimensionToString(tertiaryDimension, terms)}`;

  return dimensionString;
}

// Provide a human-readable version of this report definition.
export function toString(definition, terms) {
  return [
    dimensionsToString(definition, terms),
    reportFiltersToString(definition.filters, terms),
    reportOptionsToString(definition.options, terms),
  ]
    .filter(Boolean)
    .map(appendPeriod)
    .join("\n");
}
