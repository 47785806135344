// User has double-clicked on the photo. Zoom it.
// TODO: move this into `@nims/jsutils/firebase-storage.util`.

import * as firebase from "firebase";

const MODULE_NAME = "console/utils/view-big.ts";

export function viewBig(
  imageUrl: string,
  filename = "snag.jpg",
  firebaseStorage: firebase.storage.Storage
) {
  if (!imageUrl) throw new Error(`${MODULE_NAME}: photo URL is missing`);

  // The metadata object we will pass to `updateMetadata`.
  const metadata = {contentDisposition: `filename="${filename}`};

  // Derive the path, so we can set metadata on it.
  // We depend on the path following an `/o/` segment in the URL, and being followed by a `?`.
  // The path will of the form `timestamp/big`.
  const matches = imageUrl.match(/\/o\/(.*?)\?/);
  if (!matches) throw new Error("malformed photo URL");
  const path = decodeURIComponent(matches[1]);

  // Set the `contentDisposition` metadata field to give a nice download name.
  // Note: the `download` attribute on an `a` tag will not work here,
  // since the image is coming from a different domain.
  firebaseStorage
    .ref(path)
    .updateMetadata(metadata)
    .then(
      () => {
        console.log(`${MODULE_NAME}: opening window`, imageUrl);
        window.open(imageUrl);
      },
      error => console.error(`${MODULE_NAME}: error in updating metadata on big picture`, error)
    );
}
