// services/idb.service.ts
//
// Get and set dashboard data from and to IndexedDb.

import {IndexedDb} from "../utils/idb.util";
import {Injectable} from "@angular/core";

// v2.3 changes the format of the pre-calculated  dashboard objects.
const DB_VERSION = 2;
const DB_NAME = "nims-console";
const DASHBOARD_OBJECT_STORE = "dashboard";

// On the upgrade event, create an object store for each model.
function upgrade(db) {
  // Delete the old object store, if it exists.
  try {
    db.deleteObjectStore(DASHBOARD_OBJECT_STORE);
  } catch (e) {
    if (e.code !== DOMException.NOT_FOUND_ERR) throw e;
  }

  db.createObjectStore(DASHBOARD_OBJECT_STORE, {keyPath: "projectId"});
}

@Injectable()
export class IdbService {
  // Create our indexed DB.
  private indexedDb = new IndexedDb(DB_NAME, DB_VERSION, upgrade);

  constructor() {}

  // DASHBOARD DATA
  setDashboardData(data) {
    this.set(DASHBOARD_OBJECT_STORE, data);
  }
  getDashboardData(projectId) {
    // If the data is older than June 2, 2017, 12:33 IST, then pretend its not there.      .then(data =>
    return this.get(DASHBOARD_OBJECT_STORE, projectId);
  }

  // Retrieve a resource from Idb.
  // Return a promise for the result.
  // The promise will reject if the key doesn't exist, or the JSON is invalid.
  public get(store, id): Promise<any> {
    return this.makeRequest("readonly", store, objectStore => objectStore.get(id));
  }

  public getAll(store, id?): Promise<any> {
    return this.makeRequest("readonly", store, objectStore => objectStore.getAll(id));
  }

  // Set a resource into IDB.
  public set(store: string, r: any): Promise<any> {
    return this.makeRequest("readwrite", store, objectStore => objectStore.put(r));
  }

  // Remove a resource from IDB.
  // Returns a promise which rejects if the remove fails--why would it>
  public remove(store, id): Promise<void> {
    return this.makeRequest("readwrite", store, objectStore => objectStore.delete(id));
  }

  // Make a generic request, based on a function passed in.
  private makeRequest(access, store, request): Promise<any> {
    return this.indexedDb.db.then(
      db =>
        new Promise<any>((resolve, reject) => {
          const req = request(db.transaction([store], access).objectStore(store));

          req.onerror = e => reject(new Error(`Error in IDB: ${e} (store is ${store})`));
          req.onsuccess = () => /*evt*/ resolve(req.result);
        })
    );
  }
}
