// components/add-button/add-button.component.ts
//
// TS logic for add button component.
// ```
// <add-button (add)="onAddClick()" title="Add frobnoz"></add-button>
// ```

import {Component, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: "add-button",
  templateUrl: "./add-button.component.html",
  styleUrls: ["./add-button.component.css"],
})
export class AddButtonComponent {
  @Input() title = "Add";
  @Output() add = new EventEmitter<void>();

  public onClick() {
    this.add.emit();
  }
}
