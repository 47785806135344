// aspects/aspects.component.ts
//
// Display template or project aspects.

import {Component, OnInit, Input, ViewContainerRef} from "@angular/core";
import {FirebaseListObservable} from "angularfire2/database-deprecated";
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";
import {pluralize} from "@nims/jsutils";
import {AfValue, trackByKey, Aspect, Terms} from "@nims/red-shared";
import {ConfirmRemoveService} from "@nims/ngutils";
import {ChecklistService} from "../../services/checklist.service";

type List<T> = FirebaseListObservable<AfValue<T>[]>;

@Component({
  selector: "aspects",
  templateUrl: "./aspects.component.html",
  styleUrls: ["./aspects.component.css"],
})
export class AspectsComponent implements OnInit {
  @Input()
  public aspects: List<Aspect>;

  @Input()
  title: string;
  @Input()
  editable: boolean;
  @Input()
  terms: Terms;

  public help = false;
  public emptyMessage = "Loading, wait...";
  public trackByKey = trackByKey;

  private ref: DatabaseReference;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private confirmRemoveService: ConfirmRemoveService,
    private readonly checklistService: ChecklistService
  ) {}

  ngOnInit() {
    this.emptyMessage = `No ${pluralize(this.terms.activity)} found`;
    this.ref = (this.aspects.$ref as DatabaseReference).parent;
  }

  // Handle updates to section properties.
  // TOOD: rename this.
  public onChangeName(aspect: AfValue<Aspect>) {
    this.update(aspect, {name: aspect.name, description: aspect.description});
  }

  // create a new aspect.
  public create() {
    return this.checklistService
      .createAspect(this.ref, {}, this.terms.activity)
      .then(() => this.success("Successfully created aspect"))
      .catch(e => this.error("Failed to create aspect", e));
  }

  // Move sections up and down.
  public moveUp(aspect: AfValue<Aspect>) {
    this.move(aspect, -1);
  }
  public moveDown(aspect: AfValue<Aspect>) {
    this.move(aspect, +1);
  }

  // Remove a section, after confirmation.
  public remove({$key: key, name}: AfValue<Aspect>) {
    return this.confirmRemove(name).subscribe(res => {
      if (res) {
        this.checklistService
          .removeAspect(this.ref, key)
          .then(() => this.success("Successfully removed aspect"))
          .catch(e => this.error("Failed to remove aspect", e));
      }
    });
  }

  // Make sure the user is OK with deleting this aspect.
  private confirmRemove(name: string) {
    return this.confirmRemoveService.confirm(
      "Really remove aspect?",
      `Are you sure you want to remove ${this.terms.activity} &ldquo;${name}&rdquo;?
<b>This ${this.terms.activity} will be permanently lost!</b>
All ${pluralize(this.terms.checklist)} with this ${
        this.terms.activity
      } will be set back to the default.`,
      this.viewContainerRef
    );
  }

  // Update an aspect property.
  private update({$key: key}: AfValue<Aspect>, data: Partial<Aspect>) {
    return this.checklistService
      .updateAspect(this.ref, key, data)
      .then(() => this.success("Successfully updated aspect"))
      .catch(e => this.error("Failed to update aspect", e));
  }

  // Move an aspect up or down.
  private move(aspect, delta: number) {
    return this.checklistService
      .moveAspect(this.ref, aspect.$key, delta)
      .then(() => this.success("Successfully moved aspect"))
      .catch(e => this.error("Failed to move aspect", e));
  }

  private error(msg: string, err: Error) {
    console.error(msg, err);
  }

  private success(msg: string) {
    console.log(msg);
  }
}
