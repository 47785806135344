import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {FirebaseListObservable} from "angularfire2/database-deprecated";

import {DatabaseService, UserService} from "../../../services";

import {map, tap} from "rxjs/operators";

import {
  Summary,
  Capability,
  AfValue,
  isUnviewableTestProjectForUser,
  isUserAssignedProject,
} from "@nims/red-shared";

import {not} from "@nims/jsutils";

class SelectedProject {
  projectId: string;
  projectName: string;
  currentDate: string;
  lastUpdateDate: string;
  update: boolean;
}

@Component({
  selector: "multi-project-dashboard",
  templateUrl: "./multi-project-dashboard.component.html",
  styleUrls: ["./multi-project-dashboard.component.css"]
})
export class MultiProjectDashboardComponent implements OnInit {

  public help = false;

  // Capabilities for editing and viewing.
  public canEdit;
  public canViewProjectInfo;

  // Dashboard
  public filteredSummaries$;
  public projectIds: string[] = [];
  public summaries: FirebaseListObservable<Summary[]>;
  public summariesObject;

  // Selected project table
  public emptyMessage = "Loading, wait...";
  public selectedProjects: SelectedProject[] = [];

  // Auto complete
  public projectResults: string[];
  public projects: string[] = [];

  constructor(private router: Router,
              private databaseService: DatabaseService,
              private userService: UserService) {}

  // Return home.
  public gotoHome() {
    this.router.navigate(["/home"]);
  }

  public toggleHelp() {
    this.help = !this.help;
  }

  ngOnInit() {

    // Get a list of subscribed summaries and projects to display in auto complete and get relevant project details

    // Created filtered list of summaries to be vieweed.
    // For customers and project managers, this includes only projects to which they have access.
    const {subscriber, user} = this.userService;
    const isUnviewableTestProject = isUnviewableTestProjectForUser(user);
    this.summaries = this.databaseService.getSummariesListBySubscriber(subscriber);
    this.canEdit = this.userService.can(Capability.edit);
    this.canViewProjectInfo = this.userService.can(Capability.viewProjectInfo);

    this.filteredSummaries$ = this.summaries.pipe(
      tap(() => (this.emptyMessage = "No projects found")),
      map((summaries: Summary[]) => summaries.filter(not(isUnviewableTestProject))),
      map((summaries: AfValue<Summary>[]) =>
        summaries.filter(summary => isUserAssignedProject(user)(summary.$key))
      )
    );
    this.filteredSummaries$.subscribe(summaries => {
      // Store a copy of summaries which gets updated if there's an update
      this.summariesObject = summaries;

      summaries.forEach(summary => {
        // Should this push only if the project is not in the list? Can we have 2 projects with same name?
        this.projects.push(summary["name"]);
      })
    });

  }

  // Search projects function for auto complete
   public searchProjects(event): void {
      this.projectResults = this.projects.filter(c => c.toLowerCase().startsWith(((event.query).toLowerCase())));
   }

  // Populate selected project table after project selection + add project to dashboard
  public addProject(projectName) {
    this.summariesObject.forEach(async summary => {
      if ( summary["name"] === projectName ) {

        const currentProjectId = summary.$key;

        // Add to dashboard
        if ( this.projectIds.findIndex( id => ( id === currentProjectId ) ) === -1 ) {
          this.projectIds.push(currentProjectId);
          this.projectIds = this.projectIds.slice();
        }

        // Add to selected project table
        const currentProject = new SelectedProject();

        currentProject.projectId = currentProjectId;
        currentProject.projectName = projectName;
        currentProject.currentDate = await this.getCurrentProjectDownloadDate(currentProjectId);

        this.databaseService.getProjectChangedAt(currentProjectId).subscribe(date => {
          currentProject.lastUpdateDate = date.$value;
          currentProject.update = this.requiresUpdate(currentProject.lastUpdateDate, currentProject.currentDate);

          // If in case this project is already in the table, delete it and add the new updated object
          this.removeFromSelectedProjects(currentProjectId);

          this.selectedProjects.push(currentProject);
          this.selectedProjects = this.selectedProjects.slice(); // This is to trigger change detection
        });

      }
    });
   }

   public async getCurrentProjectDownloadDate (projectId: string) {
    const currentData = await this.databaseService.getPrecalculatedDashboardData(projectId);
    if (currentData) return currentData["changedAt"];
    else return (new Date()).toDateString(); // If in case table is not pre calculated yet, it will be done now - so current
   }

   removeProject (projectId: string) {
    // Remove from selected project table
      this.removeFromSelectedProjects(projectId);

    // Remove from dashboard
      let index = 0;
      this.projectIds.forEach(currProjectId => {
        if ( currProjectId === projectId ) {
          this.projectIds.splice(index, 1);
          this.projectIds = this.projectIds.slice(); // This is to trigger change detection
        }
        index++;
      })
   }

   private removeFromSelectedProjects(projectId: string) {
    let index = 0;
    this.selectedProjects.forEach(project => {
      if ( project.projectId === projectId ) {
        this.selectedProjects.splice(index, 1);
        this.selectedProjects = this.selectedProjects.slice(); // This is to trigger change detection
      }
      index++;
    })
   }

   // If last update date on server is more than download date of project then the function returns true
   public requiresUpdate(lastUpdateDate: string, currentDate: string): boolean {
     if (!lastUpdateDate || !currentDate) return false;
      else return parseInt(lastUpdateDate, 10) > parseInt(currentDate, 10);
   }

   // Download the fresh project
   public async updateProject(projectId: string, projectName: string) {
    await this.databaseService.calculateAndStoreDashboardData(projectId);
    this.addProject(projectName);
   }

}
