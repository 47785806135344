// @nims/red/console/src/app/modules/services.module.ts
// Define services module for NIMS red console.
//
// Imported from app module.

import {NgModule} from "@angular/core";

import {
  AspectsService,
  ChecklistService,
  DatabaseService,
  DescriptionsService,
  FirebaseAccountService,
  GoogleAnalyticsService,
  IdbService,
  ItemsService,
  ReadingsService,
  RoomsService,
  SectionsService,
  TemplatesService,
  UserService,
} from "../services/";

@NgModule({
  providers: [
    AspectsService,
    ChecklistService,
    DatabaseService,
    DescriptionsService,
    FirebaseAccountService,
    GoogleAnalyticsService,
    IdbService,
    ItemsService,
    ReadingsService,
    RoomsService,
    SectionsService,
    TemplatesService,
    UserService,
  ],
})
export class ServicesModule {}
