// pods/dashboard/report-options/report-options.component.ts
//
// JS logic for component for report options.

import {Component, OnChanges, Input, Output, EventEmitter} from "@angular/core";
import {mapFromArray} from "@nims/jsutils";
import {Terms} from "@nims/red-shared";

import {
  ReportOptions,
  addReportOptionsDefaults,
  displayOptions,
  // schemes,
  // setScheme,
  snagDisplayOptions,
} from "./report-options.class";

@Component({
  selector: "report-options",
  templateUrl: "./report-options.component.html",
  styleUrls: ["./report-options.component.css"],
})
export class ReportOptionsComponent implements OnChanges {
  @Input()
  options: ReportOptions;
  @Input()
  terms: Terms;
  @Output()
  onChange = new EventEmitter<void>();

  // public schemes = schemes;
  // public setScheme = setScheme;

  // There are now three color schemes, depending on the status breakdown.
  // public schemeProps = [
  //   {prop: "schemeSnagging", name: "Snagging colours"},
  //   {prop: "schemeFixing", name: "Fixing colours"},
  //   {prop: "scheme", name: "Desnagging colours"},
  // ];

  // We handle all options except for color schemes in this tabular fashion.
  public optionsInfo = [
    {
      label: "Display",
      prop: "display",
      type: "select",
      options: displayOptions,
      multiple: true,
      value: [],
    },
    {
      label: "Show snags as",
      prop: "snagDisplay",
      type: "select",
      options: snagDisplayOptions,
      multiple: false,
      value: [],
    },
  ];

  ngOnChanges() {
    addReportOptionsDefaults(this.options);

    this.optionsInfo.forEach(
      elt =>
        (elt.value = elt.multiple
          ? Object.keys(this.options[elt.prop] || {})
          : this.options[elt.prop])
    );
  }

  public update(elt) {
    this.options[elt.prop] = elt.multiple ? mapFromArray(elt.value, () => true) : elt.value;
    this.onChange.emit();
  }

  // public updateScheme(prop: string, val: number) {
  //   this.options[prop] = val;
  //   this.onChange.emit();
  // }
}
