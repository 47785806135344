// services/report-definition/report-definition.service.ts
//
// Read and write report definitions to database.
// IS THIS USED?

import {Injectable} from "@angular/core";
import {AngularFireDatabase} from "angularfire2/database-deprecated";

import {subscriberQuery} from "@nims/red-shared";

import {ReportDefinition} from "../../pods/dashboard/report-definition/report-definition.interface";

@Injectable()
export class ReportDefinitionService {
  constructor(private db: AngularFireDatabase) {}

  public get() {
    return this.db.list("reportDefinitions");
  }

  public getBySubscriber(subscriber: string) {
    return this.db.list("reportDefinitions", subscriberQuery(subscriber));
  }

  public add(definition: ReportDefinition) {
    return this.get().push(definition);
  }

  public update(key: string, definition: Partial<ReportDefinition>) {
    return this.get().update(key, definition);
  }

  public remove(definition) {
    return this.get().remove(definition);
  }
}
