// checklist/checklist.component.ts
//
// Display template for project checklist, including sections and items.
// This is used from both the template and the project screens.

import {Component, Input, OnInit, OnChanges, SimpleChanges} from "@angular/core";

import {Subject} from "rxjs";

// ANGULARFIRE
import {AngularFireDatabase, FirebaseListObservable} from "angularfire2/database-deprecated";
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

import {
  Room,
  Item,
  Reading,
  Section,
  Description,
  Aspect,
  AfValue,
  Terms,
  trackByKey,
} from "@nims/red-shared";

import {ChecklistService} from "../../services/checklist.service";

type List<T> = FirebaseListObservable<AfValue<T>[]>;

export interface SectionUpdate {
  key: string;
  data: Partial<Section>;
}
export interface SectionMove {
  key: string;
  delta: number;
}
export interface SectionToggleRoom {
  key: string;
  roomKey: string;
  value: boolean;
}

@Component({
  selector: "checklist",
  templateUrl: "./checklist.component.html",
  styleUrls: ["./checklist.component.css"],
})
export class ChecklistComponent implements OnInit, OnChanges {
  @Input()
  public rooms: List<Room>;
  @Input()
  public sections: List<Section>;
  @Input()
  public items: List<Item>;
  @Input()
  public aspects: List<Aspect>;
  @Input()
  public readings: List<Reading>;
  @Input()
  public terms: Terms;
  @Input()
  public descriptions: List<Description>;

  @Input()
  title: string;

  public selectedSections = new Subject<Section[]>();
  public checklist: DatabaseReference;

  public help = false;
  public editable = false;

  public selection: AfValue<Section>;
  public trackBySection = trackByKey;

  constructor(
    private readonly db: AngularFireDatabase,
    private readonly checklistService: ChecklistService
  ) {}

  // TODO: fix this up
  public ngOnChanges(simpleChanges: SimpleChanges) {
    this.checklist = (this.sections.$ref as DatabaseReference).parent;
    this.selectedSections.next([]);
  }

  public ngOnInit() {}

  // Emit a new array of selected sections (actually, FirebaseObjectObservables for them).
  // This array is consumed by the `[section]="selectedSections | async"` property in the template.
  onSectionSelected(selections) {
    const getObject = section =>
      this.db.object((<DatabaseReference>this.sections.$ref).child(section.$key));

    this.selectedSections.next(selections.map(getObject));
  }

  // SECTION EVENTS
  public removeSection(key: string) {
    this.checklistService
      .removeSection(this.checklist, key)
      .catch(e => this.error("Could not remove section", e));
  }

  public createSection() {
    this.checklistService
      .createSection(this.checklist, {}, this.terms.checklist)
      .catch(e => this.error("Could not create section", e));
  }

  public updateSection({key, data}: SectionUpdate) {
    return this.checklistService
      .updateSection(this.checklist, key, data)
      .catch(e => this.error("Could not update section", e));
  }

  public moveSection({key, delta}: SectionMove) {
    this.checklistService
      .moveSection(this.checklist, key, delta)
      .catch(e => this.error("Could not move section.", e));
  }

  public toggleRoomSection({key, roomKey, value}: SectionToggleRoom) {
    this.checklistService
      .toggleRoomSection(this.checklist, key, roomKey, value)
      .catch(e => this.error("Could not toggle section room", e));
  }

  private error(msg: string, error: Error) {
    // TODO: display this error on the screen.
    console.error(msg, error);
  }
}
