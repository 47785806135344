// unit-status.component.ts: Component for choosing unit status.
//
// Called from `project-units`.

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MenuItem} from "primeng/primeng";

import {
  ModeHash,
  UnitStatus,
  getPossibleUnitStatuses,
  getUnitStatusTransitions,
} from "@nims/red-shared";

const MODULE_NAME = "console\\unit-status.component";

// We wanted to use these for titles, but the PrimeNG steps component doesn't seem to support that.
const unitStatusDescriptions = {
  [UnitStatus.notReady]: ``,
  [UnitStatus.snagging]: `Snagging phase (available to inspectors)`,
  [UnitStatus.snaggingComplete]: `Snagging phase complete (not available to inspectors)`,
  [UnitStatus.fixing]: `Fixing phase (available to inspectors and engineers)`,
  [UnitStatus.fixingComplete]: `Fixing phase complete (not available to inspectors and engineers)`,
  [UnitStatus.desnagging]: `De-snagging phase (available to inspectors)`,
  [UnitStatus.desnaggingComplete]: `De-snagging phase complete (not available to inspectors)`,
};

@Component({
  selector: "unit-status",
  templateUrl: "./unit-status.component.html",
  styleUrls: ["./unit-status.component.css"],
})
export class UnitStatusComponent implements OnInit {
  @Input() public readonly status: UnitStatus;
  @Input() public phases: ModeHash<boolean>;

  @Output() public statusChange = new EventEmitter<UnitStatus>();

  public items: MenuItem[];
  public possibleUnitStatuses: UnitStatus[];
  public activeIndex: number;

  ngOnInit() {
    const unitStatusTransitions = getUnitStatusTransitions(this.status, this.phases);

    this.possibleUnitStatuses = getPossibleUnitStatuses(this.phases);

    this.items = this.possibleUnitStatuses.map(status => ({
      label: status,
      disabled: unitStatusTransitions.indexOf(status) === -1,
      title: unitStatusDescriptions[status],
    }));

    this.activeIndex = this.possibleUnitStatuses.indexOf(this.status);

    console.assert(this.activeIndex >= 0, `${MODULE_NAME}#ngOnInit: unknown status`, this.status);
  }

  public onActiveIndexChange(index: number) {
    this.statusChange.emit(this.possibleUnitStatuses[index]);
  }
}
