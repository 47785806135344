// services/readings.service.ts
//
// Handle manipulation of "readings" in "checklists".
// Invoked from `ReadingsComponent` via `ChecklistService`.

import {Injectable} from "@angular/core";

// FIREBASE
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

// OUR REPO
import {Reading} from "@nims/red-shared";
import {Hash, length} from "@nims/jsutils";
import {once} from "@nims/afutils";

type Readings = Hash<Reading>;

@Injectable()
export class ReadingsService {
  // Update an aspect.
  public update(ref: DatabaseReference, key: string, data: Partial<Reading>) {
    return ref.child(key).update(data);
  }

  // create a new reading.
  public async create(ref: DatabaseReference, term: string) {
    const readings = await this.get(ref);

    // This is not exactly right.
    const count = length(readings);

    const reading: Reading = {
      name: `New ${term} category ${count + 1}`,
      description: `New ${term} category ${count + 1}`,
      unit: "",
    };

    return ref.push(reading);
  }

  // Remove an aspect, and re-order around it.
  public async remove(ref: DatabaseReference, key: string) {
    const readings = await this.get(ref);

    delete readings[key];

    return ref.set(readings);
  }

  // Put and get this node.
  private async get(ref: DatabaseReference): Promise<Readings> {
    return (await once<Readings>(ref)) || {};
  }

  // private async put(ref: DatabaseReference, aspects: Readings) {
  //   return ref.set(readings);
  // }
}
