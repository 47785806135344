// pods/dashboard-table/dashboard-table.component.ts
//
// JS logic for dashboard table component.
// This is used both for the first-level, as well as inner tables.

import {Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter} from "@angular/core";

import {
  I,
  P,
  arrayEqual,
  count as arrayCount,
  findIndex,
  groupBy,
  map,
  mapToArray,
  pairs,
  smarten,
} from "@nims/jsutils";

import {
  FlatItemResults,
  ItemArtifacts,
  SnaggingStatus,
  Mode,
  Terms,
  allModes,
  getItemStatuses,
  itemStatusesDescription,
  statusColors,
} from "@nims/red-shared";

import {ReportOptions, addReportOptionsDefaults} from "../report-options/";
import {ReportFilters} from "../report-filters/";
import {ReportDimension, fieldGetter} from "../report-dimension";
import {itemStatusesCombinations} from "../item-statuses-combinations";

// const MODULE_NAME = "console\\dashboard-table.compoent";

////////////////////////////////////////////////////////////////
// LOCAL DEFINITIONS AND FUNCTIONS

function snaggingStatusFilterer(snaggingStatus: SnaggingStatus) {
  return function(item: FlatItemResults) {
    return item.itemArtifactSet.snagging.status === snaggingStatus;
  };
}

const ok = snaggingStatusFilterer(SnaggingStatus.ok);
const notOk = snaggingStatusFilterer(SnaggingStatus.notOk);

// Find the top "n" of some list of items, in terms of occurrences.
function makeTopTen(items: FlatItemResults[], TOPN = 10) {
  return pairs(map(groupBy(items, P("name")), P("length")))
    .sort(([, len1], [, len2]) => len2 - len1)
    .slice(0, TOPN)
    .map(([name, len]) => `${smarten(name)} (${len})`);
}

// Hold the information necessary to display an "overlay" (expanded view of some inspection point).
interface OverlayInfo {
  roomName: string;
  unitName: string;
  itemArtifacts: ItemArtifacts;
}

////////////////////////////////////////////////////////////////
// COMPONENT

@Component({
  selector: "dashboard-table",
  templateUrl: "./dashboard-table.component.html",
  styleUrls: ["./dashboard-table.component.css"],
})
export class DashboardTableComponent implements OnInit, OnChanges {
  @Input() items: FlatItemResults[];
  @Input() options: ReportOptions;

  // We only need the filters to determine if OK items are filtered in,
  // because that affects the verbiage used by "statistics".
  @Input() filters: ReportFilters;

  @Input() dimension: ReportDimension;
  @Input() secondaryDimension: ReportDimension;
  @Input() tertiaryDimension: ReportDimension;
  @Input() max: number;

  @Input() terms: Terms;

  // Is this a second-level (nested) table?
  @Input() secondLevel: boolean;

  // If this is a second-level (nested) table, the key of the outer level.
  @Input() key: string;

  // Control whether bars shows counts or percentages.
  @Input() barLabelPercent: boolean;
  @Output() changeBarLabel = new EventEmitter<boolean>();

  public overlayInfo: OverlayInfo;
  public maxCount: number;
  public getField;
  public statusFn = I;
  public itemStatusesCombinations = itemStatusesCombinations;
  public itemStatusesCombinationsDescriptions = itemStatusesCombinations.map(
    itemStatusesDescription
  );
  public statusColors = statusColors;

  @ViewChild("overlayPanel") overlayPanel;

  public bunches;

  // TODO: Restrict this to those modes available on this project.
  // That will suppress the corresponding legend entry.
  public allModes = allModes;

  constructor() {}

  ngOnInit() {
    // TODO: this is already in `ngOnChanges`; is it necessary here?
    this.getField = fieldGetter(this.dimension);
  }

  ngOnChanges() {
    this.getField = fieldGetter(this.dimension);
    this.update();
    addReportOptionsDefaults(this.options);
  }

  // When the user clicks on a dot, or thumbnail, we pop up a description of the snag.
  public showDetails($event, item: FlatItemResults, mode: Mode) {
    const {itemArtifactSet, roomName, unitName} = item;

    this.overlayInfo = {roomName, unitName, itemArtifacts: itemArtifactSet[mode]};

    this.overlayPanel.toggle($event);
  }

  // The items have changed, probably because a filter changed.
  // Recalculate everything in the form of the `bunches` property,
  // which drives template display.
  public update() {
    if (!this.items) return;


    // Bunch the items by the field for this dimension.
    // This creates an object keyed by field value, as in
    // `{unit1: [], unit2: []}`.
    const bunchGroups = groupBy(this.items, item => this.getField(item));

  //  console.log(bunchGroups);

    // Convert object of bunches to array whose elements are bunches.
    // Each contains name, items, and other metadata such as counts by statuses.
    this.bunches = mapToArray(bunchGroups, (value, key) => {
      const count = value.length;
      
      // Calculate counts for each combination of statuses.
      // That object is keyed by status for that mode (or OK), and contains number
      // of items in that status, in other words, the length of the corresponding segment of the bar graph.
      const counts = map(
        groupBy(value, item =>
          findIndex(itemStatusesCombinations, combination =>
            arrayEqual(combination, getItemStatuses(item.itemArtifactSet))
          )
        ),
        P("length")
      );

      const okCount = arrayCount(value, ok);
      const snagCount = count - okCount;
      const topTen = makeTopTen(value.filter(notOk));

     // console.log(key);


      return {
        key, // name of bunch, such as "Tower A"
        value, // array of items in this bunch
        count,
        counts,
        topTen,
        snagCount,
        okCount,
      };
    });

    // The maximum count for any bunch is used to scale the bars.
    this.maxCount = Math.max(...this.bunches.map(bunch => bunch.count));

    this.sort();
  }

  // Sort the results by the desired field.
  // Called whenever data is updated, or sort order changed (from template).
  public sort() {
    const {sortField, sortAscending} = this.dimension;

    this.bunches = this.bunches.sort((b1, b2) => {
      switch (
        sortField // this.options.sortOption) {
      ) {
        case "name":
          return b1.key.localeCompare(b2.key) * (sortAscending ? +1 : -1);
        case "snagCount":
          return (b1.count - b2.count) * (sortAscending ? +1 : -1);
        default:
          console.error(`Invalid sort option '${sortField}'`);
      }
    });
  }

  // By specifying a `trackBy` on the loop over bunches, we let Angular2 match bunches
  // across recalcs, which makes it possible to do cool-looking transitions on bar widths.
  public trackBunch(index, bunch) {
    return bunch.key;
  }

  // User has clicked on a bar. Change the bar label.
  public toggleBarLabelPercent() {
    this.changeBarLabel.emit((this.barLabelPercent = !this.barLabelPercent));
  }
}
