// pods/dashboard/report-dimension/report-dimension.component.ts
//
// JS logic for component for entering report dimensions.

// ANGULAR
import {SelectItem} from "primeng/primeng";

import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";

import {Terms} from "@nims/red-shared";

import {
  ReportDimension,
  fieldOptions,
  sortFieldOptions,
  sortAscendingOptions,
  dateTransformOptions,
  positionOptions,
  fieldIsDate,
} from "./report-dimension.class";

const noneOption: SelectItem = {label: "None", value: "none"};

@Component({
  selector: "report-dimension",
  templateUrl: "./report-dimension.component.html",
  styleUrls: ["./report-dimension.component.css"],
})
export class ReportDimensionComponent implements OnInit {
  @Input()
  dimension: ReportDimension;
  @Input()
  none = true;
  @Input()
  label: string;
  @Input()
  second = false;
  @Input()
  terms: Terms;

  @Output()
  onChange = new EventEmitter<void>();

  public fieldOptions;
  public sortFieldOptions = sortFieldOptions;
  public sortAscendingOptions = sortAscendingOptions;
  public dateTransformOptions = dateTransformOptions;
  public positionOptions = positionOptions;
  public fieldIsDate = fieldIsDate;

  ngOnInit() {
    this.fieldOptions = fieldOptions(this.terms);
    if (this.second) this.fieldOptions.unshift(noneOption);
  }

  public update() {
    this.onChange.emit();
  }
}
