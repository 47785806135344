import {NgModule} from "@angular/core";

import {} from "../pipes";

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
})
export class PipesModule {}
