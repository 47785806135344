// components/edit-button/edit-button.component.ts
//
// TS logic for edit button component.
// Designed for two-way binding, as in:
// ```
// <edit-button [(editable)]></edit-button>
// ```

import {Component, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: "edit-button",
  templateUrl: "./edit-button.component.html",
  styleUrls: ["./edit-button.component.css"],
})
export class EditButtonComponent {
  @Input() editable: boolean;
  @Input() title = "Edit";

  @Output() editableChange = new EventEmitter<boolean>();

  public onClick() {
    this.editableChange.emit((this.editable = !this.editable));
  }
}
