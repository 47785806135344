// @nims/red/console/src/app/pods/subscribers/subscribers.component.ts
//
// TS logic for page to display and edit subscribers,
// which are people who have signed up to use NIMS for their own inspections.
// This route is accessible only to super users (and developers).

import {Component, OnInit, ViewContainerRef} from "@angular/core";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";

import {not, filterer} from "@nims/jsutils";
import {ConfirmRemoveService} from "@nims/ngutils";
import {AfValue, Subscriber, SubscribersService, isDisabled} from "@nims/red-shared";

import {UserService} from "../../services/user/user.service";

@Component({
  selector: "app-subscribers",
  templateUrl: "./subscribers.component.html",
  styleUrls: ["./subscribers.component.css"],
})
export class SubscribersComponent implements OnInit {
  public help = false;
  public editable = false;

  // We need to filter the subscribers by disabled status?
  public filteredSubscribers$;

  constructor(
    private router: Router,
    private userService: UserService,
    private confirmRemoveService: ConfirmRemoveService,
    private viewContainerRef: ViewContainerRef,
    private subscribersService: SubscribersService
  ) {}

  ngOnInit() {
    this.filteredSubscribers$ = this.subscribersService.get$().pipe(map(filterer(not(isDisabled))));
  }

  // Return home.
  public gotoHome() {
    this.router.navigate(["/home"]);
  }

  // Create new subscriber.
  // First, create new Firebase user.
  // Then, using the uid, create a user in our own database with role etc.
  public create() {
    if (!this.editable) return;

    return this.subscribersService
      .create(this.userService.uid)
      .then(ref => this.gotoSubscriber(ref.key));
  }

  // Removes the highlighted subscriber.
  // Subsequently, navigates the user to the subscribers page.
  public remove(subscriber: AfValue<Subscriber>) {
    if (!this.editable) return;

    this.confirmRemoveService
      .confirm(
        "Really remove subscriber?",
        `Are you sure you want to remove subscriber <q>${subscriber.name}</q> from the system?
<b>This cannot be undone!</b>`,
        this.viewContainerRef
      )
      .subscribe(res => {
        const id = subscriber.$key;

        this.subscribersService
          .disable(id)
          .catch(error => alert(`Problem with deletion: ${error}`));

        this.gotoSubscribers();
      });
  }

  public updateName(user) {
    if (!this.editable) return;

    this.subscribersService.update(user.$key, {name: user.name});
  }

  public selectSubscriber(subscriber: AfValue<Subscriber>) {
    this.gotoSubscriber(subscriber.$key);
  }

  private gotoSubscribers() {
    this.router.navigate(["/subscribers"]);
  }

  private gotoSubscriber(key) {
    this.router.navigate(["subscribers", "subscriber", key]);
  }
}
