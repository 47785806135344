// pods/dashboard/report-definition/report-definition.component.ts
//
// JS logic for component allowing user to enter report definition.
// This in turn includes the dimension(s), options, and filters.

import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  OnDestroy,
  ViewContainerRef,
  ViewChild,
} from "@angular/core";

import {Observable, Subscription} from "rxjs";
import {map, switchMap, take} from "rxjs/operators";

import {FirebaseListObservable, FirebaseObjectObservable} from "angularfire2/database-deprecated";

import {mapFromArray} from "@nims/jsutils";
import {ConfirmRemoveService} from "@nims/ngutils";
import {AfValue, Summary, trackByKey, Terms, UserRole, legacyTerms} from "@nims/red-shared";

import {DatabaseService} from "../../../services/database/database.service";
import {UserService} from "../../../services/user/user.service";

import {ReportDefinition, toString as definitionToString} from "./report-definition.class";
import {schemes} from "../report-options/report-options.class";

@Component({
  selector: "report-definition",
  templateUrl: "./report-definition.component.html",
  styleUrls: ["./report-definition.component.css"],
})
export class ReportDefinitionComponent implements OnDestroy, OnChanges {
  @Input()
  definition: ReportDefinition;
  @Input()
  editable: boolean;
  @Input()
  projectIds: string[];
  @Input()
  terms: Terms = legacyTerms;

  @Output()
  onChange = new EventEmitter<ReportDefinition>();
  @Output()
  onNew = new EventEmitter<ReportDefinition>();

  // Can the user display advanced options?
  @Input()
  canShowAdvanced = true;

  @Input()
  hasReportListOption = true;

  @ViewChild("dataTable")
  private dataTable;

  // CONTROL DISPLAY
  public collapseStored = true;
  public collapseGroups = true;
  public collapseOptions = true;
  public collapseFilters = true;
  public showAdvanced = false;
  public showReports = false;
  public includeds$ = new Observable<any>();
  public summary$: FirebaseObjectObservable<Summary>;

  public emptyMessage = "Loading report definitions";

  public uid: string;

  public schemes = schemes;

  public description: string;

  // FirebaseListObservable of report definitions.
  public list$: FirebaseListObservable<ReportDefinition[]>;
  public shownList$;

  // AngularFire observables of reports associated with this project.
  public summaryDefinitions = {};
  public customerDefinitions = {};

  public summaryReportDefinitionsObject: FirebaseObjectObservable<any>;
  public customerReportDefinitionsObject: FirebaseObjectObservable<any>;
  public summaryReportDefinitionsList: FirebaseListObservable<any>;
  public customerReportDefinitionsList: FirebaseListObservable<any>;

  // The FB key of the currently selected report.
  public key;

  // The current default. Bound to radio buttons in "default" column of table.
  public selectedDefault: string;

  // Track table rows by definition key to avoid unnecessary updates.
  public trackByKey = trackByKey;

  // Descriptions, indexed by definition key, for display when row is expanded.
  public descriptions: {};

  public isCustomer: boolean;

  private summaryDefinitionsSubscription;
  private customerDefinitionsSubscription;
  private summarySubscription;
  private shownListSubscription: Subscription;

  public noOfProjects;

  constructor(
    private confirmRemoveService: ConfirmRemoveService,
    private viewContainerRef: ViewContainerRef,
    private databaseService: DatabaseService,
    private userService: UserService
  ) {}

  ngOnChanges() {
    const uid = (this.uid = this.userService.uid);
    const role = this.userService.role;
    const isCustomer = (this.isCustomer = role === UserRole.customer);
    this.description = definitionToString(this.definition, this.terms);

    this.noOfProjects = this.projectIds.length;

    if (this.noOfProjects > 0) {
      this.summary$ = this.databaseService.getSummaryObject(this.projectIds[0]);
      // Observables of reports (included, customer-visible) associated with this project.
      this.summaryReportDefinitionsObject = this.databaseService.getSummaryReportDefinitionsObject(
        this.projectIds[0]
      );
      this.customerReportDefinitionsObject = this.databaseService.getCustomerReportDefinitionsObject(
        this.projectIds[0]
      );
      this.summaryReportDefinitionsList = this.databaseService.getSummaryReportDefinitionsList(
        this.projectIds[0]
      );
      this.customerReportDefinitionsList = this.databaseService.getCustomerReportDefinitionsList(
        this.projectIds[0]
      );

      // Get list of all definitions from DB.
      this.list$ = this.databaseService.getReportDefinitionsListBySubscriber(
        this.userService.subscriber
      );

      // Find definitions which are non-private (or owned by this user).
      // This is what drives the table.
      this.shownList$ = this.customerReportDefinitionsObject.pipe(
        switchMap(customerDefinitions =>
          this.list$.pipe(
            map(definitions =>
              definitions.filter(definition => show(definition, customerDefinitions))
            )
          )
        )
      );

      // Create an object containing descriptions of each definition.
      this.shownListSubscription = this.shownList$.subscribe(
        (definitions: AfValue<ReportDefinition>[]) => {
          this.descriptions = mapFromArray(
            definitions.map(definition => definition.$key),
            (key, i) => definitionToString(definitions[i], this.terms)
          );
          this.emptyMessage = "No reports available";
        }
      );

      this.summaryDefinitionsSubscription = this.summaryReportDefinitionsObject.subscribe(
        summaryDefinitions => (this.summaryDefinitions = summaryDefinitions)
      );

      this.customerDefinitionsSubscription = this.customerReportDefinitionsObject.subscribe(
        customerDefinitions => (this.customerDefinitions = customerDefinitions)
      );

      // Subscribe to the summary observable, and set the selectedDefault to whatever it says,
      // to control the radio button in each row in the report.
      this.summarySubscription = this.summary$.subscribe(
        summary => (this.selectedDefault = summary.defaultReportDefinition)
      );

      this.summary$.pipe(take(1)).subscribe((summary: Summary) => {
        const {defaultReportDefinition} = summary;

        if (!defaultReportDefinition) return;
        this.list$.$ref.once("value").then(snapshot => {
          const defs = snapshot.val();
          const def = defs[defaultReportDefinition];
          console.assert(def, "default report definition must exist in report-definitions node");

          // This is not a documented API. Use with caution.
          if (this.dataTable && this.dataTable.selectRowWithRadio) {
            this.dataTable.selectRowWithRadio(null, def);
          }
        });
      });
    } else {
      // If in case no projects are selected, clear out the reports list and display appropriate empty message
      // This is the moment when user has selected no project, or deleted all the projects he selected earlier
      this.shownList$ = null;
      this.emptyMessage = "No reports available";
    }

    // Should this definition be shown?
    function show(definition, customerDefinitions) {
      const notPrivate = !definition.private || definition.owner === uid;
      const customerVisible = !isCustomer || customerDefinitions[definition.$key];

      return notPrivate && customerVisible;
    }
  }

  ngOnDestroy() {
    if (this.summaryDefinitionsSubscription) this.summaryDefinitionsSubscription.unsubscribe();
    if (this.summarySubscription) this.summarySubscription.unsubscribe();
    if (this.customerDefinitionsSubscription) this.customerDefinitionsSubscription.unsubscribe();
    if (this.shownListSubscription) this.shownListSubscription.unsubscribe();
  }

  // The definition has changed. Alert the parent component.
  // TODO: handle changes only in filter separately, to avoid unnecessary re-filtering.
  public update() {
    this.onChange.emit();
    this.description = definitionToString(this.definition, this.terms);
  }

  public onFilterChange() {
    this.update();
  }

  // The user has edited the name, description, etc.
  // update the database.
  public onEditComplete(definition) {
    this.list$.update(definition.$key, definition);
  }

  public save(definition) {
    this.list$.update(this.key, this.definition);
  }

  public saveNew(name) {
    this.list$
      .push({
        ...this.definition,
        name,
        owner: this.userService.uid,
        subscriber: this.userService.subscriber,
      })
      // Weird problems with conflicting versions of `Reference`.
      .then((x: any) => (this.key = x.$ref));
  }

  // User has chosen a saved report--emit the change.
  public select(definition) {
    this.onNew.emit(definition);
    this.key = definition.$key;
    this.description = definitionToString(definition, this.terms);
  }

  // Remove the definition selected in the UI.
  public remove(definition) {
    this.confirmRemoveService
      .confirm(
        "Really remove report definition?",
        `Are you sure you want to remove report definition <b>${definition.name}<b>?<br>
This report will no longer appear in any visual reports.<br>
<span style="color: red">This cannot be undone.</span>`,
        this.viewContainerRef
      )
      .subscribe(res => {
        if (res) this._remove(definition.$key);
      });
  }

  // Toggle a report's status as being included by default in the visual report for its units.
  public toggleIncludeReportDefinition(definition: AfValue<ReportDefinition>, checked: boolean) {
    const key = definition.$key;

    if (!key)
      return console.error(
        "report-definition.component#_toggleIncludeReportDefinition: missing key on definition"
      );

    if (checked) this.summaryReportDefinitionsObject.update({[key]: true});
    else this.summaryReportDefinitionsList.remove(key);
  }

  // Toggle a report's visiblity to customer.
  public toggleCustomerReportDefinition(definition: AfValue<ReportDefinition>, checked: boolean) {
    const key = definition.$key;

    if (!key)
      return console.error(
        "report-definition.component#_toggleCustomerReportDefinition: missing key on definition"
      );

    if (checked) this.customerReportDefinitionsObject.update({[key]: true});
    else this.customerReportDefinitionsList.remove(key);
  }

  // The user has clicked on the radio button to set a definition as the default.
  // So update the database.
  public updateDefault(definition: AfValue<ReportDefinition>) {
    const key = definition.$key;

    if (!key)
      return console.error("report-definition.component#updateDefault: missing key on definition");
    this.summary$.update({defaultReportDefinition: key});
  }

  // Remove the definition, assuming the user has already confirmed he wants to do this.
  private _remove(key: string) {
    if (!key)
      return console.error("report-definition.component#_remove: missing key on definition");

    this.list$.remove(key);
    this.databaseService.removeReportDefinitionsFromSummary(key);
  }

  // What was this supposed to do?
  // private filterList(list$, uid) {
  //   return list$.map(list => list.filter(isVisible));

  //   function isVisible(definition) {
  //     return !definition.private || definition.owner === uid;
  //   }
  // }
}
