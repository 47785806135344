// pods/master-rooms/master-rooms.component.ts
//
// Component to display template room types.

import {Component, OnInit, Input} from "@angular/core";
import {FirebaseListObservable} from "angularfire2/database-deprecated";
import {Room, Terms, AfValue} from "@nims/red-shared";

@Component({
  selector: "master-rooms",
  templateUrl: "./master-rooms.component.html",
})
export class MasterRoomsComponent implements OnInit {
  @Input()
  rooms: FirebaseListObservable<Room[]>;
  @Input()
  terms: Terms;

  public editable = false;
  public help = false;

  constructor() {}

  ngOnInit() {}

  // Create a new room.
  // The template room type will be set later.
  public create() {
    this.rooms.push({name: `New ${this.terms.feature}`});
  }

  // User has edited the room's name; update it in DB.
  public update(event) {
    const room: AfValue<Room> = event.data;
    const colData = event.column;

    switch (colData.field) {
      case "name":
        this.rooms.update(room.$key, {name: room.name});
        break;
      default:
        throw new Error("Invalid column field in project-rooms.component#update");
    }
  }

  // Remove the template room type selected in the UI.
  public remove(room: AfValue<Room>) {
    this.rooms.remove(room.$key);
  }
}
