
import {map, pluck} from 'rxjs/operators';
// pods/results/results.component.ts
//
// Implementation of component to display "results" page,
// with dashboard and visual report tabs.

import {Component, OnInit} from "@angular/core";
import {Router, ActivatedRoute} from "@angular/router";
import {AngularFireDatabase} from "angularfire2/database-deprecated";
import {Summary, Capability} from "@nims/red-shared";
import {DatabaseService, UserService} from "../../services/";

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
})
export class ResultsComponent implements OnInit {
  public navLinks = [
    {route: "dashboard", label: "Dashboard", icon: "dashboard"},
    {route: "report", label: "Visual report", icon: "table"},
    {route: "progress", label: "Project progress", icon: "calendar"},
  ];

  public help = false;
  public name$;
  public canEdit;
  public projectChangedAt$;
  public precalculatedAt;
  public upToDate$;
  public recalculating = false;

  private id;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
    private databaseService: DatabaseService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const id = (this.id = this.route.snapshot.params["id"]);
    this.projectChangedAt$ = this.databaseService.getProjectChangedAt(id).pipe(pluck("$value"));

    this.name$ = this.db.object(`summaries/${id}`).pipe(map(name));
    this.canEdit = this.userService.can(Capability.edit);

    (async () => {
      const data = await this.databaseService.getPrecalculatedDashboardData(id);

      this.calculateUpToDate(data);
    })();

    function name(data: Summary) {
      return data.name;
    }
  }

  // NAVIGATION
  public gotoProject() {
    this.router.navigate(["/project", this.id]);
  }
  public gotoProjects() {
    this.router.navigate(["/projects"]);
  }

  // Recalculate dashboard and progress information.
  public async recalculate() {
    this.recalculating = true;

    const data = await this.databaseService.calculateAndStoreDashboardData(this.id);
    this.calculateUpToDate(data);

    this.recalculating = false;
  }

  private calculateUpToDate(data) {
    this.precalculatedAt = data ? data.changedAt : 0;

    this.upToDate$ = this.projectChangedAt$.pipe(map(changedAt => changedAt < this.precalculatedAt));
  }
}
