// dashboard-legend.component.ts: TS logic for dashboard legend
// Invoked from `DashboardTable` component.

import {Component} from "@angular/core";
import {itemStatusesDescription} from "@nims/red-shared";
import {itemStatusesCombinations} from "../item-statuses-combinations";

@Component({
  selector: "dashboard-legend",
  templateUrl: "./dashboard-legend.component.html",
  // styleUrls: ["./dashboard-legend.component.css"],
})
export class DashboardLegendComponent {
  public legendInfo = itemStatusesCombinations.map(statuses => ({
    name: itemStatusesDescription(statuses),
    statuses,
  }));
}
