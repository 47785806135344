// pods/dashboard/report-options.class.ts
//
// Definition of report options.

import {SelectItem} from "primeng/primeng";

import {ReportOptions} from "./report-options.interface";

// This makes compilation under the `isolatedModules` option stop working.
export {ReportOptions};

import {K, joinComma, mapFromArray} from "@nims/jsutils";
import {Terms} from "@nims/red-shared";

const TRUE = K(true);

export function getDefaultReportOptions(): ReportOptions {
  return {
    snagDisplay: "none",
    display: {bar: true},
    scheme: 0,
    schemeSnagging: 1,
    schemeFixing: 2,
  };
}

export const snagDisplayOptions: SelectItem[] = [
  {label: "none", value: "none"},
  {label: "dots", value: "dot"},
  {label: "thumbnail", value: "thumbnail"},
  {label: "tiny thumbnail", value: "tiny"},
  {label: "description", value: "description"},

  // Kill this until we get it working again.
  //  {label: "full", value: "full"},
];

export const displayOptions: SelectItem[] = [
  {label: "bars", value: "bar"},
  {label: "top ten snags", value: "topten"},
  {label: "statistics", value: "statistics"},
];

export const NEMMADI_COLOR = "rgb(0, 188, 212)";
export const PENDING_COLOR = "#a9a9a9";

// The first color is for "OK". The second color is for "pending".
// The third and succeeding colors are for specific statuses.
export const schemes = [
  ["rgb(215,25,28)", "rgb(253,174,97)", "rgb(166,217,106)", "rgb(26,150,65)"],
  ["rgb(208,28,139)", "rgb(241,182,218)", "rgb(184,225,134)", "rgb(77,172,38)"],
  ["rgb(230,97,1)", "rgb(253,184,99)", "rgb(178,171,210)", "rgb(94,60,153)"],
  ["rgb(202,0,32)", "rgb(244,165,130)", "rgb(146,197,222)", "rgb(5,113,176)"],
  ["rgb(202,0,32)", "rgb(244,165,130)", "rgb(186,186,186)", "rgb(64,64,64)"],
  ["rgb(215,25,28)", "rgb(253,174,97)", "rgb(171,217,233)", "rgb(44,123,182)"],
  ["rgb(215,25,28)", "rgb(253,174,97)", "rgb(171,221,164)", "rgb(43,131,186)"],
  ["rgb(166,97,26)", "rgb(223,194,125)", "rgb(128,205,193)", "rgb(1,133,113)"],
];

export function setDisplay(options: ReportOptions, display) {
  options.display = mapFromArray(display, TRUE);
}
export function setScheme(options: ReportOptions, scheme) {
  options.scheme = scheme;
}

// I don't think these are being used any more.
export function setSchemeSnagging(options: ReportOptions, scheme) {
  options.schemeSnagging = scheme;
}
export function setSchemeFixing(options: ReportOptions, scheme) {
  options.schemeFixing = scheme;
}
export function setSnagDisplay(options: ReportOptions, display) {
  options.snagDisplay = display;
}

export function getSchemeColors(options: ReportOptions) {
  return schemes[options.scheme];
}

export function reportOptionsToString(options: ReportOptions, terms: Terms) {
  const {display} = options;
  const text = joinComma(
    Object.keys(display).map(
      displayOption => displayOptions.find(option => option.value === displayOption).label
    )
  );

  return `Display ${text}`;
}

// Some older report options may be missing the defaults for the new schemes for snagging and fixing.
// Add them.
export function addReportOptionsDefaults(reportOptions: ReportOptions) {
  if (reportOptions.schemeSnagging === undefined) reportOptions.schemeSnagging = 1;
  if (reportOptions.schemeFixing === undefined) reportOptions.schemeFixing = 2;
}
