// pods/dashboard/dashboard-filter/dashboard-filter.ts
//
// JS logic for component which filters data and then displays tables.
// Used both by dashboard and visual reports (unit results).

// ANGULAR
import {Component, OnInit, OnChanges, SimpleChanges, Input} from "@angular/core";

import {Terms} from "@nims/red-shared";

// OUR APP
import {ReportDefinition} from "../report-definition/report-definition.class";
import {filterer} from "../report-filters/report-filters.class";

@Component({
  selector: "dashboard-filter",
  templateUrl: "./dashboard-filter.component.html",
})
export class DashboardFilterComponent implements OnInit, OnChanges {
  @Input()
  public items;
  @Input()
  public definition: ReportDefinition;
  @Input()
  public terms: Terms;

  // Observable of items filtered only to contain the relevant ones.
  // This is what drives the `dashboard` component.
  public filteredItems = [];
  public filteredCount;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.items) return;

    const filter = filterer(this.definition.filters);

    this.filteredItems = this.items.filter(filter);
    this.filteredCount = this.filteredItems.length;
  }
}
