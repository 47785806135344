// dashboard-dot.component.ts
//
// Logic for a dot representing an item, with its statuses shown as a gradient.
// Note: this component does not control the shape of the dot. Do that from the outside.

import {Component, Input} from "@angular/core";
import {ItemResults, ItemStatus, getItemStatuses} from "@nims/red-shared";

@Component({
  selector: "dashboard-dot",
  template: `<div class="dashboard-dot" [itemStatusesGradient]="itemStatuses"></div>`,
  styles: [".dashboard-dot { width: 100%; height: 100%; }"],
})
export class DashboardDotComponent {
  @Input() item: ItemResults;

  public itemStatuses: ItemStatus[];

  ngOnInit() {
    this.itemStatuses = getItemStatuses(this.item.itemArtifactSet);
  }
}
