// services/templates.service.ts
//
// Handle manipulation of templates.
// Mainly invoked from the templates page.

import {Injectable} from "@angular/core";
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";
import {
  ProjectTemplate,
  defaultAspects,
  defaultReadings,
  defaultDescriptions,
  legacyTerms,
} from "@nims/red-shared";
import {UserService} from "./user/user.service";

const MODULE_NAME = "services/templates.service";

@Injectable()
export class TemplatesService {
  constructor(private readonly userService: UserService) {}

  // Create a new template.
  public async create(ref: DatabaseReference, subscriber: string): Promise<string> {
    const template: Partial<ProjectTemplate> = {
      name: "New template",
      desc: "",
      createdOn: +new Date(),
      createdBy: this.userService.uid,
      aspects: defaultAspects,
      descriptions: defaultDescriptions(),
      readings: defaultReadings(),
      terms: legacyTerms,
      subscriber,
    };

    const snapshot = await ref.push(template);
    const {key} = snapshot;

    console.assert(!!key, `${MODULE_NAME}#create: missing key in response snapshot`);
    return key;
  }

  // Make a new template from an existing one.
  public async copy(
    ref: DatabaseReference,
    template: ProjectTemplate,
    subscriber: string
  ): Promise<string> {
    const {rooms, sections, items, aspects, descriptions, readings, terms} = template;

    const newTemplate: ProjectTemplate = {
      aspects,
      createdBy: this.userService.uid,
      createdOn: +new Date(),
      desc: "",
      descriptions,
      items,
      name: `Copy of ${template.name}`,
      rooms,
      sections,
      readings: readings || defaultReadings(),
      subscriber,
      terms: terms || legacyTerms,
      test: false,
    };

    // TODO: specify correct type of snapshot, since FB is of no help.
    const snapshot = await ref.push(newTemplate);
    const {key} = snapshot;

    console.assert(!!key, `${MODULE_NAME}#copy: missing key in response snapshot`);
    return key;
  }

  public update(ref: DatabaseReference, templateKey: string, data: Partial<ProjectTemplate>) {
    return ref.child(templateKey).update(data);
  }

  public remove(ref: DatabaseReference, templateKey: string) {
    return ref.child(templateKey).remove();
  }

  // Put and get this node.
  // private async get(ref: DatabaseReference): Promise<ProjectTemplates> { return (await once<ProjectTemplates>(ref)) || {}; }
  // private async put(ref: DatabaseReference, items: ProjectTemplates) { return ref.set(items); }
}
