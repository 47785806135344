import {Component, OnInit, OnDestroy} from "@angular/core";
import {Router} from "@angular/router";

import {FirebaseObjectObservable} from "angularfire2/database-deprecated";

import {UserService} from "../../services/user/user.service";
import {DatabaseService} from "../../services/database/database.service";

@Component({
  selector: "my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.css"],
})
export class MyAccountComponent implements OnInit, OnDestroy {
  public newPassword;
  public newName;
  public newEmail;

  public help = false;
  public error;
  public showPassword = false;

  private userObject: FirebaseObjectObservable<any>;

  constructor(
    private router: Router,
    private userService: UserService,
    private databaseService: DatabaseService
  ) {}

  ngOnInit() {
    this.newName = this.userService.user.name;
    this.userObject = this.databaseService.getUserObject(this.userService.uid);
  }

  ngOnDestroy() {}

  public changePassword() {
    const passwordChanged = this.userService.changePassword(this.newPassword);

    passwordChanged.catch(error => console.error(error));

    passwordChanged.then(() => this.gotoHome(), error => (this.error = error));
  }

  public changeName() {
    this.userObject.update({name: this.newName});
  }

  public changeEmail() {
    const emailChanged = this.userService.changeEmail(this.newEmail);

    emailChanged.catch(error => console.error(error));

    emailChanged.then(() => this.gotoHome(), error => (this.error = error));

    // update email in users database
    this.userObject.update({email: this.newEmail});
  }

  // Return home.
  public gotoHome() {
    this.router.navigate(["/home"]);
  }
}
