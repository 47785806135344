// readings/readings.component.ts
//
// Display template or project readings (value categories).

import {Component, OnInit, Input, ViewContainerRef} from "@angular/core";
import {FirebaseListObservable} from "angularfire2/database-deprecated";
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";
import {pluralize} from "@nims/jsutils";
import {AfValue, trackByKey, Reading, Terms} from "@nims/red-shared";
import {ConfirmRemoveService} from "@nims/ngutils";
import {ChecklistService} from "../../services/checklist.service";

type List<T> = FirebaseListObservable<AfValue<T>[]>;

@Component({
  selector: "readings",
  templateUrl: "./readings.component.html",
  styleUrls: ["./readings.component.css"],
})
export class ReadingsComponent implements OnInit {
  @Input()
  public readings: List<Reading>;

  @Input()
  title: string;
  @Input()
  editable: boolean;
  @Input()
  terms: Terms;

  public help = false;
  public emptyMessage = "Loading, wait...";
  public trackByKey = trackByKey;

  private ref: DatabaseReference;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private confirmRemoveService: ConfirmRemoveService,
    private readonly checklistService: ChecklistService
  ) {}

  ngOnInit() {
    this.emptyMessage = `No ${this.terms.reading} categories found`;
    this.ref = (this.readings.$ref as DatabaseReference).parent;
  }

  // Handle updates to section properties.
  // TOOD: rename this.
  public onChangeName(reading: AfValue<Reading>) {
    const {name, description, unit} = reading;

    this.update(reading, {name, description, unit});
  }

  // create a new aspect.
  public create() {
    return this.checklistService
      .createReading(this.ref, {}, this.terms.reading)
      .then(() => this.success("Successfully created value category"))
      .catch(e => this.error("Failed to create value category", e));
  }

  // Remove a section, after confirmation.
  public remove({$key: key, name}: AfValue<Reading>) {
    return this.confirmRemove(name).subscribe(res => {
      if (res) {
        this.checklistService
          .removeAspect(this.ref, key)
          .then(() => this.success("Successfully removed value category"))
          .catch(e => this.error("Failed to remove value category", e));
      }
    });
  }

  // Make sure the user is OK with deleting this aspect.
  private confirmRemove(name: string) {
    return this.confirmRemoveService.confirm(
      "Really remove aspect?",
      `Are you sure you want to remove ${this.terms.reading} category &ldquo;${name}&rdquo;?
<b>This ${this.terms.reading} category will be permanently lost!</b>
 All ${pluralize(this.terms.checkpoint)} with this ${
        this.terms.reading
      } category will be set back to the default.`,
      this.viewContainerRef
    );
  }

  // Update an aspect property.
  private update({$key: key}: AfValue<Reading>, data: Partial<Reading>) {
    return this.checklistService
      .updateReading(this.ref, key, data)
      .then(() => this.success("Successfully updated value category"))
      .catch(e => this.error("Failed to update value category", e));
  }

  private error(msg: string, err: Error) {
    console.error(msg, err);
  }

  private success(msg: string) {
    console.log(msg);
  }
}
