// items/items.component.ts
//
// Display template or project items.

import {
  Component,
  OnChanges,
  OnInit,
  OnDestroy,
  Input,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AngularFireDatabase, FirebaseListObservable} from "angularfire2/database-deprecated";
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";
import {letter, forEach, Hash, values} from "@nims/jsutils";
import {ConfirmRemoveService} from "@nims/ngutils";

import {
  Room,
  Item,
  Section,
  AfValue,
  Reading,
  Description,
  Terms,
  displayReading,
  trackByKey,
  moveOrdered,
  removeOrdered,
} from "@nims/red-shared";

@Component({
  selector: "items",
  templateUrl: "./items.component.html",
  styleUrls: ["./items.component.css"],
})
export class ItemsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public rooms: FirebaseListObservable<Room>;
  @Input() public section: Section;
  @Input() public items: FirebaseListObservable<Item[]>;
  @Input() public sections: FirebaseListObservable<Section>;
  @Input() public editable: boolean;
  @Input() public readings: FirebaseListObservable<Reading[]>;
  @Input() public descriptions: FirebaseListObservable<Description[]>;
  @Input() public terms: Terms;

  @Input() title: string;

  public newName: string;
  public help = false;
  public emptyMessage = "Loading, wait...";
  public sectionItems$: Observable<Item[]>;
  public trackByItem = trackByKey;
  public letter = letter;
  public readingOptions: Observable<any[]>;
  public readingMap: Observable<{[readingId: string]: Reading}>;
  public descriptionOptions: Observable<any[]>;
  public descriptionMap: Observable<{[id: string]: Description}>;

  private currentItems;
  private itemsSubscription;

  constructor(
    private db: AngularFireDatabase,
    private viewContainerRef: ViewContainerRef,
    private confirmRemoveService: ConfirmRemoveService
  ) {}

  ngOnInit() {
    const section = this.section as AfValue<Section>;

    // TODO: figure out why this AngularFire query doesn't work.
    // this.items = this.db.list(
    //   (<firebase.database.Reference>this.sections.$ref).parent.child('items'),
    //   {query: {orderByChild: 'section', equalTo: section.$key}});
    this.sectionItems$ = this.items.pipe(
      map(items => items.filter(item => item.section === section.$key))
    );
    this.itemsSubscription = this.sectionItems$.subscribe(items => {
      console.log(items, 'items');
      this.currentItems = items;
      this.emptyMessage = "No items found";
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    // Prepare list of possible readings.
    this.readingOptions = this.readings.pipe(
      map((readings: AfValue<Reading>[]) => [
        {label: "none", value: null},
        ...readings
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(reading => ({label: displayReading(reading), value: reading.$key})),
      ])
    );
    // Readings as passed in is a list observable.
    // Create an object addressable by reading ID for use in displaying reading name in table.
    this.readingMap = this.db.object(this.readings.$ref);

    this.descriptionOptions = this.descriptions.pipe(
      map((descriptions: AfValue<Description>[]) => [
        {label: "none", value: null},
        ...descriptions
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({name: label, $key: value}) => ({label, value})),
      ])
    );
    this.descriptionMap = this.db.object(this.descriptions.$ref);
  }

  ngOnDestroy() {
    this.itemsSubscription.unsubscribe();
  }

  // Set the room applicability for a particular item,
  // which is held in rooms/roomId/items/itemId.
  public toggleRoomMap(event: Event, item: AfValue<Item>, room: AfValue<Room>) {
    (this.rooms.$ref as DatabaseReference)
      .child(`${room.$key}/items/${item.$key}`)
      .once("value")
      .then(itemSnap => (itemSnap.exists() ? itemSnap.ref.remove() : itemSnap.ref.set(true)));

    event.preventDefault();
  }

  // Add a new item, ordered at the end of other items in the section.
  public create() {
    let order = Math.max(...this.currentItems.map(_item => _item.order));

    if (order === -Infinity || isNaN(order)) order = 0;
    else order++;

    const item = {
      name: `New ${this.terms.checkpoint}`,
      disabled: false,
      order,
      section: (<AfValue<Section>>this.section).$key,
    } as Item;

    this.items.push(item);
  }

  // User has edited item's name (description).
  public onChangeName(item: AfValue<Item>) {
    this.items.update(item.$key, {name: item.name});
  }

  // When the user toggles the reading value, add a new reading field to the item,
  // or toggle the disabled status if it's already there.
  // If the reading is null, the user has selected "none".
  // In that case we could theoretically remove the property; instead we just store the null.
  public updateReading(item: AfValue<Item>, reading: string) {
    this.items.update(item.$key, {reading: reading});
  }

  // Handle description.
  public updateDescription(item: AfValue<Item>, description: string) {
    this.items.update(item.$key, {description});
  }

  // User has edited item's disabled status.
  public toggleDisabled(item: AfValue<Item>) {
    this.items.update(item.$key, {disabled: !item.disabled});
  }

  public moveUp(item: AfValue<Item>) {
    this.move(item, -1);
  }
  public moveDown(item: AfValue<Item>) {
    this.move(item, +1);
  }

  public remove(item: AfValue<Item>) {
    this.confirmRemoveService
      .confirm(
        "Really remove item?",
        `Are you sure you want to remove item &ldquo;${item.name}&rdquo; from the checklist?
 Remember that removing this item will have no effect on units already being inspected.
 <b>This cannot be undone!</b>`,
        this.viewContainerRef
      )
      .subscribe(res => {
        if (res) this._remove(item);
      });
  }

  // Delete an item.
  // Remove it from the database, and from the list of selected section rows.
  // Also delete it from all rooms,
  // and renumber everything items within the section.
  public _remove(item: AfValue<Item>) {
    const itemsRef = this.items.$ref as DatabaseReference;
    const projectRef = itemsRef.parent;
    const roomsRef = projectRef.child("rooms");
    const itemId = item.$key;

    return _removeOrdered().then(removeFromRooms);

    function removeFromRooms() {
      // Remove this item from all rooms.
      return roomsRef.transaction((rooms: Hash<Room>) => {
        forEach(rooms || {}, room => delete room.items[itemId]);
        return rooms;
      });
    }

    // Remove an item from its position in order.
    // TODO: Fix this to make sure transaction is actually happening!!
    function _removeOrdered() {
      return itemsRef.transaction((items: Hash<Item>) => {
        const {$key: key} = item;
        const _item = items[key];
        const sectionItems = values(items).filter(i => i.section === item.section);

        removeOrdered(sectionItems, _item);
        delete items[key];
        return items;
      });
    }
  }

  // Move items around inside a section.
  private move(item, delta: number) {
    const itemsRef = this.items.$ref as DatabaseReference;

    return itemsRef.transaction((items: Hash<AfValue<Item>>) => {
      const {$key: key} = item;
      const _item = items[key];
      const sectionItems = values(items).filter(i => i.section === item.section);

      moveOrdered(sectionItems, _item, delta);

      return items;
    });
  }
}
