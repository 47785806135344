// firebase.module.ts
//
// Module to bring in all necessary Firebase-related things,
// and initialize Firebase.
//
// TODO: Figure out why this doesn't work when brought in from @nims/red-shared.

import {NgModule} from "@angular/core";

import {AngularFireModule} from "angularfire2";
import {AngularFireDatabaseModule} from "angularfire2/database-deprecated";
import {AngularFireAuthModule} from "angularfire2/auth";

import {environment} from "../../environments/environment";

const firebaseConfig = environment.firebaseConfig;
console.assert(!!firebaseConfig, "firebaseConfig must be present in environment");

@NgModule({
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
  ],
})
export class FirebaseModule {}

// Create a secondary app for use in creating new accounts.
// export const secondaryApp = firebase.initializeApp(firebaseConfig, "secondary");
