import {Environment} from "./environment.type";

export const environment: Environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBXeVFXGDmISOWveMJY7xhpn5RnBp8dpBc",
    authDomain: "nemmadi-console.firebaseapp.com",
    databaseURL: "https://nemmadi-console.firebaseio.com",
    storageBucket: "nemmadi-console.appspot.com",
    messagingSenderId: "351667525848",
  },
  googleAnalytics: {
    enable: false,
  },
  googleMaps: {
    apiKey: "AIzaSyBr480bYHcKkNFDGHjGtHAbculq_JkodH4",
  },
  endpoints: {
    CREATE_USER: "https://us-central1-nemmadi-console.cloudfunctions.net/createUser",
    DELETE_USER: "https://us-central1-nemmadi-console.cloudfunctions.net/deleteUser",
  },
  debug: {
    ngLog: true,
    enableRouterTracing: false,
  },
  version: "2.3.2 (dev)",
  name: "NIMS",
  sentryDsn: null,
};
