// services/user/authguards.ts
//
// Auth guards related to users.

// ANGULAR
import {Injectable} from "@angular/core";
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";

// COMMON
import {Capability} from "@nims/red-shared";

// SERVICES
import {UserService} from "./user.service";

@Injectable()
export class IsLoggedIn implements CanActivate {
  constructor(private userService: UserService, router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.loggedInPromise;
  }
}

@Injectable()
export class CanViewProjects implements CanActivate {
  constructor(userService: UserService, router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
  }
}

// Protect manage accounts page.
@Injectable()
export class CanManageAccounts implements CanActivate {
  constructor(private userService: UserService, router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.can(Capability.manageAccounts);
  }
}

// Protect manage subscribers page.
@Injectable()
export class CanManageSubscribers implements CanActivate {
  constructor(private userService: UserService, router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.can(Capability.manageSubscribers);
  }
}

@Injectable()
export class CanViewSelectedProjects implements CanActivate {
  constructor(userService: UserService, router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
    // return this.userService.can$(Capability.viewSelectedProjects).do(
    //   can => {
    //     if (!can) this.router.navigate(['/login']);
    //   });
  }
}

@Injectable()
export class CanManageTemplates implements CanActivate {
  constructor(userService: UserService, router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
    // return this.userService.can$(Capability.manageTemplates).do(
    //   can => {
    //     if (!can) this.router.navigate(['/login']);
    //   });
  }
}

@Injectable()
export class ManagerAuthGuard implements CanActivate {
  constructor(userService: UserService, router: Router) {}

  canActivate() {
    return true;
    // return this.userService.user$.map(
    //   user => {
    //     const role = user.role;
    //     const ok = role === UserRole.administrator || role === UserRole.manager;

    //   if (!ok) this.router.navigate(['/login']);
    //   return ok;
    // });
  }
}

@Injectable()
export class CanViewMultiProjectDashboard implements CanActivate {
  constructor( userService: UserService, router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return this.userService.can(Capability.viewMultiProjectDashboard);
    return true;
  }
}
