// stutus-gradient.directive.ts
//
// TS logic for giving something gradient background, representing a bunch of item statuses.
// The idea is that we can use this both for things like "dots" and bar segments,
// not to mention legend entries.

import {Directive, ElementRef, Input, Renderer2} from "@angular/core";
import {makeStippledLinearGradient} from "@nims/jsutils";
import {ItemStatus, makeItemStatusesGradient} from "@nims/red-shared";

@Directive({
  selector: "[itemStatusesGradient]",
})
export class ItemStatusesGradientDirective {
  @Input("itemStatusesGradient") itemStatuses: ItemStatus[];
  @Input() itemStatusesGradientAngle = "to bottom";

  constructor(private elementRef: ElementRef, private readonly renderer2: Renderer2) {}

  ngOnInit() {
    const gradientOptions = {sideOrCorner: this.itemStatusesGradientAngle};
    const gradient = makeItemStatusesGradient(this.itemStatuses, gradientOptions);
    const stipple = makeStippledLinearGradient();

    this.renderer2.setStyle(
      this.elementRef.nativeElement,
      "backgroundImage",
      `${stipple}, ${gradient}`
    );
  }
}
