// services/checklist.service.ts
//
// Handle manipulation of "checklists"--items, sections, readings, descriptions, and aspects.

import {Injectable} from "@angular/core";

// FIREBASE
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

// COMMON
import {Section, Aspect, Description, Reading, Terms, legacyTerms} from "@nims/red-shared";

import {SectionsService} from "./sections.service";
import {ItemsService} from "./items.service";
import {RoomsService} from "./rooms.service";
import {AspectsService} from "./aspects.service";
import {DescriptionsService} from "./descriptions.service";
import {ReadingsService} from "./readings.service";

const child = (name: string) => (ref: DatabaseReference) => ref.child(name);

const sections = child("sections");
const items = child("items");
const readings = child("readings");
const aspects = child("aspects");
const descriptions = child("descriptions");
const rooms = child("rooms");
const terms = child("terms");

@Injectable()
export class ChecklistService {
  constructor(
    private readonly sectionsService: SectionsService,
    private readonly itemsService: ItemsService,
    private readonly roomsService: RoomsService,
    private readonly aspectsService: AspectsService,
    private readonly descriptionsService: DescriptionsService,
    private readonly readingsService: ReadingsService
  ) {}

  ////////////////////////////////////////////////////////////////
  // SECTIONS

  // Create a section.
  public createSection(ref: DatabaseReference, data: Partial<Section> = {}, term: string) {
    return this.sectionsService.create(sections(ref), data, term);
  }

  // Move a section.
  public moveSection(ref: DatabaseReference, sectionKey: string, delta: number) {
    return this.sectionsService.move(sections(ref), sectionKey, delta);
  }

  // Update a section.
  public updateSection(ref: DatabaseReference, sectionKey: string, data: Partial<Section>) {
    return this.sectionsService.update(sections(ref), sectionKey, data);
  }

  // Remove a section.
  // Remove it from the database, and delete all items in the section, and remove them from rooms.
  public async removeSection(ref: DatabaseReference, sectionKey: string) {
    const itemKeys = await this.getItemKeysBySection(ref, sectionKey);

    await this.sectionsService.remove(sections(ref), sectionKey);
    await this.itemsService.removeMany(items(ref), itemKeys);
    await this.roomsService.removeItems(rooms(ref), itemKeys);
  }

  // Set the room applicability for all items in a section.
  // We do this when the user clicks on the double checkmark.
  public async toggleRoomSection(
    ref: DatabaseReference,
    sectionKey: string,
    roomKey: string,
    value: boolean
  ) {
    const itemKeys = await this.getItemKeysBySection(ref, sectionKey);

    this.roomsService.toggleItems(rooms(ref), roomKey, itemKeys, value);
  }

  // public removeItem(ref: Ref, itemKey: string) {
  //   this.itemsService.remove(items(ref), itemKey);
  //   this.roomsService.removeItem(rooms(ref), itemKey);
  // }

  // public removeItems(ref: Ref, itemKeys: string[]) {
  //   this.itemsService.remove(items(ref), itemKeys);
  //   this.roomsService.removeItems(rooms(ref), itemKeys);
  // }

  private getItemKeysBySection(ref: DatabaseReference, sectionKey: string): Promise<string[]> {
    return this.itemsService.getKeysBySection(items(ref), sectionKey);
  }

  ////////////////////////////////////////////////////////////////
  // ASPECTS

  // Create a aspect.
  public createAspect(ref: DatabaseReference, data: Partial<Aspect> = {}, term = "aspect") {
    return this.aspectsService.create(aspects(ref), term);
  }

  // Move a aspect.
  public moveAspect(ref: DatabaseReference, key: string, delta: number) {
    return this.aspectsService.move(aspects(ref), key, delta);
  }

  // Update a aspect.
  public updateAspect(ref: DatabaseReference, key: string, data: Partial<Aspect>) {
    return this.aspectsService.update(aspects(ref), key, data);
  }

  // Remove an aspect.
  // Remove it from the database, and set all sections using it back to default.
  public async removeAspect(ref: DatabaseReference, key: string) {
    await this.aspectsService.remove(aspects(ref), key);
    await this.sectionsService.removeAspect(sections(ref), key);
  }

  ////////////////////////////////////////////////////////////////
  // DESCRIPTIONS

  // Create a description.
  public createDescription(ref: DatabaseReference, data: Partial<Aspect> = {}) {
    return this.descriptionsService.create(descriptions(ref));
  }

  // Update a description.
  public updateDescription(ref: DatabaseReference, key: string, data: Partial<Description>) {
    return this.descriptionsService.update(descriptions(ref), key, data);
  }

  // Remove a description.
  // Remove it from the database, and set all sections using it back to default.
  public async removeDescription(ref: DatabaseReference, key: string) {
    await this.descriptionsService.remove(descriptions(ref), key);
    await this.itemsService.removeDescription(descriptions(ref), key);
  }

  ////////////////////////////////////////////////////////////////
  // READINGS

  // Create a reading.
  public createReading(ref: DatabaseReference, data: Partial<Reading> = {}, term: string) {
    return this.readingsService.create(readings(ref), term);
  }

  // Update a reading.
  public updateReading(ref: DatabaseReference, key: string, data: Partial<Reading>) {
    return this.readingsService.update(readings(ref), key, data);
  }

  // Remove a reading.
  // Remove it from the database, and set all sections using it back to default.
  public async removeReading(ref: DatabaseReference, key: string) {
    await this.readingsService.remove(readings(ref), key);
    await this.itemsService.removeReading(items(ref), key);
  }

  ////////////////////////////////////////////////////////////////
  // TERMS
  // Update a reading.
  // Note the argument is the reference for the project or the template, not the terms node itself!!!
  public updateTerms(ref: DatabaseReference, data: Terms) {
    return terms(ref).update(data);
  }

  // Check to see if the terms node exists under a template or project.
  // If it doesn't, fill it in.
  public async ensureTerms(ref: DatabaseReference) {
    const node = terms(ref);
    const snapshot = await node.once("value");

    if (!snapshot.exists()) node.update(legacyTerms);
  }
}
