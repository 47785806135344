// utils/date-range.util.ts
//
// Format a series of dates.
// Example: January 15, February 22 and March 27–28 & 30, 2017; June 22, 2018
//
// TODO: repalce this with the version in `@nims/jsutils`.

import {mapToArray, partitionAt, map} from "@nims/jsutils";

const monthNames = [
  "Jan.",
  "Feb.",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug.",
  "Sep.",
  "Oct.",
  "Nov.",
  "Dec.",
];

interface DateParts {
  year: number;
  month: number;
  date: number;
}

function join2(arr, delim1, delim2) {
  const clone = arr.slice();
  const last = clone.pop();

  return clone.length ? [clone.join(delim1), last].join(delim2) : last;
}

// Group an array according to some predicate.
function groupBy<T>(arr: T[], pred: (elt: T) => string | number): {[key: string]: T[]} {
  return arr.reduce((result, elt) => {
    const key = pred(elt);
    (result[key] = result[key] || []).push(elt);
    return result;
  }, {});
}

export function formatDateRange(dates: Date[]) {
  // Sort dates numerically.
  const sortDate = (a, b) => (+a < +b ? -1 : +a > +b ? +1 : 0);

  // Create an object containing year, month, and date from a date.
  const makeDateParts: (date: Date) => DateParts = date => ({
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
  });

  // Determine if an elt is immediately adjacent to the previous one.
  const nonAdjacentDays = (elt, idx, arr) => !idx || elt.date !== arr[idx - 1].date + 1;

  // Partition an array of day numbers into groups of adjacent days.
  const dayRanges = days => partitionAt(days, nonAdjacentDays);

  // Output an array containing a single or contiguous range of day numbers, as in "29" or "30-31".
  const outputDayGroup = dayGroup =>
    dayGroup[0].date + (dayGroup.length === 1 ? "" : "\u2013" + dayGroup[dayGroup.length - 1].date);

  // Output a range of days, as in "28-29 and 31".
  const outputDays = days => dayRanges(days).map(outputDayGroup);

  // Output an array of dates in the same month, as in "March 28-29 and 31"
  const outputMonths = (days, month) => monthNames[month] + " " + outputDays(days).join(" & ");

  // Output a year object.
  const outputYear = (months, year) =>
    join2(mapToArray(months, outputMonths), ", ", " and ") + ", " + year;

  // Break down the input list of dates into objects of date parts.
  const dateParts = dates.sort(sortDate).map(makeDateParts);

  // Create year groups of form `{year1: [date1, date2], year2: [date3, date4], ...}`.
  const yearGroups: {[key: string]: DateParts[]} = groupBy(dateParts, parts => parts.year);

  // Create data of form `{year1: {month1: [date1], month2: [date2]}, year2: {month3: [date3]}}`.
  const yearGroupsByMonth = map(yearGroups, yearGroup => groupBy(yearGroup, group => group.month));

  return mapToArray(yearGroupsByMonth, outputYear).join("; ");
}

// const dates = [new Date(2017, 0, 15), new Date(2017, 1, 22), new Date(2017, 2, 27),
// new Date(2017, 2, 28), new Date(2017, 2, 30), new Date(2018, 5, 22)];
// console.log(formatDateRange(dates));
