// projects/projects.component.ts
//
// JS logic for "projects" component to display list of projects.

import {Component, OnInit, ViewContainerRef} from "@angular/core";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import {FirebaseApp} from "angularfire2";
import {AngularFireDatabase, FirebaseListObservable} from "angularfire2/database-deprecated";
import {DatabaseSnapshot} from "angularfire2/database-deprecated/interfaces";
import * as firebase from "firebase";
import {FilterMetadata, SelectItem} from "primeng/primeng";

// NEMMADI COMMON
import {
  Summary,
  Project,
  projectStatuses,
  Capability,
  AfValue,
  ProjectTemplate,
  isUnviewableTestProjectForUser,
  isUnviewableTestTemplateForUser,
  isUserAssignedProject,
  legacyTerms,
} from "@nims/red-shared";

import {allObject, mapFromArray, not} from "@nims/jsutils";
import {once} from "@nims/afutils";
import {ConfirmRemoveService} from "@nims/ngutils";

import {UserService, DatabaseService} from "../../services/";

const MODULE_NAME = "console/projects.component";
// const LOG = false;

function byName(a, b) {
  return a.name.localeCompare(b.name);
}

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.css"],
})
export class ProjectsComponent implements OnInit {
  public summaries: FirebaseListObservable<Summary[]>;

  public statuses = projectStatuses.map(status => ({label: status, value: status}));

  public editable = false;
  public help = false;
  public emptyMessage = "Loading, wait...";
  public selectedTypes = projectStatuses.filter(status => status !== "archived");
  public templateOptions$: Observable<SelectItem[]>;
  public templatesList$: Observable<ProjectTemplate[]>;
  public templateObject$;
  public projectSourceOptions$;

  // Template fields for creating or cloning new project.
  public newProjectTemplate;
  public newProjectName: string;
  public clonedProjectName: string;
  public clonedProjectSource: AfValue<Summary>;
  public clonedProjectError = "";

  // Capabilities for editing and viewing.
  public canEdit;
  public canViewProjectInfo;

  // Summaries filtered for accessilibity by customer.
  public filteredSummaries$;

  // Filters to apply declaratively on `p-datatable` element.
  public dtFilters = {status: {value: this.selectedTypes, matchMode: "in"} as FilterMetadata};

  constructor(
    private router: Router,
    private confirmRemoveService: ConfirmRemoveService,
    private viewContainerRef: ViewContainerRef,
    private db: AngularFireDatabase,
    private userService: UserService,
    private databaseService: DatabaseService,
    private readonly firebaseApp: FirebaseApp
  ) {}

  ngOnInit() {
    const {subscriber, user} = this.userService;

    // Obtain user. Get checker for whether this user view test projects.
    if (!user) throw new Error(`${MODULE_NAME}#ngOnInit: User not set`);

    const isUnviewableTestProject = isUnviewableTestProjectForUser(user);
    const isUnviewableTestTemplate = isUnviewableTestTemplateForUser(user);

    this.summaries = this.databaseService.getSummariesListBySubscriber(subscriber);

    // Get a list of templates in case user wants to create a new template-based project, omitting test templates.
    this.templatesList$ = this.databaseService
      .getTemplateListBySubscriber(subscriber)
      .pipe(map(templates => templates.filter(not(isUnviewableTestTemplate))));

    this.templateObject$ = this.databaseService.getTemplateObject();

    // Make list of options for dropdown to select template to create new project.
    this.templateOptions$ = this.templatesList$.pipe(
      map(templates => [
        {label: "Select template", value: null},
        ...templates.sort(byName).map(template => ({label: template.name, value: template})),
      ])
    );

    this.canEdit = this.userService.can(Capability.edit);
    this.canViewProjectInfo = this.userService.can(Capability.viewProjectInfo);

    // Make list of options for dropdown to select project to clone.
    // Omit test projects.
    this.projectSourceOptions$ = this.summaries.pipe(
      map(summaries => summaries.filter(not(isUnviewableTestProject))),
      map(summaries => [
        {label: "Select project", value: null},
        ...summaries.sort(byName).map(summary => ({label: summary.name, value: summary})),
      ])
    );

    // Created filtered list of summaries to be vieweed.
    // For customers and project managers, this includes only projects to which they have access.
    this.filteredSummaries$ = this.summaries.pipe(
      tap(() => (this.emptyMessage = "No projects found")),
      map((summaries: Summary[]) => summaries.filter(not(isUnviewableTestProject))),
      map((summaries: AfValue<Summary>[]) =>
        summaries.filter(summary => isUserAssignedProject(user)(summary.$key))
      )
    );
  }

  public toggleHelp() {
    this.help = !this.help;
  }

  // Jump to a particular project info page or results page.
  // TODO: Simpify by moving thse into `routerLink` attributes in template.
  public gotoInfo(project) {
    this.router.navigate(["/project", project.$key]);
  }
  public gotoResults(project) {
    this.router.navigate(["/results", project.$key, "dashboard"]);
  }

  // Return home.
  public gotoHome() {
    this.router.navigate(["/home"]);
  }

  // The user has clicked on a row on the projects list.
  // Customers go directly to results, others to info page.
  public onRowSelect(event) {
    if (this.userService.can(Capability.viewProjectInfo)) this.gotoInfo(event.data);
    else this.gotoResults(event.data);
  }

  public update(project) {
    this.summaries.update(project.$key, {name: project.name});
  }

  // Create a new project.
  // Push it to the summaries branch, then create the project itself.
  // TODO: this should be in a service.
  public create() {
    const {uid: createdBy, subscriber} = this.userService;

    const summary = {
      name: this.newProjectName,
      status: "not ready",
      type: "",
      address: null,
      customer: "",
      createdOn: firebase.database.ServerValue.TIMESTAMP,
      createdBy: createdBy,
      subscriber: subscriber,
      noDesnagging: false,
    };

    this.addToSummaries(<Summary>summary);
  }

  private addToSummaries(summary: Summary) {
    this.summaries.push(summary).then(({key}) => this.makeProject(key));
  }

  // Remove a project after confirmation.
  public remove(project) {
    this.confirmRemoveService
      .confirm(
        "Really remove project?",
        `Are you sure you want to remove project &ldquo;${project.name}&rdquo;?
<b>All project data will be permanently lost!</b>`,
        this.viewContainerRef
      )
      .subscribe(res => {
        if (res) this._remove(project);
      });
  }

  // Clone the project.
  public clone() {
    try {
      this._clone(this.clonedProjectName, this.clonedProjectSource);
    } catch (e) {
      this.setClonedProjectError(e);
    }

    this.clonedProjectName = this.clonedProjectSource = null;
  }

  // Set the error message for creating a new project,
  // and leave it up for a while.
  private setClonedProjectError(e) {
    this.clonedProjectError = e;
    setTimeout(() => (this.clonedProjectError = ""), 5000);
  }

  // Actually remove the project.
  private _remove(project) {
    const projectId = project.$key;

    this.db.object(`projects/${projectId}`).remove();
    this.summaries.remove(projectId);
  }

  // Clone a project from another one.
  // Name is in `this.cloneProjectName`; summary to copy is in `this.clonedProjectSource`.
  // TODO: Move project cloning into a service.
  private async _clone(name: string, source: AfValue<Summary>) {
    const {$key = undefined} = source;

    console.assert(!!$key, `${MODULE_NAME}#_clone: source must be set and have $key`);
    console.assert(!!name, `${MODULE_NAME}#_clone: name must be set`);

    // Get project to clone.
    const projectRef = this.firebaseApp.database().ref(`projects/${$key}`);
    const {subscriber} = this.userService;

    // Create a new project, with new name, and get the key.
    const newSummarySnap: DatabaseSnapshot = (await this.summaries.push({
      name: this.clonedProjectName,
      createdOn: firebase.database.ServerValue.TIMESTAMP,
      createdBy: this.userService.uid,
      status: "not ready",
      subscriber,

      // For leagacy reasos, and because of how this property was named,
      // we need to explicitly initialize it to false, which is not the case
      // for other properties like `noDesnagging`.
      noFixing: false,
    } as Summary)) as any; // Wrid problem with conflicting types between firebase and Angularfire.

    const newSummaryKey: string = newSummarySnap.key;

    // Read all the fields of the project to be copied, except results and units.
    const properties: Array<keyof Project> = [
      "aspects",
      "descriptions",
      "rooms",
      "masterRooms",
      "layouts",
      "items",
      "sections",
      "readings",
      "descriptions",
      "terms",
    ];
    const values: Partial<Project> = await allObject(
      mapFromArray(properties, property => once(projectRef.child(property)))
    );

    values.terms = values.terms || legacyTerms;

    // Create the new project, with the same key as the newly created summary.
    this.db.object(`projects/${newSummaryKey}`).update(values);
  }

  // Clone the master checklist (sections, items, aspects, descriptions, readings) into a project.
  private makeProject(id) {
    const template = this.newProjectTemplate;
    const {sections, items, aspects, readings, rooms: masterRooms, terms, descriptions} = template;
    const project: Partial<Project> = {template: template.$key};

    if (items) project.items = items;
    if (sections) project.sections = sections;
    if (aspects) project.aspects = aspects;
    if (descriptions) project.descriptions = descriptions;
    if (masterRooms) project.masterRooms = masterRooms;
    if (readings) project.readings = readings;

    project.terms = terms || legacyTerms;

    this.db.object(`projects/${id}`).update(project);
    /*this.db.object(`projects/${id}`).update({
      sections,
      items,
      aspects,
      descriptions,
      masterRooms,
      template: template.$key,
    } as Partial<Project>); */
  }
}
