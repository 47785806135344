// pods/dashboard/report-filters/report-filters.component.ts
//
// JS logic for component for report filters.

// ANGULAR
import {Component, OnInit, OnChanges, Input, Output, EventEmitter} from "@angular/core";

// COMMON
import {mapFromArray, pluralize, sentenceCase} from "@nims/jsutils";
import {Terms} from "@nims/red-shared";

import {
  ReportFilters,
  reviewedOptions,
  statusOptions,
  rectificationStatusOptions,
  fixStatusOptions,
  snagPriorityOptions,
} from "./report-filters.class";

@Component({
  selector: "report-filters",
  templateUrl: "./report-filters.component.html",
})
export class ReportFiltersComponent implements OnInit, OnChanges {
  @Input()
  filters: ReportFilters;
  @Input()
  scheme: Array<string>;
  @Input()
  terms: Terms;

  @Output()
  onChange = new EventEmitter<void>();

  public filterInfo;

  ngOnChanges() {
    this.filterInfo = this.makeFilterInfo();

    this.filterInfo.forEach(elt => (elt.value = Object.keys(this.filters[elt.prop] || {})));
  }

  ngOnInit() {}

  public update(elt) {
    this.filters[elt.prop] = mapFromArray(elt.value, () => true);
    this.onChange.emit();
  }

  public reset(elt) {
    elt.values = elt.default;
    this.update(elt);
  }

  public clear(elt) {
    elt.values = [];
    this.update(elt);
  }

  private makeFilterInfo() {
    return [
      {
        label: "Snagging result",
        prop: "statuses",
        type: "select",
        options: statusOptions,
        value: [],
        title: "Choose one or more snagging results to include in report",
      },

      {
        label: "Fixing result",
        prop: "fixStatuses",
        type: "select",
        options: fixStatusOptions,
        value: [],
        title: "Choose one or more fixing results to include in report",
      },

      {
        label: "De-snagging result",
        prop: "rectificationStatuses",
        type: "select",
        options: rectificationStatusOptions,
        value: [],
        title: "Choose one or more de-snagging results to include in report",
      },

      {
        label: "Snag priority",
        prop: "snagPriorities",
        type: "select",
        options: snagPriorityOptions,
        value: [],
        title: "Choose one or more snag priorities to include in report",
      },

      {
        label: "Review status",
        prop: "reviewed",
        type: "select",
        options: reviewedOptions,
        value: [],
        title: `Choose whether to include reviewed or unreviewed ${pluralize(
          this.terms.space
        )} or both in report`,
      },

      {
        label: `Tower/sector/${this.terms.space}`,
        prop: "locationSearchStrings",
        type: "chip",
        value: [],
        placeholder: "Tower A, Floor 2, 2341 etc.",
        title: `Enter names or parts of names of towers, floors or ${pluralize(
          this.terms.space
        )} to include in report. End each with Enter`,
      },

      {
        label: sentenceCase(this.terms.feature),
        prop: "roomSearchStrings",
        type: "chip",
        value: [],
        placeholder: "Bathroom, bedroom etc.",
        title: `Enter names or parts of names of ${this.terms.feature}s to include in report. End each with Enter`,
      },

      {
        label: sentenceCase(this.terms.checkpoint),
        prop: "itemSearchStrings",
        type: "chip",
        value: [],
        title: `Enter words to find ${this.terms.checkpoint} names. End each with Enter`,
        placeholder: "Gap, crack etc.",
      },

      {
        label: "Description",
        prop: "descriptionSearchStrings",
        type: "chip",
        value: [],
        title: "Enter words to find in snag descriptions. End each with Enter",
        placeholder: "Gap, crack etc.",
      },

      {
        label: sentenceCase(this.terms.activity),
        prop: "aspectSearchStrings",
        type: "chip",
        value: [],
        title: "Enter words to find in aspect names. End each with Enter",
        placeholder: "electrical, plumbing, etc.",
      },
    ];
  }
}
