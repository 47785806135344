// item-results.component.ts
//
// TS logic for component to display the visual report results for a single item,
// including the artifacts for each mode.

import {Component, OnInit, Input} from "@angular/core";

import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

import {ItemArtifacts} from "@nims/red-shared";

import {
  AfValue,
  ItemResults,
  Mode,
  RoomResults,
  Summary,
  Terms,
  UnitResults,
  allModes,
} from "@nims/red-shared";

// Down in the component that displays artifacts for a specific mode,
// we may want to save a picture or something, which requires additional
// contextual information. Capture that in this interface and pass down.
export interface ItemArtifactsMetadata {
  projectId: string;
  unitId: string;
  roomId: string;
  itemId: string;
  snagIndex: number;
  roomName: string;
}

const values = (o: object) => Object.keys(o).map(k => o[k]);
const hasPhoto = (artifacts: ItemArtifacts) => !!artifacts.photo;

@Component({
  selector: "item-results",
  templateUrl: "./item-results.component.html",
  styleUrls: ["./item-results.component.css"],
})
export class ItemResultsComponent implements OnInit {
  @Input()
  public summary: AfValue<Summary>;
  @Input()
  public unit: AfValue<UnitResults>;
  @Input()
  public room: RoomResults;
  @Input()
  public item: ItemResults & {snagIndex: number};
  @Input()
  public itemRef: DatabaseReference;
  @Input()
  public mode: Mode;
  @Input()
  public canEdit;
  @Input()
  public terms: Terms;
  @Input()
  public noSnagPriorities: boolean;

  public itemArtifactsMetadata: ItemArtifactsMetadata;
  public allModes = allModes;

  // Suppress photos in all modes if there are none to show.
  public noPhotos = false;

  ngOnInit() {
    this.itemArtifactsMetadata = {
      projectId: this.summary.$key,
      unitId: this.unit.$key,
      roomId: (<any>this.item).roomId,
      itemId: (<any>this.item).itemId,
      roomName: (<any>this.item).roomName,
      snagIndex: this.item.snagIndex,
    };

    // See if there are any photos in any of the "modes".
    // If so, put a class which suppresses the photo display.
    this.noPhotos = !values(this.item.itemArtifactSet).some(hasPhoto);
  }

  // Something has changed in the artifacts for some mode.
  // Update the item in the database.
  // Note: we cannot update just the `itemArtifacts`, because the `itemArtifactSet`
  // might not be present in the DB, if this is legacy data.
  // Hence update the entire `itemArtifactSet`.
  public onChangeItemArtifacts(mode: Mode) {
    return this.itemRef.child("itemArtifactSet").update(this.item.itemArtifactSet);
  }
}
