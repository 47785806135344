// terms/terms.component.ts
//
// Display template or project terms.

import {Subscription} from "rxjs";

import {Component, OnInit, OnDestroy, Input} from "@angular/core";
import {FirebaseObjectObservable} from "angularfire2/database-deprecated";
import {Terms, legacyTerms, genericTerms, termDescriptions} from "@nims/red-shared";
import {ChecklistService} from "../../services/checklist.service";

@Component({
  selector: "terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.css"],
})
export class TermsComponent implements OnInit, OnDestroy {
  @Input()
  public terms$: FirebaseObjectObservable<Terms>;

  @Input()
  editable: boolean;

  public help = false;
  public terms: Terms;
  public fields: string[];

  public descriptions: Terms;
  public legacyTerms = legacyTerms;
  public genericTerms = genericTerms;

  private subscription: Subscription;

  constructor(private readonly checklistService: ChecklistService) {}

  ngOnInit() {
    this.subscription = this.terms$.subscribe(terms => {
      this.terms = terms;
      this.fields = Object.keys(terms).sort();
      this.descriptions = termDescriptions(terms);
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  // Update an aspect property.
  public async update() {
    try {
      await this._update();
      this.success("Successfully updated aspect");
    } catch (e) {
      this.error("Failed to update aspect", e);
    }
  }

  public onSetLegacy() {
    this.terms = legacyTerms;
    this.update();
  }

  public onSetGeneric() {
    this.terms = genericTerms;
    this.update();
  }

  private _update() {
    const {terms} = this;

    // Normalize the terms. Exit if blank.
    for (const term in terms) {
      if (!terms[term]) return;
      terms[term] = terms[term].toLowerCase();
    }

    return this.checklistService.updateTerms(this.terms$.$ref.parent, this.terms);
  }

  private error(msg: string, err: Error) {
    console.error(msg, err);
  }

  private success(msg: string) {
    console.log(msg);
  }
}
