import {Component, OnInit, OnDestroy} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Title} from "@angular/platform-browser";

import {Observable, BehaviorSubject} from "rxjs";
import {map, switchMap, tap} from "rxjs/operators";

import {
  AngularFireDatabase,
  FirebaseObjectObservable,
  FirebaseListObservable,
} from "angularfire2/database-deprecated";
import {FirebaseApp} from "angularfire2";

import {
  Summary,
  Unit,
  unitStatuses,
  Aspect,
  AfValue,
  Capability,
  Terms,
  legacyTerms,
} from "@nims/red-shared";

import {DatabaseService, UserService} from "../../../services/";

const LOG = false;
const MODULE_NAME = "console/project-results.component";

@Component({
  selector: "app-project-results",
  templateUrl: "./project-results.component.html",
  providers: [Title],
})
export class ProjectResultsComponent implements OnInit, OnDestroy {
  public name$: Observable<string>;
  public units$: Observable<Unit[]>;
  public aspects$: FirebaseListObservable<AfValue<Aspect>[]>;
  public selectedUnit;
  public projectRef;
  public summary;
  public help = false;
  public emptyMessage = "Loading...";
  public layouts = {};
  public layoutOptions;
  public summary$: FirebaseObjectObservable<Summary>;
  public canEdit;
  public subscriber$;
  public terms$: FirebaseObjectObservable<Terms>;

  // Dashboard items for the selected unit, to pass to `unit-results`.
  public unitItems;

  // Create statuses for dropdown to filter units by status.
  public statuses = unitStatuses
    .filter(status => status !== "not ready")
    .map(status => ({label: status, value: status}));

  public resultsRef;
  public showAllUnits = false;
  public reportDefinitions$;
  public items;

  private id: string;
  private summarySubscription;
  private showAllUnits$ = new BehaviorSubject<boolean>(false);

  public selectedItem;
  public unitName;

  public allItems;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly db: AngularFireDatabase,
    private readonly title: Title,
    private readonly userService: UserService,
    private readonly databaseService: DatabaseService,
    private readonly firebaseApp: FirebaseApp
  ) {}

  ngOnInit() {
    const id = (this.id = this.route.parent.snapshot.params["id"]);
    
    const firebaseDatabase = this.firebaseApp.database();

    const projectRef = (this.projectRef = firebaseDatabase.ref(`projects/${id}`));
    this.resultsRef = this.projectRef.child("results");
    const aspectsRef = this.projectRef.child("aspects");
    const summaryRef = firebaseDatabase.ref(`summaries/${id}`);    

    this.summary$ = this.db.object(summaryRef);
    this.summarySubscription = this.summary$.subscribe(summary => (this.summary = summary));
    this.name$ = this.summary$.pipe(map(data => data.name));  

    this.canEdit = this.userService.can(Capability.edit);
    if (LOG) console.log(`${MODULE_NAME}#ngOnInit: canEdit is`, this.canEdit);

    this.subscriber$ = this.userService.subscriber$;

    this.aspects$ = this.db.list(aspectsRef);

    // Retrieve terms. Fill in if missing.
    const terms$ = (this.terms$ = this.db.object(projectRef.child("terms")));
    terms$.$ref.once("value").then(snapshot => {
      if (!snapshot.exists()) terms$.update(legacyTerms);
    });

    this.units$ = this.db.object(projectRef.child("layouts")).pipe(
      switchMap(layouts => {
        return this.db
          .list(projectRef.child("units"), {
            query: {
              orderByChild: "touchedOn",
              limitToLast: this.showAllUnits$.pipe(map(b => (b ? 9999 : 5))),
            } as any,
          })
          .pipe(
            tap(() => (this.emptyMessage = "No units found")),
            map(units =>
              units
                .filter(unit => unit.status !== "not ready")
                .map(unit =>
                  Object.assign({}, unit, {$key: unit.$key, layoutName: layouts[unit.layout].name})
                )
            )
          );
      })
    );

    // Get list of reports to include in visual reports for units in this project.
    const reportDefinitions$ = this.databaseService.getSummaryReportDefinitionsList(id);
    this.reportDefinitions$ = reportDefinitions$.pipe(
      map(definitions =>
        definitions.map(definition => {
          return this.databaseService.getReportDefinitionObject(definition.$key);
        })
      )
    );

    (async () => {
      const data = await this.databaseService.getDashboardData(id);
      this.items = data.dashboard;
    })();
  }

  ngOnDestroy() {
    if (this.summarySubscription) this.summarySubscription.unsubscribe();
    this.title.setTitle("NIMS Console");
  }

  public changeShowAllUnits() {
    this.showAllUnits$.next(this.showAllUnits);
  }

  // When the user selects a unit, set the page title,
  // since this will be used as the default PDF file name when printing.
  // Unset this when leaving component.
  public onRowSelect(rowdata) {
    const METHOD_NAME = "project-results.component#onRowSelect";

    console.assert(!!this.summary, `this.summary must be present in ${METHOD_NAME}`);
    console.assert(!!this.selectedUnit, `this.selectedUnit must be set in ${METHOD_NAME}`);

    this.title.setTitle(`${this.summary.name}, ${this.selectedUnit.name}`);

    // Remember that this unit was selected, so it displays at the top next time.
    this.projectRef
      .child("units")
      .child(this.selectedUnit.$key)
      .child("touchedOn")
      .set(+new Date());

    this.unitItems = this.items.filter(item => item.unitId === this.selectedUnit.$key);
    this.allItems = this.items.filter(item => {      
    })
    this.selectedItem = this.selectedUnit.$key;
    this.unitName = rowdata.name;
    // console.log(this.unitItems);
  }

  // Trackby function for rows of table (units).
  // This is needed since the rows will jump around as their `touchedOn` property changes
  public trackById(index: number, unit: AfValue<Unit>) {
    return unit.$key;
  }
}
