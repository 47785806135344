import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nemmadi-logo',
  templateUrl: './nemmadi-logo.component.html',
  styleUrls: ['./nemmadi-logo.component.css']
})
export class NemmadiLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
