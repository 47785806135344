// home.component.ts
//
// Home page component.

import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";

import {Observable, combineLatest} from "rxjs";
import {map, pluck} from "rxjs/operators";

import {
  Summary,
  AfValue,
  Capability,
  isSubscriberExpired,
  SubscribersService,
} from "@nims/red-shared";

import {UserService, DatabaseService} from "../../services/";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  public firstName;
  public user;

  public canManageTemplates;
  public canManageAccounts;
  public canManageSubscribers;
  public canMasqueradeAsSubscriber;
  public canViewProjects;
  public canViewSelectedProjects;
  public canViewMultiProjectDashboard;

  public filteredSummaries$;
  public subscriberId$: Observable<string>;

  public subscriberOptions$;

  public subscriberName$: Observable<string>;
  public expired$: Observable<boolean>;

  constructor(
    private router: Router,
    private userService: UserService,
    private databaseService: DatabaseService,
    private subscribersService: SubscribersService
  ) {}

  ngOnInit() {
    const userService = this.userService;
    const user = (this.user = userService.user);

    const userProjects$ = this.databaseService
      .getUserObject(this.userService.uid)
      .pipe(pluck("projects"));
    const summaries$ = this.databaseService.getSummariesList();

    this.firstName = user.name.split(" ", 1)[0];

    this.canManageTemplates = userService.can(Capability.manageTemplates);
    this.canManageAccounts = userService.can(Capability.manageAccounts);
    this.canManageSubscribers = userService.can(Capability.manageSubscribers);
    this.canMasqueradeAsSubscriber = userService.can(Capability.masqueradeAsSubscriber);
    this.canViewProjects = userService.can(Capability.viewProjects);
    this.canViewSelectedProjects = userService.can(Capability.viewSelectedProjects);
    this.canViewMultiProjectDashboard = userService.can(Capability.viewMultiProjectDashboard);

    this.subscriberName$ = userService.subscriber$.pipe(pluck("name"));
    this.expired$ = userService.subscriber$.pipe(map(isSubscriberExpired));

    this.subscriberId$ = userService.subscriberId$;

    this.filteredSummaries$ = combineLatest(userProjects$, summaries$).pipe(
      map(([userProjects, summaries]) =>
        summaries.filter((summary: AfValue<Summary>) => userProjects && userProjects[summary.$key])
      )
    );

    // If user can change his subscriber (eg superuser), create a dropdown for them to select from.
    // TODO: make this type-safe.
    if (this.canMasqueradeAsSubscriber) {
      this.subscriberOptions$ = this.subscribersService.get$().pipe(
        map(subscribers =>
          subscribers
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(subscriber => !subscriber.disabled)
            .map(subscriber => ({label: subscriber.name, value: subscriber.$key}))
        )
      );
    }
  }

  public logout() {
    this.userService.logout().then(() => this.gotoLogin());
  }

  public changeSubscriber(id) {
    return this.userService.setSubscriberId(id);
  }

  private gotoLogin() {
    return this.router.navigate(["/login"]);
  }
}
