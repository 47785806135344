// dashboard/report-dimension/report-dimension.class.ts
//
// Define the dashboard dimension (primary and secondary).

import {SelectItem} from "primeng/primeng";

import {day, week, month, sentenceCase} from "@nims/jsutils";
import {Terms} from "@nims/red-shared";

import {ReportDimension} from "./report-dimension.interface";

export {ReportDimension};

// Get the label in in a set of options for a particular value.
function findOptionLabel(options: SelectItem[], value: string) {
  const entry = options.find(option => option.value === value);

  if (!entry) throw new Error(`Cannot find '${value}' in list of options`);
  return entry.label;
}

export function getDefault(): ReportDimension {
  return {
    field: "tower",
    sortField: "name",
    sortAscending: true,
    dateTransform: "day",
    position: "left",
  };
}

export function fieldOptions(terms: Terms): SelectItem[] {
  return [
    {label: "Tower", value: "tower"},
    {label: "Project", value: "projectName"},
    {label: "Sector", value: "sector"},
    {label: sentenceCase(terms.space), value: "unitName"},
    {label: sentenceCase(terms.feature), value: "roomName"},
    {label: sentenceCase(terms.activity), value: "aspect"},
    {label: "Date snagged", value: "snaggedOn"},
    {label: "Date fixed", value: "fixedOn"},
    {label: "Date de-snagged", value: "desnaggedOn"},
  ];
}

export const fieldIsDate = {
  snaggedOn: true,
  fixedOn: true,
  desnaggedOn: true,
};

const dateTransforms = {day, week, month};

export const sortFieldOptions: SelectItem[] = [
  {label: "name", value: "name"},
  {label: "snag count", value: "snagCount"},
];

export const sortAscendingOptions: SelectItem[] = [
  {label: "ascending", value: true},
  {label: "descending", value: false},
];

export const dateTransformOptions: SelectItem[] = [
  {label: "day", value: "day"},
  {label: "week", value: "week"},
  {label: "month", value: "month"},
];

export const positionOptions: SelectItem[] = [
  {label: "left", value: "left"},
  {label: "top", value: "top"},
];

// Convert the dimension info to a human-friendly string representation.
export function toString(dimension, terms: Terms) {
  const {field, sortAscending, sortField, dateTransform = "day"} = dimension;
  const deflt = getDefault();

  let fieldLabel = findOptionLabel(fieldOptions(terms), field).toLowerCase();
  const sortAscendingLabel =
    sortAscending !== deflt.sortAscending
      ? findOptionLabel(sortAscendingOptions, sortAscending)
      : "";
  const sortFieldLabel =
    sortField !== deflt.sortField ? findOptionLabel(sortFieldOptions, sortField) : "";
  const dateTransformLabel = findOptionLabel(dateTransformOptions, dateTransform);

  // If grouping by date, changed "Date snagged" to "week snagged" etc.
  if (fieldIsDate[field]) fieldLabel = fieldLabel.replace("date", dateTransformLabel);

  let result = fieldLabel;
  // Currently, not showing sort order in description.
  if (sortAscendingLabel || sortFieldLabel) result += `, sorted by ${sortFieldLabel}`;

  return result;
}

// Create a function to get the field specified by this dimension from an item, applying a date transform if applicable,
// and mapping aspect.
export function fieldGetter(dimension) {
  const {field, dateTransform = "day"} = dimension;

  return function(item) {
    let value = item[field];

    if (fieldIsDate[field]) {
      const transform = dateTransforms[dateTransform];
      console.assert(
        transform,
        `In report-dimension.class, transform must be defined for ${dateTransform}`
      );

      value = dateTransforms[dateTransform](value);
    }

    // Special case missing aspect.
    if (field === "aspect") value = value || "other";

    return value;
  };
}
