// pods/aspect-results.component.ts
//
// JS logic for component to display results for one aspect.
// Invoked from `units-results` component.

import {Component, Input} from "@angular/core";

import {ItemResults, Subscriber, Summary, Terms, UnitResults} from "@nims/red-shared";

@Component({
  selector: "aspect-results",
  templateUrl: "./aspect-results.component.html",
  styleUrls: ["./aspect-results.component.css"],
})
export class AspectResultsComponent {
  @Input() public summary: Summary;
  @Input("unit") public unitResults: UnitResults;
  @Input() public aspect;
  @Input() public subscriber: Subscriber;

  @Input() public reviewedByName: string;
  @Input() public inspectedBy;
  @Input() public inspectedOn;

  @Input() public canEdit;

  @Input() public allSnaggedBy;
  @Input() public allSnaggedOn;
  @Input() public snaggingReviewedBy;

  @Input() public allFixedBy;
  @Input() public allFixedOn;
  @Input() public fixReviewedBy;

  @Input() public allDesnaggedBy;
  @Input() public allDesnaggedOn;
  @Input() public desnaggingReviewedBy;

  @Input() public noSnagPriorities: boolean;

  @Input() public terms: Terms;

  public unitRef;
  public roomsRef;
  public rooms$;
  public itemCols: ItemResults[][];
  public aspectTitle: string;

  public itemTrackByFn(index, {itemId}: {itemId: string}) {
    return itemId;
  }
}
