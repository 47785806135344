// pods/dashboard/dashboard.component.ts
//
// JS logic for dashboard component.

// ANGULAR
import { Component, OnInit, Input, OnChanges } from "@angular/core";

import { Subject } from "rxjs";

// COMMON
import { Capability, Terms, legacyTerms, Summary } from "@nims/red-shared";

// OUR APP
import { ReportDefinition, getDefault } from "./report-definition/report-definition.class";
import { DatabaseService } from "../../services/database/database.service";
import { UserService } from "../../services/user/user.service";
import axios from "axios";
import { MatDialog } from "@angular/material/dialog";
import { TemplateRef } from '@angular/core';
import * as firebase from "firebase";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Chart } from "chart.js";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit, OnChanges {
  @Input()
  public projectIds: string[];
  @Input()
  public terms: Terms = legacyTerms;
  @Input()
  summary: Summary;

  public definition: ReportDefinition = getDefault();
  public definition$ = new Subject<ReportDefinition>();

  // Observable of all items, taken from precalculated data.
  public items = [];

  // Observable of items filtered only to contain the relevant ones.
  // This is what drives the `dashboard` component.
  public filteredItems;
  public filteredCount;
  public filtering = false;

  public help = false;
  public editable = false;
  public canEdit;
  public emailsent = false;
  public custmB2BEnable = false;
  public isReportSent = false;
  public projectId;
  public showSummary = false;
  public checkPointAvl = false;
  public snagsAvl = false;
  public fixedSnags = false;
  public prDesnags = false;
  public nrDesnags = false;
  public snagsByTowers = false;
  public totalCheckPointsKeys = [];
  public totalCheckPointsValues = [];
  public totalSnagsKeys = [];
  public totalSnagsValues = [];
  public totalSnagsFixedKeys = [];
  public totalSnagsFixedValues = [];

  public wingAItemKeys = [];
  public wingAItemValues = [];
  public wingBItemKeys = [];
  public wingBItemValues = [];
  public wingCItemKeys = [];
  public wingCItemValues = [];
  public wingASnagsKeys = [];
  public wingASnagsValues = [];
  public wingBSnagsKeys = [];
  public wingBSnagsValues = [];
  public wingCSnagsKeys = [];
  public wingCSnagsValues = [];
  public wingADeSnagsKeys = [];
  public wingADeSnagsValues = [];
  public wingBDeSnagsKeys = [];
  public wingBDeSnagsValues = [];
  public wingCDeSnagsKeys = [];
  public wingCDeSnagsValues = [];
  public seriesDItemKeys = [];
  public seriesDItemValues = [];
  public seriesDSnagsKeys = [];
  public seriesDSnagsValues = [];
  public seriesDNRDeSnaggedArr = [];

  public seriesDPRDeSnagsKeys = [];
  public seriesDPRDeSnagsValues = [];
  public seriesDRDeSnagsKeys = [];
  public seriesDRDeSnagsValues = [];
  public seriesDNRDeSnagsKeys = [];
  public seriesDNRDeSnagsValues = [];

  public wingAPRDeSnagsKeys = [];
  public wingAPRDeSnagsValues = [];
  public wingARDeSnagsKeys = [];
  public wingARDeSnagsValues = [];
  public wingANRDeSnagsKeys = [];
  public wingANRDeSnagsValues = [];

  public wingBPRDeSnagsKeys = [];
  public wingBPRDeSnagsValues = [];
  public wingBRDeSnagsKeys = [];
  public wingBRDeSnagsValues = [];
  public wingBNRDeSnagsKeys = [];
  public wingBNRDeSnagsValues = [];

  public wingCPRDeSnagsKeys = [];
  public wingCPRDeSnagsValues = [];
  public wingCRDeSnagsKeys = [];
  public wingCRDeSnagsValues = [];
  public wingCNRDeSnagsKeys = [];
  public wingCNRDeSnagsValues = [];

  public wingSeries;

  public checkPointsKeys = [];
  public checkPointsValues = [];
  public snagKeys = [];
  public snagValues = [];
  public checkPointsAKeys = [];
  public checkPointsAValues = [];
  public checkPointsBKeys = [];
  public checkPointsBValues = [];
  public checkPointsCKeys = [];
  public checkPointsDKeys = [];
  public checkPointsCValues = [];
  public snagAKeys = [];
  public snagAValues = [];
  public snagBKeys = [];
  public snagBValues = [];
  public snagCKeys = [];
  public snagCValues = [];
  public snagDKeys = [];
  public snagDValues = [];

  public wingAFixedKeys = [];
  public wingAFixedValues = [];
  public wingBFixedKeys = [];
  public wingBFixedValues = [];
  public wingCFixedKeys = [];
  public wingCFixedValues = [];

  public wingANASKeys = [];
  public wingANASValues = [];
  public wingBNASKeys = [];
  public wingBNASValues = [];
  public wingCNASKeys = [];
  public wingCNASValues = [];

  public wingACFKeys = [];
  public wingACFValues = [];
  public wingBCFKeys = [];
  public wingBCFValues = [];
  public wingCCFKeys = [];
  public wingCCFValues = [];
  public checkpointsCount;
  public snagsCount;
  public wingACheckPointsCount;
  public wingBCheckPointsCount;
  public wingCCheckPointsCount;
  public wingASnagsCount;
  public wingBSnagsCount;
  public wingCSnagsCount;

  public itemSnagsKeys = [];
  public itemSnagsValues = [];
  public itemDesnagsKeys = [];
  public itemDesnagsValues = [];
  public itemFixingKeys = [];
  public itemFixingValues = [];

  constructor(private databaseService: DatabaseService, private userService: UserService, private readonly matDialog: MatDialog, private readonly route: ActivatedRoute, private readonly matSnackBar: MatSnackBar) { }

  ngOnInit() {
    this.projectId = this.route.parent.snapshot.params["id"];
    let reportSent = this.summary.isReportSent;
    if (reportSent == true) {
      this.isReportSent = false;
    } else if (reportSent == false || reportSent == undefined) {
      this.isReportSent = true;
    }
    // A point to note : When we access multi project dashboard directly with url .../multi-project-dashboard canedit remains undefined
    this.canEdit = this.userService.can(Capability.edit);
    this.enableSendReportforB2B();

    if (this.projectId === '-NJxxl3N5Ru_boSmjPLp') {
      this.wingSeries = true;
    }
  }

  allowMeSendReport() {
    firebase.database().ref('summaries/' + this.projectId).update({
      isReportSent: false
    });
  }

  ngOnChanges() {
    this.getDataForAllIds();
  }

  private getDataForAllIds() {
    this.items = [];
    this.projectIds.forEach(async id => {
      const data = await this.databaseService.getDashboardData(id);
      this.items = this.items.concat(data.dashboard);
      this.update();
    });

  }

  private snackBar(msg: string) {
    this.matSnackBar.open(msg, null, { duration: 3000 });
  }

  // Update the `filteredItems` property which contains only relevant items.
  // Invoked whenever filters change.
  public update() {
    this.definition$.next({ ...this.definition });
  }

  // Install a new definition.
  public new(definition: ReportDefinition) {
    this.definition = definition;
    this.update();
  }

  // function for sending the summary report for B2B onclick  
  public sendEmailBuilder() {

    const allItems = this.items;

    let finalResltArr = [];

    let itemsArr = [];
    allItems.forEach(function (item) {
      const apectkey = item.hasOwnProperty('aspect');
      if (apectkey) {
        itemsArr.push(item);
      }
    });

    let snaggeditemsArr = [];
    itemsArr.forEach(function (item) {
      if (typeof item.snaggingStatus !== 'undefined') {
        snaggeditemsArr.push(item);
      }
    });


    let projectName = this.summary.name;
    let BuilderName = this.summary.customer;
    let customerEmail = this.summary.email;
    let BuilderAddress = this.summary.address;

    const { user } = this.userService;
    let loggedInUser = user.name;
    let loggedInUserEmail = user.email;

    const inspectedOndate = new Date((snaggeditemsArr[0].snaggedOn)).toLocaleDateString("es-CL");

    let objectKeysToLowerCase = function (origObj) {
      return Object.keys(origObj).reduce(function (newObj, key) {
        let val = origObj[key];
        let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
        newObj[key.toLowerCase()] = newVal;
        return newObj;
      }, {});
    }

    //Extract Summary Chart data Aspect Wise
    const getItemGroupByAspect = snaggeditemsArr.reduce((getItemGroupByAspect, item) => {
      const aspect = (getItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getItemGroupByAspect[item.aspect] = aspect;
      return getItemGroupByAspect;
    }, {});

    var checpointsByAspectCount = {};
    let totalCheckPoints = 0;
    Object.keys(getItemGroupByAspect).forEach(function (key) {
      totalCheckPoints += getItemGroupByAspect[key].length;
      Object.assign(checpointsByAspectCount, { total: totalCheckPoints });
      checpointsByAspectCount[key] = getItemGroupByAspect[key].length;
    });

    const byaspectcheckpointsFinalObj = Object.keys(checpointsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = checpointsByAspectCount[key];
        return obj;
      },
      {}
    );

    //get items array which are snagged
    let byaspectsnaggeditemArr = [];

    Object.keys(getItemGroupByAspect).forEach(function (key) {
      const itemAspectName = getItemGroupByAspect[key];

      let searchbytext = "not OK";

      itemAspectName.filter(function (obj) {
        let serrchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            byaspectsnaggeditemArr.push(obj);
          }
        }
      });
    });


    const getSnaggedItemGroupByAspect = byaspectsnaggeditemArr.reduce((getSnaggedItemGroupByAspect, item) => {
      const aspect = (getSnaggedItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getSnaggedItemGroupByAspect[item.aspect] = aspect;
      return getSnaggedItemGroupByAspect;
    }, {});

    var snagsByAspectCount = {};
    let totalSnags = 0;
    Object.keys(getSnaggedItemGroupByAspect).forEach(function (key) {
      totalSnags += getSnaggedItemGroupByAspect[key].length;
      Object.assign(snagsByAspectCount, { total: totalSnags });
      snagsByAspectCount[key] = getSnaggedItemGroupByAspect[key].length;
    });

    const byaspectsnagsFinalObj = Object.keys(snagsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = snagsByAspectCount[key];
        return obj;
      },
      {}
    );

    //get items array which are rectified de-snagged
    let byaspectrcdesnaggeditemArr = [];
    Object.keys(getItemGroupByAspect).forEach(function (key) {
      const itemAspectName = getItemGroupByAspect[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'rectified') {
            //add this object to the filtered array
            byaspectrcdesnaggeditemArr.push(obj);
          }
        }
      });
    });

    const getRcDeSnaggedItemGroupByAspect = byaspectrcdesnaggeditemArr.reduce((getRcDeSnaggedItemGroupByAspect, item) => {
      const aspect = (getRcDeSnaggedItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getRcDeSnaggedItemGroupByAspect[item.aspect] = aspect;
      return getRcDeSnaggedItemGroupByAspect;
    }, {});

    var rcdesnagsByAspectCount = {};
    let totalDeSnags = 0;
    Object.keys(getRcDeSnaggedItemGroupByAspect).forEach(function (key) {
      totalDeSnags += getRcDeSnaggedItemGroupByAspect[key].length;
      Object.assign(rcdesnagsByAspectCount, { total: totalDeSnags });
      rcdesnagsByAspectCount[key] = getRcDeSnaggedItemGroupByAspect[key].length;
    });

    const byaspectrcdesnagsFinalObj = Object.keys(rcdesnagsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = rcdesnagsByAspectCount[key];
        return obj;
      },
      {}
    );

    //get items array which are partially rectified de-snagged
    let byaspectprdesnaggeditemArr = [];
    Object.keys(getItemGroupByAspect).forEach(function (key) {
      const itemAspectName = getItemGroupByAspect[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'partially rectified') {
            //add this object to the filtered array
            byaspectprdesnaggeditemArr.push(obj);
          }
        }
      });
    });


    const getPrDeSnaggedItemGroupByAspect = byaspectprdesnaggeditemArr.reduce((getPrDeSnaggedItemGroupByAspect, item) => {
      const aspect = (getPrDeSnaggedItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getPrDeSnaggedItemGroupByAspect[item.aspect] = aspect;
      return getPrDeSnaggedItemGroupByAspect;
    }, {});

    var prdesnagsByAspectCount = {};
    Object.keys(getPrDeSnaggedItemGroupByAspect).forEach(function (key) {
      prdesnagsByAspectCount[key] = getPrDeSnaggedItemGroupByAspect[key].length;
    });

    const byaspectprdesnagsFinalObj = Object.keys(prdesnagsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = prdesnagsByAspectCount[key];
        return obj;
      },
      {}
    );

    //get items array which are not rectified de-snagged
    let byaspectnrdesnaggeditemArr = [];
    Object.keys(getItemGroupByAspect).forEach(function (key) {
      const itemAspectName = getItemGroupByAspect[key];

      //let searchbytext = "rectified";

      itemAspectName.filter(function (obj) {
        // let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'not rectified') {
            //add this object to the filtered array
            byaspectnrdesnaggeditemArr.push(obj);
          }
        }
      });
    });

    const getNrDeSnaggedItemGroupByAspect = byaspectnrdesnaggeditemArr.reduce((getNrDeSnaggedItemGroupByAspect, item) => {
      const aspect = (getNrDeSnaggedItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getNrDeSnaggedItemGroupByAspect[item.aspect] = aspect;
      return getNrDeSnaggedItemGroupByAspect;
    }, {});

    var nrdesnagsByAspectCount = {};
    Object.keys(getNrDeSnaggedItemGroupByAspect).forEach(function (key) {
      nrdesnagsByAspectCount[key] = getNrDeSnaggedItemGroupByAspect[key].length;
    });

    const byaspectnrdesnagsFinalObj = Object.keys(nrdesnagsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = nrdesnagsByAspectCount[key];
        return obj;
      },
      {}
    );

    //End Extract Summary Chart data Aspect Wise

    //Total Checkpoints
    //get items by each tower
    const getItemGroupByTower = snaggeditemsArr.reduce((getItemGroupByTower, item) => {
      const tower = (getItemGroupByTower[item.tower] || []);
      tower.push(item);
      getItemGroupByTower[item.tower] = tower;
      return getItemGroupByTower;
    }, {});

    const orderedgetItemGroupByTower = Object.keys(getItemGroupByTower).sort().reduce(
      (obj, key) => {
        obj[key] = getItemGroupByTower[key];
        return obj;
      },
      {}
    );


    //get total checkpoints in each tower by each aspect
    var checkpointsByAspectTower = {};
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {

      const gettotalItemGroupByAspect = orderedgetItemGroupByTower[key].reduce((gettotalItemGroupByAspect, item) => {

        const aspect = (gettotalItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gettotalItemGroupByAspect[item.aspect] = aspect;
        return gettotalItemGroupByAspect;
      }, {});

      var itemcountByAspectTower = {};
      Object.keys(gettotalItemGroupByAspect).forEach(function (el) {
        itemcountByAspectTower[el] = gettotalItemGroupByAspect[el].length;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(itemcountByAspectTower);

      const ordereditemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );


      checkpointsByAspectTower[key] = ordereditemcountByAspectTower;

    });


    let lowerkeysCheckpointsObj = objectKeysToLowerCase(checkpointsByAspectTower);

    const checkpointsFinalObj = Object.keys(lowerkeysCheckpointsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysCheckpointsObj[key];
        return obj;
      },
      {}
    );


    //Snagged Items  
    //get items array which are snagged
    let snaggeditemArr = [];
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {
      const itemAspectName = orderedgetItemGroupByTower[key];

      let searchbytext = "not OK";

      itemAspectName.filter(function (obj) {
        let serrchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            snaggeditemArr.push(obj);
          }
        }
      });
    });

    //get snagged items by tower group
    const getSnaggedItemGroupByTower = snaggeditemArr.reduce((getSnaggedItemGroupByTower, item) => {
      const tower = (getSnaggedItemGroupByTower[item.tower] || []);
      tower.push(item);
      getSnaggedItemGroupByTower[item.tower] = tower;
      return getSnaggedItemGroupByTower;
    }, {});

    //get count of snags by aspect for each tower        
    var snaggeditemsByAspectTower = {};
    Object.keys(getSnaggedItemGroupByTower).forEach(function (key) {

      const getsnaggedItemGroupByAspect = getSnaggedItemGroupByTower[key].reduce((getsnaggedItemGroupByAspect, item) => {

        const aspect = (getsnaggedItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        getsnaggedItemGroupByAspect[item.aspect] = aspect;
        return getsnaggedItemGroupByAspect;
      }, {});


      var snaggeditemcountByAspectTower = {};
      Object.keys(getsnaggedItemGroupByAspect).forEach(function (el) {
        snaggeditemcountByAspectTower[el] = getsnaggedItemGroupByAspect[el].length;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(snaggeditemcountByAspectTower);

      const orderedsnaggeditemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );

      snaggeditemsByAspectTower[key] = orderedsnaggeditemcountByAspectTower;

    });

    let lowerkeysSnagsObj = objectKeysToLowerCase(snaggeditemsByAspectTower);

    const snagsFinalObj = Object.keys(lowerkeysSnagsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysSnagsObj[key];
        return obj;
      },
      {}
    );


    //Desnagged items having status not rectified
    let desnaggednritemArr = [];
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {
      const itemAspectName = orderedgetItemGroupByTower[key];

      let searchbynr = "not rectified";
      itemAspectName.filter(function (obj) {
        let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if ((serrchval.indexOf(searchbynr) != -1)) {
            //add this object to the filtered array
            desnaggednritemArr.push(obj);
          }
        }
      });
    });


    //get not rectified desnagged items by tower group
    const getDeSnaggednrItemGroupByTower = desnaggednritemArr.reduce((getDeSnaggednrItemGroupByTower, item) => {
      const tower = (getDeSnaggednrItemGroupByTower[item.tower] || []);
      tower.push(item);
      getDeSnaggednrItemGroupByTower[item.tower] = tower;
      return getDeSnaggednrItemGroupByTower;
    }, {});

    //get count of desnags by aspect for each tower        
    var desnaggeditemsByAspectTower = {};
    Object.keys(getDeSnaggednrItemGroupByTower).forEach(function (key) {

      const getdesnaggedItemGroupByAspect = getDeSnaggednrItemGroupByTower[key].reduce((getdesnaggedItemGroupByAspect, item) => {

        const aspect = (getdesnaggedItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        getdesnaggedItemGroupByAspect[item.aspect] = aspect;
        return getdesnaggedItemGroupByAspect;
      }, {});

      var desnaggeditemcountByAspectTower = {};
      Object.keys(getdesnaggedItemGroupByAspect).forEach(function (el) {
        desnaggeditemcountByAspectTower[el] = getdesnaggedItemGroupByAspect[el].length;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(desnaggeditemcountByAspectTower);

      const ordereddesnaggeditemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );

      desnaggeditemsByAspectTower[key] = ordereddesnaggeditemcountByAspectTower;

    });

    let lowerkeysDeSnagsObj = objectKeysToLowerCase(desnaggeditemsByAspectTower);

    const desnagsnrFinalObj = Object.keys(lowerkeysDeSnagsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysDeSnagsObj[key];
        return obj;
      },
      {}
    );


    //Desnagged items having status partially rectified
    let desnaggedpritemArr = [];
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {
      const itemAspectName = orderedgetItemGroupByTower[key];

      let searchbypr = "partially rectified";
      itemAspectName.filter(function (obj) {
        let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if ((serrchval.indexOf(searchbypr) != -1)) {
            //add this object to the filtered array
            desnaggedpritemArr.push(obj);
          }
        }
      });
    });


    //get partially rectified desnagged items by tower group
    const getDeSnaggedprItemGroupByTower = desnaggedpritemArr.reduce((getDeSnaggedprItemGroupByTower, item) => {
      const tower = (getDeSnaggedprItemGroupByTower[item.tower] || []);
      tower.push(item);
      getDeSnaggedprItemGroupByTower[item.tower] = tower;
      return getDeSnaggedprItemGroupByTower;
    }, {});

    //get count of partially rectified desnags by aspect for each tower        
    var desnaggeditemsprByAspectTower = {};
    Object.keys(getDeSnaggedprItemGroupByTower).forEach(function (key) {

      const getdesnaggedItemGroupByAspect = getDeSnaggedprItemGroupByTower[key].reduce((getdesnaggedItemGroupByAspect, item) => {

        const aspect = (getdesnaggedItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        getdesnaggedItemGroupByAspect[item.aspect] = aspect;
        return getdesnaggedItemGroupByAspect;
      }, {});

      var desnaggeditemcountByAspectTower = {};
      Object.keys(getdesnaggedItemGroupByAspect).forEach(function (el) {
        desnaggeditemcountByAspectTower[el] = getdesnaggedItemGroupByAspect[el].length;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(desnaggeditemcountByAspectTower);

      const ordereddesnaggeditemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );

      desnaggeditemsprByAspectTower[key] = ordereddesnaggeditemcountByAspectTower;

    });

    let lowerkeysDeSnagsprObj = objectKeysToLowerCase(desnaggeditemsprByAspectTower);

    const desnagsprFinalObj = Object.keys(lowerkeysDeSnagsprObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysDeSnagsprObj[key];
        return obj;
      },
      {}
    );


    var highprioritysnagsByAspectTower = {};
    Object.keys(getSnaggedItemGroupByTower).forEach(function (key) {

      const gethighpriorityItemGroupByAspect = getSnaggedItemGroupByTower[key].reduce((gethighpriorityItemGroupByAspect, item) => {

        const aspect = (gethighpriorityItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gethighpriorityItemGroupByAspect[item.aspect] = aspect;
        return gethighpriorityItemGroupByAspect;
      }, {});

      var highpriorityitemcountByAspectTower = {};
      Object.keys(gethighpriorityItemGroupByAspect).forEach(function (el) {
        let getAspectPriority = calSnagsPriority(el, gethighpriorityItemGroupByAspect);

        highpriorityitemcountByAspectTower[el] = getAspectPriority;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(highpriorityitemcountByAspectTower);

      const orderedhighpriorityitemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );

      highprioritysnagsByAspectTower[key] = orderedhighpriorityitemcountByAspectTower;

    });


    let lowerkeysPriorityObj = objectKeysToLowerCase(highprioritysnagsByAspectTower);

    const snagsPriorityFinalObj = Object.keys(lowerkeysPriorityObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysPriorityObj[key];
        return obj;
      },
      {}
    );


    //Get data based on high and medium priority count
    function calSnagsPriority(type, aspectdata) {
      let highcount = 0;
      let lowcount = 0;
      let checklen;
      let checkaspect = aspectdata[type];
      checkaspect.forEach(item => {
        checklen = checkaspect.length;
        let checkSnagPriority = item.itemArtifactSet.snagging.snagPriority;
        if (checkSnagPriority == 'high') {
          highcount++;
        } else if (checkSnagPriority == 'low') {
          lowcount++;
        }
      });

      let mediumcount = checklen - (highcount + lowcount);

      let returnobj = { 'highpriority': highcount, 'mediumpriority': mediumcount }
      return returnobj;

    }

    //Get data based on section name by aspect and tower
    //Total Checkpoints
    var bySectionCheckpoints = {};
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {

      const gettotalItemGroupByAspect = orderedgetItemGroupByTower[key].reduce((gettotalItemGroupByAspect, item) => {

        const aspect = (gettotalItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gettotalItemGroupByAspect[item.aspect] = aspect;
        return gettotalItemGroupByAspect;
      }, {});


      var checkpointsbySectionName = {};
      Object.keys(gettotalItemGroupByAspect).forEach(function (key) {

        const getcheckpointCountBySection = gettotalItemGroupByAspect[key].reduce((getcheckpointCountBySection, item) => {

          const sectionName = (getcheckpointCountBySection[item.sectionName] || []);
          sectionName.push(item);
          getcheckpointCountBySection[item.sectionName] = sectionName;
          return getcheckpointCountBySection;
        }, {});

        var setionitemByAsepct = {};
        Object.keys(getcheckpointCountBySection).forEach(function (el) {

          setionitemByAsepct[el] = getcheckpointCountBySection[el].length;
        })

        var sortableitemBySection = [];
        for (var nameCount in setionitemByAsepct) {
          sortableitemBySection.push([nameCount, setionitemByAsepct[nameCount]]);
        }

        // sort section name based on descending order
        sortableitemBySection.sort(function (a, b) {
          return b[1] - a[1];
        });

        var sortableitemBySectionObj = sortableitemBySection.reduce(function (acc, cur, i) {
          acc[i] = cur;
          return acc;
        }, {});

        checkpointsbySectionName[key] = sortableitemBySectionObj;

      });

      bySectionCheckpoints[key] = checkpointsbySectionName;

    });


    let lowerkeysbysectioncheckpointsObj = objectKeysToLowerCase(bySectionCheckpoints);

    const byseccheckpointsFinalObj = Object.keys(lowerkeysbysectioncheckpointsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysbysectioncheckpointsObj[key];
        return obj;
      },
      {}
    );


    //Total Snags
    var bySectionSnags = {};
    Object.keys(getSnaggedItemGroupByTower).forEach(function (key) {

      const gettotalItemGroupByAspect = getSnaggedItemGroupByTower[key].reduce((gettotalItemGroupByAspect, item) => {

        const aspect = (gettotalItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gettotalItemGroupByAspect[item.aspect] = aspect;
        return gettotalItemGroupByAspect;
      }, {});


      var snagsbySectionName = {};
      Object.keys(gettotalItemGroupByAspect).forEach(function (key) {

        const getsnagsCountBySection = gettotalItemGroupByAspect[key].reduce((getsnagsCountBySection, item) => {

          const sectionName = (getsnagsCountBySection[item.sectionName] || []);
          sectionName.push(item);
          getsnagsCountBySection[item.sectionName] = sectionName;
          return getsnagsCountBySection;
        }, {});

        var setionitemByAsepct = {};
        Object.keys(getsnagsCountBySection).forEach(function (el) {

          setionitemByAsepct[el] = getsnagsCountBySection[el].length;
        })

        var sortableitemBySection = [];
        for (var nameCount in setionitemByAsepct) {
          sortableitemBySection.push([nameCount, setionitemByAsepct[nameCount]]);
        }

        // sort section name based on descending order
        sortableitemBySection.sort(function (a, b) {
          return b[1] - a[1];
        });

        var sortableitemBySectionObj = sortableitemBySection.reduce(function (acc, cur, i) {
          acc[i] = cur;
          return acc;
        }, {});

        snagsbySectionName[key] = sortableitemBySectionObj;

      });

      bySectionSnags[key] = snagsbySectionName;

    });

    let lowerkeysbysectionsnagsObj = objectKeysToLowerCase(bySectionSnags);

    const bysecsnagsFinalObj = Object.keys(lowerkeysbysectionsnagsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysbysectionsnagsObj[key];
        return obj;
      },
      {}
    );


    //By Sections Desnagged items having status partially rectified and not rectified
    let bysecdesnaggeditemArr = [];
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {
      const itemAspectName = orderedgetItemGroupByTower[key];

      let searchbypr = "partially rectified";
      let searchbynr = "not rectified";
      itemAspectName.filter(function (obj) {
        let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if ((serrchval.indexOf(searchbypr) != -1) || (serrchval.indexOf(searchbynr) != -1)) {
            //add this object to the filtered array
            bysecdesnaggeditemArr.push(obj);
          }
        }
      });
    });


    //get partially rectified desnagged items by tower group
    const getbysecDeSnaggedprItemGroupByTower = bysecdesnaggeditemArr.reduce((getbysecDeSnaggedprItemGroupByTower, item) => {
      const tower = (getbysecDeSnaggedprItemGroupByTower[item.tower] || []);
      tower.push(item);
      getbysecDeSnaggedprItemGroupByTower[item.tower] = tower;
      return getbysecDeSnaggedprItemGroupByTower;
    }, {});

    //Total partially rectified De-Snags
    var bySectionDeSnags = {};
    Object.keys(getbysecDeSnaggedprItemGroupByTower).forEach(function (key) {

      const gettotalItemGroupByAspect = getbysecDeSnaggedprItemGroupByTower[key].reduce((gettotalItemGroupByAspect, item) => {

        const aspect = (gettotalItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gettotalItemGroupByAspect[item.aspect] = aspect;
        return gettotalItemGroupByAspect;
      }, {});

      var desnagsbySectionName = {};
      Object.keys(gettotalItemGroupByAspect).forEach(function (key) {

        const getdesnagsCountBySection = gettotalItemGroupByAspect[key].reduce((getdesnagsCountBySection, item) => {

          const sectionName = (getdesnagsCountBySection[item.sectionName] || []);
          sectionName.push(item);
          getdesnagsCountBySection[item.sectionName] = sectionName;
          return getdesnagsCountBySection;
        }, {});

        var setionitemByAsepct = {};
        Object.keys(getdesnagsCountBySection).forEach(function (el) {

          setionitemByAsepct[el] = getdesnagsCountBySection[el].length;
        })

        var sortableitemBySection = [];
        for (var nameCount in setionitemByAsepct) {
          sortableitemBySection.push([nameCount, setionitemByAsepct[nameCount]]);
        }

        // sort section name based on descending order
        sortableitemBySection.sort(function (a, b) {
          return b[1] - a[1];
        });

        var sortableitemBySectionObj = sortableitemBySection.reduce(function (acc, cur, i) {
          acc[i] = cur;
          return acc;
        }, {});

        desnagsbySectionName[key] = sortableitemBySectionObj;

      });

      bySectionDeSnags[key] = desnagsbySectionName;

    });

    let lowerkeysbysectiondesnagsObj = objectKeysToLowerCase(bySectionDeSnags);

    const bysecdesnagsFinalObj = Object.keys(lowerkeysbysectiondesnagsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysbysectiondesnagsObj[key];
        return obj;
      },
      {}
    );


    const summarydatabyaspect = {};
    Object.assign(summarydatabyaspect, { byaspectsummarydata: { totalcheckpoints: byaspectcheckpointsFinalObj, totalsnags: byaspectsnagsFinalObj, fixeddesnags: byaspectrcdesnagsFinalObj, prdesnags: byaspectprdesnagsFinalObj, nrdesnags: byaspectnrdesnagsFinalObj } });

    const aspectsnagdesnagbyTowerObj = {};
    Object.assign(aspectsnagdesnagbyTowerObj, { aspectsnagdesnag: { totalcheckpoints: checkpointsFinalObj, totalsnags: snagsFinalObj, totaldesnags: { desnagspr: desnagsprFinalObj, desnagsnr: desnagsnrFinalObj } } });

    const snagsbyprioritybyTowerObj = {};
    Object.assign(snagsbyprioritybyTowerObj, { snagsbypriority: snagsPriorityFinalObj });

    const bysectionsnagsdesnagsObj = {};
    Object.assign(bysectionsnagsdesnagsObj, { aspectbyitemsectionname: { totalcheckpoints: byseccheckpointsFinalObj, totalsnags: bysecsnagsFinalObj, totaldesnags: bysecdesnagsFinalObj } });

    finalResltArr.push(summarydatabyaspect);
    finalResltArr.push(aspectsnagdesnagbyTowerObj);
    finalResltArr.push(snagsbyprioritybyTowerObj);
    finalResltArr.push(bysectionsnagsdesnagsObj);


    this.sendEmailToB2BTower(finalResltArr, customerEmail, BuilderName, projectName, BuilderAddress, inspectedOndate, loggedInUser, loggedInUserEmail);
  }

  public sendEmailToB2BTower(towerlevelProjData, email, BuilderName, projectName, BuilderAddress, inspectedOndate, sender, loggedInUserEmail) {
    let url = "https://us-central1-nemmadi-console.cloudfunctions.net/sendReportsB2BTowerTest";
    let data = {
      dest: email,
      cname: BuilderName,
      pname: projectName,
      address: BuilderAddress,
      date: inspectedOndate,
      sname: sender,
      semail: loggedInUserEmail,
      dataObj: towerlevelProjData
    }
    axios.post(url, data)
      .then(res => {
        // console.log("res",res); 
        this.snackBar("Report sent successfully !!");
        firebase.database().ref('summaries/' + this.projectId).update({
          isReportSent: true
        });
        this.isReportSent = false;
        this.custmB2BEnable = true;
      })
      .catch(err => {
        //  console.log("error",err);
        this.snackBar("Something went wrong !!");
      })
  }

  onSendReport() {
    this.sendEmailBuilder();
    this.emailsent = true;
  }

  ConfirmSendSummary(ref: TemplateRef<any>) {
    this.matDialog.open(ref);
  }

  sendReportTitle() {
    return (this.emailsent ? "Report Sent" : "Send Report");
  }

  public enableSendReportforB2B() {
    let ctype = this.summary.customerType;
    if ((ctype == "RWA") || (ctype == "B2B")) {
      this.custmB2BEnable = true;
    }
  }

  // function for showing the summary report for B2B onclick  
  public getSummaryDataB2BToDisp() {

    const allItems = this.items;

    let finalResltArr = [];

    let itemsArr = [];
    allItems.forEach(function (item) {
      const apectkey = item.hasOwnProperty('aspect');
      if (apectkey) {
        itemsArr.push(item);
      }
    });

    let filteredSnags = [];
    itemsArr.forEach(function (item) {
      if (typeof item.snaggingStatus !== 'undefined' && item.desnaggingStatus !== 'undefined' && item.fixingStatus !== 'undefined') {
        filteredSnags.push(item);
      }
    });

    // version 2 //

    let winga = 'T4-Wing A';
    let wingb = 'T4-Wing B';
    let wingc = 'T4-Wing C';

    let seriesa = 'T1-Series A';
    let seriesb = 'T1-Series B';
    let seriesc = 'T1-Series C';
    let seriesd = 'T1-Series D';
    let wingaArr = [];
    let wingbArr = [];
    let wingcArr = [];

    let seriesdArr = [];

    itemsArr.filter(function (obj) {
      if (obj.sector == winga || obj.sector == seriesa) {
        wingaArr.push(obj);
      } else if (obj.sector == wingb || obj.sector == seriesb) {
        wingbArr.push(obj);
      } else if (obj.sector == wingc || obj.sector == seriesc) {
        wingcArr.push(obj);
      } else if (obj.sector == seriesd) {
        seriesdArr.push(obj);
      }
    });

    // Aspect wise stage info

    const getItems = filteredSnags.reduce((getItems, item) => {
      const aspect = (getItems[item.aspect] || []);
      aspect.push(item);
      getItems[item.aspect] = aspect;
      return getItems;
    }, {});

    let itemsSnaggedArr = [];
    Object.keys(getItems).forEach(function (key) {
      const itemAspectName = getItems[key];
      let searchbytext = "not OK";
      itemAspectName.filter(function (obj) {
        let searchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (searchval.indexOf(searchbytext) != -1) {
            itemsSnaggedArr.push(obj);
          }
        }
      });
    });

    const getSnaggedItem = itemsSnaggedArr.reduce((getSnaggedItem, item) => {
      const aspect = (getSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getSnaggedItem[item.aspect] = aspect;
      return getSnaggedItem;
    }, {});

    this.itemSnagsKeys = [];
    this.itemSnagsValues = [];
    Object.keys(getSnaggedItem).forEach(key => {
      this.itemSnagsKeys.push(key);
      this.itemSnagsValues.push(getSnaggedItem[key].length);
    });

    let itemsDesnaggedArr = [];
    Object.keys(getItems).forEach(function (key) {
      const itemAspectName = getItems[key];
      let searchbytext = "rectified";
      itemAspectName.filter(function (obj) {
        let searchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (searchval.indexOf(searchbytext) != -1) {
            itemsDesnaggedArr.push(obj);
          }
        }
      });
    });

    const getDesnaggedItem = itemsDesnaggedArr.reduce((getDesnaggedItem, item) => {
      const aspect = (getDesnaggedItem[item.aspect] || []);
      aspect.push(item);
      getDesnaggedItem[item.aspect] = aspect;
      return getDesnaggedItem;
    }, {});

    this.itemDesnagsKeys = [];
    this.itemDesnagsValues = [];
    Object.keys(getDesnaggedItem).forEach(key => {
      this.itemDesnagsKeys.push(key);
      this.itemDesnagsValues.push(getDesnaggedItem[key].length);
    });


    let itemsFixedArr = [];
    Object.keys(getItems).forEach(function (key) {
      const itemAspectName = getItems[key];
      let searchbytext = "fixed";
      itemAspectName.filter(function (obj) {
        let searchval = obj.fixingStatus;
        if (typeof obj.fixingStatus !== 'undefined') {
          if (searchval.indexOf(searchbytext) != -1) {
            itemsFixedArr.push(obj);
          }
        }
      });
    });

    const getFixedItem = itemsFixedArr.reduce((getFixedItem, item) => {
      const aspect = (getFixedItem[item.aspect] || []);
      aspect.push(item);
      getFixedItem[item.aspect] = aspect;
      return getFixedItem;
    }, {});

    this.itemFixingKeys = [];
    this.itemFixingValues = [];
    Object.keys(getFixedItem).forEach(key => {
      this.itemFixingKeys.push(key);
      this.itemFixingValues.push(getFixedItem[key].length);
    });

    // wing wise checkpoints

    let wingasnags = [];
    wingaArr.forEach(function (item) {
      if (typeof item.snaggingStatus !== 'undefined') {
        wingasnags.push(item);
      }
    });

    let wingbsnags = [];
    wingbArr.forEach(function (item) {
      if (typeof item.snaggingStatus !== 'undefined') {
        wingbsnags.push(item);
      }
    });

    let wingcsnags = [];
    wingcArr.forEach(function (item) {
      if (typeof item.snaggingStatus !== 'undefined') {
        wingcsnags.push(item);
      }
    });

    let seriesdsnags = [];
    seriesdArr.forEach(function (item) {
      if (typeof item.snaggingStatus !== 'undefined') {
        seriesdsnags.push(item);
      }
    });

    let snaggeditemsArr = [];
    itemsArr.forEach(function (item) {
      if (typeof item.snaggingStatus !== 'undefined') {
        snaggeditemsArr.push(item);
      }
    });

    let wingafixes = [];
    wingaArr.forEach(function (item) {
      if (typeof item.fixingStatus !== 'undefined') {
        wingafixes.push(item);
      }
    });

    let wingbfixes = [];
    wingbArr.forEach(function (item) {
      if (typeof item.fixingStatus !== 'undefined') {
        wingbfixes.push(item);
      }
    });

    let wingcfixes = [];
    wingcArr.forEach(function (item) {
      if (typeof item.fixingStatus !== 'undefined') {
        wingcfixes.push(item);
      }
    });

    let objectKeysToLowerCase = function (origObj) {
      return Object.keys(origObj).reduce(function (newObj, key) {
        let val = origObj[key];
        let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
        newObj[key.toLowerCase()] = newVal;
        return newObj;
      }, {});
    }

    // wing wise data total checkpoints
    const getWingAItem = wingaArr.reduce((getWingAItem, item) => {
      const aspect = (getWingAItem[item.aspect] || []);
      aspect.push(item);
      getWingAItem[item.aspect] = aspect;
      return getWingAItem;
    }, {});


    this.wingAItemKeys = [];
    this.wingAItemValues = [];
    Object.keys(getWingAItem).forEach(key => {
      this.wingAItemKeys.push(key);
      this.wingAItemValues.push(getWingAItem[key].length);
    });

    this.wingACheckPointsCount = this.wingAItemValues.reduce((a, b) => a + b, 0);

    const getWingBItem = wingbArr.reduce((getWingBItem, item) => {
      const aspect = (getWingBItem[item.aspect] || []);
      aspect.push(item);
      getWingBItem[item.aspect] = aspect;
      return getWingBItem;
    }, {});

    this.wingBItemKeys = [];
    this.wingBItemValues = [];
    Object.keys(getWingBItem).forEach(key => {
      this.wingBItemKeys.push(key);
      this.wingBItemValues.push(getWingBItem[key].length);
    });

    this.wingBCheckPointsCount = this.wingBItemValues.reduce((a, b) => a + b, 0);

    const getWingCItem = wingcArr.reduce((getWingCItem, item) => {
      const aspect = (getWingCItem[item.aspect] || []);
      aspect.push(item);
      getWingCItem[item.aspect] = aspect;
      return getWingCItem;
    }, {});

    this.wingCItemKeys = [];
    this.wingCItemValues = [];
    Object.keys(getWingCItem).forEach(key => {
      this.wingCItemKeys.push(key);
      this.wingCItemValues.push(getWingCItem[key].length);
    });

    this.wingCCheckPointsCount = this.wingCItemValues.reduce((a, b) => a + b, 0);

    const getSeriesDItem = seriesdArr.reduce((getSeriesDItem, item) => {
      const aspect = (getSeriesDItem[item.aspect] || []);
      aspect.push(item);
      getSeriesDItem[item.aspect] = aspect;
      return getSeriesDItem;
    }, {});


    this.seriesDItemKeys = [];
    this.seriesDItemValues = [];
    Object.keys(getSeriesDItem).forEach(key => {
      this.seriesDItemKeys.push(key);
      this.seriesDItemValues.push(getSeriesDItem[key].length);
    });

    let wingASnaggedArr = [];
    Object.keys(getWingAItem).forEach(function (key) {
      const itemAspectName = getWingAItem[key];
      let searchbytext = "not OK";
      itemAspectName.filter(function (obj) {
        let searchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (searchval.indexOf(searchbytext) != -1) {
            wingASnaggedArr.push(obj);
          }
        }
      });
    });

    const getwingASnaggedItem = wingASnaggedArr.reduce((getwingASnaggedItem, item) => {
      const aspect = (getwingASnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingASnaggedItem[item.aspect] = aspect;
      return getwingASnaggedItem;
    }, {});

    this.wingASnagsKeys = [];
    this.wingASnagsValues = [];
    Object.keys(getwingASnaggedItem).forEach(key => {
      this.wingASnagsKeys.push(key);
      this.wingASnagsValues.push(getwingASnaggedItem[key].length);
    });

    this.wingASnagsCount = this.wingASnagsValues.reduce((a, b) => a + b, 0);

    let wingBSnaggedArr = [];
    Object.keys(getWingBItem).forEach(function (key) {
      const itemAspectName = getWingBItem[key];
      let searchbytext = "not OK";
      itemAspectName.filter(function (obj) {
        let searchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (searchval.indexOf(searchbytext) != -1) {
            wingBSnaggedArr.push(obj);
          }
        }
      });
    });

    const getwingBSnaggedItem = wingBSnaggedArr.reduce((getwingBSnaggedItem, item) => {
      const aspect = (getwingBSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingBSnaggedItem[item.aspect] = aspect;
      return getwingBSnaggedItem;
    }, {});

    this.wingBSnagsKeys = [];
    this.wingBSnagsValues = [];
    Object.keys(getwingBSnaggedItem).forEach(key => {
      this.wingBSnagsKeys.push(key);
      this.wingBSnagsValues.push(getwingBSnaggedItem[key].length);
    });

    this.wingBSnagsCount = this.wingBSnagsValues.reduce((a, b) => a + b, 0);

    let wingCSnaggedArr = [];
    Object.keys(getWingCItem).forEach(function (key) {
      const itemAspectName = getWingCItem[key];
      let searchbytext = "not OK";
      itemAspectName.filter(function (obj) {
        let searchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (searchval.indexOf(searchbytext) != -1) {
            wingCSnaggedArr.push(obj);
          }
        }
      });
    });

    const getwingCSnaggedItem = wingCSnaggedArr.reduce((getwingCSnaggedItem, item) => {
      const aspect = (getwingCSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingCSnaggedItem[item.aspect] = aspect;
      return getwingCSnaggedItem;
    }, {});

    this.wingCSnagsKeys = [];
    this.wingCSnagsValues = [];
    Object.keys(getwingCSnaggedItem).forEach(key => {
      this.wingCSnagsKeys.push(key);
      this.wingCSnagsValues.push(getwingCSnaggedItem[key].length);
    });

    this.wingCSnagsCount = this.wingCSnagsValues.reduce((a, b) => a + b, 0);

    let seriesDSnaggedArr = [];
    Object.keys(getSeriesDItem).forEach(function (key) {
      const itemAspectName = getSeriesDItem[key];
      let searchbytext = "not OK";
      itemAspectName.filter(function (obj) {
        let searchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (searchval.indexOf(searchbytext) != -1) {
            seriesDSnaggedArr.push(obj);
          }
        }
      });
    });

    const getSeriesDSnaggedItem = seriesDSnaggedArr.reduce((getSeriesDSnaggedItem, item) => {
      const aspect = (getSeriesDSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getSeriesDSnaggedItem[item.aspect] = aspect;
      return getSeriesDSnaggedItem;
    }, {});

    this.seriesDSnagsKeys = [];
    this.seriesDSnagsValues = [];
    Object.keys(getSeriesDSnaggedItem).forEach(key => {
      this.seriesDSnagsKeys.push(key);
      this.seriesDSnagsValues.push(getSeriesDSnaggedItem[key].length);
    });

    let wingAPRDeSnaggedArr = [];
    let wingARDeSnaggedArr = [];
    let wingANRDeSnaggedArr = [];
    let seriesDNRDeSnaggedArr = [];
    Object.keys(getWingAItem).forEach(function (key) {
      const itemAspectName = getWingAItem[key];
      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'partially rectified') {
            wingAPRDeSnaggedArr.push(obj);
          } else if (obj.desnaggingStatus == 'rectified') {
            wingARDeSnaggedArr.push(obj);
          } else if (obj.desnaggingStatus == 'not rectified') {
            wingANRDeSnaggedArr.push(obj);
          }
        }
      });
    });

    const getwingAPRDeSnaggedItem = wingAPRDeSnaggedArr.reduce((getwingAPRDeSnaggedItem, item) => {
      const aspect = (getwingAPRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingAPRDeSnaggedItem[item.aspect] = aspect;
      return getwingAPRDeSnaggedItem;
    }, {});

    this.wingAPRDeSnagsKeys = [];
    this.wingAPRDeSnagsValues = [];
    Object.keys(getwingAPRDeSnaggedItem).forEach(key => {
      this.wingAPRDeSnagsKeys.push(key);
      this.wingAPRDeSnagsValues.push(getwingAPRDeSnaggedItem[key].length);
    });

    const getwingARDeSnaggedItem = wingARDeSnaggedArr.reduce((getwingARDeSnaggedItem, item) => {
      const aspect = (getwingARDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingARDeSnaggedItem[item.aspect] = aspect;
      return getwingARDeSnaggedItem;
    }, {});

    this.wingARDeSnagsKeys = [];
    this.wingARDeSnagsValues = [];
    Object.keys(getwingARDeSnaggedItem).forEach(key => {
      this.wingARDeSnagsKeys.push(key);
      this.wingARDeSnagsValues.push(getwingARDeSnaggedItem[key].length);
    });

    const getwingANRDeSnaggedItem = wingANRDeSnaggedArr.reduce((getwingANRDeSnaggedItem, item) => {
      const aspect = (getwingANRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingANRDeSnaggedItem[item.aspect] = aspect;
      return getwingANRDeSnaggedItem;
    }, {});

    this.wingANRDeSnagsKeys = [];
    this.wingANRDeSnagsValues = [];
    Object.keys(getwingANRDeSnaggedItem).forEach(key => {
      this.wingANRDeSnagsKeys.push(key);
      this.wingANRDeSnagsValues.push(getwingANRDeSnaggedItem[key].length);
    });

    let wingBPRDeSnaggedArr = [];
    let wingBRDeSnaggedArr = [];
    let wingBNRDeSnaggedArr = [];
    Object.keys(getWingBItem).forEach(function (key) {
      const itemAspectName = getWingBItem[key];
      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'partially rectified') {
            wingBPRDeSnaggedArr.push(obj);
          } else if (obj.desnaggingStatus == 'rectified') {
            wingBRDeSnaggedArr.push(obj);
          } else if (obj.desnaggingStatus == 'not rectified') {
            wingBNRDeSnaggedArr.push(obj);
          }
        }
      });
    });

    const getwingBPRDeSnaggedItem = wingBPRDeSnaggedArr.reduce((getwingBPRDeSnaggedItem, item) => {
      const aspect = (getwingBPRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingBPRDeSnaggedItem[item.aspect] = aspect;
      return getwingBPRDeSnaggedItem;
    }, {});

    this.wingBPRDeSnagsKeys = [];
    this.wingBPRDeSnagsValues = [];
    Object.keys(getwingBPRDeSnaggedItem).forEach(key => {
      this.wingBPRDeSnagsKeys.push(key);
      this.wingBPRDeSnagsValues.push(getwingBPRDeSnaggedItem[key].length);
    });

    const getwingBRDeSnaggedItem = wingBRDeSnaggedArr.reduce((getwingBRDeSnaggedItem, item) => {
      const aspect = (getwingBRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingBRDeSnaggedItem[item.aspect] = aspect;
      return getwingBRDeSnaggedItem;
    }, {});

    this.wingBRDeSnagsKeys = [];
    this.wingBRDeSnagsValues = [];
    Object.keys(getwingBRDeSnaggedItem).forEach(key => {
      this.wingBRDeSnagsKeys.push(key);
      this.wingBRDeSnagsValues.push(getwingBRDeSnaggedItem[key].length);
    });

    const getwingBNRDeSnaggedItem = wingBNRDeSnaggedArr.reduce((getwingBNRDeSnaggedItem, item) => {
      const aspect = (getwingBNRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingBNRDeSnaggedItem[item.aspect] = aspect;
      return getwingBNRDeSnaggedItem;
    }, {});

    this.wingBNRDeSnagsKeys = [];
    this.wingBNRDeSnagsValues = [];
    Object.keys(getwingBNRDeSnaggedItem).forEach(key => {
      this.wingBNRDeSnagsKeys.push(key);
      this.wingBNRDeSnagsValues.push(getwingBNRDeSnaggedItem[key].length);
    });

    let wingCPRDeSnaggedArr = [];
    let wingCRDeSnaggedArr = [];
    let wingCNRDeSnaggedArr = [];
    Object.keys(getWingCItem).forEach(function (key) {
      const itemAspectName = getWingCItem[key];
      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'partially rectified') {
            wingCPRDeSnaggedArr.push(obj);
          } else if (obj.desnaggingStatus == 'rectified') {
            wingCRDeSnaggedArr.push(obj);
          } else if (obj.desnaggingStatus == 'not rectified') {
            wingCNRDeSnaggedArr.push(obj);
          }
        }
      });
    });

    const getwingCPRDeSnaggedItem = wingCPRDeSnaggedArr.reduce((getwingCPRDeSnaggedItem, item) => {
      const aspect = (getwingCPRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingCPRDeSnaggedItem[item.aspect] = aspect;
      return getwingCPRDeSnaggedItem;
    }, {});

    this.wingCPRDeSnagsKeys = [];
    this.wingCPRDeSnagsValues = [];
    Object.keys(getwingCPRDeSnaggedItem).forEach(key => {
      this.wingCPRDeSnagsKeys.push(key);
      this.wingCPRDeSnagsValues.push(getwingCPRDeSnaggedItem[key].length);
    });

    const getwingCRDeSnaggedItem = wingCRDeSnaggedArr.reduce((getwingCRDeSnaggedItem, item) => {
      const aspect = (getwingCRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingCRDeSnaggedItem[item.aspect] = aspect;
      return getwingCRDeSnaggedItem;
    }, {});

    this.wingCRDeSnagsKeys = [];
    this.wingCRDeSnagsValues = [];
    Object.keys(getwingCRDeSnaggedItem).forEach(key => {
      this.wingCRDeSnagsKeys.push(key);
      this.wingCRDeSnagsValues.push(getwingCRDeSnaggedItem[key].length);
    });

    const getwingCNRDeSnaggedItem = wingCNRDeSnaggedArr.reduce((getwingCNRDeSnaggedItem, item) => {
      const aspect = (getwingCNRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getwingCNRDeSnaggedItem[item.aspect] = aspect;
      return getwingCNRDeSnaggedItem;
    }, {});

    this.wingCNRDeSnagsKeys = [];
    this.wingCNRDeSnagsValues = [];
    Object.keys(getwingCNRDeSnaggedItem).forEach(key => {
      this.wingCNRDeSnagsKeys.push(key);
      this.wingCNRDeSnagsValues.push(getwingCNRDeSnaggedItem[key].length);
    });

    let seriesDPRDeSnaggedArr = [];
    let seriesDRDeSnaggedArr = [];
    Object.keys(getSeriesDItem).forEach(function (key) {
      const itemAspectName = getSeriesDItem[key];
      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'partially rectified') {
            seriesDPRDeSnaggedArr.push(obj);
          } else if (obj.desnaggingStatus == 'rectified') {
            seriesDRDeSnaggedArr.push(obj);
          } else if (obj.desnaggingStatus == 'not rectified') {
            seriesDNRDeSnaggedArr.push(obj);
          }
        }
      });
    });

    const getSeriesDPRDeSnaggedItem = seriesDPRDeSnaggedArr.reduce((getSeriesDPRDeSnaggedItem, item) => {
      const aspect = (getSeriesDPRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getSeriesDPRDeSnaggedItem[item.aspect] = aspect;
      return getSeriesDPRDeSnaggedItem;
    }, {});

    this.seriesDPRDeSnagsKeys = [];
    this.seriesDPRDeSnagsValues = [];
    Object.keys(getSeriesDPRDeSnaggedItem).forEach(key => {
      this.seriesDPRDeSnagsKeys.push(key);
      this.seriesDPRDeSnagsValues.push(getSeriesDPRDeSnaggedItem[key].length);
    });

    const getSeriesDRDeSnaggedItem = seriesDRDeSnaggedArr.reduce((getSeriesDRDeSnaggedItem, item) => {
      const aspect = (getSeriesDRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getSeriesDRDeSnaggedItem[item.aspect] = aspect;
      return getSeriesDRDeSnaggedItem;
    }, {});

    this.seriesDRDeSnagsKeys = [];
    this.seriesDRDeSnagsValues = [];
    Object.keys(getSeriesDRDeSnaggedItem).forEach(key => {
      this.seriesDRDeSnagsKeys.push(key);
      this.seriesDRDeSnagsValues.push(getSeriesDRDeSnaggedItem[key].length);
    });

    const getSeriesDNRDeSnaggedItem = seriesDNRDeSnaggedArr.reduce((getSeriesDNRDeSnaggedItem, item) => {
      const aspect = (getSeriesDNRDeSnaggedItem[item.aspect] || []);
      aspect.push(item);
      getSeriesDNRDeSnaggedItem[item.aspect] = aspect;
      return getSeriesDNRDeSnaggedItem;
    }, {});

    this.seriesDNRDeSnagsKeys = [];
    this.seriesDNRDeSnagsValues = [];
    Object.keys(getSeriesDNRDeSnaggedItem).forEach(key => {
      this.seriesDNRDeSnagsKeys.push(key);
      this.seriesDNRDeSnagsValues.push(getSeriesDNRDeSnaggedItem[key].length);
    });

    //fixed data start

    let wingAFixedArr = [];
    let wingACFArr = [];
    let wingANASArr = [];
    Object.keys(getWingAItem).forEach(function (key) {
      const itemAspectName = getWingAItem[key];
      itemAspectName.filter(function (obj) {
        if (typeof obj.fixingStatus !== 'undefined') {
          if (obj.fixingStatus == 'fixed') {
            wingAFixedArr.push(obj);
          } else if (obj.fixingStatus == `can't fix`) {
            wingACFArr.push(obj);
          } else if (obj.fixingStatus == 'not a snag') {
            wingANASArr.push(obj);
          }
        }
      });
    });

    const getwingAFixedItem = wingAFixedArr.reduce((getwingAFixedItem, item) => {
      const aspect = (getwingAFixedItem[item.aspect] || []);
      aspect.push(item);
      getwingAFixedItem[item.aspect] = aspect;
      return getwingAFixedItem;
    }, {});

    this.wingAFixedKeys = [];
    this.wingAFixedValues = [];
    Object.keys(getwingAFixedItem).forEach(key => {
      this.wingAFixedKeys.push(key);
      this.wingAFixedValues.push(getwingAFixedItem[key].length);
    });

    const getwingACFItem = wingACFArr.reduce((getwingACFItem, item) => {
      const aspect = (getwingACFItem[item.aspect] || []);
      aspect.push(item);
      getwingACFItem[item.aspect] = aspect;
      return getwingACFItem;
    }, {});

    this.wingACFKeys = [];
    this.wingACFValues = [];
    Object.keys(getwingACFItem).forEach(key => {
      this.wingACFKeys.push(key);
      this.wingACFValues.push(getwingACFItem[key].length);
    });

    const getwingANASItem = wingANASArr.reduce((getwingANASItem, item) => {
      const aspect = (getwingANASItem[item.aspect] || []);
      aspect.push(item);
      getwingANASItem[item.aspect] = aspect;
      return getwingANASItem;
    }, {});

    this.wingANASKeys = [];
    this.wingANASValues = [];
    Object.keys(getwingANASItem).forEach(key => {
      this.wingANASKeys.push(key);
      this.wingANASValues.push(getwingANASItem[key].length);
    });

    // wing B

    let wingBFixedArr = [];
    let wingBCFArr = [];
    let wingBNASArr = [];
    Object.keys(getWingBItem).forEach(function (key) {
      const itemAspectName = getWingBItem[key];
      itemAspectName.filter(function (obj) {
        if (typeof obj.fixingStatus !== 'undefined') {
          if (obj.fixingStatus == 'fixed') {
            wingBFixedArr.push(obj);
          } else if (obj.fixingStatus == `can't fix`) {
            wingBCFArr.push(obj);
          } else if (obj.fixingStatus == 'not a snag') {
            wingBNASArr.push(obj);
          }
        }
      });
    });

    const getwingBFixedItem = wingBFixedArr.reduce((getwingBFixedItem, item) => {
      const aspect = (getwingBFixedItem[item.aspect] || []);
      aspect.push(item);
      getwingBFixedItem[item.aspect] = aspect;
      return getwingBFixedItem;
    }, {});

    this.wingBFixedKeys = [];
    this.wingBFixedValues = [];
    Object.keys(getwingBFixedItem).forEach(key => {
      this.wingBFixedKeys.push(key);
      this.wingBFixedValues.push(getwingBFixedItem[key].length);
    });

    const getwingBCFItem = wingBCFArr.reduce((getwingBCFItem, item) => {
      const aspect = (getwingBCFItem[item.aspect] || []);
      aspect.push(item);
      getwingBCFItem[item.aspect] = aspect;
      return getwingBCFItem;
    }, {});

    this.wingBCFKeys = [];
    this.wingBCFValues = [];
    Object.keys(getwingBCFItem).forEach(key => {
      this.wingBCFKeys.push(key);
      this.wingBCFValues.push(getwingBCFItem[key].length);
    });

    const getwingBNASItem = wingBNASArr.reduce((getwingBNASItem, item) => {
      const aspect = (getwingBNASItem[item.aspect] || []);
      aspect.push(item);
      getwingBNASItem[item.aspect] = aspect;
      return getwingBNASItem;
    }, {});

    this.wingBNASKeys = [];
    this.wingBNASValues = [];
    Object.keys(getwingBNASItem).forEach(key => {
      this.wingBNASKeys.push(key);
      this.wingBNASValues.push(getwingBNASItem[key].length);
    });

    //wing c

    let wingCFixedArr = [];
    let wingCCFArr = [];
    let wingCNASArr = [];
    Object.keys(getWingCItem).forEach(function (key) {
      const itemAspectName = getWingCItem[key];
      itemAspectName.filter(function (obj) {
        if (typeof obj.fixingStatus !== 'undefined') {
          if (obj.fixingStatus == 'fixed') {
            wingCFixedArr.push(obj);
          } else if (obj.fixingStatus == `can't fix`) {
            wingCCFArr.push(obj);
          } else if (obj.fixingStatus == 'not a snag') {
            wingCNASArr.push(obj);
          }
        }
      });
    });

    const getwingCFixedItem = wingCFixedArr.reduce((getwingCFixedItem, item) => {
      const aspect = (getwingCFixedItem[item.aspect] || []);
      aspect.push(item);
      getwingCFixedItem[item.aspect] = aspect;
      return getwingCFixedItem;
    }, {});

    this.wingCFixedKeys = [];
    this.wingCFixedValues = [];
    Object.keys(getwingCFixedItem).forEach(key => {
      this.wingCFixedKeys.push(key);
      this.wingCFixedValues.push(getwingCFixedItem[key].length);
    });

    const getwingCCFItem = wingCCFArr.reduce((getwingCCFItem, item) => {
      const aspect = (getwingCCFItem[item.aspect] || []);
      aspect.push(item);
      getwingCCFItem[item.aspect] = aspect;
      return getwingCCFItem;
    }, {});

    this.wingCCFKeys = [];
    this.wingCCFValues = [];
    Object.keys(getwingCCFItem).forEach(key => {
      this.wingCCFKeys.push(key);
      this.wingCCFValues.push(getwingCCFItem[key].length);
    });

    const getwingCNASItem = wingCNASArr.reduce((getwingCNASItem, item) => {
      const aspect = (getwingCNASItem[item.aspect] || []);
      aspect.push(item);
      getwingCNASItem[item.aspect] = aspect;
      return getwingCNASItem;
    }, {});

    this.wingCNASKeys = [];
    this.wingCNASValues = [];
    Object.keys(getwingCNASItem).forEach(key => {
      this.wingCNASKeys.push(key);
      this.wingCNASValues.push(getwingCNASItem[key].length);
    });

    //fixed data end


    //Extract Summary Chart data Aspect Wise
    const getItemGroupByAspect = snaggeditemsArr.reduce((getItemGroupByAspect, item) => {
      const aspect = (getItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getItemGroupByAspect[item.aspect] = aspect;
      return getItemGroupByAspect;
    }, {});

    var checpointsByAspectCount = {};
    let totalCheckPoints = 0;
    Object.keys(getItemGroupByAspect).forEach(function (key) {
      totalCheckPoints += getItemGroupByAspect[key].length;
      Object.assign(checpointsByAspectCount, { total: totalCheckPoints });
      checpointsByAspectCount[key] = getItemGroupByAspect[key].length;
    });

    const byaspectcheckpointsFinalObj = Object.keys(checpointsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = checpointsByAspectCount[key];
        return obj;
      },
      {}
    );

    //get items array which are snagged
    let byaspectsnaggeditemArr = [];
    Object.keys(getItemGroupByAspect).forEach(function (key) {
      const itemAspectName = getItemGroupByAspect[key];

      let searchbytext = "not OK";

      itemAspectName.filter(function (obj) {
        let serrchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            byaspectsnaggeditemArr.push(obj);
          }
        }
      });
    });

    const getSnaggedItemGroupByAspect = byaspectsnaggeditemArr.reduce((getSnaggedItemGroupByAspect, item) => {
      const aspect = (getSnaggedItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getSnaggedItemGroupByAspect[item.aspect] = aspect;
      return getSnaggedItemGroupByAspect;
    }, {});

    var snagsByAspectCount = {};
    let totalSnags = 0;
    Object.keys(getSnaggedItemGroupByAspect).forEach(function (key) {
      totalSnags += getSnaggedItemGroupByAspect[key].length;
      Object.assign(snagsByAspectCount, { total: totalSnags });
      snagsByAspectCount[key] = getSnaggedItemGroupByAspect[key].length;
    });

    const byaspectsnagsFinalObj = Object.keys(snagsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = snagsByAspectCount[key];
        return obj;
      },
      {}
    );

    //get items array which are rectified de-snagged
    let byaspectrcdesnaggeditemArr = [];
    Object.keys(getItemGroupByAspect).forEach(function (key) {
      const itemAspectName = getItemGroupByAspect[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'rectified') {
            //add this object to the filtered array
            byaspectrcdesnaggeditemArr.push(obj);
          }
        }
      });
    });

    const getRcDeSnaggedItemGroupByAspect = byaspectrcdesnaggeditemArr.reduce((getRcDeSnaggedItemGroupByAspect, item) => {
      const aspect = (getRcDeSnaggedItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getRcDeSnaggedItemGroupByAspect[item.aspect] = aspect;
      return getRcDeSnaggedItemGroupByAspect;
    }, {});

    var rcdesnagsByAspectCount = {};
    let totalDeSnags = 0;
    Object.keys(getRcDeSnaggedItemGroupByAspect).forEach(function (key) {
      totalDeSnags += getRcDeSnaggedItemGroupByAspect[key].length;
      Object.assign(rcdesnagsByAspectCount, { total: totalDeSnags });
      rcdesnagsByAspectCount[key] = getRcDeSnaggedItemGroupByAspect[key].length;
    });

    const byaspectrcdesnagsFinalObj = Object.keys(rcdesnagsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = rcdesnagsByAspectCount[key];
        return obj;
      },
      {}
    );

    //get items array which are partially rectified de-snagged
    let byaspectprdesnaggeditemArr = [];
    Object.keys(getItemGroupByAspect).forEach(function (key) {
      const itemAspectName = getItemGroupByAspect[key];

      itemAspectName.filter(function (obj) {
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'partially rectified') {
            //add this object to the filtered array
            byaspectprdesnaggeditemArr.push(obj);
          }
        }
      });
    });


    const getPrDeSnaggedItemGroupByAspect = byaspectprdesnaggeditemArr.reduce((getPrDeSnaggedItemGroupByAspect, item) => {
      const aspect = (getPrDeSnaggedItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getPrDeSnaggedItemGroupByAspect[item.aspect] = aspect;
      return getPrDeSnaggedItemGroupByAspect;
    }, {});

    var prdesnagsByAspectCount = {};
    Object.keys(getPrDeSnaggedItemGroupByAspect).forEach(function (key) {
      prdesnagsByAspectCount[key] = getPrDeSnaggedItemGroupByAspect[key].length;
    });

    const byaspectprdesnagsFinalObj = Object.keys(prdesnagsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = prdesnagsByAspectCount[key];
        return obj;
      },
      {}
    );

    //get items array which are not rectified de-snagged
    let byaspectnrdesnaggeditemArr = [];
    Object.keys(getItemGroupByAspect).forEach(function (key) {
      const itemAspectName = getItemGroupByAspect[key];

      //let searchbytext = "rectified";

      itemAspectName.filter(function (obj) {
        // let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if (obj.desnaggingStatus == 'not rectified') {
            //add this object to the filtered array
            byaspectnrdesnaggeditemArr.push(obj);
          }
        }
      });
    });

    const getNrDeSnaggedItemGroupByAspect = byaspectnrdesnaggeditemArr.reduce((getNrDeSnaggedItemGroupByAspect, item) => {
      const aspect = (getNrDeSnaggedItemGroupByAspect[item.aspect] || []);
      aspect.push(item);
      getNrDeSnaggedItemGroupByAspect[item.aspect] = aspect;
      return getNrDeSnaggedItemGroupByAspect;
    }, {});

    var nrdesnagsByAspectCount = {};
    Object.keys(getNrDeSnaggedItemGroupByAspect).forEach(function (key) {
      nrdesnagsByAspectCount[key] = getNrDeSnaggedItemGroupByAspect[key].length;
    });

    const byaspectnrdesnagsFinalObj = Object.keys(nrdesnagsByAspectCount).sort().reduce(
      (obj, key) => {
        obj[key] = nrdesnagsByAspectCount[key];
        return obj;
      },
      {}
    );

    //End Extract Summary Chart data Aspect Wise

    //Total Checkpoints
    //get items by each tower
    const getItemGroupByTower = snaggeditemsArr.reduce((getItemGroupByTower, item) => {
      const tower = (getItemGroupByTower[item.tower] || []);
      tower.push(item);
      getItemGroupByTower[item.tower] = tower;
      return getItemGroupByTower;
    }, {});

    const orderedgetItemGroupByTower = Object.keys(getItemGroupByTower).sort().reduce(
      (obj, key) => {
        obj[key] = getItemGroupByTower[key];
        return obj;
      },
      {}
    );


    //get total checkpoints in each tower by each aspect
    var checkpointsByAspectTower = {};
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {

      const gettotalItemGroupByAspect = orderedgetItemGroupByTower[key].reduce((gettotalItemGroupByAspect, item) => {

        const aspect = (gettotalItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gettotalItemGroupByAspect[item.aspect] = aspect;
        return gettotalItemGroupByAspect;
      }, {});

      var itemcountByAspectTower = {};
      Object.keys(gettotalItemGroupByAspect).forEach(function (el) {
        itemcountByAspectTower[el] = gettotalItemGroupByAspect[el].length;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(itemcountByAspectTower);

      const ordereditemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );


      checkpointsByAspectTower[key] = ordereditemcountByAspectTower;

    });


    let lowerkeysCheckpointsObj = objectKeysToLowerCase(checkpointsByAspectTower);

    const checkpointsFinalObj = Object.keys(lowerkeysCheckpointsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysCheckpointsObj[key];
        return obj;
      },
      {}
    );


    //Snagged Items  
    //get items array which are snagged
    let snaggeditemArr = [];
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {
      const itemAspectName = orderedgetItemGroupByTower[key];

      let searchbytext = "not OK";

      itemAspectName.filter(function (obj) {
        let serrchval = obj.snaggingStatus;
        if (typeof obj.snaggingStatus !== 'undefined') {
          if (serrchval.indexOf(searchbytext) != -1) {
            //add this object to the filtered array
            snaggeditemArr.push(obj);
          }
        }
      });
    });

    //get snagged items by tower group
    const getSnaggedItemGroupByTower = snaggeditemArr.reduce((getSnaggedItemGroupByTower, item) => {
      const tower = (getSnaggedItemGroupByTower[item.tower] || []);
      tower.push(item);
      getSnaggedItemGroupByTower[item.tower] = tower;
      return getSnaggedItemGroupByTower;
    }, {});

    //get count of snags by aspect for each tower        
    var snaggeditemsByAspectTower = {};
    Object.keys(getSnaggedItemGroupByTower).forEach(function (key) {

      const getsnaggedItemGroupByAspect = getSnaggedItemGroupByTower[key].reduce((getsnaggedItemGroupByAspect, item) => {

        const aspect = (getsnaggedItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        getsnaggedItemGroupByAspect[item.aspect] = aspect;
        return getsnaggedItemGroupByAspect;
      }, {});


      var snaggeditemcountByAspectTower = {};
      Object.keys(getsnaggedItemGroupByAspect).forEach(function (el) {
        snaggeditemcountByAspectTower[el] = getsnaggedItemGroupByAspect[el].length;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(snaggeditemcountByAspectTower);

      const orderedsnaggeditemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );

      snaggeditemsByAspectTower[key] = orderedsnaggeditemcountByAspectTower;

    });

    let lowerkeysSnagsObj = objectKeysToLowerCase(snaggeditemsByAspectTower);

    const snagsFinalObj = Object.keys(lowerkeysSnagsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysSnagsObj[key];
        return obj;
      },
      {}
    );


    //Desnagged items having status not rectified
    let desnaggednritemArr = [];
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {
      const itemAspectName = orderedgetItemGroupByTower[key];

      let searchbynr = "not rectified";
      itemAspectName.filter(function (obj) {
        let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if ((serrchval.indexOf(searchbynr) != -1)) {
            //add this object to the filtered array
            desnaggednritemArr.push(obj);
          }
        }
      });
    });


    //get not rectified desnagged items by tower group
    const getDeSnaggednrItemGroupByTower = desnaggednritemArr.reduce((getDeSnaggednrItemGroupByTower, item) => {
      const tower = (getDeSnaggednrItemGroupByTower[item.tower] || []);
      tower.push(item);
      getDeSnaggednrItemGroupByTower[item.tower] = tower;
      return getDeSnaggednrItemGroupByTower;
    }, {});

    //get count of desnags by aspect for each tower        
    var desnaggeditemsByAspectTower = {};
    Object.keys(getDeSnaggednrItemGroupByTower).forEach(function (key) {

      const getdesnaggedItemGroupByAspect = getDeSnaggednrItemGroupByTower[key].reduce((getdesnaggedItemGroupByAspect, item) => {

        const aspect = (getdesnaggedItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        getdesnaggedItemGroupByAspect[item.aspect] = aspect;
        return getdesnaggedItemGroupByAspect;
      }, {});

      var desnaggeditemcountByAspectTower = {};
      Object.keys(getdesnaggedItemGroupByAspect).forEach(function (el) {
        desnaggeditemcountByAspectTower[el] = getdesnaggedItemGroupByAspect[el].length;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(desnaggeditemcountByAspectTower);

      const ordereddesnaggeditemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );

      desnaggeditemsByAspectTower[key] = ordereddesnaggeditemcountByAspectTower;

    });

    let lowerkeysDeSnagsObj = objectKeysToLowerCase(desnaggeditemsByAspectTower);

    const desnagsnrFinalObj = Object.keys(lowerkeysDeSnagsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysDeSnagsObj[key];
        return obj;
      },
      {}
    );


    //Desnagged items having status partially rectified
    let desnaggedpritemArr = [];
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {
      const itemAspectName = orderedgetItemGroupByTower[key];

      let searchbypr = "partially rectified";
      itemAspectName.filter(function (obj) {
        let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if ((serrchval.indexOf(searchbypr) != -1)) {
            //add this object to the filtered array
            desnaggedpritemArr.push(obj);
          }
        }
      });
    });


    //get partially rectified desnagged items by tower group
    const getDeSnaggedprItemGroupByTower = desnaggedpritemArr.reduce((getDeSnaggedprItemGroupByTower, item) => {
      const tower = (getDeSnaggedprItemGroupByTower[item.tower] || []);
      tower.push(item);
      getDeSnaggedprItemGroupByTower[item.tower] = tower;
      return getDeSnaggedprItemGroupByTower;
    }, {});

    //get count of partially rectified desnags by aspect for each tower        
    var desnaggeditemsprByAspectTower = {};
    Object.keys(getDeSnaggedprItemGroupByTower).forEach(function (key) {

      const getdesnaggedItemGroupByAspect = getDeSnaggedprItemGroupByTower[key].reduce((getdesnaggedItemGroupByAspect, item) => {

        const aspect = (getdesnaggedItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        getdesnaggedItemGroupByAspect[item.aspect] = aspect;
        return getdesnaggedItemGroupByAspect;
      }, {});

      var desnaggeditemcountByAspectTower = {};
      Object.keys(getdesnaggedItemGroupByAspect).forEach(function (el) {
        desnaggeditemcountByAspectTower[el] = getdesnaggedItemGroupByAspect[el].length;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(desnaggeditemcountByAspectTower);

      const ordereddesnaggeditemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );

      desnaggeditemsprByAspectTower[key] = ordereddesnaggeditemcountByAspectTower;

    });

    let lowerkeysDeSnagsprObj = objectKeysToLowerCase(desnaggeditemsprByAspectTower);

    const desnagsprFinalObj = Object.keys(lowerkeysDeSnagsprObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysDeSnagsprObj[key];
        return obj;
      },
      {}
    );


    var highprioritysnagsByAspectTower = {};
    Object.keys(getSnaggedItemGroupByTower).forEach(function (key) {

      const gethighpriorityItemGroupByAspect = getSnaggedItemGroupByTower[key].reduce((gethighpriorityItemGroupByAspect, item) => {

        const aspect = (gethighpriorityItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gethighpriorityItemGroupByAspect[item.aspect] = aspect;
        return gethighpriorityItemGroupByAspect;
      }, {});

      var highpriorityitemcountByAspectTower = {};
      Object.keys(gethighpriorityItemGroupByAspect).forEach(function (el) {
        let getAspectPriority = calSnagsPriority(el, gethighpriorityItemGroupByAspect);

        highpriorityitemcountByAspectTower[el] = getAspectPriority;
      });

      let objectKeysToLowerCase = function (origObj) {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key];
          let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
          newObj[key.toLowerCase()] = newVal;
          return newObj;
        }, {});
      }

      let lowerkeysObj = objectKeysToLowerCase(highpriorityitemcountByAspectTower);

      const orderedhighpriorityitemcountByAspectTower = Object.keys(lowerkeysObj).sort().reduce(
        (obj, key) => {
          obj[key] = lowerkeysObj[key];
          return obj;
        },
        {}
      );

      highprioritysnagsByAspectTower[key] = orderedhighpriorityitemcountByAspectTower;

    });


    let lowerkeysPriorityObj = objectKeysToLowerCase(highprioritysnagsByAspectTower);

    const snagsPriorityFinalObj = Object.keys(lowerkeysPriorityObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysPriorityObj[key];
        return obj;
      },
      {}
    );


    //Get data based on high and medium priority count
    function calSnagsPriority(type, aspectdata) {
      let highcount = 0;
      let lowcount = 0;
      let checklen;
      let checkaspect = aspectdata[type];
      checkaspect.forEach(item => {
        checklen = checkaspect.length;
        let checkSnagPriority = item.itemArtifactSet.snagging.snagPriority;
        if (checkSnagPriority == 'high') {
          highcount++;
        } else if (checkSnagPriority == 'low') {
          lowcount++;
        }
      });

      let mediumcount = checklen - (highcount + lowcount);

      let returnobj = { 'highpriority': highcount, 'mediumpriority': mediumcount }
      return returnobj;

    }

    //Get data based on section name by aspect and tower
    //Total Checkpoints
    var bySectionCheckpoints = {};
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {

      const gettotalItemGroupByAspect = orderedgetItemGroupByTower[key].reduce((gettotalItemGroupByAspect, item) => {

        const aspect = (gettotalItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gettotalItemGroupByAspect[item.aspect] = aspect;
        return gettotalItemGroupByAspect;
      }, {});


      var checkpointsbySectionName = {};
      Object.keys(gettotalItemGroupByAspect).forEach(function (key) {

        const getcheckpointCountBySection = gettotalItemGroupByAspect[key].reduce((getcheckpointCountBySection, item) => {

          const sectionName = (getcheckpointCountBySection[item.sectionName] || []);
          sectionName.push(item);
          getcheckpointCountBySection[item.sectionName] = sectionName;
          return getcheckpointCountBySection;
        }, {});

        var setionitemByAsepct = {};
        Object.keys(getcheckpointCountBySection).forEach(function (el) {

          setionitemByAsepct[el] = getcheckpointCountBySection[el].length;
        })

        var sortableitemBySection = [];
        for (var nameCount in setionitemByAsepct) {
          sortableitemBySection.push([nameCount, setionitemByAsepct[nameCount]]);
        }

        // sort section name based on descending order
        sortableitemBySection.sort(function (a, b) {
          return b[1] - a[1];
        });

        var sortableitemBySectionObj = sortableitemBySection.reduce(function (acc, cur, i) {
          acc[i] = cur;
          return acc;
        }, {});

        checkpointsbySectionName[key] = sortableitemBySectionObj;

      });

      bySectionCheckpoints[key] = checkpointsbySectionName;

    });


    let lowerkeysbysectioncheckpointsObj = objectKeysToLowerCase(bySectionCheckpoints);

    const byseccheckpointsFinalObj = Object.keys(lowerkeysbysectioncheckpointsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysbysectioncheckpointsObj[key];
        return obj;
      },
      {}
    );


    //Total Snags
    var bySectionSnags = {};
    Object.keys(getSnaggedItemGroupByTower).forEach(function (key) {

      const gettotalItemGroupByAspect = getSnaggedItemGroupByTower[key].reduce((gettotalItemGroupByAspect, item) => {

        const aspect = (gettotalItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gettotalItemGroupByAspect[item.aspect] = aspect;
        return gettotalItemGroupByAspect;
      }, {});


      var snagsbySectionName = {};
      Object.keys(gettotalItemGroupByAspect).forEach(function (key) {

        const getsnagsCountBySection = gettotalItemGroupByAspect[key].reduce((getsnagsCountBySection, item) => {

          const sectionName = (getsnagsCountBySection[item.sectionName] || []);
          sectionName.push(item);
          getsnagsCountBySection[item.sectionName] = sectionName;
          return getsnagsCountBySection;
        }, {});

        var setionitemByAsepct = {};
        Object.keys(getsnagsCountBySection).forEach(function (el) {

          setionitemByAsepct[el] = getsnagsCountBySection[el].length;
        })

        var sortableitemBySection = [];
        for (var nameCount in setionitemByAsepct) {
          sortableitemBySection.push([nameCount, setionitemByAsepct[nameCount]]);
        }

        // sort section name based on descending order
        sortableitemBySection.sort(function (a, b) {
          return b[1] - a[1];
        });

        var sortableitemBySectionObj = sortableitemBySection.reduce(function (acc, cur, i) {
          acc[i] = cur;
          return acc;
        }, {});

        snagsbySectionName[key] = sortableitemBySectionObj;

      });

      bySectionSnags[key] = snagsbySectionName;

    });

    let lowerkeysbysectionsnagsObj = objectKeysToLowerCase(bySectionSnags);

    const bysecsnagsFinalObj = Object.keys(lowerkeysbysectionsnagsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysbysectionsnagsObj[key];
        return obj;
      },
      {}
    );


    //By Sections Desnagged items having status partially rectified and not rectified
    let bysecdesnaggeditemArr = [];
    Object.keys(orderedgetItemGroupByTower).forEach(function (key) {
      const itemAspectName = orderedgetItemGroupByTower[key];

      let searchbypr = "partially rectified";
      let searchbynr = "not rectified";
      itemAspectName.filter(function (obj) {
        let serrchval = obj.desnaggingStatus;
        if (typeof obj.desnaggingStatus !== 'undefined') {
          if ((serrchval.indexOf(searchbypr) != -1) || (serrchval.indexOf(searchbynr) != -1)) {
            //add this object to the filtered array
            bysecdesnaggeditemArr.push(obj);
          }
        }
      });
    });


    //get partially rectified desnagged items by tower group
    const getbysecDeSnaggedprItemGroupByTower = bysecdesnaggeditemArr.reduce((getbysecDeSnaggedprItemGroupByTower, item) => {
      const tower = (getbysecDeSnaggedprItemGroupByTower[item.tower] || []);
      tower.push(item);
      getbysecDeSnaggedprItemGroupByTower[item.tower] = tower;
      return getbysecDeSnaggedprItemGroupByTower;
    }, {});

    //Total partially rectified De-Snags
    var bySectionDeSnags = {};
    Object.keys(getbysecDeSnaggedprItemGroupByTower).forEach(function (key) {

      const gettotalItemGroupByAspect = getbysecDeSnaggedprItemGroupByTower[key].reduce((gettotalItemGroupByAspect, item) => {

        const aspect = (gettotalItemGroupByAspect[item.aspect] || []);
        aspect.push(item);
        gettotalItemGroupByAspect[item.aspect] = aspect;
        return gettotalItemGroupByAspect;
      }, {});

      var desnagsbySectionName = {};
      Object.keys(gettotalItemGroupByAspect).forEach(function (key) {

        const getdesnagsCountBySection = gettotalItemGroupByAspect[key].reduce((getdesnagsCountBySection, item) => {

          const sectionName = (getdesnagsCountBySection[item.sectionName] || []);
          sectionName.push(item);
          getdesnagsCountBySection[item.sectionName] = sectionName;
          return getdesnagsCountBySection;
        }, {});

        var setionitemByAsepct = {};
        Object.keys(getdesnagsCountBySection).forEach(function (el) {

          setionitemByAsepct[el] = getdesnagsCountBySection[el].length;
        })

        var sortableitemBySection = [];
        for (var nameCount in setionitemByAsepct) {
          sortableitemBySection.push([nameCount, setionitemByAsepct[nameCount]]);
        }

        // sort section name based on descending order
        sortableitemBySection.sort(function (a, b) {
          return b[1] - a[1];
        });

        var sortableitemBySectionObj = sortableitemBySection.reduce(function (acc, cur, i) {
          acc[i] = cur;
          return acc;
        }, {});

        desnagsbySectionName[key] = sortableitemBySectionObj;

      });

      bySectionDeSnags[key] = desnagsbySectionName;

    });

    let lowerkeysbysectiondesnagsObj = objectKeysToLowerCase(bySectionDeSnags);

    const bysecdesnagsFinalObj = Object.keys(lowerkeysbysectiondesnagsObj).sort().reduce(
      (obj, key) => {
        obj[key] = lowerkeysbysectiondesnagsObj[key];
        return obj;
      },
      {}
    );

    const bysectionsnagsdesnagsObj = {};
    Object.assign(bysectionsnagsdesnagsObj, { aspectbyitemsectionname: { totalcheckpoints: byseccheckpointsFinalObj, totalsnags: bysecsnagsFinalObj, totaldesnags: bysecdesnagsFinalObj } });

    const summarydatabyaspect = {};
    Object.assign(summarydatabyaspect, { byaspectsummarydata: { totalcheckpoints: byaspectcheckpointsFinalObj, totalsnags: byaspectsnagsFinalObj, fixeddesnags: byaspectrcdesnagsFinalObj, prdesnags: byaspectprdesnagsFinalObj, nrdesnags: byaspectnrdesnagsFinalObj } });

    const aspectsnagdesnagbyTowerObj = {};
    Object.assign(aspectsnagdesnagbyTowerObj, { aspectsnagdesnag: { totalcheckpoints: checkpointsFinalObj, totalsnags: snagsFinalObj, totaldesnags: { desnagspr: desnagsprFinalObj, desnagsnr: desnagsnrFinalObj } } });

    const snagsbyprioritybyTowerObj = {};
    Object.assign(snagsbyprioritybyTowerObj, { snagsbypriority: snagsPriorityFinalObj });

    finalResltArr.push(bysectionsnagsdesnagsObj);
    finalResltArr.push(summarydatabyaspect);
    finalResltArr.push(aspectsnagdesnagbyTowerObj);
    finalResltArr.push(snagsbyprioritybyTowerObj);

    this.showB2BSummary(finalResltArr);
  }

  public showB2BSummary(dataObj) {
    var chart;
    var backgroundColor = ['rgb(244, 109, 67)', 'rgb(50, 136, 189)', 'rgb(171, 221, 164)', 'rgb(102, 194, 165)', 'rgb(230, 245, 152)', 'rgb(230, 245, 152)', 'rgb(253, 174, 97)', 'rgb(244, 109, 67)', '#2bd9b1', '#caa5c5', '#e88275', '#d0bdf4', '#ecf069', '#c8ff10', '#fe104a', '#A2231', '#B5B8B1', '#316650', '#3F888F', '#A98307'];
    var prDesnagsKeys = [];
    var prDesnagsValues = [];
    var nrDesnagsKeys = [];
    var nrDesnagsValues = [];
    var summaryAreaAspects = [];
    var summaryArea1ValuesF = [];

    var legendLabelTS = [];
    var snTowerIndv = [];
    var snTower = [];
    var legendLabelS = [];
    // var highTowDataInd = [];
    // var highTowData = [];

    // var arrPr = [];
    // var highLabel = [];
    // var medTowData = [];
    // var medLabel = [];
    // var medTowDataInd = [];
    // var legendLabelP = [];
    // var legendPr;

    var radarTitle = [];
    var legendLabelSect = [];
    var towAspects;
    var towAspectsCaps = [];
    var sect = [];
    var snData = [];
    var legendLabelSe = [];
    var snDataSect = [];
    var dsDataSect = [];
    var dsData = [];

    console.log(chart);

    function splitArrayIntoChunksOfLen(arr, len) {
      var chunks = [], i = 0, n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, i += len));
      }
      return chunks;
    }

    function sortArray(arr1, arr2) {
      let zipped = arr1.map((x, i) => [x, arr2[i]]);
      zipped.sort((a, b) => b[0] - a[0]);

      return [zipped.map(x => x[0]), zipped.map(x => x[1])];
    }

    dataObj.map((data) => {
      if (data.byaspectsummarydata) {
        if (data.byaspectsummarydata.totalcheckpoints) {
          if (Object.keys(data.byaspectsummarydata.totalcheckpoints).length == 0) {
            this.checkPointAvl = false;
          } else {
            this.checkPointAvl = true;
          }
          this.totalCheckPointsKeys = [];
          this.totalCheckPointsValues = [];
          Object.keys(data.byaspectsummarydata.totalcheckpoints).forEach(key => {
            this.totalCheckPointsKeys.push(key);
            this.totalCheckPointsValues.push(data.byaspectsummarydata.totalcheckpoints[key]);
          })

          this.checkpointsCount = this.totalCheckPointsValues.pop();

          this.totalCheckPointsKeys.pop();
          this.totalCheckPointsValues.pop();

          document.getElementById("chart1").innerHTML = '<canvas id="dougnutChart1"></canvas><span style="position:absolute;top:50%;left:49%;">' + this.totalCheckPointsValues.reduce(function (a, b) { return a + b; }, 0) + '</span>';
          document.getElementById("newchart1").innerHTML = '<canvas id="wingacheckpoints"></canvas>';
          document.getElementById("newchart2").innerHTML = '<canvas id="wingbcheckpoints"></canvas>';
          document.getElementById("newchart3").innerHTML = '<canvas id="wingccheckpoints"></canvas>';
          document.getElementById("newchart14").innerHTML = '<canvas id="wingafixes"></canvas>';
          document.getElementById("newchart15").innerHTML = '<canvas id="wingbfixes"></canvas>';
          document.getElementById("newchart16").innerHTML = '<canvas id="wingcfixes"></canvas>';
          if (!this.wingSeries) {
            document.getElementById("newchart11").innerHTML = '<canvas id="seriesdcheckpoints"></canvas>';
          }


          const checkPointsSortedArray = sortArray(this.totalCheckPointsValues, this.totalCheckPointsKeys);

          this.checkPointsKeys = checkPointsSortedArray[1];
          this.checkPointsValues = checkPointsSortedArray[0];

          // total checkpoint doughnut chart
          chart = new Chart('dougnutChart1', {
            type: 'doughnut',
            data: {
              labels: checkPointsSortedArray[1],
              datasets: [{
                data: checkPointsSortedArray[0],
                backgroundColor: backgroundColor
              }]
            },
            options: {
              responsive: true,
              legend: {
                position: 'bottom',
                labels: {
                  padding: 10,
                  boxWidth: 10,
                  fontSize: 15,
                  fontColor: 'black'
                }
              }
            }
          });

          const wingACPSortedArray = sortArray(this.wingAItemValues, this.wingAItemKeys);

          this.checkPointsAKeys = wingACPSortedArray[1];
          this.checkPointsAValues = wingACPSortedArray[0];

          chart = new Chart('wingacheckpoints', {
            type: 'bar',
            data: {
              labels: wingACPSortedArray[1],
              datasets: [{
                data: wingACPSortedArray[0],
                backgroundColor: backgroundColor,
              }
              ]
            },
            options: {
              // scales: {
              //   yAxes: [{
              //     ticks: {
              //       beginAtZero: true,
              //       stepSize: 1
              //     }
              //   }],
              //   xAxes: [{
              //     ticks: {
              //       autoSkip: false,
              //       maxRotation: 90,
              //       minRotation: 90
              //     }
              //   }]
              // },
              legend: {
                display: false
              },
              plugins: {
                datalabels: {
                  anchor: 'end',
                  align: 'top'
                },
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });

          const wingBCPSortedArray = sortArray(this.wingBItemValues, this.wingBItemKeys);

          this.checkPointsBKeys = wingBCPSortedArray[1];
          this.checkPointsBValues = wingBCPSortedArray[0];

          chart = new Chart('wingbcheckpoints', {
            type: 'bar',
            data: {
              labels: wingBCPSortedArray[1],
              datasets: [{
                data: wingBCPSortedArray[0],
                backgroundColor: backgroundColor,
              }
              ]
            },
            options: {
              // scales: {
              //   yAxes: [{
              //     ticks: {
              //       beginAtZero: true,
              //       stepSize: 1
              //     }
              //   }],
              //   xAxes: [{
              //     ticks: {
              //       autoSkip: false,
              //       maxRotation: 90,
              //       minRotation: 90
              //     }
              //   }]
              // },
              legend: {
                display: false
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });

          const wingCCPSortedArray = sortArray(this.wingCItemValues, this.wingCItemKeys);

          this.checkPointsCKeys = wingCCPSortedArray[1];
          this.checkPointsCValues = wingCCPSortedArray[0];

          chart = new Chart('wingccheckpoints', {
            type: 'bar',
            data: {
              labels: wingCCPSortedArray[1],
              datasets: [{
                data: wingCCPSortedArray[0],
                backgroundColor: backgroundColor,
              }
              ]
            },
            options: {
              // scales: {
              //   yAxes: [{
              //     ticks: {
              //       beginAtZero: true,
              //       stepSize: 1
              //     }
              //   }],
              //   xAxes: [{
              //     ticks: {
              //       autoSkip: false,
              //       maxRotation: 90,
              //       minRotation: 90
              //     }
              //   }]
              // },
              legend: {
                display: false
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });
          chart = new Chart('seriesdcheckpoints', {
            type: 'bar',
            data: {
              labels: this.seriesDItemKeys,
              datasets: [{
                data: this.seriesDItemValues,
                backgroundColor: backgroundColor,
              }
              ]
            },
            options: {
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                  }
                }],
                xAxes: [{
                  ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                  }
                }]
              },
              legend: {
                display: false
              },
              plugins: {
                datalabels: {
                  anchor: 'end',
                  align: 'top'
                },
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });

          chart = new Chart('wingafixes', {
            type: 'bar',
            data: {
              labels: this.wingACFKeys,
              datasets: [
                {
                  label: 'Cant fix',
                  backgroundColor: 'rgb(250, 128, 114)',
                  data: this.wingACFValues
                },
                {
                  label: 'Fixed',
                  backgroundColor: 'rgb(171, 221, 164)',
                  data: this.wingAFixedValues
                },
                {
                  label: 'Not a Snag',
                  backgroundColor: 'rgba(242, 202, 133, 0.5)',
                  data: this.wingANASValues
                }
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  stacked: false
                }],
                yAxes: [{
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                  }
                }]
              },
              responsive: true,
              legend: {
                display: true,
                position: 'bottom'
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });

          chart = new Chart('wingbfixes', {
            type: 'bar',
            data: {
              labels: this.wingBCFKeys,
              datasets: [
                {
                  label: 'Cant fix',
                  backgroundColor: 'rgb(250, 128, 114)',
                  data: this.wingBCFValues
                },
                {
                  label: 'Fixed',
                  backgroundColor: 'rgb(171, 221, 164)',
                  data: this.wingBFixedValues
                },
                {
                  label: 'Not a Snag',
                  backgroundColor: 'rgba(242, 202, 133, 0.5)',
                  data: this.wingBNASValues
                }
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  stacked: false
                }],
                yAxes: [{
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                  }
                }]
              },
              responsive: true,
              legend: {
                display: true,
                position: 'bottom'
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });

          chart = new Chart('wingcfixes', {
            type: 'bar',
            data: {
              labels: this.wingCCFKeys,
              datasets: [
                {
                  label: 'Cant fix',
                  backgroundColor: 'rgb(250, 128, 114)',
                  data: this.wingCCFValues
                },
                {
                  label: 'Fixed',
                  backgroundColor: 'rgb(171, 221, 164)',
                  data: this.wingCFixedValues
                },
                {
                  label: 'Not a Snag',
                  backgroundColor: 'rgba(242, 202, 133, 0.5)',
                  data: this.wingCNASValues
                }
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  stacked: false
                }],
                yAxes: [{
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                  }
                }]
              },
              responsive: true,
              legend: {
                display: true,
                position: 'bottom'
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });
        }
        if (data.byaspectsummarydata.prdesnags) {
          if (Object.keys(data.byaspectsummarydata.prdesnags).length == 0) {
            this.prDesnags = false;
          } else {
            this.prDesnags = true;
          }
          Object.keys(data.byaspectsummarydata.prdesnags).forEach(key => {
            prDesnagsKeys.push(key);
            prDesnagsValues.push(data.byaspectsummarydata.prdesnags[key]);
          })
        }
        if (data.byaspectsummarydata.nrdesnags) {
          if (Object.keys(data.byaspectsummarydata.nrdesnags).length == 0) {
            this.nrDesnags = false;
          } else {
            this.nrDesnags = true;
          }
          Object.keys(data.byaspectsummarydata.nrdesnags).forEach(key => {
            nrDesnagsKeys.push(key);
            nrDesnagsValues.push(data.byaspectsummarydata.nrdesnags[key]);
          })

          prDesnagsKeys.sort();
          nrDesnagsKeys.sort();

          summaryAreaAspects = prDesnagsKeys;
          for (var i = 0; i < nrDesnagsKeys.length; i++) {
            if (!summaryAreaAspects.includes(nrDesnagsKeys[i])) {
              summaryAreaAspects.push(nrDesnagsKeys[i]);
            }
          }

          //aspects for partially rectified in area chart
          for (var e = 0; e < summaryAreaAspects.length; e++) {
            for (var f = 0; f < prDesnagsKeys.length; f++) {
              if (prDesnagsKeys[f] == summaryAreaAspects[e]) {
                summaryArea1ValuesF.push(prDesnagsValues[f]);
                e++;
              }
              else {
                summaryArea1ValuesF.push('');
                e++;
                f--;
              }
            }
          }

          if (this.prDesnags || this.nrDesnags) {
            document.getElementById("chart4").innerHTML = '<canvas id="areachart"></canvas>';
          } else {
            document.getElementById("chart4").innerHTML = '<div  style="text-align: center;"><img style="height: 40vh;" src="../../assets/images/nodata.svg"></div>';
            document.getElementById("newchart7").innerHTML = '<canvas id="wingadesnags"></canvas>';
            document.getElementById("newchart8").innerHTML = '<canvas id="wingbdesnags"></canvas>';
            document.getElementById("newchart9").innerHTML = '<canvas id="wingcdesnags"></canvas>';
            if (!this.wingSeries) {
              document.getElementById("newchart13").innerHTML = '<canvas id="seriesddesnags"></canvas>';
            }
          }

          document.getElementById("chart17").innerHTML = '<canvas id="stageChart"></canvas>';

          chart = new Chart('areachart', {
            type: 'bar',
            data: {
              labels: summaryAreaAspects,
              datasets: [
                {
                  label: 'Partially Rectified',
                  backgroundColor: 'rgb(253, 174, 97)',
                  data: prDesnagsValues,
                },
                {
                  label: 'Not Rectified',
                  backgroundColor: 'rgb(102, 194, 165)',
                  data: nrDesnagsValues,
                }
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  stacked: false,
                  ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                }
                }],
                yAxes: [{
                  stacked: false
                }]
              },
              responsive: true,
              legend: {
                display: true,
                position: 'bottom'
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            },
          });

          chart = new Chart('wingadesnags', {
            type: 'bar',
            data: {
              labels: this.wingAPRDeSnagsKeys,
              datasets: [
                {
                  label: 'Partially Rectified',
                  backgroundColor: 'rgba(242, 202, 133, 0.5)',
                  data: this.wingAPRDeSnagsValues
                },
                {
                  label: 'Rectified',
                  backgroundColor: 'rgb(171, 221, 164)',
                  data: this.wingARDeSnagsValues
                },
                {
                  label: 'Not Rectified',
                  backgroundColor: 'rgb(250, 128, 114)',
                  data: this.wingANRDeSnagsValues
                }
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  stacked: false
                }],
                yAxes: [{
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                  }
                }]
              },
              responsive: true,
              legend: {
                display: true,
                position: 'bottom'
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });

          chart = new Chart('wingbdesnags', {
            type: 'bar',
            data: {
              labels: this.wingBPRDeSnagsKeys,
              datasets: [
                {
                  label: 'Partially Rectified',
                  backgroundColor: 'rgba(242, 202, 133, 0.5)',
                  data: this.wingBPRDeSnagsValues
                },
                {
                  label: 'Rectified',
                  backgroundColor: 'rgb(171, 221, 164)',
                  data: this.wingBRDeSnagsValues
                },
                {
                  label: 'Not Rectified',
                  backgroundColor: 'rgb(250, 128, 114)',
                  data: this.wingBNRDeSnagsValues
                }
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  stacked: false
                }],
                yAxes: [{
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                  }
                }]
              },
              responsive: true,
              legend: {
                display: true,
                position: 'bottom'
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });

          chart = new Chart('wingcdesnags', {
            type: 'bar',
            data: {
              labels: this.wingCPRDeSnagsKeys,
              datasets: [
                {
                  label: 'Partially Rectified',
                  backgroundColor: 'rgba(242, 202, 133, 0.5)',
                  data: this.wingCPRDeSnagsValues
                },
                {
                  label: 'Rectified',
                  backgroundColor: 'rgb(171, 221, 164)',
                  data: this.wingCRDeSnagsValues
                },
                {
                  label: 'Not Rectified',
                  backgroundColor: 'rgb(250, 128, 114)',
                  data: this.wingCNRDeSnagsValues
                }
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  stacked: false
                }],
                yAxes: [{
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                  }
                }]
              },
              responsive: true,
              legend: {
                display: true,
                position: 'bottom'
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: 0
                }
              }
            }
          });

          chart = new Chart('seriesddesnags', {
            type: 'bar',
            data: {
              labels: this.seriesDPRDeSnagsKeys,
              datasets: [
                {
                  label: 'Partially Rectified',
                  backgroundColor: 'rgba(242, 202, 133, 0.5)',
                  data: this.seriesDPRDeSnagsValues
                },
                {
                  label: 'Rectified',
                  backgroundColor: 'rgb(171, 221, 164)',
                  data: this.seriesDRDeSnagsValues
                },
                {
                  label: 'Not Rectified',
                  backgroundColor: 'rgb(250, 128, 114)',
                  data: this.seriesDNRDeSnagsValues
                }
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  stacked: false
                }],
                yAxes: [{
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                  }
                }]
              },
              responsive: true,
              legend: {
                display: true,
                position: 'bottom'
              },
              plugins: {
                labels: {
                  render: () => { }
                }
              }
            }
          });
        }

        chart = new Chart('wingwiseaspects', {
          type: 'bar',
          data: {
            labels: this.wingASnagsKeys,
            datasets: [
              {
                label: this.wingSeries ? 'Wing A' : 'Series A',
                backgroundColor: 'rgba(242, 202, 133, 0.5)',
                data: this.wingASnagsValues
              },
              {
                label: this.wingSeries ? 'Wing B' : 'Series B',
                backgroundColor: 'rgb(250, 128, 114)',
                data: this.wingBSnagsValues
              },
              {
                label: this.wingSeries ? 'Wing C' : 'Series C',
                backgroundColor: 'rgb(171, 221, 164)',
                data: this.wingCSnagsValues
              }
            ],
          },
          options: {
            responsive: true,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  stepSize: 1
                }
              }]
            },
            legend: {
              display: true,
              position: 'bottom'
            },
            plugins: {
              labels: {
                showZero: false,
                render: 'value',
                fontColor: ['black'],
                textMargin: -5
              }
            }
          },
        });

        chart = new Chart('stageChart', {
          type: 'bar',
          data: {
            labels: this.itemSnagsKeys,
            datasets: [
              {
                label: 'Snag',
                backgroundColor: 'rgba(242, 202, 133, 0.5)',
                data: this.itemSnagsValues
              },
              {
                label: 'Fixing',
                backgroundColor: 'rgb(250, 128, 114)',
                data: this.itemFixingValues
              },
              {
                label: 'Desnag',
                backgroundColor: 'rgb(171, 221, 164)',
                data: this.itemDesnagsValues
              }
            ],
          },
          options: {
            responsive: true,
            legend: {
              display: true,
              position: 'bottom'
            },
            scales: {
              xAxes: [{
                stacked: false,
                ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
              }
            }]
            },
            plugins: {
              labels: {
                showZero: false,
                render: 'value',
                fontColor: ['black'],
                textMargin: 0
              }
            }
          }
        });

        if (data.byaspectsummarydata.totalsnags) {
          if (Object.keys(data.byaspectsummarydata.totalsnags).length == 0) {
            this.snagsAvl = false;
          } else {
            this.snagsAvl = true;
          }
          this.totalSnagsKeys = [];
          this.totalSnagsValues = [];
          Object.keys(data.byaspectsummarydata.totalsnags).forEach(key => {
            this.totalSnagsKeys.push(key);
            this.totalSnagsValues.push(data.byaspectsummarydata.totalsnags[key]);
          })

          var snagCopyArray = this.totalSnagsValues.slice();
          this.snagsCount = snagCopyArray.pop();

          this.totalSnagsKeys.pop();
          this.totalSnagsValues.pop();

          if (this.snagsAvl) {
            document.getElementById("chart2").innerHTML = '<canvas id="dougnutChart2"></canvas><span style="position:absolute;top:50%;left:49%;">' + this.totalSnagsValues.reduce(function (a, b) { return a + b; }, 0) + '</span>';
          } else {
            document.getElementById("chart2").innerHTML = '<div style="text-align: center;"><img style="height: 40vh;" src="../../assets/images/nodata.svg"></div>';
            document.getElementById("newchart4").innerHTML = '<canvas id="wingasnags"></canvas>';
            document.getElementById("newchart5").innerHTML = '<canvas id="wingbsnags"></canvas>';
            document.getElementById("newchart6").innerHTML = '<canvas id="wingcsnags"></canvas>';
            if (!this.wingSeries) {
              document.getElementById("newchart12").innerHTML = '<canvas id="seriesdsnags"></canvas>';
            }
          }

          const snagsSortedArray = sortArray(this.totalSnagsValues, this.totalSnagsKeys);

          this.snagKeys = snagsSortedArray[1];
          this.snagValues = snagsSortedArray[0]

          // total snags doughnut chart
          chart = new Chart('dougnutChart2', {
            type: 'doughnut',
            data: {
              labels: snagsSortedArray[1],
              datasets: [{
                data: snagsSortedArray[0],
                backgroundColor: backgroundColor
              }]
            },
            options: {
              responsive: true,
              legend: {
                position: 'bottom',
                labels: {
                  padding: 10,
                  boxWidth: 10,
                  fontSize: 15,
                  fontColor: 'black'
                }
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: -5
                }
              }
            }
          });

          const wingASnagsSortedArray = sortArray(this.wingASnagsValues, this.wingASnagsKeys);

          this.snagAKeys = wingASnagsSortedArray[1];
          this.snagAValues = wingASnagsSortedArray[0];

          chart = new Chart('wingasnags', {
            type: 'bar',
            data: {
              labels: wingASnagsSortedArray[1],
              datasets: [{
                data: wingASnagsSortedArray[0],
                backgroundColor: backgroundColor,
              }
              ]
            },
            options: {
              // scales: {
              //   yAxes: [{
              //     ticks: {
              //       beginAtZero: true
              //     }
              //   }],
              //   xAxes: [{
              //     ticks: {
              //       autoSkip: false,
              //       maxRotation: 90,
              //       minRotation: 90
              //     }
              //   }]
              // },
              legend: {
                display: false
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: -5
                }
              }
            }
          });

          const wingBSnagsSortedArray = sortArray(this.wingBSnagsValues, this.wingBSnagsKeys);

          this.snagBKeys = wingBSnagsSortedArray[1];
          this.snagBValues = wingBSnagsSortedArray[0];

          chart = new Chart('wingbsnags', {
            type: 'bar',
            data: {
              labels: wingBSnagsSortedArray[1],
              datasets: [{
                data: wingBSnagsSortedArray[0],
                backgroundColor: backgroundColor,
              }
              ]
            },
            options: {
              // scales: {
              //   yAxes: [{
              //     ticks: {
              //       beginAtZero: true
              //     }
              //   }],
              //   xAxes: [{
              //     ticks: {
              //       autoSkip: false,
              //       maxRotation: 90,
              //       minRotation: 90
              //     }
              //   }]
              // },
              legend: {
                display: false
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: -5
                }
              }
            }
          });

          const wingCSnagsSortedArray = sortArray(this.wingCSnagsValues, this.wingCSnagsKeys);

          this.snagCKeys = wingCSnagsSortedArray[1];
          this.snagCValues = wingCSnagsSortedArray[0];

          chart = new Chart('wingcsnags', {
            type: 'bar',
            data: {
              labels: wingCSnagsSortedArray[1],
              datasets: [{
                data: wingCSnagsSortedArray[0],
                backgroundColor: backgroundColor,
              }
              ]
            },
            options: {
              // scales: {
              //   yAxes: [{
              //     ticks: {
              //       beginAtZero: true
              //     }
              //   }],
              //   xAxes: [{
              //     ticks: {
              //       autoSkip: false,
              //       maxRotation: 90,
              //       minRotation: 90
              //     }
              //   }]
              // },
              legend: {
                display: false
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: -5
                }
              }
            }
          });

          const seriesDnagsSortedArray = sortArray(this.seriesDSnagsValues, this.seriesDSnagsKeys);

          this.snagDKeys = seriesDnagsSortedArray[1];
          this.snagDValues = seriesDnagsSortedArray[0];

          chart = new Chart('seriesdsnags', {
            type: 'bar',
            data: {
              labels: seriesDnagsSortedArray[1],
              datasets: [{
                data: seriesDnagsSortedArray[0],
                backgroundColor: backgroundColor,
              }
              ]
            },
            options: {
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }],
                xAxes: [{
                  ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                  }
                }]
              },
              legend: {
                display: false
              },
              plugins: {
                labels: {
                  showZero: false,
                  render: 'value',
                  fontColor: ['black'],
                  textMargin: -5
                }
              }
            }
          });
        }
    
        if (data.byaspectsummarydata.fixeddesnags) {

          if (Object.keys(data.byaspectsummarydata.fixeddesnags).length == 0) {
            this.fixedSnags = false;
          } else {
            this.fixedSnags = true;
          }
          this.totalSnagsFixedKeys = [];
          this.totalSnagsFixedValues = [];
          Object.keys(data.byaspectsummarydata.fixeddesnags).forEach(key => {
            this.totalSnagsFixedKeys.push(key);
            this.totalSnagsFixedValues.push(data.byaspectsummarydata.fixeddesnags[key]);
          })

          this.totalSnagsFixedKeys.pop();
          this.totalSnagsFixedValues.pop();

          if (this.fixedSnags) {
            document.getElementById("chart3").innerHTML = '<canvas id="dougnutChart3"></canvas><span style="position:absolute;top:50%;left:49%;">' + this.totalSnagsFixedValues.reduce(function (a, b) { return a + b; }, 0) + '</span>';
          } else {
            document.getElementById("chart3").innerHTML = '<div  style="text-align: center;"><img style="height: 40vh;" src="../../assets/images/nodata.svg"></div>';
          }

          const fixedSnagsSortedArray = sortArray(this.totalSnagsFixedValues, this.totalSnagsFixedKeys);
          
          // total fixed snags doughnut chart
          chart = new Chart('dougnutChart3', {
            type: 'doughnut',
            data: {
              labels: fixedSnagsSortedArray[1],
              datasets: [{
                data: fixedSnagsSortedArray[0],
                backgroundColor: backgroundColor
              }]
            },
            options: {
              responsive: true,
              legend: {
                position: 'bottom',
                labels: {
                  padding: 10,
                  boxWidth: 10,
                  fontSize: 15,
                  fontColor: 'black'
                }
              }
            }
          });
        }
      }
      if (data.aspectsnagdesnag) {
        if (data.aspectsnagdesnag.totalcheckpoints) {

          if (Object.keys(data.aspectsnagdesnag.totalsnags).length == 0) {
            this.snagsByTowers = false;
          } else {
            this.snagsByTowers = true;
          }

          //Snags Identified by Towers chart configurations    
          var arr = [];

          Object.keys(data.aspectsnagdesnag.totalsnags).forEach(tkey => {//num of towers
            const towrarr = tkey.split(" ");
            for (var i = 0; i < towrarr.length; i++) {
              towrarr[i] = towrarr[i].charAt(0).toUpperCase() + towrarr[i].slice(1);
            }
            legendLabelTS.push(towrarr.join(" "));
          });

          var towerValArr = [];
          Object.keys(data.aspectsnagdesnag.totalcheckpoints).forEach(tkey => {//num of towers
            var towerVal = data.aspectsnagdesnag.totalcheckpoints[tkey];
            towerValArr.push(towerVal);
            Object.keys(data.aspectsnagdesnag.totalcheckpoints[tkey]).forEach(akey => {//num of aspects
              var legends = akey;
              if (!legendLabelS.includes(legends)) {
                legendLabelS.push(legends);
              }
            })
          });

          legendLabelS.sort();

          for (var x = 0; x < towerValArr.length; x++) {
            for (var y = 0; y < legendLabelS.length; y++) {
              var asp = Object.keys(towerValArr[x])[y];
              if (legendLabelS[y] == asp) {
                snTower.push(Object.values(towerValArr[x])[y]);
              } else {
                snTower.push('');
              }
            }
          }

          var aspectValPairs = splitArrayIntoChunksOfLen(snTower, legendLabelS.length);
          aspectValPairs.forEach((aspectVal) => {
            snTowerIndv.push(aspectVal);
          });

          for (var j = 0; j < legendLabelTS.length; j++) {
            arr.push(
              {
                label: legendLabelTS[j],
                backgroundColor: backgroundColor,
                data: snTowerIndv[j],
              },
            );
          }

          // if (this.fixedSnags) {
          //   document.getElementById("chart5").innerHTML = '<canvas id="barchart1"></canvas>';
          // } else {
          //   document.getElementById("chart5").innerHTML = '<div style="text-align: center;"><img style="height: 40vh;" src="../../assets/images/nodata.svg"></div>';
          // }

          // chart = new Chart('barchart1', {
          //   type: 'bar',
          //   data: {
          //     labels: legendLabelS,
          //     datasets: arr
          //   },
          //   options: {
          //     title: {
          //       display: true,
          //       fontSize: 11,
          //     },
          //     tooltips: {
          //       mode: 'index',
          //       intersect: true,
          //     },
          //     responsive: true,
          //     scales: {
          //       xAxes: [{
          //         ticks: {
          //           fontSize: 10,
          //         }
          //       }],
          //       yAxes: [
          //         {
          //           id: 'y',
          //           type: 'linear',
          //           display: true,
          //           position: 'left',
          //           scaleLabel: {
          //             display: true,
          //             labelString: 'Snags - Bar Chart',
          //             fontSize: 10
          //           }
          //         }
          //       ],
          //     },
          //     legend: {
          //       position: 'bottom',
          //       labels: {
          //         padding: 5,
          //         boxWidth: 10,
          //         fontSize: 10,
          //       }
          //     },
          //     plugins: {
          //       labels: {
          //         render: () => { }
          //       }
          //     }
          //   },
          // });
        }
        if (data.aspectsnagdesnag.totaldesnags) {
          var arrD = [];
          var legendLabelTD = [];
          var dsTowerIndv = [];
          var dsTower = [];
          var desnagArr = [];
          var aspectsArr2 = [];
          var allZero = [];
          var dsTowerIndvN = [];
          var dsTowerN = [];
          var desnagNRArr = [];
          var aspectsArr3 = [];

          Object.keys(data.aspectsnagdesnag.totaldesnags).forEach(skey => {//num of desnags
            if (skey == 'desnagspr') {//partially rectified desnags
              Object.keys(data.aspectsnagdesnag.totaldesnags[skey]).forEach(tkey => {//num of tower
                const towrarr = tkey.split(" ");
                for (var i = 0; i < towrarr.length; i++) {
                  towrarr[i] = towrarr[i].charAt(0).toUpperCase() + towrarr[i].slice(1);
                }
                legendLabelTD.push('PR:' + towrarr.join(" "));
              });


              Object.keys(data.aspectsnagdesnag.totaldesnags[skey]).forEach(tkey => {//towers
                var desnagObj = data.aspectsnagdesnag.totaldesnags[skey][tkey];
                desnagNRArr.push(desnagObj);
                Object.keys(data.aspectsnagdesnag.totaldesnags[skey][tkey]).forEach(akey => {//aspects
                  var aspe = akey;
                  if (!aspectsArr3.includes(aspe)) {
                    aspectsArr3.push(aspe);
                  }
                });
                aspectsArr3.sort();
              });

              for (var x = 0; x < desnagNRArr.length; x++) {
                for (var y = 0; y < legendLabelS.length; y++) {
                  var asp = Object.keys(desnagNRArr[x])[y];
                  if (legendLabelS[y] == asp) {
                    dsTowerN.push(Object.values(desnagNRArr[x])[y]);
                  } else {
                    dsTowerN.push('');
                  }
                }
              }

              var aspectValPairs = splitArrayIntoChunksOfLen(dsTowerN, legendLabelS.length);
              aspectValPairs.forEach((aspectVal) => {
                dsTowerIndvN.push(aspectVal);
              });

              var desnagArr = [];
              Object.keys(data.aspectsnagdesnag.totaldesnags[skey]).forEach(tkey => {//towers
                var desnagObj = data.aspectsnagdesnag.totaldesnags[skey][tkey];
                desnagArr.push(desnagObj);
                Object.keys(data.aspectsnagdesnag.totaldesnags[skey][tkey]).forEach(akey => {//aspects
                  var aspe = akey;
                  if (!aspectsArr2.includes(aspe)) {
                    aspectsArr2.push(aspe);
                  }
                });
                aspectsArr2.sort();
              });

              for (var x = 0; x < desnagArr.length; x++) {
                for (var y = 0; y < legendLabelS.length; y++) {
                  var asp = Object.keys(desnagArr[x])[y];
                  if (legendLabelS[y] == asp) {
                    dsTower.push(Object.values(desnagArr[x])[y]);
                  } else {
                    dsTower.push('');
                  }
                }
              }


              var aspectValPairs = splitArrayIntoChunksOfLen(dsTower, legendLabelS.length);
              aspectValPairs.forEach((aspectVal) => {
                dsTowerIndv.push(aspectVal);
              });

              dsTower = [];

              for (var t = 0; t < desnagArr.length; t++) {//towers
                if (dsTowerIndv[t].every(item => item !== 0) && dsTowerIndv.length != 0) {
                  allZero.push(false);
                }//end if
                else {
                  allZero.push(true);
                }//end else
              }
            }
          });

          for (var j = 0; j < legendLabelTD.length; j++) {
            arrD.push(
              {
                label: legendLabelTD[j],
                backgroundColor: backgroundColor,
                stack: 'Stack ' + j,
                data: dsTowerIndv[j],
              },
            );
          }

          for (var x = 0; x < desnagArr.length; x++) {
            for (var y = 0; y < legendLabelS.length; y++) {
              var asp = Object.keys(desnagArr[x])[y];
              if (legendLabelS[y] == asp) {
                dsTower.push(Object.values(desnagArr[x])[y]);
              } else {
                dsTower.push('');
              }
            }
          }

          var aspectValPairs = splitArrayIntoChunksOfLen(dsTower, legendLabelS.length);
          aspectValPairs.forEach((aspectVal) => {
            dsTowerIndv.push(aspectVal);
          });

          // if(arrD.length != 0) {
          //   document.getElementById("chart6").innerHTML = '<canvas id="barchart2"></canvas>';
          // } else {
          //   document.getElementById("chart6").innerHTML = '<div style="text-align: center;"><img style="height: 40vh;" src="../../assets/images/nodata.svg"></div>';
          // }

          //  chart = new Chart('barchart2', {
          //    type: 'bar',
          //    data: {
          //      labels: legendLabelS,
          //      datasets: arrD
          //    },
          //    options: {
          //      title: {
          //        display: true,
          //        fontSize: 11,
          //      },
          //      tooltips: {
          //        mode: 'index',
          //        intersect: false,
          //      },
          //      responsive: true,
          //      scales: {
          //        xAxes: [{
          //          ticks: {
          //            fontSize: 10,
          //          }
          //        }],
          //        yAxes: [
          //          {
          //            id: 'y',
          //            type: 'linear',
          //            display: true,
          //            position: 'left',
          //            scaleLabel: {
          //              display: true,
          //              labelString: 'Partially & Not Rectified Desnags - Stacked Bar',
          //              fontSize: 10
          //            }
          //          }
          //        ],
          //      },
          //      legend: {
          //        position: 'bottom',
          //        labels: {
          //          padding: 5,
          //          boxWidth: 10,
          //          fontSize: 10,
          //        }
          //      }
          //    },
          //  });
        }
      }
      // if(data.snagsbypriority) {
      //   var towerValArr = [];
      //   Object.keys(data.snagsbypriority).forEach(tkey => {//num of towers
      //     var towerVal = data.snagsbypriority[tkey];
      //     towerValArr.push(towerVal);
      //     Object.keys(data.snagsbypriority[tkey]).forEach(akey => {//num of aspects
      //       legendPr = akey;
      //       if (legendPr != 'undefined') {
      //         if (!legendLabelP.includes(legendPr)) {
      //           legendLabelP.push(legendPr);
      //         }
      //       }
      //     });
      //   });
      //   legendLabelP.sort();

      //   for (var x = 0; x < towerValArr.length; x++) {
      //     for (var y = 0; y < legendLabelS.length; y++) {
      //       var asc = Object.keys(towerValArr[x])[y];
      //       if (legendLabelS[y] == asc) {
      //         highTowData.push(Object.values(towerValArr[x])[y].highpriority);
      //         medTowData.push(Object.values(towerValArr[x])[y].mediumpriority);
      //       } else {
      //         highTowData.push(0);
      //         medTowData.push(0);
      //       }
      //     }
      //   }

      //   var medValPairs = splitArrayIntoChunksOfLen(medTowData, legendLabelS.length);
      //   medValPairs.forEach((medVal) => {
      //     medTowDataInd.push(medVal);
      //   });

      //   for (var t = 0; t < towerValArr.length; t++) {//towers
      //     if (medTowDataInd[t].every(item => item !== 0) && medTowDataInd.length != 0) {
      //       allZero.push(false);
      //       Object.keys(data.snagsbypriority).forEach(tkey => {//num of towers
      //         const towrarr = tkey.split(" ");
      //         for (var i = 0; i < towrarr.length; i++) {
      //           towrarr[i] = towrarr[i].charAt(0).toUpperCase() + towrarr[i].slice(1);
      //         }
      //         medLabel.push('Med:' + towrarr.join(" "));
      //       });
      //     }//end if
      //     else {
      //       allZero.push(true);
      //       medLabel.push();
      //     }//end else
      //   }

      //   var highValPairs = splitArrayIntoChunksOfLen(highTowData, legendLabelS.length);
      //   highValPairs.forEach((highVal) => {
      //     highTowDataInd.push(highVal);
      //   });

      //   for (var t = 0; t < towerValArr.length; t++) {//towers
      //     if (highTowDataInd[t].every(item => item !== 0) && highTowDataInd.length != 0) {
      //       allZero.push(false);
      //       Object.keys(data.snagsbypriority).forEach(tkey => {//num of towers
      //         const towrarr = tkey.split(" ");
      //         for (var i = 0; i < towrarr.length; i++) {
      //           towrarr[i] = towrarr[i].charAt(0).toUpperCase() + towrarr[i].slice(1);
      //         }
      //         highLabel.push('High:' + towrarr.join(" "));
      //       });
      //     }//end if
      //     else {
      //       allZero.push(true);
      //       highLabel.push();
      //     }//end else
      //   }

      //   for (var x = 0; x < highLabel.length; x++) {
      //     arrPr.push(
      //       {
      //         label: highLabel[x],
      //         backgroundColor: backgroundColor,
      //         data: highTowDataInd[x],
      //         stack: 'Stack 1'
      //       },
      //     )
      //   }

      //   for (var x = 0; x < medLabel.length; x++) {
      //     arrPr.push(
      //       {
      //         label: medLabel[x],
      //         backgroundColor: backgroundColor,
      //         data: medTowDataInd[x],
      //         stack: 'Stack 2',

      //       }
      //     );
      //   }

      //   chart = new Chart('barchart3', {
      //     type: 'bar',
      //     data: {
      //       labels: legendLabelP,
      //       datasets: arrPr
      //     },
      //     options: {
      //       responsive: true,
      //       title: {
      //       },
      //       tooltips: {
      //         mode: 'index',
      //       },
      //       hover: {
      //         mode: 'index',
      //       },
      //       scales: {
      //         xAxes: [
      //           {
      //             scaleLabel: {

      //             },
      //             ticks: {
      //               fontSize: 10,
      //             }
      //           },
      //         ],
      //         yAxes: [
      //           {
      //             scaleLabel: {
      //               display: true,
      //               labelString: 'High & Medium Priority Snags - Stacked Bar',
      //               fontSize: 10
      //             },
      //             ticks: {
      //               userCallback: function (value) {
      //                 if ((value / 1000) >= 1) {
      //                   value = value / 1000 + "K";
      //                 }
      //                 return value;
      //               },
      //               fontSize: 10,
      //             }
      //           },
      //         ],
      //       },
      //       legend: {
      //         position: 'bottom',
      //         align: 'center',
      //         labels: {
      //           padding: 5,
      //           boxWidth: 10,
      //           fontSize: 10,
      //         }
      //       },
      //       plugins: {
      //         datalabels: {
      //           anchor: 'center',
      //           align: 'center',
      //           color: 'black',
      //           font: {
      //             weight: 'normal',
      //             size: 7,
      //           },
      //         },
      //       },
      //     },
      //   });
      // }
      if (data.aspectbyitemsectionname) {
        if (data.aspectbyitemsectionname.totaldesnags) {

          Object.keys(data.aspectbyitemsectionname.totaldesnags).forEach(tkey => {//num of towers 
            Object.keys(data.aspectbyitemsectionname.totaldesnags[tkey]).forEach(akey => {//num of aspects
              towAspects = akey;
              if (towAspects == 'undefined') {
                towAspects = akey + 1;
              }
              towAspectsCaps = towAspects.charAt(0).toUpperCase() + towAspects.slice(1);

              Object.keys(data.aspectbyitemsectionname.totaldesnags[tkey][akey]).forEach(skey => {
                sect = data.aspectbyitemsectionname.totaldesnags[tkey][akey][skey][0];
                dsData.push(data.aspectbyitemsectionname.totaldesnags[tkey][akey][skey][1]);
              });// end for section
            });// end for aspect
            dsDataSect.push(dsData);
            dsData = [];
          });// end for towers
        }
        if (data.aspectbyitemsectionname.totalsnags) {
          radarTitle.push(Object.keys(data.aspectbyitemsectionname.totalsnags));

          Object.keys(data.aspectbyitemsectionname.totalsnags).forEach(tkey => {//num of towers 
            Object.keys(data.aspectbyitemsectionname.totalsnags[tkey]).forEach(akey => {//num of aspects
              towAspects = akey;
              if (towAspects == 'undefined') {
                towAspects = akey + 1;
              }
              towAspectsCaps = towAspects.charAt(0).toUpperCase() + towAspects.slice(1);

              Object.keys(data.aspectbyitemsectionname.totalsnags[tkey][akey]).forEach(skey => {
                sect = data.aspectbyitemsectionname.totalsnags[tkey][akey][skey][0];
                snData.push(data.aspectbyitemsectionname.totalsnags[tkey][akey][skey][1]);
                legendLabelSe.push(towAspectsCaps + ':' + sect);
              });// end for section
            });// end for aspect

            snDataSect.push(snData);
            snData = [];
            legendLabelSect.push(legendLabelSe);
            legendLabelSe = [];
          });//end for towers

          // if (snDataSect[1] || dsDataSect[1]) {
          //   document.getElementById("chart9").innerHTML = '<canvas id="radar0"></canvas>';
          // } else {
          //   document.getElementById("chart9").innerHTML = '<div style="text-align: center;"><img style="height: 40vh;" src="../../assets/images/nodata.svg"></div>';
          // }

          // if(snDataSect[2] || dsDataSect[2]) {
          //   document.getElementById("chart10").innerHTML = '<canvas id="radar3"></canvas>';
          // } else {
          //   document.getElementById("chart10").innerHTML = '<div style="text-align: center;"><img style="height: 40vh;" src="../../assets/images/nodata.svg"></div>';
          // }

          // if (snDataSect[3] || dsDataSect[3]) {
          //   document.getElementById("chart11").innerHTML = '<canvas id="radar1"></canvas>';
          // } else {
          //   document.getElementById("chart11").innerHTML = '<div style="text-align: center;"><img style="height: 40vh;" src="../../assets/images/nodata.svg"></div>';
          // }

          // if (snDataSect[4] || dsDataSect[4]) {
          //   document.getElementById("chart12").innerHTML = '<canvas id="radar2"></canvas>';
          // } else {
          //   document.getElementById("chart12").innerHTML = '<div style="text-align: center;"><img style="height: 40vh;" src="../../assets/images/nodata.svg"></div>';
          // }


          //Snags Identified by Sections chart configurations
          for (var i = 0; i < radarTitle[0].length; i++) {
            if (legendLabelSect[i].length < 3) {
              // barchart2
              chart = new Chart('radar3', {
                type: "radar",
                data: {
                  labels: ['A', 'B', 'C', 'D'],
                  datasets: [
                    {
                      backgroundColor: 'rgba(70,130,180, 0.2)',
                      borderColor: 'rgb(70,130,180)',
                      data: snDataSect[2],
                      label: 'Total Snags',
                      borderWidth: 1,
                    },
                    {
                      backgroundColor: 'rgba(218, 160, 61, 0.2)',
                      borderColor: 'rgb(218, 160, 61)',
                      data: dsDataSect[2],
                      label: 'No Desnags',
                      borderWidth: 1,
                    }
                  ]
                },
                options: {
                  title: {
                    display: true,
                    text: radarTitle[0][2],
                    fontSize: 11
                  },
                  legend: {
                    position: 'bottom',
                    labels: {
                      padding: 16,
                      boxWidth: 10,
                      fontSize: 10,
                    }
                  },
                  datalabels: {
                    anchor: 'center',
                    align: 'center',
                    color: 'black',
                    rotation: 270,
                    font: {
                      weight: 'normal',
                      size: 10,
                    },
                  },
                  plugins: {
                    roundedBars: true
                  },
                  scales: {
                    xAxes: [{
                      ticks: {
                        fontSize: 10,
                      }
                    }],
                    yAxes: [{
                      ticks: {
                        userCallback: function (value) {
                          if ((value / 1000) >= 1) {
                            value = value / 1000 + "K";
                          }
                          return value;
                        },
                        fontSize: 10,
                      }
                    }]
                  }
                }
              })

            }

            if (legendLabelSect[i].length >= 3) {
              // chart = new Chart('radar0', {
              //   type: "radar",
              //   data: {
              //     labels: legendLabelSect[1],
              //     datasets: [
              //       {
              //         backgroundColor: 'rgba(70,130,180, 0.2)',
              //         borderColor: 'rgb(70,130,180)',
              //         data: snDataSect[1],
              //         label: 'Total Snags',
              //         fill: false,
              //         pointRadius: 3,
              //         borderDash: [2],
              //         borderWidth: 2
              //       },
              //       {
              //         backgroundColor: 'rgba(218, 160, 61, 0.2)',
              //         borderColor: 'rgb(218, 160, 61)',
              //         data: dsDataSect[1],
              //         label: 'No Desnags',
              //         fill: false,
              //         pointRadius: 3,
              //         borderWidth: 2,
              //         pointStyle: 'rect'
              //       }
              //     ]
              //   },
              //   options: {
              //     title: {
              //       display: true,
              //       text: radarTitle[0][1],
              //       fontSize: 11
              //     },
              //     plugins: {
              //       datalabels: {
              //         display: true,
              //         anchor: 'end',
              //         align: 'end',
              //         font: {
              //           weight: 'bold',
              //           size: 7,
              //         },
              //       },
              //     },
              //     legend: {
              //       position: 'bottom',
              //       align: 'center',
              //       labels: {
              //         padding: 16,
              //         boxWidth: 12,
              //         fontSize: 10,
              //       }
              //     },
              //     scale: {
              //       ticks: {
              //         fontSize: 18
              //       },
              //       pointLabels: {
              //         callback: function (labels) {
              //           if (labels.length <= 15) {
              //             return labels.substr(0, labels.length)
              //           }
              //           else if (labels.length > 30) {
              //             return labels.substr(0, 15) + '-\n' + labels.substr(15, 15) + '-\n' + labels.substr(30, labels.length);
              //           }
              //           else if (labels.length > 15) {
              //             return labels.substr(0, 15) + '-\n' + labels.substr(15, labels.length)
              //           }
              //           else {
              //             return labels;
              //           }
              //         },
              //         fontSize: 7,
              //         fontColor: 'black',
              //         lineHeight: 2
              //       }
              //     }
              //   }
              // })

              //radar chart 2
              // chart = new Chart('radar1', {
              //   type: "radar",
              //   data: {
              //     labels: legendLabelSect[3],
              //     datasets: [
              //       {
              //         backgroundColor: 'rgba(70,130,180, 0.2)',
              //         borderColor: 'rgb(70,130,180)',
              //         data: snDataSect[3],
              //         label: 'Total Snags',
              //         fill: false,
              //         pointRadius: 3,
              //         borderDash: [2],
              //         borderWidth: 2
              //       },
              //       {
              //         backgroundColor: 'rgba(218, 160, 61, 0.2)',
              //         borderColor: 'rgb(218, 160, 61)',
              //         data: dsDataSect[3],
              //         label: 'No Desnags',
              //         fill: false,
              //         pointRadius: 3,
              //         borderWidth: 2,
              //         pointStyle: 'rect'
              //       }
              //     ]
              //   },
              //   options: {
              //     title: {
              //       display: true,
              //       text: radarTitle[0][3],
              //       fontSize: 11
              //     },
              //     plugins: {
              //       datalabels: {
              //         display: true,
              //         anchor: 'end',
              //         align: 'end',
              //         font: {
              //           weight: 'bold',
              //           size: 7,
              //         },
              //       },
              //     },
              //     legend: {
              //       position: 'bottom',
              //       align: 'center',
              //       labels: {
              //         padding: 16,
              //         boxWidth: 12,
              //         fontSize: 10,
              //       }
              //     },
              //     scale: {
              //       ticks: {
              //         fontSize: 18
              //       },
              //       pointLabels: {
              //         callback: function (labels) {
              //           if (labels.length <= 15) {
              //             return labels.substr(0, labels.length)
              //           }
              //           else if (labels.length > 30) {
              //             return labels.substr(0, 15) + '-\n' + labels.substr(15, 15) + '-\n' + labels.substr(30, labels.length);
              //           }
              //           else if (labels.length > 15) {
              //             return labels.substr(0, 15) + '-\n' + labels.substr(15, labels.length)
              //           }
              //           else {
              //             return labels;
              //           }
              //         },
              //         fontSize: 7,
              //         fontColor: 'black',
              //         lineHeight: 2
              //       }
              //     }
              //   }
              // })

              //radar chart 3

              // chart = new Chart('radar2', {
              //   type: "radar",
              //   data: {
              //     labels: legendLabelSect[4],
              //     datasets: [
              //       {
              //         backgroundColor: 'rgba(70,130,180, 0.2)',
              //         borderColor: 'rgb(70,130,180)',
              //         data: snDataSect[4],
              //         label: 'Total Snags',
              //         fill: false,
              //         pointRadius: 3,
              //         borderDash: [2],
              //         borderWidth: 2
              //       },
              //       {
              //         backgroundColor: 'rgba(218, 160, 61, 0.2)',
              //         borderColor: 'rgb(218, 160, 61)',
              //         data: dsDataSect[4],
              //         label: 'No Desnags',
              //         fill: false,
              //         pointRadius: 3,
              //         borderWidth: 2,
              //         pointStyle: 'rect'
              //       }
              //     ]
              //   },
              //   options: {
              //     title: {
              //       display: true,
              //       text: radarTitle[0][4],
              //       fontSize: 11
              //     },
              //     plugins: {
              //       datalabels: {
              //         display: true,
              //         anchor: 'end',
              //         align: 'end',
              //         font: {
              //           weight: 'bold',
              //           size: 7,
              //         },
              //       },
              //     },
              //     legend: {
              //       position: 'bottom',
              //       align: 'center',
              //       labels: {
              //         padding: 16,
              //         boxWidth: 12,
              //         fontSize: 10,
              //       }
              //     },
              //     scale: {
              //       ticks: {
              //         fontSize: 18
              //       },
              //       pointLabels: {
              //         callback: function (labels) {
              //           if (labels.length <= 15) {
              //             return labels.substr(0, labels.length)
              //           }
              //           else if (labels.length > 30) {
              //             return labels.substr(0, 15) + '-\n' + labels.substr(15, 15) + '-\n' + labels.substr(30, labels.length);
              //           }
              //           else if (labels.length > 15) {
              //             return labels.substr(0, 15) + '-\n' + labels.substr(15, labels.length)
              //           }
              //           else {
              //             return labels;
              //           }
              //         },
              //         fontSize: 7,
              //         fontColor: 'black',
              //         lineHeight: 2
              //       }
              //     }
              //   }
              // })
            }
          }
        }
      }
    })
  }

  onDispSummary() {
    this.showSummary = true;
    this.getSummaryDataB2BToDisp();
  }

}
