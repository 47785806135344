// pods/templates/templates.component.ts
//
// JS logic for "templates" component to display and edit list of templates,
// and provide taabbed interface to edit template checklist, roomtypes, readings (value categories), aspects, and descriptions.
//
// We make no particular checks for capabilities here.
// We assume the user cannot get here at all unless authorized to view/edit templates.

import {Component, OnInit, ViewContainerRef} from "@angular/core";

import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";

import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";

import {
  FirebaseListObservable,
  FirebaseObjectObservable,
  AngularFireDatabase,
} from "angularfire2/database-deprecated";

import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

import {CopyTemplateToSubscriberComponent} from "../../components/copy-template-to-subscriber";

// COMMON
import {
  Capability,
  ProjectTemplate,
  AfValue,
  Reading,
  Terms,
  defaultReadings,
  legacyTerms,
  isUnviewableTestTemplateForUser,
} from "@nims/red-shared";

import {find, not} from "@nims/jsutils";
import {ConfirmRemoveService} from "@nims/ngutils";

// SERVICES
import {DatabaseService, TemplatesService, UserService} from "../../services/";

@Component({
  selector: "app-templates",
  templateUrl: "./templates.component.html",
  styleUrls: ["./templates.component.css"],
})
export class TemplatesComponent implements OnInit {
  // The stream of sets of templates which drives the tabular display.
  public templates$: Observable<AfValue<ProjectTemplate>[]>;

  public editable = false;
  public help = false;

  // The current selection, bound to the selected row of the table.
  public selection: AfValue<ProjectTemplate>;

  // The ability to view test templates is a role-drive capability.
  // Failing that, the test column is not displayed, and test templates themselves are not displayed.
  public canViewTestTemplates: boolean;

  // The ability to view test templates is limited to superusers.
  // Otherwise, copying templates is always within the current subscriber.
  public canCopyTemplateToSubscriber: boolean;

  // Elements of a particular selected template, used to drive the template display.
  public rooms$;
  public items$;
  public sections$;
  public aspects$;
  public descriptions$;
  public readings$: FirebaseListObservable<Reading[]>;
  public terms$: FirebaseObjectObservable<Terms>;

  // Remember the selection key, so we can map back to newly created templates and select them.
  public selectionKey: string;

  // Firebase reference to the `templates` node.
  private ref: DatabaseReference;

  constructor(
    private readonly angularFireDatabase: AngularFireDatabase,
    private readonly confirmRemoveService: ConfirmRemoveService,
    private readonly databaseService: DatabaseService,
    private readonly matSnackBar: MatSnackBar,
    private readonly matDialog: MatDialog,
    private readonly templatesService: TemplatesService,
    private readonly userService: UserService,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    const {user} = this.userService;
    const isUnviewableTestTemplate = isUnviewableTestTemplateForUser(user);

    const originalTemplates$ = this.databaseService.getTemplateListBySubscriber(
      this.userService.subscriber
    );

    this.templates$ = originalTemplates$.pipe(
      // Omit test templates.
      map(templates => templates.filter(not(isUnviewableTestTemplate))),

      // After creating a new template, or if for any reason the stream of templates fires,
      // restore the existing selection to be the matching element from the new list.
      tap(templates =>
        // Race condition: this observable will fire with the newly created tempalte
        // **before** the `then` on the promise for creating it, which we need in order
        // to get the key, has run. Hence this hack.
        setTimeout(() => {
          if (this.selectionKey)
            this.selection = find(templates, ({$key}) => $key === this.selectionKey);
        }, 500)
      )
    );

    this.canViewTestTemplates = this.userService.can(Capability.viewTestTemplates);
    this.canCopyTemplateToSubscriber = this.userService.can(Capability.copyTemplateToSubscriber);

    this.ref = originalTemplates$.$ref as DatabaseReference;
  }

  public update(template: AfValue<ProjectTemplate>) {
    this.templatesService
      .update(this.ref, template.$key, {name: template.name, desc: template.desc})
      .then(() => this.snackBar("Template successfully updated."))
      .catch(error => this.snackBar("Tempate update failed"));
  }

  // Create a new template.
  // Push it to the summaries branch, then create the project itself.
  public async create() {
    this.clearSelection();

    try {
      this.selectionKey = await this.templatesService.create(this.ref, this.userService.subscriber);
      this.setSelectedTemplate(this.selectionKey);
      this.snackBar("Template successfully created.");
    } catch (error) {
      this.snackBar("Template creation failed");
    }
  }

  // User has chosen to copy a template.
  // If they are allowed to copy to another subscriber, put up a dialog box to choose.
  // Otherwise, just copy within this subscriber.
  public onCopy(template: ProjectTemplate) {
    if (!this.canCopyTemplateToSubscriber) return this.copyHere(template);

    this.matDialog
      .open(CopyTemplateToSubscriberComponent)
      .afterClosed()
      .subscribe(result => {
        if (result === "cancel") return;
        if (result === "current") return this.copyHere(template);

        return this.copyOther(template, result);
      });
  }

  // When copying to same subscriber, select newly copied template in list.
  private async copyHere(template: ProjectTemplate) {
    this.clearSelection();
    const key = await this.copyOther(template, this.userService.subscriber);
    if (key) {
      this.selectionKey = key;
      this.setSelectedTemplate(key);
    }
  }

  private async copyOther(template: ProjectTemplate, subscriberId: string) {
 if(!template.descriptions && !template.items){
   this.snackBar("Template Description & checklist-item can not be Empty. Please add template description & alteast one checklist-item.");
 }else if(!template.descriptions || !template.items){
      if(!template.descriptions){
        this.snackBar("Template Description can not be Empty. Please add template description.");
      }else{
        this.snackBar("Template checklist-item can not be Empty. Please add atleast one item to template checklist.");
      }
    }else{

    try {     
      const key = await this.templatesService.copy(this.ref, template, subscriberId);
      this.snackBar("Template successfully copied.");
  
      return key;
     
    } catch(error) {
      this.snackBar("Template copy failed");
    }
  }
  }

  public remove(template: AfValue<ProjectTemplate>) {
    if (this.selection === template) this.clearSelection();

    return this.confirmRemove(template.name).subscribe(res => {
      if (res) {
        this.templatesService
          .remove(this.ref, template.$key)
          .then(() => this.snackBar("Template successfully removed."))
          .catch(error => this.snackBar("Tempate remove failed"));
      }
    });
  }

  // The user has selected a template from the table.
  // Populate the data to view and edit it.
  public selectTemplate({$key}: AfValue<ProjectTemplate>) {
    this.setSelectedTemplate((this.selectionKey = $key));

    console.log($key, 'key');
  }

  // The user has put a template into test mode.
  public setTestTemplate(template: AfValue<ProjectTemplate>, test: boolean) {
    return this.templatesService.update(this.ref, template.$key, {test});
  }

  // Confirm that the user wants to remove the template.
  private confirmRemove(name: string) {
    return this.confirmRemoveService.confirm(
      `Really remove template?`,
      `Are you sure you want to completely remove template <strong>&ldquo;${name}&rdquo;</strong>?
        You will forever lose all the checklist items, sections, rooms, value categories, aspects, and descriptions in this template.
 However, this will have no impact on existing projects created using this template.
 <strongb>This cannot be undone</strong>.`,
      this.viewContainerRef
    );
  }

  // TODO: move these database accesses into the template service or something.
  private setSelectedTemplate($key: string) {
    const db = this.angularFireDatabase;

    this.rooms$ = db.list(`templates/${$key}/rooms`);
    this.items$ = db.list(`templates/${$key}/items`);
    this.sections$ = db.list(`templates/${$key}/sections`);
    this.aspects$ = db.list(`templates/${$key}/aspects`);
    this.descriptions$ = db.list(`templates/${$key}/descriptions`);
    this.readings$ = db.list(`templates/${$key}/readings`);
    this.terms$ = db.object(`templates/${$key}/terms`);

    // Plug in missing readings field.
    const readingsObject = db.object(`templates/${$key}/readings`);
    readingsObject.$ref.once("value").then(snapshot => {
      if (!snapshot.exists()) readingsObject.update(defaultReadings());
    });

    // Plug in missing terms field.
    this.terms$.$ref.once("value").then(snapshot => {
      if (!snapshot.exists()) this.terms$.update(legacyTerms);
    });
  }

  private snackBar(msg: string) {
    this.matSnackBar.open(msg, null, {duration: 3000});
  }

  // When the user creates, deletes, or clones a template, clear the selection.
  private clearSelection() {
    this.selection = this.selectionKey = this.rooms$ = this.items$ = this.sections$ = this.readings$ = this.aspects$ = this.descriptions$ = this.terms$ = null;
  }
}
