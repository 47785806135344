import {Component, OnInit, Input, OnDestroy} from "@angular/core";
import { TemplateRef } from '@angular/core';

// RXJS
import {flatMap, pluck} from "rxjs/operators";
import {of} from "rxjs";
import * as firebase from "firebase/app";
import {FirebaseObjectObservable} from "angularfire2/database-deprecated";

import {GoogleMapsService} from "@nims/ngutils";
import {
  Capability,
  ProjectsService,
  Summary,
  can,
  normalizeSummary,
  projectStatuses,
  projectTypes,
} from "@nims/red-shared";

import {UserService} from "../../services/user/user.service";
import { AngularFireStorage } from "angularfire2/storage";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: "project-info",
  templateUrl: "./project-info.component.html",
  styleUrls: ["./project-info.component.css"],
})
export class ProjectInfoComponent implements OnInit, OnDestroy {
  @Input() summary: FirebaseObjectObservable<Summary>;
  @Input() summaryId: String;
 
  selectedImage : any = null;
  selectedLogo : any = null;

 // @ViewChild('secondDialog') secondDialog: TemplateRef<any>;

  // Local fields to which inputs are bound.
  public name;
  public customer;
  public address;
  public type;
  public numUnits;
  public status;
  public maybeStatus;
  public noSnagPriorities;
  public projectImagePath = '/assets/images/image_not_available.jpg';
  public showUpload;
  public downloadImagePath;
  public addcustomerlogo;
  public showLogoUpload;
  public downloadCustomerLogoImagePath;
  public email;

  // What phases does this project have?
  public noFixing;
  public noDesnagging;
  public test;

  public visualReportTitle;

  public editable = false;
  public help = false;
  public error;
  public canViewTest;

  public typeOptions = projectTypes.map(type => ({view: type, viewValue: type}));
  public statusOptions = projectStatuses.map(status => ({label: status, value: status}));
  public projectTypeOptions = projectTypes.map(type => ({label: type, value: type}));

  public createdBy$;
  public updatedBy$;
  public customerTypeList = ['PDQI', 'RWA', 'B2B'];
  public selected;

  private summarySubscription;


  constructor(
    private readonly userService: UserService,
    private readonly projectsService: ProjectsService,
    private readonly googleMapsService: GoogleMapsService,
    private storage: AngularFireStorage,
    private readonly matDialog: MatDialog,
  ) {}

  ngOnInit() {   
    this.getFilePath();
    this.getLogoPath();
    // TODO: Handle the error case where user is missing from DB.
    const pluckName = prop =>
      this.summary.pipe(
        flatMap(summary =>
          summary[prop] ? this.userService.getUser(summary[prop]).pipe(pluck("name")) : of(null)
        )
      );

    this.updatedBy$ = pluckName("updatedBy");
    this.createdBy$ = pluckName("createdBy");

    // Copy all the summary fields into the component as properties.
    // TODO: fix this up.
    this.summarySubscription = this.summary.subscribe(summary => {
      const {
        address,
        customer,
        name,
        noDesnagging,
        noFixing,
        noSnagPriorities,
        numUnits,
        status,
        test,
        type,
        visualReportTitle,
        addcustomerlogo,
        email,
        customerType,
      } = normalizeSummary(summary);

      // TODO: use reactive forms or something to avoid all this rigamarole.
      Object.assign(this, {
        address,
        customer,
        name,
        noDesnagging,
        noFixing,
        noSnagPriorities,
        numUnits,
        status,
        test,
        type,
        visualReportTitle,
        addcustomerlogo,
        email,
        customerType,
      });
      this.maybeStatus = this.status;
    });

    // Figure out if this user can view test projects.
    this.canViewTest = can(null, this.userService.user, Capability.viewTestProjects);
  }

  ngOnDestroy() {
    if (this.summarySubscription) this.summarySubscription.unsubscribe();
  }

  public updateName() {
    this.update({name: this.name});
  }
  public updateCustomer() {
    this.update({customer: this.customer});
  }
  public updateCustomerEmail() {
    this.update({email: this.email});
  }
  public updateType() {
    this.update({type: this.type});
  }
  public updateNumUnits() {
    this.update({numUnits: this.numUnits});
  }
  public updateNoSnagPriorities() {
    this.update({noSnagPriorities: this.noSnagPriorities});
  }
  public updateNoFixing() {
    this.update({noFixing: this.noFixing});
  }
  public updateNoDesnagging() {
    this.update({noDesnagging: this.noDesnagging});
  }
  public updateTest() {
    this.update({test: this.test});
  }
  public updateVisualReportTitle() {
    this.update({visualReportTitle: this.visualReportTitle});
  }
  public updateProjectImagePath(urlPath:string) {
    this.update({projectImage: urlPath});
  }
  public updateAddLogo() {
    this.update({addcustomerlogo: this.addcustomerlogo});
  }
  public updateCustomerLogoPath(urlPath:string) {
    this.update({customerLogo: urlPath});
  }
  public updateCustomerType(ctype) {
    this.update({customerType: ctype});
  }

  // Update status.
  // Check to make sure the subscriber has the ability to put more projects in progress.
  public async updateStatus() {
    try {
      await this.projectsService.setSummaryStatus(this.summary.$ref, this.maybeStatus);
      this.status = this.maybeStatus;
    } catch (e) {
      // TODO: Generalize this to use some kind of error code for more different types of errors, etc.
      this.error =
        "You have exhausted the number of in-progress projects available to you as a subscriber.";
      window.setTimeout(() => (this.error = ""), 5000);
    }
  }

  // Address has been modified. Find city name, and update.
  public async updateAddress() {
    const address = this.address;
    const city = await this.googleMapsService.geocode(address.trim()).catch(() => "Unknown");

    return this.update({city, address});
  }

  private update(data) {
    return this.summary.update(
      Object.assign(data, {
        updatedOn: firebase.database.ServerValue.TIMESTAMP,
        updatedBy: this.userService.uid,
      })
    );
  }

  //Project Image Upload Functionalities For Summary tab
  showPreview(event:any){
    if(event.target.files && event.target.files[0]){
      this.showUpload=false;
       const reader = new FileReader();
       reader.onload = (e:any) => this.downloadImagePath = e.target.result;
       reader.readAsDataURL(event.target.files[0]);
       this.selectedImage = event.target.files[0];
       const path = `projectImages/${this.summaryId}`;
       this.storage.upload(path, this.selectedImage, {}).then((res:any) => {
         this.getFilePath();
         this.updateProjectImagePath(this.downloadImagePath);
         //console.log('success' + res);
       }).catch((error:any) => {
         //console.log(error);
       })
    }else{
       this.projectImagePath = '/assets/images/image_not_available.jpg';
       this.selectedImage = null;
}
  }

  getFilePath(){
    const refPath = `projectImages/${this.summaryId}`;
    const fileRef = this.storage.ref(refPath);
    fileRef.getDownloadURL().subscribe((url) => {
      if (url && url.length > 0) {
        //console.log('url :' + url );
        this.downloadImagePath=url;
        this.updateProjectImagePath(url);
        this.showUpload = false;
      }else{
        //console.log('empty');
        this.showUpload = true;
      }
    }, error => {
      //console.log('Error' + error);
      this.showUpload = true;
    }) 
  }

  onRemoveImage(){
    this.showUpload = true;    
    this.storage.storage.refFromURL(this.downloadImagePath).delete().then((res:any) => {
      this.updateProjectImagePath('');
      //alert('Image removed successfully !');
    }).catch((error: any) => {
      //alert('Error' + error);
    });

  }

  openConfirmationDialog(ref: TemplateRef<any>) {
    this.matDialog.open(ref);
  }

    // Customer logo upload fix
    customerLogoUpload(event:any){
      if(event.target.files && event.target.files[0]){
        this.showLogoUpload=false;
         const reader = new FileReader();
         reader.onload = (e:any) => this.downloadCustomerLogoImagePath = e.target.result;         
         reader.readAsDataURL(event.target.files[0]);
         this.selectedLogo = event.target.files[0];
        // console.log(this.selectedLogo);
         const path = `customerLogo/${this.summaryId}`;
         this.storage.upload(path, this.selectedLogo, {}).then((res:any) => {
          console.log(this.downloadCustomerLogoImagePath);
           this.getLogoPath();
           this.updateCustomerLogoPath(this.downloadCustomerLogoImagePath);
           //console.log('success' + res);
         }).catch((error:any) => {
           //console.log(error);
         })
      }else{
       // this.customerLogoImagePath = '/assets/images/image_not_available.jpg';
         this.selectedLogo = null;
  }
    }
  
    getLogoPath(){
      const refPath = `customerLogo/${this.summaryId}`;
      const fileRef = this.storage.ref(refPath);
      fileRef.getDownloadURL().subscribe((url) => {
        if (url && url.length > 0) {
          //console.log('url :' + url );
          this.downloadCustomerLogoImagePath=url;
          this.updateCustomerLogoPath(url);
          this.showLogoUpload = false;
        }else{
          this.showLogoUpload = true;
        }
      }, error => {
        //console.log('Error' + error);
        this.showLogoUpload = true;
      }) 
    }
  
    onRemoveCustomerLogo(){
     // console.log(this.downloadCustomerLogoImagePath);
      this.showLogoUpload = true;    
      this.storage.storage.refFromURL(this.downloadCustomerLogoImagePath).delete().then((res:any) => {
        this.updateCustomerLogoPath('');
        //alert('Logo removed successfully !');
      }).catch((error: any) => {
        //alert('Error' + error);
      });
  
    }
  
    openConfirmationDialogRemoveLogo(ref: TemplateRef<any>) {
      this.matDialog.open(ref);
    }

}
