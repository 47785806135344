// services/items.service.ts
//
// Handle manipulation of items in checklists.
// Mainly invoked via the checklist service.

import {Injectable} from "@angular/core";

// FIREBASE
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

// COMMON
import {Item} from "@nims/red-shared";
import {Hash, forEach, filter} from "@nims/jsutils";
import {once} from "@nims/afutils";

type Items = Hash<Item>;

@Injectable()
export class ItemsService {
  public async getBySection(ref: DatabaseReference, sectionKey: string): Promise<Items> {
    const sections = await once<Items>(ref.orderByChild("section").equalTo(sectionKey));

    return sections || {};
  }

  public async getKeysBySection(ref: DatabaseReference, sectionKey: string): Promise<string[]> {
    const items = await this.getBySection(ref, sectionKey);

    return Object.keys(items);
  }

  // Remove all the items in a section.
  public async removeBySection(ref: DatabaseReference, sectionKey: string) {
    const items = await this.get(ref);
    const sectionItems = filter(items, item => item.section === sectionKey);

    forEach(sectionItems, (_, itemId) => delete items[itemId]);

    return this.put(ref, items);
  }

  public async removeMany(ref: DatabaseReference, keys: string[]) {
    const items = await this.get(ref);

    keys.forEach(key => delete items[key]);

    return this.put(ref, items);
  }

  // When a reading is deleted, all the items referring to it must be set back to default.
  public async removeReading(ref: DatabaseReference, key: string) {
    const items = await this.get(ref);

    forEach(items, item => {
      if (item.reading === key) delete item.reading;
    });

    return ref.set(items);
  }

  // When a description is deleted, all the items referring to it must be set back to default.
  public async removeDescription(ref: DatabaseReference, key: string) {
    const items = await this.get(ref);

    forEach(items, item => {
      if (item.description === key) delete item.description;
    });

    return ref.set(items);
  }

  // Put and get this node.
  private async get(ref: DatabaseReference): Promise<Items> {
    return (await once<Items>(ref)) || {};
  }
  private async put(ref: DatabaseReference, items: Items) {
    return ref.set(items);
  }
}
