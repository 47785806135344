// google-analytics.service.ts
//
// Handle Google Analytics.
// Initialize GA right in constructor.

// ANGULAR
import {Injectable} from "@angular/core";
import {Router, NavigationEnd} from "@angular/router";
import {filter} from "rxjs/operators";

import {environment} from "../../../environments/environment";

import "./google-analytics";

const googleAnalytics = environment.googleAnalytics || {};
const enable = googleAnalytics["enable"];
const clientId = googleAnalytics["clientId"];
const SKIP_LOCALHOST = true;

if (enable && !clientId) throw new Error("Missing Google Analytics clientId");

function isNavigationEnd(event): event is NavigationEnd {
  return event instanceof NavigationEnd;
}

@Injectable()
export class GoogleAnalyticsService {
  // Initialize Google Analytics.
  // Observe router events and ping GA on navigation event.
  constructor(router: Router) {
    if (!enable) {
      console.log("Google Analytics tracking disabled");
      return;
    }

    if (
      SKIP_LOCALHOST &&
      (location.hostname === "localhost" || location.hostname === "127.0.0.1")
    ) {
      console.log("Google Analytics tracking disabled on localhost");
      return;
    }

    ga("create", clientId, "auto");
    ga(tracker =>
      console.log("Google Analytics tracking enabled with clientId", tracker.get("clientId"))
    );

    router.events.pipe(filter(isNavigationEnd)).subscribe(event => {
      ga("set", "page", event.urlAfterRedirects);
      ga("send", "pageview");
    });
  }

  // Emit a custom Google Analytics event.
  // NOT CURRENTLY USED.
  public sendEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (!enable) return;

    ga("send", "event", {eventCategory, eventLabel, eventAction, eventValue});
  }
}
