// pods/subscriber/subscriber.components.ts
//
// TS logic for page to display (and edit) information about a subscriber.
// This page is displayed as a child route of the `subscribers` page.
// It is accessible to superusers.

import {Component, OnInit, OnDestroy} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Observable, of} from "rxjs";
import {pluck, switchMap} from "rxjs/operators";
import {FirebaseObjectObservable} from "angularfire2/database-deprecated";
import {Subscriber, SubscribersService} from "@nims/red-shared";
import {UserService} from "../../services/";

type string$ = Observable<string>;
type Subscriber$ = FirebaseObjectObservable<Subscriber>;

const NONE = of("none");

@Component({
  selector: "app-subscriber-info",
  templateUrl: "./subscriber.component.html",
  styleUrls: ["./subscriber.component.css"],
})
export class SubscriberComponent implements OnInit, OnDestroy {
  public editable = false;
  public help = false;

  public subscriber$: FirebaseObjectObservable<Subscriber>;

  public createdByName$: string$;
  public updatedByName$: string$;
  public subscriber: Subscriber;
  public expires: string;

  private subscriberSubscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private subscribersService: SubscribersService
  ) {}

  ngOnInit() {
    const userService = this.userService;

    // This route may be called multiple times with different subscriber IDs.
    // Listen to the params observable, and map it to the right subscriber.
    const subscriber$ = (this.subscriber$ = this.activatedRoute.params.pipe(
      pluck("id"),
      switchMap(id => this.subscribersService.getOne$(id))
    ) as Subscriber$);

    this.createdByName$ = getUserName$("createdBy");
    this.updatedByName$ = getUserName$("updatedBy");

    this.subscriberSubscription = this.subscriber$.subscribe(subscriber => {
      const expires = new Date(subscriber.usageLimits.expires);

      this.subscriber = subscriber;
      this.expires = expires.toISOString().replace(/T.*/, "");
    });

    // Create an observable of some user's name, based on a property on the subscriber object.
    function getUserName$(prop: string): string$ {
      return subscriber$.pipe(
        pluck(prop),
        switchMap(uid => (uid ? userService.getUser(uid).pipe(pluck("name")) : NONE))
      );
    }
  }

  ngOnDestroy() {
    this.subscriberSubscription.unsubscribe();
  }

  public onSubmit() {
    const id = this.activatedRoute.snapshot.params["id"];

    // Convert expriration date into seconds.
    this.subscriber.usageLimits.expires = +new Date(this.expires);

    this.subscribersService.putOne(id, this.subscriber).then(() => (this.editable = false));
  }
}
