// shared.module.ts
//
// Module definition for shared module for both components and pods modules.

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";

import {
  CollapsibleFieldsetModule,
  ConfirmRemoveModule,
  EditableMarkdownModule,
  FormatPipesModule,
  GoogleMapsModule,
  NgutilsDirectivesModule,
} from "@nims/ngutils";

import {RedSharedModule} from "@nims/red-shared";

import {PipesModule} from "./pipes.module";
import {MaterialModule} from "./material.module";
import {PrimengModule} from "./primeng.module";

import {NgxMdModule} from "ngx-md";
import {MomentModule} from "angular2-moment";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MaterialModule,
    PrimengModule,
    PipesModule,
    NgxMdModule,
    MomentModule,

    RedSharedModule,

    CollapsibleFieldsetModule,
    ConfirmRemoveModule,
    EditableMarkdownModule,
    FormatPipesModule,
    GoogleMapsModule,
    NgutilsDirectivesModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MaterialModule,
    PrimengModule,
    PipesModule,
    NgxMdModule,
    MomentModule,

    RedSharedModule,

    CollapsibleFieldsetModule,
    ConfirmRemoveModule,
    EditableMarkdownModule,
    FormatPipesModule,
    GoogleMapsModule,
    NgutilsDirectivesModule,
  ],
})
export class SharedModule {}
