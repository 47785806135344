import {map} from "rxjs/operators";
// sections/sections.component.ts
//
// Display master or project sections.

import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
} from "@angular/core";

import {Observable, combineLatest} from "rxjs";

import {AngularFireDatabase, FirebaseListObservable} from "angularfire2/database-deprecated";
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

import {letter, mapFromArray, pluralize} from "@nims/jsutils";
import {ConfirmRemoveService} from "@nims/ngutils";
import {Room, Item, Section, Aspect, AfValue, Terms, trackByKey} from "@nims/red-shared";

import {ChecklistService} from "../../services/checklist.service";
import {SectionUpdate, SectionMove, SectionToggleRoom} from "../checklist/checklist.component";

type List<T> = FirebaseListObservable<AfValue<T>[]>;

@Component({
  selector: "sections",
  templateUrl: "./sections.component.html",
  styleUrls: ["./sections.component.css"],
})
export class SectionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public rooms: List<Room>;
  @Input() public sections: List<Section>;
  @Input() public items: List<Item>;
  @Input() public aspects: List<Aspect>;
  @Input() public checklist: DatabaseReference;
  @Input() editable: boolean;
  @Input() terms: Terms;

  @Output() onSelected = new EventEmitter();
  @Output() onRemove = new EventEmitter<string>();
  @Output() onCreate = new EventEmitter();
  @Output() onUpdate = new EventEmitter<SectionUpdate>();
  @Output() onMove = new EventEmitter<SectionMove>();
  @Output() onToggleRoom = new EventEmitter<SectionToggleRoom>();

  public selections: Section[] = [];
  public help = false;
  public emptyMessage = "Loading, wait...";
  public aspectOptions: Observable<any[]>;
  public trackBySection = trackByKey;
  public aspectMap: Observable<{[aspectId: string]: Aspect}>;

  public roomMap = {};
  public letter = letter;

  private subscriber;

  constructor(
    private db: AngularFireDatabase,
    private viewContainerRef: ViewContainerRef,
    private confirmRemoveService: ConfirmRemoveService,
    private checklistService: ChecklistService
  ) {}

  ngOnInit() {
    this.emptyMessage = "No sections found";
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.makeSectionRoomMap();

    this.aspectOptions = this.aspects.pipe(
      map((aspects: AfValue<Aspect>[]) =>
        aspects
          .sort((a, b) => a.order - b.order)
          .map(aspect => ({label: aspect.name, value: aspect.$key}))
      )
    );

    // Aspects as passed in is a list observable.
    // Create an object addressable by aspect ID for use in displaying aspect name in table.
    this.aspectMap = this.db.object(this.aspects.$ref);
  }

  ngOnDestroy() {
    if (this.subscriber) this.subscriber.unsubscribe();
  }

  // A row (section) has been selected or deselected.
  public onRowSelect() {
    // For some reason, the `selection` binding to `this.selections` has not been updated yet.
    // So wait for it to update itself.
    setTimeout(() => this.onSelected.emit(this.selections));
  }

  // Populate the `roomMap` property,
  // indexed by section, and containing information (all/some/none) on section selections by room.
  // This is used to show double chackmarks: dimmed, semi-dimmed, or solid.
  public makeSectionRoomMap() {
    this.subscriber = combineLatest(this.rooms, this.sections, this.items).subscribe(
      ([rooms, sections, items]) => (this.roomMap = make(rooms, sections, items))
    );

    function make(rooms, sections, items) {
      return mapFromArray(sections.map(section => section.$key), sectionId =>
        getSectionRooms(sectionId)
      );

      // Given a section, and the current list of rooms,
      // return an object indexed by room with one of three values
      // indicating the applicability of each item to that room.
      function getSectionRooms(sectionId) {
        return mapFromArray(rooms.map(room => room.$key), (roomId, idx) =>
          getRoomItems(rooms[idx], items.filter(item => item.section === sectionId))
        );

        // Given a room, see if all, or some, or none of a list of items apply to it.
        function getRoomItems(room, _items: Item[]) {
          let sum = 0;

          _items.forEach((item: AfValue<Item>) => {
            sum += Number(!!(room.items && room.items[item.$key]));
          });

          return sum === 0 ? "none" : sum === _items.length ? "all" : "some";
        }
      }
    }
  }

  // Set the room applicability for all items in this section.
  // We do this when the user clicks on the double checkmark.
  public toggleRoom(section: AfValue<Section>, room: AfValue<Room>, value: string) {
    const newVal = value === "none" || value === "some";
    const {$key: key} = section;
    const {$key: roomKey} = room;

    this.onToggleRoom.emit({key, roomKey, value: newVal});
  }

  // Handle update to section name, etc..
  public onChangeName(section: AfValue<Section>) {
    this.update(section, {name: section.name});
  }
  public updateAspect(section: AfValue<Section>, aspect: string) {
    this.update(section, {aspect});
  }
  public toggleDisabled(section: AfValue<Section>) {
    this.update(section, {disabled: !section.disabled});
  }

  // Create a new section.
  // Invoked by user pressing "+" icon.
  public create() {
    this.checklistService
      .createSection(this.checklist, {}, this.terms.checklist)
      .catch(e => this.error("Could not create section.", e));
  }

  // Move sections up and down.
  // Invoked by little up and down icons on each row.
  public moveUp(section: AfValue<Section>) {
    this.move(section, -1);
  }
  public moveDown(section: AfValue<Section>) {
    this.move(section, +1);
  }

  // Remove a section, after confirmation.
  // Invoked by trash icon on each row.
  public remove(section: AfValue<Section>) {
    this.confirmRemove(section.name).subscribe(res => {
      if (res) this.removeSection(section.$key);
    });
  }

  // Confirm removal of section.
  private confirmRemove(name: string) {
    return this.confirmRemoveService.confirm(
      "Really remove ${this.terms.checklist}?",
      `Are you sure you want to remove ${this.terms.checklist} &ldquo;${name}&rdquo; from the ${
        this.terms.program
      }?
        <b>This ${this.terms.checklist} and all ${pluralize(
        this.terms.checkpoint
      )} in it will be permanently lost!</b>
        Any assignments of this ${this.terms.checklist}'s ${pluralize(
        this.terms.checkpoint
      )} to ${pluralize(this.terms.feature)} will be deleted.`,
      this.viewContainerRef
    );
  }

  // Actually remove the section.
  private removeSection(key: string) {
    this.selections = this.selections.filter((s: AfValue<Section>) => s.$key !== key);
    this.onSelected.emit(this.selections);

    this.onRemove.emit(key);
  }

  // Update a section property.
  private update(section: AfValue<Section>, data: Partial<Section>) {
    this.onUpdate.emit({key: section.$key, data});
  }

  // Move a section up or down.
  // Find the section about or below, and swap the orders.
  private move(section: AfValue<Section>, delta: number) {
    this.onMove.emit({key: section.$key, delta});
  }

  private error(msg: string, error: Error) {
    // TODO: display this error on the screen.
    console.error(msg, error);
  }
}
