// components.module.ts
//
// Module definition for utility components (non-page components).

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "./shared.module";

import {
  AddButtonComponent,
  CopyTemplateToSubscriberComponent,
  CoverPageComponent,
  EditButtonComponent,
  HelpButtonComponent,
  HelpComponent,
  NemmadiLogoComponent,
  ReportHeaderComponent,
  TemplateInterpolatablesComponent,
  UnitStatusComponent,
  UserErrorComponent
} from "../components/";

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    AddButtonComponent,
    CopyTemplateToSubscriberComponent,
    CoverPageComponent,
    EditButtonComponent,
    HelpButtonComponent,
    HelpComponent,
    NemmadiLogoComponent,
    ReportHeaderComponent,
    TemplateInterpolatablesComponent,
    UnitStatusComponent,
    UserErrorComponent
  ],
  exports: [
    AddButtonComponent,
    CoverPageComponent,
    EditButtonComponent,
    HelpButtonComponent,
    HelpComponent,
    NemmadiLogoComponent,
    ReportHeaderComponent,
    TemplateInterpolatablesComponent,
    UnitStatusComponent,
    UserErrorComponent
  ],
})
export class ComponentsModule {}
