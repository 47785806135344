// components/help-button/edit-button.component.ts
//
// TS logic for help button component.
// Designed for two-way (banana-in-a-box) binding:
// ```
// <help-button [(help)]="showHelp"></help-button>
// ```

import {Component, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: "help-button",
  templateUrl: "./help-button.component.html",
  styleUrls: ["./help-button.component.css"],
})
export class HelpButtonComponent {
  @Input() help;
  @Output() helpChange = new EventEmitter<boolean>();

  public onClick() {
    this.helpChange.emit((this.help = !this.help));
  }
}
