// services/descriptions.service.ts
//
// Handle manipulation of "descriptions" in "checklists".
// Invoked from `DescriptionsComponent` via `ChecklistService`.

import {Injectable} from "@angular/core";

// FIREBASE
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

// OUR REPO
import {Description} from "@nims/red-shared";
import {length} from "@nims/jsutils";
import {once} from "@nims/afutils";

@Injectable()
export class DescriptionsService {
  // Update a description.
  public update(ref: DatabaseReference, key: string, data: Partial<Description>) {
    return ref.child(key).update(data);
  }

  // Create a new description.
  public async create(ref: DatabaseReference) {
    const descriptions = await this.get(ref);
    const count = length(descriptions);

    const description: Description = {
      name: `New description ${count + 1}`,
      source: `New description ${count + 1}`,
    };

    return ref.push(description);
  }

  // Remove a description.
  public async remove(ref: DatabaseReference, key: string) {
    const descriptions = await this.get(ref);

    delete descriptions[key];

    return ref.set(descriptions);
  }

  // Put and get this node.
  private async get(ref: DatabaseReference) {
    return (await once<Description>(ref)) || {};
  }

  // private put(ref: DatabaseReference, descriptions: Descriptions) {
  //   return ref.set(descriptions);
  // }
}
