// Define modules for primeng.

// ANGULAR
import {NgModule} from "@angular/core";

// PRIMENG
import {
  AutoCompleteModule,
  CheckboxModule,
  ChipsModule,
  DataTableModule,
  DropdownModule,
  InputSwitchModule,
  MultiSelectModule,
  OverlayPanelModule,
  PanelModule,
  RadioButtonModule,
  SelectButtonModule,
  StepsModule,
} from "primeng/primeng";

const imports = [
  AutoCompleteModule,
  CheckboxModule,
  ChipsModule,
  DataTableModule,
  DropdownModule,
  InputSwitchModule,
  MultiSelectModule,
  OverlayPanelModule,
  PanelModule,
  RadioButtonModule,
  SelectButtonModule,
  StepsModule,
];

@NgModule({imports, exports: imports})
export class PrimengModule {}
