import {map, switchMap, take} from "rxjs/operators";
// user.service.ts
//
// Handle user management, logging in, logging out.

import {Injectable} from "@angular/core";
import {Observable, ReplaySubject, of} from "rxjs";
import {AngularFireAuth} from "angularfire2/auth";
import {User, Subscriber, SubscribersService, Capability, can} from "@nims/red-shared";
import {DatabaseService} from "../database/database.service";
import {FirebaseAccountService} from "../firebase-account.service"; // Call cloud functions to create and delete accounts.
import axios from "axios";

const LOG = false;

@Injectable()
export class UserService {
  public user: User;
  public user$: Observable<User>;

  // Streams for subscriber ID and subscriber info.
  // This is a subject because when a superuser masquerades as some subscriber,
  // we need to write to it.
  public subscriberId$ = new ReplaySubject<string>(1);
  public subscriber$: Observable<Subscriber>;

  // Key into `subscribers` collection for this user.
  // Normally, this is the subscriber to which the user belongs,
  // but it could also be changed on a super-user's home page.
  public subscriber: string;

  // Keep an unwrapped version of the subscriber object around.
  public subscriberData: Subscriber;

  // The intent is to have a setting to allow superusers to view ALL projects etc.
  // Probably this would be set in My Account page, or Home page.
  public viewAllSubscribers = false;

  // Manage promise for user data being available.
  public loggedInPromise: Promise<any>;
  private resolveLoggedInPromise;
  //  private rejectLoggedInPromise;

  // Constructor watches AngularFireAuth `authState` observable.
  constructor(
    private angularFireAuth: AngularFireAuth,
    private databaseService: DatabaseService,
    private firebaseAccountService: FirebaseAccountService,
    private subscribersService: SubscribersService
  ) {
    this.makeLoggedInPromise();

    const authState = angularFireAuth.authState;

    this.user$ = authState.pipe(
      switchMap(authUser => (authUser ? this.getUser(authUser.uid) : of(null)))
    );
    this.user$.pipe(map(user => (user ? user.subscriber : null))).subscribe(this.subscriberId$);

    // DEBUG
    if (LOG) this.user$.subscribe(user => console.log("user is", user));
    this.subscriberId$.subscribe(id => (this.subscriber = id));

    this.user$.subscribe(user => {
      this.user = user;
      this.resolveLoggedInPromise(true);
    });

    // Keep an up-to-date stream of subscriber information, based on the most recent subscriber ID.
    this.subscriber$ = this.subscriberId$.pipe(
      switchMap(id => (id ? this.subscribersService.getOne$(id) : of(null)))
    );

    // Keep an unwrapped subscriber object around.
    this.subscriber$.subscribe(subscriber => (this.subscriberData = subscriber));

    authState.subscribe(authUser => {
      if (authUser) {
        this.getUser(authUser.uid)
          .pipe(take(1))
          .subscribe(
            user => {
              this.user = user;
              this.resolveLoggedInPromise(true);
            },
            error => console.error("Error from authState", error)
            //          () => console.log("authState completed")
          );
      } else {
        this.user = null;
        this.makeLoggedInPromise();
      }
    });
  }

  public getUser(uid) {
    return this.databaseService.getUserObject(uid);
  }

  public getUserP(uid) {
    return this.databaseService.getUserObjectP(uid);
  }

  // Does the current user have some specified capability?
  // This requires the system to be in a stable state after login,
  // with the system having had enough time to listen to the change to subscriber
  // and store away an unwrapped version.
  public can(capability) {
    return this.user && this.subscriberData && can(this.subscriberData, this.user, capability);
  }

  public get role() {
    return this.user && this.user.role;
  }

  // GETTERS
  public get uid() {
    const user = this.angularFireAuth.auth.currentUser;
    return user && user.uid;
  }

  public get loggedIn() {
    return !!this.angularFireAuth.auth.currentUser;
  }

  public get email() {
    const user = this.angularFireAuth.auth.currentUser;
    return user && user.email;
  }

  // OPERATIONS ON CURRENT USER

  // Log the user in, returning a stream of success values.
  // Will throw if login fails.
  public async login(email: string, password: string) {
    await this.angularFireAuth.auth.signInWithEmailAndPassword(email, password);

    return this.angularFireAuth.auth.currentUser;
  }

  public logout() {
    this.makeLoggedInPromise();
    return this.angularFireAuth.auth.signOut();
  }

  public changePassword(newPassword: string) {
    return this.angularFireAuth.auth.currentUser.updatePassword(newPassword);
  }

  public changeEmail(newEmail: string) {
    return this.angularFireAuth.auth.currentUser.updateEmail(newEmail);
  }

  public forgotPassword(email: string) {
    return this.angularFireAuth.auth.sendPasswordResetEmail(email);
  }

  //Send email to inspector when assigned new project
  public sendEmailToInspector(projectassignedData){
    let url = "https://us-central1-nemmadi-console.cloudfunctions.net/notifyProjectAssigned";
    let data= projectassignedData
      axios.post( url, data )
                  .then(res=>{
                    console.log("res",res);  
                      
                  })
                  .catch(err=>{
                     console.log("error",err);  
                  })
                //  return this.firebaseAccountService.send(dest,proj);     
  }

  // Create a new user by calling the server API.
  // The response will be of the form {uid, ..., providerData: {password, ...}}`.
  public createFirebaseAccount(email): Observable<any> {
    return this.firebaseAccountService.create(email);
  }

  // Delete a firebase user account by calling the server API.
  public deleteFirebaseAccount(uid) {
    return this.firebaseAccountService.delete(uid);
  }

  public toggleFavorite(projectId) {
    const projectsNode = this.databaseService.getUserObject(this.uid);

    projectsNode.$ref.child("projects").transaction(projects => {
      projects = projects || {};
      if (projects[projectId]) delete projects[projectId];
      else projects[projectId] = true;

      return projects;
    });
  }

  // Set (masquerade) as a subscriber.
  // Invoked via pulldown on Home page.
  public setSubscriberId(id: string) {
    if (!this.can(Capability.masqueradeAsSubscriber))
      throw new Error("Attempt by non-masquerading user to set subscriber ID");

    // This will trigger a new emission on the `subscriber$` property.
    this.subscriberId$.next(id);
  }

  private makeLoggedInPromise() {
    this.loggedInPromise = new Promise((resolve, reject) => {
      this.resolveLoggedInPromise = resolve;
      //      this.rejectLoggedInPromise = reject;
    });
  }
}
