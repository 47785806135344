// Define pods (pages) module.

// ANGULAR
import {NgModule} from "@angular/core";
import {CommonModule, DatePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";

// OUR MODULES
import {ComponentsModule} from "./components.module";
import {SharedModule} from "./shared.module";

// OUR COMPONENTS
import {
  AccountsComponent,
  AspectResultsComponent,
  AspectsComponent,
  ChecklistComponent,
  DashboardComponent,
  DashboardDotComponent,
  DashboardFilterComponent,
  DashboardLegendComponent,
  DashboardTableComponent,
  DescriptionsComponent,
  HomeComponent,
  ItemArtifactsComponent,
  ItemResultsComponent,
  ItemStatusesGradientDirective,
  ItemsComponent,
  LoginComponent,
  MasterRoomsComponent,
  MultiProjectDashboardComponent,
  MyAccountComponent,
  ProjectComponent,
  ProjectInfoComponent,
  ProjectLayoutsComponent,
  ProjectProgressComponent,
  ProjectResultsComponent,
  ProjectRoomsComponent,
  ProjectUnitsComponent,
  ProjectsComponent,
  ReadingsComponent,
  ReportDefinitionComponent,
  ReportDimensionComponent,
  ReportFiltersComponent,
  ReportOptionsComponent,
  ResultsComponent,
  SectionsComponent,
  SingleProjectDashboardComponent,
  SubscriberComponent,
  SubscribersComponent,
  TemplatesComponent,
  TermsComponent,
  UnitResultsComponent
} from "../pods/";

// DIRECTIVES

@NgModule({
  imports: [
    // ANGULAR
    CommonModule,
    FormsModule,
    RouterModule,

    SharedModule,
    ComponentsModule,
  ],
  declarations: [
    AccountsComponent,
    AspectResultsComponent,
    AspectsComponent,
    ChecklistComponent,
    DescriptionsComponent,
    HomeComponent,
    ItemArtifactsComponent,
    ItemResultsComponent,
    ItemsComponent,
    LoginComponent,
    MasterRoomsComponent,
    MyAccountComponent,
    ProjectComponent,
    ProjectInfoComponent,
    ProjectLayoutsComponent,
    ProjectProgressComponent,
    ProjectResultsComponent,
    ProjectRoomsComponent,
    ProjectUnitsComponent,
    ProjectsComponent,
    ReadingsComponent,
    ResultsComponent,
    SectionsComponent,
    SubscriberComponent,
    SubscribersComponent,
    TermsComponent,
    TemplatesComponent,
    UnitResultsComponent,

    // DASHBOARD-RELATED COMPONENTS
    DashboardComponent,
    DashboardDotComponent,
    DashboardLegendComponent,
    DashboardTableComponent,
    DashboardFilterComponent,
    ReportOptionsComponent,
    ReportFiltersComponent,
    ReportDimensionComponent,
    ReportDefinitionComponent,

    // MULTI PROJECT DASHBOARD
    MultiProjectDashboardComponent,

    // SINGLE PROJECT DASHBOARD
    SingleProjectDashboardComponent,

    ItemStatusesGradientDirective,
  ],
  exports: [
    ChecklistComponent,
    HomeComponent,
    ItemArtifactsComponent,
    ItemResultsComponent,
    ItemsComponent,
    LoginComponent,
    MasterRoomsComponent,
    ProjectComponent,
    ProjectInfoComponent,
    ProjectLayoutsComponent,
    ProjectProgressComponent,
    ProjectResultsComponent,
    ProjectRoomsComponent,
    ProjectUnitsComponent,
    ProjectsComponent,
    SectionsComponent,
    TemplatesComponent,
    UnitResultsComponent,

    // Why do we have to export these things from common? Do we?
    // EditableMarkdownComponent,

    ItemStatusesGradientDirective,
  ],
  providers: [DatePipe],
})
export class PodsModule {}
