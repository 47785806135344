// services/aspects.service.ts
//
// Handle manipulation of "aspects" in "checklists".
// Invoked from `AspectsComponent` via `ChecklistService`.

import {Injectable} from "@angular/core";

// FIREBASE
import {DatabaseReference} from "angularfire2/database-deprecated/interfaces";

// OUR REPO
import {Aspect, moveOrdered, removeOrdered, maxOrdered} from "@nims/red-shared";
import {values, Hash, length} from "@nims/jsutils";
import {once} from "@nims/afutils";

type Aspects = Hash<Aspect>;

@Injectable()
export class AspectsService {
  // Update an aspect.
  public update(ref: DatabaseReference, key: string, data: Partial<Aspect>) {
    return ref.child(key).update(data);
  }

  // create a new aspect.
  public async create(ref: DatabaseReference, term = "aspect") {
    const aspects = await this.get(ref);
    const count = length(aspects);
    const last = maxOrdered(values(aspects));

    const aspect: Aspect = {
      name: `New ${term} ${count + 1}`,
      order: last === -Infinity ? 0 : last + 1,
      description: `New ${term} ${count + 1}`,
    };

    return ref.push(aspect);
  }

  // Move an aspect up or down.
  public async move(ref: DatabaseReference, key: string, delta: number) {
    const aspects = await this.get(ref);

    moveOrdered(values(aspects), aspects[key], delta);
    return this.put(ref, aspects);
  }

  // Remove an aspect, and re-order around it.
  public async remove(ref: DatabaseReference, key: string) {
    const aspects = await this.get(ref);

    removeOrdered(values(aspects), aspects[key]);
    delete aspects[key];

    return ref.set(aspects);
  }

  // Put and get this node.
  private async get(ref: DatabaseReference): Promise<Aspects> {
    return (await once<Aspects>(ref)) || {};
  }
  private async put(ref: DatabaseReference, aspects: Aspects) {
    return ref.set(aspects);
  }
}
